/**
 * 
 */


import {
  Text,
  Flex,
  Card,
  Grid,
} from "@tremor/react";

import { 
  DisplayItem,
  DisplayPumpSpeed,
  DisplaySliderItem,
  DisplayToggleItem,
  DisplaySelectPickerItem,
  DisplayListAccordian,
  Display2ValueToggleItem,
} from "./DisplayItems";

import {
 
  MdSpeed as IconSpeed,
  MdOutlineSettings as IconSettings,
  MdOutlineSchedule as IconClock,
} from "react-icons/md";



import {
  TbEngine as IconPump,
} from "react-icons/tb";


import { COLOURS} from "../styles"
import {
  offOnOptions,
  pumpManualOnOff,
  pumpModelOptionsSelect,
  offOnAutoOptions,
  ReverseDisableEnableOptions
} from "../constants";

import {
  REGS,
} from "../registers";

export const PumpView = props => {
    return (
      
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
          <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-2 space-y-4 text-left">
              <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-2" >
                <Text color={COLOURS.default}>Pump</Text>
              </Flex>

              <Grid
                numItems={2}
                numItemsSm={2}
                numItemsLg={2}
                className="gap-4"
              >
                <DisplayItem register={props.onGetRegisterData(REGS.PUMP_STATE)} icon={IconPump} />
                <DisplayItem register={props.onGetRegisterData(REGS.PUMP_LAST_RUN_TIME)} icon={IconPump} />
                <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_SPEED_TYPE)} icon={IconPump} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff} />
                {/* <DisplayItem register={props.onGetRegisterData(REGISTERS.PUMP_MODEL)} icon={IconPump} /> */}
                <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.PUMP_MODEL)} icon={IconPump} options={pumpModelOptionsSelect} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff} />
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_1)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_2)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_3)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_4)} icon={IconSpeed} min={1000} max={2800} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>

                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_PERCENTAGE_1)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_PERCENTAGE_2)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_PERCENTAGE_3)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplaySliderItem register={props.onGetRegisterData(REGS.PUMP_SPEED_PERCENTAGE_4)} icon={IconSpeed} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_MANUAL_ON_OFF)} icon={IconPump} options={pumpManualOnOff} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                <DisplayPumpSpeed register={props.onGetRegisterData(REGS.WATER_FEATURE_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplayPumpSpeed register={props.onGetRegisterData(REGS.PUMP_DEFAULT_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                <DisplayPumpSpeed register={props.onGetRegisterData(REGS.HEATING_PUMP_CALL_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
              
                <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_VSP_MODE)} icon={IconPump} options={offOnAutoOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                
                <DisplayItem register={props.onGetRegisterData(REGS.PUMP_MANUAL_ON_TIME)} icon={IconClock} />
                <DisplayItem register={props.onGetRegisterData(REGS.PUMP_MANUAL_OFF_TIME)} icon={IconClock} />
                <Display2ValueToggleItem register={props.onGetRegisterData(REGS.PUMP_PRIME_DISABLE)} icon={IconSettings} options={ReverseDisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
              </Grid>
              <DisplayListAccordian register={props.onGetRegisterData(REGS.PUMP_TRIGGER)} icon={IconPump} />
            </div>
          </Card>
          </Grid>
    )
}