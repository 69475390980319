import React, { useState, useEffect } from "react";

import {
  AreaChart as TremorArea,
  // Button,
  Card,
  // Grid,
  //  LineChart,
  Title,
  TabGroup,
  TabList,
  Tab,
  Flex,
  
  Text as TextTremor,
 } from "@tremor/react";
import ResizeObserver from 'resize-observer-polyfill'

import {
  SelectDateRange,
 } from "./Common";

import { paramsColors ,
  paramsColorsORP,
  paramsColorspH
} from "../params";

import { COLOURS} from "../styles"
import {
  orpPhValues,
} from "../constants";
import {
  GiBubbles as IconBubbles,
  GiChemicalDrop as IconChemical,

} from "react-icons/gi";

import {
  // MdOutlineEdit as IconEdit,
  // MdOutlineErrorOutline as IconError,
  // MdOutlineInsertChart as IconTrends,
  // MdOutlineSpaceDashboard as IconDashboard,
  // MdQrCode2 as IconQrCode,
  MdOutlineSync as IconRefresh,
  // MdOutlineAddCircleOutline as IconAdd,
  // MdOutlineViewList as IconList,
  // MdOutlineViewCompact as IconGridCompact,
  // MdOutlineViewModule as IconGridExpanded,
  // MdAdd as IconMore,
  // MdRemove as IconLess,
  // MdOutlineArrowDownward as IconAscending,
  // MdOutlineArrowUpward as IconDescending
} from "react-icons/md";

import {
  Button,
  // ButtonGroup,
  // ButtonToolbar,
  CheckPicker,
  // IconButton,
} from "rsuite";
import { LoadingView } from "./DisplayItems";
// import {
//   ReferenceLine
// } from "reCharts"


export const TrendsView = props => {
  const [graphData, setGraphData] = useState([]);
  const [ processingData, setProcessingData] = useState(false)
  const [ selectableParams, setSelectableParams] = useState([]);
  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }

  useEffect(() => {
    // console.log("graphData Changed changed- ", props.logData, props.configData)
    setProcessingData(true);
    setGraphData(getDataToDisplay(props.logData, props.configData))
    setProcessingData(false);
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.logData, props.configData]);


   useEffect(() => {
    // console.log("graphData Changed changed- ", props.logData, props.configData)
    setProcessingData(true);
    let combinedArrays = [...props.plottableParams,...props.plottableConfigParams];
    setSelectableParams(combinedArrays);
    setProcessingData(false);
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.plottableParams, props.plottableConfigParams]);


  function ChooseGraph() {
    const [selectedValue, setSelectedValue] = useState(orpPhValues.orp);
    
    const pumpCustomToolTip = (props) => {
      const { payload, active } = props;

      const getCategory = (cat) => 
        {
          // console.log( "Data:",cat);
          return cat.tooltip;
        }

      if (!active || !payload) return null;
      return (
        <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown mt-3">
          {payload.map((category, idx) => (
            <div key={idx} className="flex flex-1 space-x-2.5">
              <div
                className={`flex w-1 flex-col bg-${category.payload.color}-500 rounded`}
              />
              <div className="space-y-1">
                {/* <p className="text-tremor-content">{category.dataKey}</p> */}
                <p className="font-medium text-tremor-content-emphasis">
                  {getCategory(category.payload)} 
                </p>
              </div>
            </div>
          ))}
        </div>
      );
    };

    return (

      props.isLoadingOrNoDevice ? (<div></div>) : (
        <div className="space-y-2 ">
          <Card>
            <Flex className="space-x-2 space-y-2" >
              <Title > {selectedValue === orpPhValues.ph ? "pH Readings" : "ORP Readings"} </Title>
              <TabGroup onIndexChange={(value) => setSelectedValue(value)} defaultIndex={orpPhValues.orp} >
                <TabList variant="solid"
                  color="zinc"
                >
                  <Tab
                    icon={IconBubbles}
                    key="orp"
                    index= {orpPhValues.ph}
                    value={orpPhValues.orp}>
                      ORP Readings
                  </Tab>
                  <Tab
                    icon={IconChemical}
                    key="ph"
                    value={orpPhValues.ph}>pH readings
                  </Tab>
                </TabList>
              </TabGroup>
            </Flex>

            { 
              (props.phSelection === false) ? (
                <Flex alignItems="center" className="space-x-2">
                  <TextTremor color={COLOURS.warning}>No pH to Display</TextTremor>
                </Flex>
              ) : (
                (props.logData.length === 0) ? (
                  <TextTremor color={COLOURS.warning}>No data found</TextTremor>
                ) : (
                  <div>
                    <TremorArea
                    data={graphData}
                    index="dateDisplay"
                    categories={selectedValue === orpPhValues.ph ? ["pH","pH Curr", "Ph SetPoint"  ] :  ["ORP (mV)", "ORP Curr (mV)", "ORP Set-Point (mV)"]}
                    colors={selectedValue === orpPhValues.ph ? paramsColorspH : paramsColorsORP}
                    // {paramsColors}
                    showAnimation={true}
                    showLegend={true}
                    // startEndOnly={true}
                    rotateLabelX={{"angle": -45,
                    "verticalShift": 15,
                    "xAxisHeight": 50}}
                    className="mt-6 h-80"
                    tickGap={100}
                    showYAxis={false}
                    curveType = {"monotone"}
                    connectNulls ={true}
                  >
                    </TremorArea>
                    <Title>Pump</Title>
                 

                    <TremorArea
                    data={props.pumpTrackerData}
                    index="dateDisplay"
                    categories={["value"]}
                    colors={["gray"]}
                    minValue={-1}
                    maxValue={6}
                    rotateLabelX={{"angle": -45,
                    "verticalShift": 15,
                    "xAxisHeight": 50}}
                    tickGap={100}
                    showAnimation={true}
                    showLegend={true}
                    showYAxis={false}
                    // startEndOnly={true}
                    className="mt-6 h-40"
                    curveType = {"monotone"}
                    customTooltip={pumpCustomToolTip}
                    connectNulls ={true}
                  />    
                  </div>
                 )
              ) 
             } 
          </Card> 
        </div>


      )
    );
  }

  function getDataToDisplay(  array1, array2)
  {
   
    let combinedArrays = [...array1,...array2];
    // combinedArrays = [...combinedArrays, ...props.applianceDataArray]
    combinedArrays.sort((a,b) => (a.dateTime < b.dateTime?-1:1))
  
    return combinedArrays;
  }

  // let time = new Date().getTime();
  // const setActivityTime = (e) => {
  //   time = new Date().getTime();
  // }
  // document.body.addEventListener("mousemove", setActivityTime);
  // document.body.addEventListener("keypress", setActivityTime);


  // const refresh = () => {
  //   if (new Date().getTime() - time >= 10*60000) 
  //   {
  //     (props.onRefresh())
  //  //   window.location.reload(true);
  //   } 
  //   else 
  //   {
  //     setTimeout(refresh, 10000);
  //   }
  // }

  // setTimeout(refresh, 10000);
  return (
    <div className="mt-3">
      <Flex justifyContent="center">
        <SelectDateRange
          range={props.selectedDateRange}
          maxDays={30}
          //disabledDate={combine(allowedMaxDays(7), afterToday())}
          onChange={props.onSelectedDateRangeChanged}
        />
        {/* <Button
          color={COLOURS.default}
          size="sm"
          onClick={props.onRefresh}
          disabled={(props.selectedDevice.length === 0) || props.loading}>Refresh
        </Button> */}

        <Button
            appearance="primary"
            color="blue"
            endIcon={<IconRefresh />}
            size="sm"
            loading={props.isLoadingOrNoDevice}
            onClick={props.onRefresh}
          >
            Refresh
          </Button>

      </Flex>

    
      {
        (props.isLoadingOrNoDevice ||processingData ? ( <LoadingView progress={props.progress} />) : (props.selectedDevice.length === 0 ? (
          <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <TextTremor color={COLOURS.warning}>Select a controller</TextTremor>
          </Card>
        ) : ("") 
        )
        )
      }
      <div>
        {ChooseGraph()}
        
      </div>
      {
        props.isLoadingOrNoDevice ? (<div></div>) : (
          <div>
        <Flex
          justifyContent="justify-start"
          spaceX="space-x-2"
        >
          <CheckPicker
            data={selectableParams.map((param) => ({
              label: param.text,
              value: param.text
            }))}
            value={props.selectedParams}
            onChange={props.onSelectedParamsChanged}
            placeholder="Select parameters"
            style={{ width: '100%' }}
            disabled={props.loading}
          />
          </Flex>
        <Card
          decoration="bottom"
          decorationColor={COLOURS.cardDecoration}
          className="mt-3">
          {
            // (props.pool.length === 0) ? (
            //   <TextTremor color={COLOURS.warning}>No data found</TextTremor>
            // ) :
            (
              <div>
             
                <TremorArea
                  data={graphData}
                  index="dateDisplay"
                  categories={props.selectedParams}
                  //"Pool", "Roof","Water","Pool SetPoint", "Spa SetPoint", "Other Temperature", "Solar SetPoint", "Appliance 13", "Appliance 14"]
                  colors={paramsColors}
                  showAnimation={true}
                  showLegend={true}
                  startEndOnly={true}
                  className="mt-6 h-80"
                  connectNulls ={true}
                  />
              </div>
            )
          }
        </Card>
        </div>
        )

      }
    </div>
  )
};

 {/* <AreaChart width="100%" height={400} 
                data={props.logData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="dateDisplay" />
                <YAxis />
              
                <Tooltip />
                <Area type="monotone" dataKey="Pool" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="Roof" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                <Area type="monotone" dataKey="Water" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart> */}