import React, { useState, useEffect } from "react";
import AppLayout from '@cloudscape-design/components/app-layout';
import TopNavigation from '@cloudscape-design/components/top-navigation';
// import { SideNavigation } from '@cloudscape-design/components';

import { paramsConfig, paramsNames, param_t, pumpStatecolours, pumpStateStrings, applianceStatecolours } from "./params";
import {
  readAllMessage,
  registersInit,
  REGS,
  RT
} from "./registers";
import {  getCurrentUser, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Hub , I18n} from 'aws-amplify/utils';
import { CONNECTION_STATE_CHANGE , ConnectionState} from  "@aws-amplify/pubsub";

import { pubsub } from "./utils/pubsub";
import { ConsoleLogger } from 'aws-amplify/utils';
import { generateClient } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';

import config from './amplifyconfiguration.json';
import {
  Heading,
  Image,
  Text,
  useTheme,
  View,
  Authenticator, 
} from "@aws-amplify/ui-react";

import {    deleteDevice,updateDevice , createDevice} from "./graphql/mutations";

import {
  getFaultTableItem,
  getUIDFromDb,
  getIDSFromDb,
  getShortIDFromUID,
  getRecordIDFromUID,
  getRecordIDFromUIDNoDT,
  getDateTimeFromRecordID,
  getMacAddressFromUID,
  getModelTypeValueFromUID,
  getMonthYearFromDate,
  speedHasElement,
  isCurrentFaultsARealFault,
} from "./components/Common"

import {
  compareAsc,
  differenceInDays,
  endOfDay,
  endOfToday,
  formatISO9075,
  lightFormat,
  startOfDay,
  startOfYesterday,
  // parseISO
} from "date-fns";

import { DeviceView } from "./components/DeviceView";
import { HomeView } from "./components/HomeView";
import {
  Grid
} from "@tremor/react";

import "@aws-amplify/ui-react/styles.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";

import { listDevices, getDevice } from "./graphql/queries";
import banner_w from "./banner_w.png";
import banner from "./banner.png";


import {
  format, 
  formatTemperature, 
  getEnabledState,
  getLightNumber,
} from "./components/RegistersHandler";

import {
  MdCheckCircleOutline,
  MdErrorOutline,
  MdSensorsOff,
} from "react-icons/md";

import { getRandomIntInclusive } from "./utils/utilities";
import {
  accessLevels,
  datetimeFormats,
  faultView,
  faultTable,
  statusItems,
  displayViews,
  modelTypeItems,
  modelTypeDetails,
  sortKeys,
  sortOrder,
  tabItems,
  upgradeResultCodes_t,
  FIRMWARE_VERSIONS,
  RECORD_TYPES,
} from "./constants";

import { COLOURS} from "./styles";

import { canIaccessController } from "./components/AccessControl";
const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

import {fetchUserAccessRecord} from "./components/UserAccess"

export const client  = generateClient();

import { apiLimit } from "./constants";

const profileActions = [
  // { type: 'button', id: 'profile', text: 'Profile' },
  // { type: 'button', id: 'preferences', text: 'Preferences' },
  // { type: 'button', id: 'security', text: 'Security' },
  // {
  //   type: 'menu-dropdown',
  //   id: 'support-group',
  //   text: 'Support',
  //   items: [
  //     {
  //       id: 'documentation',
  //       text: 'Documentation',
  //       href: '#',
  //       external: true,
  //       externalIconAriaLabel: ' (opens in new tab)',
  //     },
  //     { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
  //     { id: 'support', text: 'Customer support' },
  //   ],
  // },
  { type: 'button', id: 'signout', text: 'Sign out' },
];


export const timer = (ms) => (new Promise(res => setTimeout(res, ms)));

const authComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View backgroundColor={tokens.colors.white} textAlign="center" padding={tokens.space.medium}
      border="1px solid var(--amplify-components-authenticator-router-border-color)">
        <Image src={banner} alt="Logo" />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    const year = new Date().getFullYear();
    return (
      <View backgroundColor={tokens.colors.black} textAlign="center" padding={tokens.space.medium}
      border="1px solid var(--amplify-components-authenticator-router-border-color)">
        <Text color={tokens.colors.white} fontSize="0.7em">
          &copy; {year} Dontek Electronics Pty Ltd
        </Text>
      </View>
    );
  },
  ResetPassword: {
    Header() {
      return (
        <Heading level={5}>Reset Password</Heading>
      )
    }
  }
};

// global.ResizeObserver = jest.fn().mockImplementation(() => {
//   return {
//     observe: jest.fn(),
//     disconnect: jest.fn(),
//     unobserve: jest.fn()
//   }
// })

Amplify.configure(config);

// function App({ signOut, user }) {
//   return (
//     <>
//       <h1>Hello {user.username}</h1>
//       <button onClick={signOut}>Sign out</button>
//     </>
//   );
// }

I18n.putVocabularies({
  en: {
    'Custom auth lambda trigger is not configured for the user pool.': 'Password cannot be empty'
  }
});



const initPSWURLS = ['https://dontekcontrol.com/ota/Dontek_PSW_v1_15_B14.bin',
                     'https://dontekcontrol.com/ota/Dontek_PSW_V1_15_B82.bin',
                     'https://dontekcontrol.com/ota/Dontek_PSW_v1_15_B13.bin',
                     ];

const initACURLS = [ 'https://dontekcontrol.com/ota/EP_v1_02_B01.bin'];
 

function App() {
  window.ResizeObserver = undefined;
  const [loginId, setLoginId] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [accessLevel, setAccessLevel] = useState(accessLevels.guest);
  const [devices, setDevices] = useState([]);
  // const [params, setParams] = useState(paramsConfig);
  const [loading, setLoading] = useState(false);
  const [loadingLogs, setLoadingLogs ] = useState(false);
  const [loadingConfig, setLoadingConfig ] = useState(false);
  
  const [progress, setProgress] = useState(0);
  const [logDevice, setLogDevice] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedModel, setselectedModel] = useState(0);
  
  const [selectedView, setSelectedView] = useState(0);
  const [selectedFaultView, setSelectedFaultView] = useState(0);
  // const [selectedLatestFaults, setSelectedLatestFaults] = useState([]);
  const [selectedHistoryFaults, setSelectedHistoryFaults] = useState([]);

  const [selectedDevices, setSelectedDevices] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState([startOfYesterday(), endOfToday()]);
  const [selectedParams, setSelectedParams] = useState([paramsConfig[0].text]);
  // const [latestFaults, setLatestFaults] = useState([]);
  const [historyFaults, setHistoryFaults] = useState([]);
  const [showDevice, setShowDevice] = useState(false);
  const [notes_data, setNotesData] = useState([]);


  const [macAddress, setMacAddress] = useState("");
  const [devTopics, setDevTopics] = useState([]);
  const [devSubscription, setDevSubscription] = useState(null);
  const [registerData, setRegisterData] = useState(registersInit);
  const [connectionState, setConnectionState] = useState({
    state: "Disconnected",
    ready: false
  });

  const [accessableGroups, setGroupAccess] = useState([]);
  const [accessableDevices, setAccessableDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedShortID, setShortID] = useState('');
  const [logData, setLogData] = useState([]);
  const [applianceTrackerDataArray, setApplianceTrackerData] = useState([]);
  const [pumpTrackerData, setPumpTrackerData] = useState([]);
  const [ORPPHLabelsData, setORPPHLabels] = useState([]);
  const [ORPData, setORPData] = useState([]);
  const [PHData, setPHData] = useState([]);
  const [ORPRTData, setORPRTData] = useState([]);
  const [PHRTData, setPHRTData] = useState([]);
  const [god_mode, setGodMode] = useState(false);
  
  // const [loaded, setLoaded] = useState(false)
  const [syncing, setSyncing] = useState(false);

  const [installers, setInstallers] = useState([]);
  const [installedBy, setInstalledBy] = useState([]);

  const [selectedSortKey, setSelectedSortKey] = useState(sortKeys.name);
  const [selectedSortOrder, setSelectedSortOrder] = useState(sortOrder.ascending);

  const [currentDevice, setCurrentDevice] = useState('')
  const [activeKey, setActiveKey] = useState(tabItems.home);
  const [editOff, setEditOff] = useState(true);
  const [showArchivedDevices, setShowArchivedDevices] = useState(false);
  const [devicesStats, setDevicesStats] = useState({});
  const [configData, setConfigData] = useState([]);

  const [plottableParams, setPlottableParams] = useState(paramsConfig);
  const [plottableConfigParams, setPlottableConfigParams] = useState([]);

  let phSelection = true;
  let ORPSelection = true;

  const [configuredAppliances, setConfiguredAppliance] = useState('');

  const [upgradeFile, setUpgradeFile] = useState({
    selectedFile: null,
    selectedFileName: "https://dontekcontrol.com/ota/Dontek_PSW_v1_15_B14.bin"
  });
  const [upgradeACFile, setUpgradeACFile] = useState({
    selectedFile: null,
    selectedFileName: "https://dontekcontrol.com/ota/EP_v1_02_B01.bin"
  });

  const [upgradeSHFile, setUpgradeSHFile] = useState({
    selectedFile: null,
    selectedFileName: "http://10.34.4.102:8000/SH_v1_02_B01.bin"
  });

  const [ PSWUpgradeURLS, setPSWUpgradeURLS] = useState(initPSWURLS) 
  
  const [ AquaChemUpgradeURLS, setAquaChemUpgradeURLS] = useState(initACURLS)
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [updateInProgressAC, setACUpdateInProgress] = useState(false); 
  const [updateInProgressSH1, setSH1UpdateInProgress] = useState(false); 
  const [updateInProgressSH2, setSH2UpdateInProgress] = useState(false); 
  const isApplianceConfigured = (id_to_check) => {

    var result = false;
    if (configuredAppliances.includes(id_to_check)) {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  };

  const getAppliances_with_id = (appliance_array, id_to_check) => {

    let return_appliances = appliance_array.filter(appliance_array => appliance_array.id === id_to_check)

    if (return_appliances.length !== 0) {
      return return_appliances;
    }
    else {
      return null;
    }
  };

  const pause = () => {
    return new Promise(r => setTimeout(r, 0))
  }

  const getTrackerdataForAppliances_with_id = (appliance_array, id_to_check) => {
    let apppliance_data = getAppliances_with_id( appliance_array, id_to_check)

    if ( apppliance_data === null)
    {
      // console.log ( "No Data for app: ", id_to_check);
    }
    else
    {
      var applianceTrackerBlocks = []
      var trackerBlock = {}
      var offset = 0;

      apppliance_data.forEach ((item)=>
      {
        trackerBlock = {}
        trackerBlock.key = String(offset);
        trackerBlock.color = item.colour;
        trackerBlock.tooltip = item.DisplayString;
        
        offset++;
        applianceTrackerBlocks.push ( trackerBlock);
      });
      // console.log ( "Appliance items:", applianceTrackerBlocks);
    }
    return applianceTrackerBlocks;
  }
  const getPumpTrackerdata = (pump_array) => {
    //let apppliance_data = getAppliances_with_id( appliance_array, id_to_check)

    if ( pump_array !== null)
    {
      var pumpTrackerBlocks = []
      var trackerBlock = {}
      var offset = 0;

      pump_array.forEach ((item)=>
      {
        trackerBlock = {}
        trackerBlock.key = String(offset);
        trackerBlock.color = item.colour;
        trackerBlock.tooltip = item.DisplayString;
        trackerBlock.dateDisplay = item.dateString;
        trackerBlock.value = item.value;
        offset++;
        pumpTrackerBlocks.push ( trackerBlock);
      });
      // console.log ( "Appliance items:", applianceTrackerBlocks);
    }
    return pumpTrackerBlocks;
  }
  

  const handleSelectedDevice = (device) => {
    setSelectedDevice(device);
    console.log("HSD");
    if ( device !== '')
    {
      setShortID(getShortIDFromUID(device))
    }
    else
    {
      setShortID('');
    }
}


  const refreshData = () => {

    if ( selectedDevice === null || selectedDevice === undefined )
    {
      console.log("No controller defined ");
    }
    else if (selectedDevice.length === 0 )
    { 
      console.log("No controller selected");
    } 
    else 
    {

      fetchSetPointData(selectedDevice, selectedDateRange);
      fetchLogData(selectedDevice, selectedDateRange);
    }
  }


  const refreshFaultsData = () => {

    if ( selectedDevice === null || selectedDevice === undefined )
    {
      console.log("No controller defined ");
    }
    else if (selectedDevice.length === 0 )
    { 
      console.log("No controller selected");
    } 
    else 
    {
      fetchFaultsData(selectedDevice, selectedDateRange);
    }
  }

  const is_loading_or_no_device = () => {
    var result = false

    if (loading) {
      result = true;
    }
    if ( loadingLogs)
    {
      result = true;
    }
    if ( loadingConfig)
    {
      result = true;
    }

    if (result === false && selectedDevice.length === 0)
    {
      //   console.log("no device")
      result = true;
    }
    return result;
  };


  const refreshDataByDate = (range) => {
    setSelectedDateRange(range);
    if (selectedDevice.length === 0) {
      console.log("No controller selected");
    } else {

      fetchSetPointData(selectedDevice, range);
      fetchLogData(selectedDevice, range);
    }
  }


  const refreshFaultDataByDate = (range) => {
   
   
    console.log("refreshFaultDataByDate", range)  ;
    setSelectedDateRange(range);
    if (selectedDevice.length === 0) {
      console.log("No controller selected");
    } else {
      fetchFaultsData(selectedDevice, range);
    }
  }

  const updateSelectedDevices = (value) => {
    setSelectedDevices(value);
    if (value.length === 0) {
      handleSelectedDevice('');
    } else if (value.length === 1) {
      handleSelectedDevice(value[0]);
    }
  }
  const addPSWUrl = (value) => {

 
    setPSWUpgradeURLS([...PSWUpgradeURLS, value ]);
  }

  const addACUrl = (value) => {

 
    setAquaChemUpgradeURLS([...AquaChemUpgradeURLS, value ]);
  }

  

  const sortDevices = (allDevices, accLvl, key, order) => {
    //console.log("key:", key, "order:", order);
    let doUpdate = true;
    const tempDevices = allDevices;
    // if(accLvl === accessLevels.guest) {
    //   tempDevices.sort((a, b) => (a.guestName.toLowerCase() < b.guestName.toLowerCase() ? -1 : 1));
    // } else {
      switch(key) 
      {
        case sortKeys.uid:
          if(order === sortOrder.ascending) {
            tempDevices.sort((a, b) => (a.uniqueDeviceId < b.uniqueDeviceId ? -1 : 1));
          } else { // descending
            tempDevices.sort((a, b) => (a.uniqueDeviceId < b.uniqueDeviceId ? 1 : -1));
          }
          break;
        case sortKeys.type:
          if(order === sortOrder.ascending) {
            tempDevices.sort((a, b) => (a.modelType < b.modelType ? -1 : 1));
          } else { // descending
            tempDevices.sort((a, b) => (a.modelType < b.modelType ? 1 : -1));
          }
          break;

        case sortKeys.name:
          if(order === sortOrder.ascending) {
            tempDevices.sort((a, b) => (a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1));
          } else { // descending
            tempDevices.sort((a, b) => (a.userName.toLowerCase() < b.userName.toLowerCase() ? 1 : -1));
          }
          break;

        case sortKeys.status:
          if(order === sortOrder.ascending) {
            tempDevices.sort((a, b) => (a.statusDT < b.statusDT ? -1 : 1));
          } else { // descending
            tempDevices.sort((a, b) => (a.statusDT < b.statusDT ? 1 : -1));
          }          
          break;

        default:
          console.log("Invalid sort key:", key);
          doUpdate = false;
          break;
      }
    // }
    if(doUpdate) 
    {
      setDevices(tempDevices);
    }
  }

  const handlerSortChanged = (id, key, order) => {
    //console.log("id:", id, "key:", key, "order:", order);
    if(id === key) {
      order = (order === sortOrder.ascending) ? sortOrder.descending : sortOrder.ascending
    } else {
      key = id;
      order = sortOrder.ascending;
    }
    sortDevices(devices, accessLevel, key, order);
    setSelectedSortKey(key);
    setSelectedSortOrder(order);
  }

  async function writeRegister(address, value) {
    const topic = "dontek" + macAddress + "/cmd/psw"
    const message = { messageId: "write", modbusReg: address, modbusVal: [value] };
    await pubsub.publish(
    { 
      topics: topic,
      message: message,
      options: { provider: 'AWSIoTProvider' }
    });

    console.log(topic, "=>", message);
  }


  async function writeRegisters(StartAddress, values) {
    const topic = "dontek" + macAddress + "/cmd/psw"
    const message = { messageId: "write", modbusReg: StartAddress, modbusVal: values };
    await pubsub.publish(
    { 
      topics: topic,
      message: message,
      options: { provider: 'AWSIoTProvider' }
    });

    console.log(topic, "=>", message);
    sleep(250);
  }

  async function writePasswordRegister(address, value) {
    // const topic = "dontek" + macAddress + "/cmd/psw"
    let password = "_d0nt3k2K19"

    writeValueStringRegister(address, value, password);

    // const message = { messageId: "write", modbusReg: address, modbusVal: [value], valueString: password };
    // // await pubsub.publish(topic, message);
    // await pubsub.publish(
    //   //topic, message);
    // { 
    //   topics: topic,
    //   message: message
    // });

    // console.log(topic, "=>", message);
  }


  async function writeValueStringRegister(address, value, valueString)
   {
    const topic = "dontek" + macAddress + "/cmd/psw"
   
    const message = { messageId: "write", modbusReg: address, modbusVal: [value], valueString: valueString };
    // await pubsub.publish(topic, message);
    await pubsub.publish(
      //topic, message);
    { 
      topics: topic,
      message: message
    });

    console.log(topic, "=>", message);
  }

  async function fetchNotes(deviceUID)
  {
    let nextToken = null;
    let noteItems = [];
    let notesRecordID = "UID_"+deviceUID;

    console.log("Starting Fetch Devices Notes for device ", deviceUID, notesRecordID);
    do {
      try {
        const eventFromAPI = await client.graphql({
          query: listDevices,
          variables: {
            recordType: RECORD_TYPES.NOTES,
            recordID:{ beginsWith: notesRecordID },
            nextToken,
            limit: apiLimit
          }
        });
        noteItems = noteItems.concat(eventFromAPI.data.listDevices.items);
        nextToken = eventFromAPI.data.listDevices.nextToken;
      } catch(error) {
        console.log("fetchNotes:", error);
        await timer(1000);
      }
    } while(nextToken != null);

    console.log("Fetched Devices Notes", noteItems , "for device ",deviceUID);
    // Probably don't need this as query now chagned to beginsWith.
    let ourNotes = noteItems.filter((e) => getIDSFromDb(e.recordID).longUID === deviceUID )
    console.log("ourNotes", ourNotes);
    setNotesData(ourNotes);
  }


  
  const updateDeviceLocalArchiveStatus = (deviceId, archived) => {
    const index = devices.findIndex((d) => d.uniqueDeviceId === deviceId);
    if(index !== -1) 
    {
      const tempDevices = [...devices];
      tempDevices[index].archived = archived;
     
      sortDevices(tempDevices, 0, selectedSortKey, selectedSortOrder);
      console.log("updateDeviceLocalArchiveStatus:", deviceId, "=", archived);
    } else {
      console.log("updateDeviceLocalArchiveStatus: ", deviceId, " NOT found");
    }
  }

  const updateLocalDeviceName = (deviceId, name) => {
    const index = devices.findIndex((d) => d.uniqueDeviceId === deviceId);
    if(index !== -1) {
      const tempDevices = [...devices];
      tempDevices[index].userName = name;
     
      sortDevices(tempDevices, 0, selectedSortKey, selectedSortOrder);
      console.log("updateDeviceName:", deviceId, "=", name);
    } else {
      console.log("updateDeviceName: ", deviceId, " NOT found");
    }
  }

  const updateLocalUserDeviceName = (deviceId, name) => {
    const index = devices.findIndex((d) => d.uniqueDeviceId === deviceId);
    if(index !== -1) {
      const tempDevices = [...devices];
      tempDevices[index].usersDeviceName = name;
     
      sortDevices(tempDevices, 0, selectedSortKey, selectedSortOrder);
      console.log("updateUserDeviceName:", deviceId, "=", name);
    } else {
      console.log("updateUserDeviceName: ", deviceId, " NOT found");
    }
  }


  
  async function updateDeviceName(deviceId, name , type) {
    let record_ID = getRecordIDFromUID( deviceId);

   console.log ( "Info record update device name :", record_ID, loginId, RECORD_TYPES.INFO, name, type );
   try {
    const result = await client.graphql({
      
      query: updateDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.INFO,
          recordID: record_ID, 
          
          userName: name,
          modelType: type
        }
      }
    });
    const { data } = result;
    if(data.updateDevice.userName === name) {
      updateLocalDeviceName(deviceId, name);
    } else {
      console.log("edit: name NOT updated ->", data);
    }
  } catch(error) {
    console.log("updateDEvice Ne:", error);
    await timer(1000);
  }
   
  }

  async function updateUserDeviceName( deviceId, name, devType) 
  {
    let record_ID = getRecordIDFromUID( deviceId);
    console.log ( "**** update User device name :", name,deviceId , record_ID, loginId);
    try
    {
  
      const getAccessResult = await fetchUserAccessRecord(loginId,deviceId)
      let result = null;
      if ( getAccessResult !== null)
      {
        console.log("Updating User Device Name");
        result = await client.graphql({
          query: updateDevice,
          variables: {
            input: {
            recordType: RECORD_TYPES.ACCESS + loginId,
            recordID: record_ID, 
            userName: name,
            },
            
          }
        });
        const { data } = result;

        if(data.updateDevice.userName === name) {
           updateLocalUserDeviceName(deviceId, name);
        } else {
           console.log("edit: user name NOT updated ->", data);
        }
      }
      else
      {
        console.log("Creating new record for User Device Name");
        result = await client.graphql({
          query: createDevice,
          variables: {
            input: {
              recordType: RECORD_TYPES.ACCESS + loginId,
              recordID: record_ID, 
              userName: name,
            }
          }
        });
       
      }
  
      
     
    } catch(error) {
      console.log("Update  User Device Name Error:", error);
      await timer(250);
    }
  }



  function isProductionTestInProductionNotCompleted( tempDevice)
  {
      var result = false;

      if ( tempDevice.productionTest !== undefined && tempDevice.productionTest !== null)
      {
        if ( tempDevice.productionTest !== "Completed")
        {
            result = true;
          
        }
      } 
      return result;
     
  }



  const updateConnStatus = ( tempDevice , theEvent) =>
  {
    if (theEvent.updatedAt === undefined ||theEvent.updatedAt === null || theEvent.updatedAt === 0 || theEvent.updatedAt === "0")
    {
      let date = new Date();
      tempDevice.connStatDate = lightFormat(date, datetimeFormats.long);
    }
    else
    {
      let date = new Date(theEvent.updatedAt);
      try
      {
        tempDevice.connStatDate = lightFormat(date, datetimeFormats.long);
      }
      catch (error) {
        console.log("invalid time format :", theEvent);
      }
    }

    if (theEvent.connectionStatus === undefined 
      ||theEvent.connectionStatus === null)
    {
       tempDevice.connStatDesc = "Undefined"
    }
    else
    {
      let connStat = theEvent.connectionStatus.toUpperCase();
      tempDevice.connStatDesc = connStat;
    }
    
    // if ( isProductionTestInProductionNotCompleted( tempDevice) )
    // {
    //   tempDevice.inProduction = true;
    // }
    
    if (tempDevice.connStatDesc === "ONLINE") 
    {
      tempDevice.connStatColor = COLOURS.good;
      tempDevice.connStatIcon = MdCheckCircleOutline;
    } 
    else 
    {
      tempDevice.connStatColor = COLOURS.warning;
      tempDevice.connStatIcon = MdSensorsOff;
    }
    return tempDevice;
  }


  
  async function doGetTheSignedInUser()
  {
    var theSignedInDetails = null
    try {
      const { signInDetails} = await getCurrentUser();
      if(signInDetails === undefined) {
        signOut();
      } else {
        setLoginId(signInDetails.loginId)
        console.log( "Got the signed in user :", signInDetails)
        theSignedInDetails = signInDetails;
      }
    } catch(err) {
      console.log(err);
    }
    return theSignedInDetails;
  } 

  // eslint-disable-next-line
  async function deleteEventRecord(record_ID ) {    
    const result = await client.graphql({
      query: deleteDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.EVENT,
          recordID: record_ID
        }
      }
    });
    console.log("Deleting Event record", record_ID, result)

  }

  const isValidVersionNumber = (loopDevice) =>
  {
    var versionNumberValid = true;
    if ( loopDevice.versionNumber === null
      || loopDevice.versionNumber === undefined
      || loopDevice.versionNumber === "undefined"
      || loopDevice.versionNumber === "Undefined"
      || loopDevice.versionNumber === "")
      {
        versionNumberValid = false;
      }
    return versionNumberValid
  }


  function doSetLoading(valueToSet)
  {
     setLoading(valueToSet);
     console.log ( "***** Set Loading ", valueToSet, "   *****")
  }


  function doSetLoadingLogs(valueToSet)
  {
     setLoadingLogs(valueToSet);
     console.log ( "***** Set Loading Logs", valueToSet, "   *****")
  }

  function doSetLoadingConfig(valueToSet)
  {
     setLoadingConfig(valueToSet);
     console.log ( "***** Set Loading Configuration", valueToSet, "   *****")
  }


  async function fetchDeviceAccess()
  {
    setGodMode(false);
    doSetLoading(true)
    setProgress(0);
    setInstallers( []);
    setInstalledBy( []);
    setAccessableDevices([]);

    let nextToken = null;
    let tmpGodMode = false;
    let accessItems = [];
    let DeviceAccess = [];
    let tmpDeviceAccess = [];
    let tmpGroupAccess = [];
    let tmpInstallers = [];
    const typeProgress = 16; // 100% / 5 type of queries
    const deltaMax = 4; // 1-4% per query

    let currProgress = typeProgress;
    let tempProgress = 0;

    do {
      try {
        const infoFromAPI = await client.graphql({
          query: listDevices,
          variables: {
            recordType: RECORD_TYPES.ACCESS+loginId,  
            nextToken,
            limit: apiLimit
          }
        });
        
        DeviceAccess = accessItems.concat(infoFromAPI.data.listDevices.items);
        nextToken = infoFromAPI.data.listDevices.nextToken;
        setProgress(tempProgress++);
      } catch(error) {
        console.log("Fetching Access ", error);
        await timer(200);
      }
    } while(nextToken != null);
   
    setProgress(0);
   
    console.log("Getting Access Levels", DeviceAccess);
    
    if ( DeviceAccess.length )
    {
      DeviceAccess.forEach ((item)=>
      { 
        if ( getUIDFromDb(item.recordID) === '0000000000000000')
        {
          tmpGroupAccess.push( item.group)
          console.log("group: ", item.group);

          if ( item.group === "god_mode")
          {
            tmpGodMode = true;
          }
        }
        else
        {
          let UDeviceId = getUIDFromDb(item.recordID);
          let usersDeviceName = item.userName
          var tempDeviceAccessDevice = {};
          
          if ( usersDeviceName === null || usersDeviceName === undefined)
          {
            usersDeviceName = "Undefined";
          }
          else
          {
            console.log("Users Device Name Set:", usersDeviceName, item )
          }
          tempDeviceAccessDevice.usersDeviceName = usersDeviceName;
          tempDeviceAccessDevice.UID = UDeviceId;
          // console.log("device: ", UDeviceId);
          tmpDeviceAccess.push(tempDeviceAccessDevice);
        }
      });
    }
    setGodMode(tmpGodMode);
    setGroupAccess(tmpGroupAccess);
    setAccessableDevices(tmpDeviceAccess);
    console.log ( "Device Access:", tmpDeviceAccess);
    console.log ( "Getting Installers...");

    do {
      try {
        const infoFromAPI = await client.graphql({
          query: listDevices,
          variables: {
            recordType: RECORD_TYPES.INSTALLER,  
            nextToken,
            limit: apiLimit
          }
        });
       
        tmpInstallers = accessItems.concat(infoFromAPI.data.listDevices.items);
        nextToken = infoFromAPI.data.listDevices.nextToken;
        if(tempProgress < currProgress) {
          tempProgress += getRandomIntInclusive(1, deltaMax);
        }
        setProgress(tempProgress);
      } catch(error) {
        console.log("fetching installers ", error);
        await timer(200);
      }
    } while(nextToken != null);
    
    if ( tmpInstallers.length )
    {
      tmpInstallers.forEach ((item)=>
      { 
        if ( item.recordID.startsWith("IID"))
        {
           //this is a newer record
           setInstallers(  {installerArry: [...installers, item] } ) 
        }
        else if ( item.recordID.startsWith("UID"))
        {
          setInstalledBy(  {installerArry: [...installedBy, item] } ) 
        }
      });
    }
  }

  async function getInfoRecordForDevice(deviceUID)
  {

      try {
        const infoFromAPI = await client.graphql({
          query: listDevices,
          variables: {
            recordType: RECORD_TYPES.INFO,
            recordID: {beginsWith: infoID } ,
            nextToken,
            limit: apiLimit
          },
        
        });
        
       
      } catch(error) {
        console.log("fetchInfo:", error);
        await timer(200);
      }
  }
    



  async function fetchDevices() 
  {
   
    console.log("Getting groups", userGroups);

    let buildInfoItems = [];
    let buildEventItems = [];
    let nextToken = null;
    
    let accLvl = accessLevels.guest;
    if(userGroups.includes('Admin')) 
    {
      if(!userGroups.includes('guest')) 
      {
        accLvl = accessLevels.super;
      }
    } 
    if(accLvl === accessLevels.guest) {
      // setLogParams(paramsGuest);
     // console.log("Guest")
      // setSelectedTab(tabItems.trends);
    }

    const typeProgress = 20; // 100% / 5 type of queries
    const deltaMax = 4; // 1-4% per query


    setAccessLevel(accLvl);
    setDevices([]);
    doSetLoading(true)
    setProgress(0);

    let currProgress = typeProgress;
    let tempProgress = 0;
    
    if ( god_mode)
    {
      console.log("Getting Info Records");
      do {
        try {
          const infoFromAPI = await client.graphql({
            query: listDevices,
            variables: {
              recordType: RECORD_TYPES.INFO,
              nextToken,
              limit: apiLimit
            },
           
          });
          
          buildInfoItems = buildInfoItems.concat(infoFromAPI.data.listDevices.items);
          nextToken = infoFromAPI.data.listDevices.nextToken;
          
          if(tempProgress < currProgress) {
            tempProgress += getRandomIntInclusive(1, deltaMax);
          }
          setProgress(tempProgress);

        } catch(error) {
          console.log("fetchInfo:", error);
          await timer(200);
        }
      } while(nextToken != null);
      console.log("Got Info Records: ", buildInfoItems.length,"Progress:", tempProgress, "/", currProgress);
      tempProgress = currProgress;
      currProgress += typeProgress;
      
      console.log("Getting Event Records");
      do {
        try {
          const eventFromAPI = await client.graphql({
            query: listDevices,
            variables: {
              recordType: RECORD_TYPES.EVENT,
              nextToken,
              limit: apiLimit
            }
          });
          buildEventItems = buildEventItems.concat(eventFromAPI.data.listDevices.items) ;
          nextToken = eventFromAPI.data.listDevices.nextToken;
          if(tempProgress < currProgress) {
            tempProgress += getRandomIntInclusive(1, deltaMax);
          }
          setProgress(tempProgress);
        } catch(error) {
          console.log("fetch event:", error);
          await timer(1000);
        }
      } while(nextToken != null);
      console.log("Got Event Records: ", buildEventItems.length,"Progress:", tempProgress, "/", currProgress);
    }
    else //godMode
    {
        var itr = 0;
        for ( itr = 0; itr < accessableDevices.length;itr++)
        {
          let deviceUID = accessableDevices[itr].UID;
          let recordID = getRecordIDFromUID(deviceUID).toString();
          let shortID = getShortIDFromUID(deviceUID).toString();
          try {
             const infoIFromAPI = await client.graphql({
              query: getDevice,
              variables: {
                recordType: RECORD_TYPES.INFO,
                recordID: recordID ,
                nextToken,
                limit: apiLimit
              }
            });
            
            // console.log( "info FROM API", infoIFromAPI)
            if ( infoIFromAPI.data.getDevice === null)
            {
              //Record not found... Try the short ID?
              let infoID = "UID_"+shortID;
              console.log( "infoID:", infoID)
              const infoIFromAPI2 = await client.graphql({
                query: listDevices,
                variables: {
                  recordType: RECORD_TYPES.INFO,
                  recordID: {beginsWith: infoID } ,
                  nextToken,
                  limit: apiLimit
                }
              });
              console.log( "info FROM API2", infoIFromAPI2)

              infoIFromAPI2.data.listDevices.items[0].recordID = recordID;

              buildInfoItems = buildInfoItems.concat(infoIFromAPI2.data.listDevices.items[0]);
            }
            else
            {
              buildInfoItems = buildInfoItems.concat(infoIFromAPI.data.getDevice);
            }
            // {beginsWith: "UID_53161124983816"},
            let eventID = recordID;
            // console.log( "EvnetID:", eventID)
            const eventItemFromAPI = await client.graphql({
              query: listDevices,
              variables: {
                recordType: RECORD_TYPES.EVENT,
                recordID: {beginsWith: eventID } ,
                nextToken,
                limit: apiLimit
              }
            });
            
            buildEventItems = buildEventItems.concat(eventItemFromAPI.data.listDevices.items) ;
            
          } catch(error) {
            console.log("fetchInfo:", error);
            
          }
      }
      console.log( "info record:", buildInfoItems, buildEventItems)
      // )};
    }
    console.log( "Adding ID's to info record:")
    const infoItemsWithUID = buildInfoItems.map(infItem => {
        
      let IDs = getIDSFromDb(infItem.recordID);
      // infItem.shortDeviceId = IDs.shortUID;
      infItem.uniqueDeviceId = IDs.longUID;
      return infItem;
     
    });
    // Re-render with the new array
    buildInfoItems = infoItemsWithUID;
    tempProgress = currProgress;
    currProgress += typeProgress;
    setProgress(tempProgress);
    console.log( "Adding ID's to event record:")

    const eventItemsWithUID = buildEventItems.map(evntItem => {
        
      let IDs = getIDSFromDb(evntItem.recordID);
      // evntItem.shortDeviceId = IDs.shortUID;
      evntItem.uniqueDeviceId = IDs.longUID;
      return evntItem;
     
    });
    // Re-render with the new array
    buildEventItems = eventItemsWithUID;
    
    console.log( "Done IDS:", buildInfoItems.length, buildEventItems.length)

    tempProgress = currProgress;
    currProgress += typeProgress;
    setProgress(tempProgress);
    var allDevices = [];
    console.log("Started Processing Records");
  
    const recordErrors = 
    {
      duplicatesFound:[],
      userNameNotFound:[],
      infoItemsNotFound: [],
      evntsNotFound: [],
      invalidDates: [],
      inProduction: [],
    };

    const stats = {
      devicesTotal : 0,

      devicesRegistered: 0,
      devicesNotRegistered: 0,
      devicesUnRegistered:0,
      devicesinProduction:0,

      devicesStatusGood : 0,
      devicesStatusFault : 0,
      devicesStatusOffline : 0,
      devicesStatusUnknown : 0,

      totalFaults:0,
      faults : [],
      // modes: [],
      versions: [],
      modelType: [],
      createdMonth: [],
      // modeByLocation: []
    }

    FIRMWARE_VERSIONS.forEach((element) => {
      stats.versions.push({ name: element.name, value: 0, colour: element.colour });
    });

    stats.versions.push({ name: "NOT SET", value: 0, colour: COLOURS.unknown});

    modelTypeDetails.forEach((element) => {
      stats.modelType.push({ name: element.label, value: 0, colour: element.colour });
    });

    faultTable.forEach((element)=> {
      stats.faults.push({ name: element.label, value: 0, colour: element.colour});
    });

    if ( god_mode)
    {
      console.log("******** GOD MODE ACTIVE ********");
      console.log("Creating info Records for non existant models");
      

      /* not needed as there are 2 currently add back in for testing.

      buildEventItems.forEach(element => {
        if (false === speedHasElementSID(buildInfoItems, element.shortDeviceId ) )
        {
          recordErrors.infoItemsNotFound.push(element);
        }
      });

      console.log("Info Records Filtering Finished", recordErrors.infoItemsNotFound.length);
      recordErrors.infoItemsNotFound.forEach((infoRecordNeeded) => {
        var tempInfoRecord = infoRecordNeeded;
        let UID = getUIDFromDb(infoRecordNeeded.recordID);
        tempInfoRecord.userName = "Info Not Found: "+UID; 
        tempInfoRecord.modelType = "Unknown"
        tempInfoRecord.macAddr = getMacAddressFromUID(UID);
        console.log("Created info Records for:", tempInfoRecord);
        tempInfoRecord.unRegistered=true;
        buildInfoItems.push( tempInfoRecord);
      })
        */
      
      console.log("Done Info records");
      // const evntsNotFound = infoItems.filter((element1) => findShortUIDInListWithShortRecordID( getUIDFromDb( element1.recordID ) , eventItems ).length === 0) ;
      // buildInfoItems.forEach(element => {
      //   if (false === speedHasElementSID(buildEventItems, element.shortDeviceId ) )
      //   {
      //     recordErrors.evntsNotFound.push(element);
      //   }

      //   if(tempProgress < currProgress) {
      //     tempProgress += getRandomIntInclusive(1, deltaMax);
      //   }

      //   setProgress(tempProgress);
      // });
      
      
      // console.log("Event records not found", recordErrors.evntsNotFound.length); 
      // console.log("Creating Event Records for non existant models");
      // // evntsNotFound.forEach ((thisDevice)=>
      // recordErrors.evntsNotFound.forEach ((thisDevice)=>
      // {
      //   var tempEvent = thisDevice;
      //   tempEvent.updatedAt = new Date();
      //   tempEvent.notRegistered = true;
      //   buildEventItems.push(tempEvent)
      // })
      console.log("Done Event Records for non existant models");
    }


    var printed_records = 0;
    tempProgress = currProgress;
    setProgress(tempProgress);
    currProgress += ( 2*typeProgress );
    console.log("Starting Processing all info items: ", buildInfoItems.length,"Progress:", tempProgress, "/", currProgress);
    //circle round info items....
    
    let progress_itr = ( 2*typeProgress )/buildInfoItems.length;
    var last_rounded = 10000;

    for (let infoItr = 0; infoItr <  buildInfoItems.length  ; infoItr++) 
    {
      let infoElement = buildInfoItems[infoItr];
     // let infoelementSI = getIDSFromDb(infoElement.recordID); 
      if ( god_mode)
      {
        if (false === speedHasElement (buildEventItems, infoElement.uniqueDeviceId) )
        {
          recordErrors.evntsNotFound.push(infoElement);
          var tempEvent = infoElement;
          tempEvent.updatedAt = new Date();
          tempEvent.notRegistered = true;
          buildEventItems.push(tempEvent)
        }
      }
      var tempDevice = {};
      // tempDevice.shortDeviceId = infoElement.shortDeviceId;
      tempDevice.uniqueDeviceId = infoElement.uniqueDeviceId;
      tempDevice.serialNumber = infoElement.serial_number;
      tempDevice.unRegistered = infoElement.unRegistered;
      tempDevice.createdAt = infoElement.createdAt;
      tempDevice.dateMade = infoElement.date;
      tempDevice.ioVersion = infoElement.ioVersion;
      tempDevice.exp1Version = infoElement.exp1Version;
      tempDevice.exp2Version = infoElement.exp2Version;
      tempDevice.chemVersion = infoElement.chemVersion;
      tempDevice.productionTest = infoElement.productionTest;
      tempDevice.inProduction = isProductionTestInProductionNotCompleted(tempDevice);
      const verData = FIRMWARE_VERSIONS.find((ver) => ver.name ===infoElement.versionNumber);
      
      if(verData !== undefined) 
      {
        tempDevice.verDesc = verData.name;
        tempDevice.verColor = verData.colour;
      }
      
      tempDevice.versionNumber =infoElement.versionNumber;
      tempDevice.currentFaults =infoElement.faults;

        if ( isValidVersionNumber(tempDevice) === false)
        {
          tempDevice.versionNumber = "NOT SET"
          tempDevice.verDesc = "NOT SET"
          tempDevice.verColor = "orange"
        }

        let isDeviceArchived = infoElement.archived === 'archived' ? true: false; 
        tempDevice.archived = isDeviceArchived;
        if ( isDeviceArchived)
        {
          console.log( "device: ", tempDevice.uniqueDeviceId, " is Archived", tempDevice.archived);
        }
        
        
        
        // let duplicatedItem = findUIDInListWithRecordID(tempDevice.uniqueDeviceId, buildInfoItems);
        // if ( duplicatedItem.length > 1)
        // {
        //   recordErrors.duplicatesFound.push( tempDevice)
        // }
    
        let theUserName = infoElement.userName;
        if ( theUserName === null || theUserName === undefined)
        {
          theUserName = "undefined";
          tempDevice.macAddress = getMacAddressFromUID(tempDevice.uniqueDeviceId);
          recordErrors.userNameNotFound.push(tempDevice); 
        }
        tempDevice.userName = theUserName;
      
        if (infoElement.modelType === undefined 
        || infoElement.modelType === null
        || infoElement.modelType === "Unknown")
        {
          try
          {
            tempDevice.modelType = getModelTypeValueFromUID( infoElement.uniqueDeviceId ).string;
          }
          catch (error)
          {
            tempDevice.modelType = "Unknown";
            console.log(" tempDevice.modelType failed ", infoElement.recordID);
          }
        }
        else
        {
          tempDevice.modelType = infoElement.modelType;
        }

        if ( tempDevice.modelType === "Unknown")
        {
          console.log("tempDevice.modelType Unknown ", infoElement.recordID);
        }
    

      let currentUniqueID = tempDevice.uniqueDeviceId
      let evtItm = {};
      // console.log(" tempDevice:", tempDevice);
      if ( currentUniqueID === undefined)
      {
        console.log(" Undefined ID ", tempDevice);
      }
      else
      {
        evtItm = buildEventItems.filter(item => item.uniqueDeviceId=== currentUniqueID );
        
        if ( (evtItm === undefined || evtItm.length === 0) ) 
        {
          console.log("event Item not found for ", currentUniqueID)
        } 
        else 
        {
          let theEvent = evtItm[0];
          if ( tempDevice.uniqueDeviceId === undefined)
          {
            console.log("tempDevice.uniqueDeviceId === undefined");  
          }
          else
          {
            let currentUID = tempDevice.uniqueDeviceId;

            evtItm.forEach ((item)=>
            {
              if ( getUIDFromDb(item.recordID) === currentUID)
              {
                theEvent = item;
                // console.log("found the real device", item, theEvent.connectionStatus);
              }
            })

            tempDevice =  updateConnStatus( tempDevice , theEvent) ;
            tempDevice.notRegistered = theEvent.notRegistered;

            if (isCurrentFaultsARealFault(tempDevice) )
            {
              tempDevice.ctrlStatDesc = "FAULT"
              tempDevice.ctrlStatDate = tempDevice.connStatDate;
            }
            else
            {
              tempDevice.ctrlStatDate = tempDevice.connStatDate;
              tempDevice.ctrlStatDesc = "GOOD";
            }
        

            if (tempDevice.ctrlStatDesc === "GOOD") {
              tempDevice.ctrlStatColor = COLOURS.good;
              tempDevice.ctrlStatIcon = MdCheckCircleOutline;
            } else {
              tempDevice.ctrlStatColor = COLOURS.error;
              tempDevice.ctrlStatIcon = MdErrorOutline;
            }
          
            var acccess_allowed = god_mode;

            const devicesAccess = canIaccessController(tempDevice,accessableDevices,accessableGroups);
            
            if ( devicesAccess.canIaccessController === true)
            {
              acccess_allowed = true;
            }

            if ( acccess_allowed)
            {
              if ( devicesAccess.item !== null)
              {
                tempDevice.usersDeviceName = devicesAccess.item.usersDeviceName;
                // console.log( "device Access:", devicesAccess, " tempDevice", tempDevice)
              }
             
              if ( !tempDevice.inProduction)
              {
                allDevices.push(tempDevice);
                stats.devicesTotal++;
              }
              else
              {
                recordErrors.inProduction.push(tempDevice);
              }

              let loopDevice = tempDevice
              if ( loopDevice.inProduction === true)
              {
                stats.devicesinProduction++;
              }
              else
              {
                if ( loopDevice.notRegistered === true)
                {
                  stats.devicesNotRegistered++;
                }
                else if ( loopDevice.unRegistered === true)
                {
                  stats.devicesUnRegistered++;
                }
                else
                {
                  stats.devicesRegistered++;
                

                  if(loopDevice.connStatDesc === "OFFLINE") 
                  {
                    stats.devicesStatusOffline++;
                  }
                  else
                  {
                    if ( loopDevice.ctrlStatDesc === "FAULT")
                    {
                      stats.devicesStatusFault++;
                      if ( isCurrentFaultsARealFault(loopDevice) )
                      {
                        for (let index = 0; index < 16; index++) {

                          const bit_field = (1 << index)
                          
                          if ( (loopDevice.currentFaults & bit_field) === bit_field)
                          {
                            const faultItem = getFaultTableItem(bit_field);
                            if ( null != faultItem)
                            {
                              let faultStat = stats.faults.find((itm) => itm.name === faultItem.label);
                              if(faultStat === undefined) 
                              {
                                // stats.faults.push({ name: loopDevice.verDesc, value: 1, colour: COLOURS.other});
                              } 
                              else 
                              {
                                faultStat.value++;
                              }
                            }
                          }
                        }
                        
                      }
                    }
                    else if ( loopDevice.ctrlStatDesc === "GOOD")
                    {
                      stats.devicesStatusGood++;
                    }
                    else
                    {
                      if ( printed_records <10)
                      {
                        console.log("unknown Status:",loopDevice.ctrlStatDesc, " : ", loopDevice );
                        printed_records++;
                      }

                      stats.devicesStatusUnknown++;
                    }
                  }
                
              
                  let verStat = stats.versions.find((itm) => itm.name === loopDevice.verDesc);
                  if(verStat === undefined) 
                  {
                    if ( isValidVersionNumber(loopDevice))
                    {
                      let verStat2 = stats.versions.find((itm) => itm.name === loopDevice.versionNumber);
                      if ( verStat2 === undefined)
                      {
                        stats.versions.push({ name: loopDevice.versionNumber, value: 1, colour: COLOURS.other});
                        console.log( "Version", loopDevice.versionNumber, "Added for:", loopDevice.uniqueDeviceId)
                      }
                      else
                      {
                        verStat2.value++;
                      }

                    }
                    else
                    {
                      if ( loopDevice.verDesc === undefined)
                      {
                        let undef = stats.versions.find((itm) => itm.name === "NOT SET")
                        if ( undef !== undefined)
                        {
                          undef.value++;
                        }
                        console.log( "Version Undefined for:", loopDevice.verDesc, loopDevice)
                      }
                      else
                      {
                        stats.versions.push({ name: loopDevice.verDesc, value: 1, colour: COLOURS.other});
                        console.log( "Version", loopDevice.verDesc, "Added for:", loopDevice.uniqueDeviceId)
                      }
                    }
                  } 
                  else 
                  {
                    verStat.value++;
                  }
                }

                let modelStat = stats.modelType.find((itm) => itm.name.toLowerCase() === loopDevice.modelType.toLowerCase());
                if(modelStat === undefined) 
                {
                  stats.modelType.push({ name: loopDevice.modelType, value: 1, colour: COLOURS.other});
                } 
                else 
                {
                  modelStat.value++;
                }

                const creatdMonth = getMonthYearFromDate(loopDevice.createdAt );

                if ( (creatdMonth.label === "Undefined")
                  || (creatdMonth.label === "Jan-1900") )
                {
                  // console.log( "Stats month Data not valid:", creatdMonth.label , "for", loopDevice.uniqueDeviceId )
                  recordErrors.invalidDates.push(loopDevice );
                }
                else
                {
                  let createdMonth = stats.createdMonth.find((itm) => itm.name === creatdMonth.label);
                  if(createdMonth === undefined) 
                  {
                    const newMonth = {
                      name : creatdMonth.label,
                      year : creatdMonth.Year,
                      month : creatdMonth.Month,
                    };

                    modelTypeDetails.forEach((element) => {
                      newMonth[element.label] = 0;
                    });
                    newMonth["Total"] = 1;
                    newMonth[loopDevice.modelType] ++;
                    stats.createdMonth.push( newMonth)
                  }
                  else
                  {
                    createdMonth[loopDevice.modelType]++;
                    createdMonth["Total"]++;
                  }
                }
              }
            }
          }
        }
      }
     

      if(tempProgress < currProgress) 
      {
        tempProgress += progress_itr; //getRandomIntInclusive(1, deltaMax);
        let rounded = Math.round(tempProgress);
        if ( rounded != last_rounded)
        {
          last_rounded = rounded;
          setProgress( rounded);
          // console.log("infoItr",infoItr, "Progress:", tempProgress, "/", currProgress ,rounded);
          await pause();
        }
      }
      setDevicesStats(stats);
    }



    console.log("Versions:", stats.versions);
    console.log("Ended Processing Records");

    if ( ( recordErrors.duplicatesFound.length !== 0) ||
      (recordErrors.userNameNotFound.length !== 0)  ||
      (recordErrors.inProduction.length !== 0)  ||
      (recordErrors.invalidDates.length !== 0) )
    {
      console.log("Record Errors: ")
      console.log("Duplicates found for",recordErrors.duplicatesFound );
      console.log("In Production",recordErrors.inProduction );
      console.log("Username not found for: ",recordErrors.userNameNotFound )
      console.log("Stats month Data not valid for: ",recordErrors.invalidDates )
    }
  
    stats.createdMonth.sort(
      function (a,b) {
        if (a.year === b.year)
        {
          return (a.month < b.month ? -1 :1);
        }
        else
        {
          return (a.year < b.year ?-1:1);
        }
      }
    );

  //  var runningSum = 0;

//     stats.createdMonth.forEach((monthData) => {
//       // if (( monthData !== "name" )&& ( monthData !== "year" ) && ( monthData !== "month" ) && ( monthData !== "Total" ))
//       // {

//       // }
//       console.log(monthData);

// });

    console.log( "Created Month",stats.createdMonth)
    
    // allFaults.sort();
    // setLatestFaults(allFaults);
    sortDevices(allDevices, accLvl, selectedSortKey, selectedSortOrder);
    // console.log("Alldevice :", allDevices.length, allDevices);
    setDevices(allDevices);
    doSetLoading(false)
  }

  const build_up_log_record_id = (deviceId, dateRange) =>
  {
    const datDt0 = startOfDay(dateRange[0]);
    const datDt1 = endOfDay(dateRange[1]);
    var tm0 = "";
    var tm1 = "";
    try {
       tm0 = formatISO9075(datDt0).replace(' ', 'T');
       tm1 = formatISO9075(datDt1).replace(' ', 'T');
    } catch(error) {
      console.log("build_up_log_record_id:", error, deviceId, dateRange, datDt0, datDt1);
     
    }
    
    const totalPerDay =  300
    const progressMax = (totalPerDay * differenceInDays(datDt1, datDt0));
    let logRecordID = getRecordIDFromUIDNoDT(deviceId);
    let logRecordID_Start = logRecordID+"_DT_"+tm0;
    let logRecordID_End = logRecordID+"_DT_"+tm1;

    console.log("build_up_log_record_id: record Id: ", logRecordID , " From: ",  logRecordID_Start , " To: ", logRecordID_End);

    return {id: logRecordID, start:  logRecordID_Start, end: logRecordID_End, maxProgress:progressMax, startDate: datDt0, endDate: datDt1}
  }


  async function fetchSetPointData(deviceId, dateRange) 
  {
    setConfigData([]);
    doSetLoadingConfig(true)
    setProgress(0);
    setPlottableConfigParams([]);
    console.log("**** Fetch SETPoint Config Data Starting");
    let dateRangeData = build_up_log_record_id( deviceId, dateRange)
    let nextToken = null;
    let allItems = [];
    const typeProgress = 20; // 100% / 5 type of queries
    const deltaMax = 4; // 1-4% per query

    let currProgress = typeProgress;
    let tempProgress = 0;
    do {
      try {
        const apiData = await client.graphql(
          {
            query: listDevices,
            variables: {
              recordType: RECORD_TYPES.CONFIG,
              recordID: {
                between: [ dateRangeData.start, dateRangeData.end]
              },
              nextToken,
              limit: apiLimit
            }
          }
        );
        allItems = allItems.concat(apiData.data.listDevices.items);
        nextToken = apiData.data.listDevices.nextToken;
        if(tempProgress < currProgress) {
          tempProgress += getRandomIntInclusive(1, deltaMax);
        }
        setProgress(tempProgress);
      }
      catch (error) {
        console.log("listDevices COnfig Data error:", error);
        timer(500);
      }
    }
    while (nextToken != null);
    console.log("Fetch COnfig Data: record Id: ", allItems);
    let tmpSetPointData = [];
    let tmpConfigParams = [].concat(paramsConfig[param_t.POOL_SP]);
    console.log("Fetch COnfig Data Progress:", tempProgress, "/", currProgress);
    tempProgress = currProgress;
    currProgress += typeProgress;
    
    for (let allItemsItr = 0, n = allItems.length; allItemsItr < n; allItemsItr++) {
      let tmpItm = allItems[allItemsItr];
      if (tmpItm !== null) 
      {
        let date_time = getDateTimeFromRecordID( tmpItm.recordID);
        const datInRange = (compareAsc(date_time, dateRangeData.startDate) >= 0) && (compareAsc(date_time, dateRangeData.endDate) <= 0);
        let item = {};
        date_time.setSeconds(date_time.getSeconds() + 10);
        item.dateDisplay = lightFormat(date_time, datetimeFormats.short);
        item["Date/Time"] = lightFormat(date_time, datetimeFormats.long);
        item.dateTime = date_time;
        let hasData = false;
        let valinRange = true;
        let hasPhOrORPData = false;
  
        paramsConfig.forEach ((curr_param)=>
        { 
          if ( curr_param.config)
          {
        // for (let j = 0; j < paramsConfig.length; j++) 
        // {

            const paramName = curr_param.name;
            const paramSearch = curr_param.search;
            const paramSelectable = curr_param.selectable;


            let val = tmpItm[paramName];

            if (val !== null && paramSearch === true) 
            {
              let itemDone = false;

              switch (paramName) 
              {
                case paramsNames.SOLAR_SP:
                case paramsNames.SPA_2_SP:
                case paramsNames.POOL_2_SP:
                case paramsNames.SPA_SP:
                case paramsNames.POOL_SP:
                {
                    if (val >= 120) 
                    {
                      valinRange = false;
                      val = 0;
                    }
                    break;
                }
                case paramsNames.ORP_SP:
                {
                    hasPhOrORPData = true;
                  
                    if ( val == 2530)
                    {
                      val = -1;
                    }
                    else if ( val == 2540)
                    {
                      val = -2;
                    }
                    else if ( val == 2550)
                    {
                      val = -3;
                    }
                    else if (val >= 2500) 
                    {
                      val = 800;
                    }

                    break;
                  }

                case paramsNames.PH_SP:
                  {
                    hasPhOrORPData = true;
                    if ( val == 25.30)
                    {
                      val = -1;
                    }
                    else if ( val == 25.40)
                    {
                      val = -2;
                    }
                    else if ( val == 25.50)
                    {
                      val = -3;
                    }
                    else if (val >= 25) 
                    {
                      val = 0;
                    }
                    // console.log("PH", val)
                    break;
                  }
                default: break;
              }
              // console.log("paramName", paramName)
              if (datInRange && valinRange) {
                hasData = true;
                if (paramName === paramsNames.PH_SP) 
                {
                  phSelection = true;
                }
                if (paramName === paramsNames.ORP_SP) {
                  ORPSelection = true;
                }
              }

              if (itemDone === false) 
              {
                item[curr_param.text] = val;
              }

              if (paramSelectable) {
                if (!tmpConfigParams.find(e => e.name === paramName)) {
                  tmpConfigParams.push(curr_param);
                }
              }
            }
            else {
              if (paramSelectable) {
                if (!tmpConfigParams.find(e => e.name === paramName)) {
                  tmpConfigParams.push(curr_param);
                }
              }
            }
          }

          if (hasData) {
            // console.log("item:", item)
            tmpSetPointData.push(item);
          }
        })
      }
    }
    // console.log("appliances:", tmpAppliancesConfigured);
    setPlottableConfigParams(tmpConfigParams);
    setConfigData(tmpSetPointData)
    doSetLoadingConfig(false)
    console.log("Fetch SetPoint Config Data Ending");
  }


  async function fetchLogData(deviceId, dateRange) 
  {
    setLogData([]);
    doSetLoadingLogs(true)
    setProgress(0);
    // setPumpData([])
    setPumpTrackerData([]);
    setApplianceTrackerData([]);
    
    // setApplianceData([])
    
    console.log("Fetch Log Data Starting");
    let dateRangeData = build_up_log_record_id( deviceId, dateRange)
    let nextToken = null;
    let allItems = [];
    phSelection = false;
    ORPSelection = false;
    const typeProgress = 20; // 100% / 5 type of queries
    const deltaMax = 4; // 1-4% per query

    let currProgress = typeProgress;
    let tempProgress = 0;
    do {
      try {
        const apiData = await client.graphql(
          {
            query: listDevices,
            variables: {
              recordType: RECORD_TYPES.DATA,
              recordID: {
                between: [ dateRangeData.start, dateRangeData.end]
              },
              nextToken,
              limit: apiLimit
            }
          }
        );
        allItems = allItems.concat(apiData.data.listDevices.items);
        nextToken = apiData.data.listDevices.nextToken;
        if(tempProgress < currProgress) {
          tempProgress += getRandomIntInclusive(1, deltaMax);
        }
        setProgress(tempProgress);
      }
      catch (error) {
        console.log("listDevices logData error:", error);
        timer(500);
      }
    }
    while (nextToken != null);
    // console.log("Fetch Log Data: record Id: ", allItems);
    let tmpLogData = [];
    let tmpPumpData = [];
    let tmpParams = [].concat(paramsConfig[0]);
    let tmpAppliancesConfigured = []
    let dataSetPHRT = []
    let dataSetORPRT = []
    let dataSetPH = []
    let dataSetORP = []
    let dataSetLabelsPHORP = []
    let APPLIANCE_ON_VALUE = 9;
    console.log("Progress:", tempProgress, "/", currProgress);
    tempProgress = currProgress;
    currProgress += typeProgress;

    for (let allItemsItr = 0, n = allItems.length; allItemsItr < n; allItemsItr++) 
    {
      let tmpItm = allItems[allItemsItr];
      if (tmpItm !== null) 
      {
        let date_time = getDateTimeFromRecordID( tmpItm.recordID);
        const datInRange = (compareAsc(date_time, dateRangeData.startDate) >= 0) && (compareAsc(date_time, dateRangeData.endDate) <= 0);
        let item = {};
        item.dateDisplay = lightFormat(date_time, datetimeFormats.short);
        item["Date/Time"] = lightFormat(date_time, datetimeFormats.long);
        item.dateTime = date_time;
        let pump_item = {};
        let hasData = false;
        let valinRange = true;
        let hasPumpData = false;
      
        let hasPhOrORPData = false;
        let ORP_data = null;
        let PH_data = null;
        let ORP_RT_data = null;
        let PH_RT_data = null;
  
        paramsConfig.forEach( (curr_param)=>
        {

        // for (let j = 0; j < paramsConfig.length; j++) 
        // {
          const paramName = curr_param.name;
          const paramSearch = curr_param.search;
          const paramSelectable = curr_param.selectable;

          let val = tmpItm[paramName];

          if (val !== null && paramSearch === true) {
            let itemDone = false;

            switch (paramName) {
              case paramsNames.ROOF:
                {
                  // hasPoolRoofOrWaterData = true;
                  if (val >= 120) {
                    valinRange = false;
                    val = 0;
                  }
                  // Roof = val;
                  break;
                }
              case paramsNames.WATER:
                {
                  // hasPoolRoofOrWaterData = true;
                  if (val >= 120) {
                    valinRange = false;
                    val = 0;
                  }
                  // Water = val;
                  break;
                }
              case paramsNames.POOL:
                {
                  // hasPoolRoofOrWaterData = true;
                  if (val >= 120) {
                    valinRange = false;
                    val = 0;
                  }
                  // Pool = val;
                  break;
                }
              case paramsNames.APP1:
              case paramsNames.APP2:
              case paramsNames.APP3:
              case paramsNames.APP4:
              case paramsNames.APP5:
              case paramsNames.APP6:
              case paramsNames.APP7:
              case paramsNames.APP8:
              case paramsNames.APP9:
              case paramsNames.APP10:
              case paramsNames.APP11:
              case paramsNames.APP12:
              case paramsNames.APP13:
                {
                  // var applianceItem = {};
                  var applianceConfigured = false;

                  let appliancestring = lightFormat(date_time, datetimeFormats.short);

                  switch (val) {
                    case 0: appliancestring += (" OFF"); applianceConfigured = true; val = 0;break; //'STARTUP',
                    case 1: appliancestring += (" ON"); applianceConfigured = true; val = APPLIANCE_ON_VALUE; break;  //  'IDLE',
                    case 2: appliancestring += (" Not In Use ");val=-1; break;  //  'POWER P',
                    default: appliancestring += ("???" + val); val = -1;break;  //  'POWER P',
                  }
                  item.id =curr_param.id;
                  item.DisplayString = appliancestring
                  item.name = curr_param.text;
                  item.colour = applianceStatecolours[val];
                  item.val = val;
                 // itemDone = true;
                 // tmpApplianceData.push(applianceItem);

                  if (applianceConfigured) {
                    if (!tmpAppliancesConfigured.find(e => e === curr_param.id)) {
                      tmpAppliancesConfigured.push(curr_param.id);
                    }
                  }
                  break;
                }
              case paramsNames.ACID:
              case paramsNames.CHLORINE:
              {
                // var dosingItem = {};
                var dosingConfigured = false;
                let DOSING_VALUE = 6;
                let appliancestring = lightFormat(date_time, datetimeFormats.short);
                switch (val) {
                  case 0: appliancestring += (" IDLE"); dosingConfigured = true; break; 
                  case 1: appliancestring += (" DOSING"); dosingConfigured = true;val = 1; break;  // 
                  case 2: appliancestring += (" Not In Use ");val = -1;break;  
                  default: appliancestring += ("???" + val); val = -1; break; 
                }
                item.id = curr_param.id;
                item.DisplayString = appliancestring
                item.name = curr_param.text;
                item.colour = applianceStatecolours[val];
                item.value = DOSING_VALUE;
                // hasApplianceData = true;
                //itemDone = true;
                // tmpApplianceData.push(dosingItem);

                // console.log( "Acid Chlorine State",j, paramName ,val , item, tmpItm, dosingItem)
                if (dosingConfigured) {
                  if (!tmpAppliancesConfigured.find(e => e === curr_param.id)) {
                    tmpAppliancesConfigured.push(curr_param.id);
                  }
                }
                break;

              }
              case paramsNames.PUMP:
              {
                let pump_speed = (val & 0xFF) + 1
                let pump_state = (val >> 8)
                let pump_string = lightFormat(date_time, datetimeFormats.short);
                let dateString = pump_string;
                let pump_value = 0;
                let ON_VALUE = 0.5;
                switch (pump_state) {
                  case 0: pump_string += (" " + pumpStateStrings[pump_state]); break;  //'STARTUP',
                  case 1: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'IDLE',
                  case 2: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER P',
                  case 3: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWERED',
                  case 4: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'WAIT',
                  case 5: pump_string += (" " + pumpStateStrings[pump_state]); pump_value = pump_speed + 2; break;  //  'PRIME', 
                  case 6: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed);pump_value = pump_speed; break;  //  'SET SPEED',
                  case 7: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed);pump_value = pump_speed; break;  //  'SPD POLL',
                  case 8: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed);pump_value = pump_speed; break;  //  'CHK SPD',
                  case 9: pump_string += (" " + pumpStateStrings[pump_state]); pump_value = ON_VALUE+pump_speed; break;  //  'SET ON',
                  case 10: pump_string += (" " + pumpStateStrings[pump_state]);pump_value = ON_VALUE+pump_speed; break;  //  'CHK ON P',
                  case 11: pump_string += (" " + pumpStateStrings[pump_state]);pump_value = ON_VALUE+pump_speed; break;  //  'CHK ON',
                  case 12: pump_string += (" " + pumpStateStrings[pump_state] + "@ Speed:" + pump_speed);pump_value = ON_VALUE+pump_speed; break;  //  'RUNNING',
                  case 13: pump_string += (" " + pumpStateStrings[pump_state]); pump_value = ON_VALUE;break;  //  'RUN ON',
                  case 14: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'PWR DWN',
                  case 15: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'V OFF P', 
                  case 16: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'VER OFF',
                  case 17: pump_string += (" " + pumpStateStrings[pump_state]); pump_value = -ON_VALUE;break;  //  'PUMP FAULT',
                  case 18: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'PRIME OFF',
                  case 19: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'RUN ON', 
                  case 20: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER OFF',
                  case 21: pump_string += (" " + pumpStateStrings[pump_state]); break;  //  'POWER ON'
                  default: pump_string += (" UnKnown");
                }

                pump_item.id = 21;
                pump_item.DisplayString = pump_string;
                pump_item.PumpState = pumpStateStrings[pump_state];
                pump_item.PumpSpeed = pump_speed;
                pump_item.colour = pumpStatecolours[pump_state];
                pump_item.name = "pump";
                pump_item.value = pump_value;
                pump_item.dateString = dateString;
               // console.log( "pump State" , pump_state , "pump Speed", pump_speed , "Item: ", pump_item)
                itemDone = true;
                hasPumpData = true;
                break;
              }
              case paramsNames.ORP:
              {
                  hasPhOrORPData = true;
                  // console.log("ORP Reading taken:",val, paramsNames.ORP)
                  if ( val == 2530)
                  {
                    val = -1;
                  }
                  else if ( val == 2540)
                  {
                    val = -2;
                  }
                  else if ( val == 2550)
                  {
                    val = -3;
                  }
                  else if (val >= 2500) 
                  {
                    val = 800;
                  }
                  ORP_data = val;
                  break;
                }

              case paramsNames.PH:
                {
                  hasPhOrORPData = true;
                  if ( val == 25.30)
                  {
                    val = -1;
                  }
                  else if ( val == 25.40)
                  {
                    val = -2;
                  }
                  else if ( val == 25.50)
                  {
                    val = -3;
                  }
                  else if (val >= 25) 
                  {
                    val = 0;
                  }
                  PH_data = val;
                  // console.log("PH", val)
                  break;
                }
              case paramsNames.PHRT:
                {
                  hasPhOrORPData = true;
                  if (val >= 100) 
                  {
                    val = val / 100;
                  }
                  if ( val == 25.30)
                  {
                    val = -1;
                  }
                  else if ( val == 25.40)
                  {
                    val = -2;
                  }
                  else if ( val == 25.50)
                  {
                    val = -3;
                  }
                  else if (val >= 25) 
                  {
                    val = 0;
                  }
                  PH_RT_data = val;
                  break;
                }

              case paramsNames.ORPRT:
                {
                  hasPhOrORPData = true;
                  if ( val == 2530)     //NOt yet Valid
                  {
                    val = -1;
                  }
                  else if ( val == 2540)
                  {
                    val = -2;
                  }
                  else if ( val == 2540)
                  {
                    val = -3;
                  }
                  else if (val >= 2500) 
                  {
                    val = 800;
                  }
                  ORP_RT_data = val;
                  break;
                }

                case paramsNames.OTHER:
                  {
                    if (val >= 120) {
                      valinRange = false;
                      val = 0;
                    }

                    break;
                  }
              default: break;
            }
            // console.log("paramName", paramName)


            if (datInRange && valinRange) 
            {
              hasData = true;

              if (paramName === paramsNames.PH || paramName === paramsNames.PHRT) {
                phSelection = true;

              }
              if (paramName === paramsNames.ORP )
                {
                  // console.log("ORP Reading taken:",val)
                  ORPSelection = true;
                }
                
              if ( paramName === paramsNames.ORPRT)
              {
                ORPSelection = true;
              }
            }

            if (itemDone === false) {
              item[curr_param.text] = val;
            }

            if (paramSelectable) {
              if (!tmpParams.find(e => e.name === paramName)) {
                tmpParams.push(curr_param);
              }
            }
          }
          else {
            if (paramSelectable) {
              if (!tmpParams.find(e => e.name === paramName)) {
                tmpParams.push(curr_param);
              }
            }
          }
        })
        if (hasData) 
        {
          // console.log("item:", item)
          tmpLogData.push(item);
        }
        if (hasPhOrORPData) {
          dataSetLabelsPHORP.push(item.dateDisplay)
          if (ORP_data != null) {
            dataSetORP.push(ORP_data)
          }

          if (PH_data != null) {
            dataSetPH.push(PH_data)
          }

          if (ORP_RT_data != null) {
            dataSetORPRT.push(ORP_RT_data)
          }

          if (PH_RT_data != null) {
            dataSetPHRT.push(PH_RT_data)
          }

        }
        if (hasPumpData) 
        {
          tmpPumpData.push(pump_item);
        }
      }
    }
  
    // console.log("appliances:", tmpAppliancesConfigured);
    setConfiguredAppliance(tmpAppliancesConfigured);
    // setPumpData(tmpPumpData);
    setPumpTrackerData(getPumpTrackerdata(tmpPumpData));
    setLogDevice(deviceId);
    setLogData(tmpLogData);
    setPlottableParams(tmpParams);
    setORPPHLabels(dataSetLabelsPHORP)
    setORPData(dataSetORP)
    setPHData(dataSetPH)
    setORPRTData(dataSetORPRT)
    setPHRTData(dataSetPHRT)
    // setApplianceData(tmpApplianceData);
    // var temptrackerdata = [];
    // for ( let itr = 0; itr < 17; itr++)
    // {
    //   let curr_app = getTrackerdataForAppliances_with_id(applianceDataArray, itr);
    //   temptrackerdata.push(curr_app);
    // }
    // setApplianceTrackerData(temptrackerdata);
    let tmpSelected = [paramsConfig[0].text];
    if (selectedParams.length !== 0) 
    {
      for (let itr = 0; itr < tmpParams.length; itr++) 
        {
        if (selectedParams.includes(tmpParams[itr].text) && !tmpSelected.includes(tmpParams[itr].text)) 
          {
          tmpSelected.push(tmpParams[itr].text);
  
        }
      }
    }
    setSelectedParams(tmpSelected);

    console.log( "*** Selected Params:", tmpSelected, tmpParams, "***")

    doSetLoadingLogs(false)
    console.log("*** Fetch Log Data Ending");
  }

  async function fetchFaultsData(deviceId, dateRange) 
  {
    doSetLoading(true)
   // setHistoryFaults([]);
    let tmpHistFlts = [];
    let nextToken = null
    let dateRangeData = build_up_log_record_id( deviceId, dateRange)

    const typeProgress = 20; // 100% / 5 type of queries
    const deltaMax = 4; // 1-4% per query

    let currProgress = typeProgress;
    let tempProgress = 0;

    console.log("Fetch Fault Data: record Id: ", dateRangeData.id , " From: ",  dateRangeData.start , " To: ", dateRangeData.end);
    do 
    {
      try {
        const faultAPIData = await client.graphql(
          {
            query: listDevices,
            variables: {
              recordType: RECORD_TYPES.FAULTS,
              recordID: {
                between: [ dateRangeData.start, dateRangeData.end]
              },
              nextToken,
              limit: apiLimit
            }
          }
        );
        tmpHistFlts = tmpHistFlts.concat(faultAPIData.data.listDevices.items);
        nextToken = faultAPIData.data.listDevices.nextToken;
        if(tempProgress < currProgress) {
          tempProgress += getRandomIntInclusive(1, deltaMax);
        }
        setProgress(tempProgress)
        // console.log("List Faults good:",faultAPIData, tmpHistFlts, nextToken);
      }
      catch (error) {
        console.log("List Faults error:", error);
        timer(500);
        //fNextToken = error;
      }
    }
    while (nextToken != null);

    // console.log("Fetch Fault Data Done: record Id: ", dateRangeData.id , " Faults: ",  tmpHistFlts );
    doSetLoading(false)
    // setLogFaults(tmpLogFaults);
    setHistoryFaults(tmpHistFlts);
  }


  const handleBackFromDevice = () => 
  {
     setShowDevice(false);
     if (connectionState.ready) {
      console.log("HandleBack CSR");
    
     if ( devSubscription!== null)
      {
        console.log("HandleBack devSubscription Unsubscribing", devSubscription);
        devSubscription.unsubscribe();
        setDevSubscription(null)
        setCurrentDevice(undefined)
        setSelectedDevice(undefined)
        setMacAddress(undefined)
        setRegisterData(registersInit)
        setHistoryFaults([]);
        setNotesData([]);
        setEditOff(true);
      }
    }
  }
  
  async function doReadAll() {
    const topic = "dontek" + macAddress + "/cmd/psw"
    await pubsub.publish(
    { 
      topics: topic,
      message: readAllMessage
    });
    console.log("readAll =>", topic);
  }
  
  // function addAfter(array, index, newItem) {
  //   return [
  //       ...array.slice(0, index),
  //       newItem,
  //       ...array.slice(index)
  //   ];
  // }

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const createBatches = ( registers ) =>
  {
    var templateBatch1 = [];
    var templateBatch2 = [];
    var templateBatch3 = [];
    registers.forEach((item)=>
    {
      var regAddress = item.address;
      if(( regAddress === REGS.SLAVE_1_ENABLE 
        || regAddress === REGS.SLAVE_2_ENABLE 
        || regAddress === REGS.SLAVE_3_ENABLE)  // Expansion
        || (regAddress >= REGS.AUTO_SET_TIME 
        && regAddress <= REGS.ADJUST_DST))                        // Auto set time
      {
          console.log( "Add register",regAddress, "to template batch 1", item)
          templateBatch1.push(item);
      }
      else if( (regAddress >= REGS.SOCKET_1_TYPE 
             && regAddress <= REGS.VFCONTACT_1_TYPE) 
             || regAddress === REGS.VFCONTACT_2_TYPE) // Appliance types
      {
       // console.log( "Add register",regAddress, "to template batch 2", item)
        templateBatch2.push(item);
      }
      else 
      {
          templateBatch3.push(item);
      }
    })
    return {batch1:templateBatch1, batch2:templateBatch2, batch3:templateBatch3 }
  }


  const groupRegistersByRanges = ( regs, rangeMax = 15) =>
  {
    var ranges = [];
    if(regs !== undefined && regs.length > 0)
    {
      var regStart = [];
      var regCount = 0;
      var regValue = 0;
      var idx = -1;
      var isKeyPresent = false;
      for (var i = 0; i < regs.length; i++)
      {
          if((i === 0) 
          || (regs[i].address !== (regs[i - 1].address + 1) )
          || (regCount >= rangeMax))
          {
            regStart = regs[i];
            idx++;
          }
          regValue = regs[i].value;
          if(regStart !== undefined)
          {
            let our_address = regStart.address;
            isKeyPresent = ranges.some(el => ( (el.startAddress === our_address) ) )

            if(!isKeyPresent)
            {
              var item = { startAddress: regStart.address, values: []}
              ranges.push(item)// = addAfter(ranges,idx,item)
              regCount = 0;
            }

            ranges[idx].values.push(regValue);
            regCount++;
          }
      }
    }
    return ranges;
  }

  async function doSaveData() 
  {
    const registersFiltered = registerData.filter(item => item.reg_type === RT.Holding);
    const registersAscending = [...registersFiltered].sort((a, b) => a.address - b.address);
    const newRegisters = registersAscending.map((item) => (
      {address: item.address ,value: item.value}
    ) );
    console.log(newRegisters);
    const fileData = JSON.stringify(newRegisters);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const dt0 = new Date();
    link.download = currentDevice.uniqueDeviceId+"_"+lightFormat(dt0, datetimeFormats.filename_long);
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }

  // import {useRef} from 'react';
  async function doLoadData(file)
  {
    // const [file] = document.querySelector("input[type=file]").files;
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (item) => {
      console.log("e.target.result",item.target.result);
      const data = JSON.parse(item.target.result);
      console.log("Json Data", data);
      const dataAscending = [...data].sort((a, b) => a.address - b.address);
      console.log("Json Sorted Data", dataAscending);
      const batchedRegister = createBatches(dataAscending);
      console.log("batched Sorted Data", batchedRegister);
      const groupedRegisters1 = groupRegistersByRanges(batchedRegister.batch1, 15)
      console.log("group Sorted Data", groupedRegisters1);
      const groupedRegisters2 = groupRegistersByRanges(batchedRegister.batch2, 15)
      console.log("group Sorted Data", groupedRegisters2);
      const groupedRegisters3 = groupRegistersByRanges(batchedRegister.batch3, 15)
      console.log("group Sorted Data", groupedRegisters3);
      groupedRegisters1.map((item) => (
        writeRegisters(item.startAddress, item.values)
      ));

      groupedRegisters2.map((item) => (
        writeRegisters(item.startAddress, item.values)
      ));

      groupedRegisters3.map((item) => (
        writeRegisters(item.startAddress, item.values)
      ));
    }

  }
  
  useEffect(() => {
   console.log("selected Device Changed - ", selectedDevice)
   setCurrentDevice( devices.find((e) => e.uniqueDeviceId === selectedDevice))
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);



  useEffect(() => {
    console.log("macAddress  changed.")
    if ( macAddress !== undefined)
    {
      if (macAddress.length !== 0) 
      {
        const newTopic = "dontek" + macAddress + "/status/psw";
        // subscriptionHandler.setTopic(topic);
        // subscriptionHandler.subscribe();
        
        let builtTopics = devTopics;
        builtTopics.push(newTopic);
        console.log("useeffect subscribe", builtTopics)
        setRegisterData(registersInit)
        const sub = pubsub.subscribe({ topics: builtTopics }).subscribe({
          next: (data) => handleReceivedData(newTopic, data),
          error: (error) => console.log("subscribe error", error),
          complete: () => console.log("subscribe complete")
        });
        setDevTopics(builtTopics);
        setDevSubscription(sub);
        // console.log("useeffect subscribe", topic)
        // var sub = pubsub.subscribe( { topics: topic }).subscribe({
        //   next: (data) => handleReceivedData(topic, data),
        //   error: (error) => console.log("subscribe error", error),
        //   complete: (data) => handlePubSubComplete(topic, data),
        //   closed: (data) => handlePubSubClosed(topic, data),
        // });

        // setSubscription(sub);
      }
      else 
      {
        console.log("Mac Address Length === 0")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [macAddress]);

  useEffect(() => {
    console.log("connectionState.ready  changed.")
    if (connectionState.ready ) {
      doReadAll();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState.ready]);



  useEffect(() => {

    console.log("currentDevice  changed.")
    if ( currentDevice === null || currentDevice === undefined  || (currentDevice.length === 0 ))
    {
      console.log("No device yet defined ");
    }

    else 
    {
      if ( currentDevice.uniqueDeviceId !== undefined)
      {
        const macAddr = getMacAddressFromUID(currentDevice.uniqueDeviceId);
        setMacAddress(macAddr);
        console.log("Got Mac Address", macAddr)
        ConsoleLogger.LOG_LEVEL = 'VERBOSE';
        const logger = new ConsoleLogger('foo');
        logger.verbose('info bar');
      }
      else
      {
         console.log("NO MAc Address")
      }
    }
  }, [ currentDevice]);

  useEffect(() => 
  {
    console.log("accessableDevices  changed.")
    if ( accessableDevices.length !== 0)
    {
      console.log("accessableDevices changed. Fetching device details...")
      fetchDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessableDevices]);

  useEffect(() => 
  {
    console.log("god_mode  changed.")
  }, [god_mode]);


  useEffect(() => 
  {
    console.log("Hub Listen  changed.")
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signedIn') {
        try {
          console.log( "Signin Data:", payload.data)
          setLoginId(payload.data.signInDetails.loginId);        
        } catch (error) {
          console.log("signIn:", error);
        }
      } 
      else if (payload.event === 'signedOut') 
      {
        setLoginId('')
        setAccessLevel(accessLevels.guest);
        setDevices([]);
        setLogDevice('');
        setLogData([]);
        setAccessableDevices([]);
        setInstallers([]);
        setInstalledBy([]);
        // setLogPowerHourly([]);
        //   setLogPowerDaily([]);
        // setLogFaults([]);
        // setLatestFaults([]);
        setHistoryFaults([]);
        // setSelectedTab(tabItems.home);
        // setNotes([]);
        //  setSelectedStatus(statusItems.all);
        // setSelectedTab(tabItems.home);
        setSelectedView(displayViews.grid);
        setSelectedStatus(statusItems.all);
        setSelectedFilter(null);
        setselectedModel(modelTypeItems.all);
        handleSelectedDevice('');
        setSelectedDevices([]);
        setSelectedDateRange([startOfYesterday(), endOfToday()]);
        setSelectedParams([paramsConfig[0].text]);
        // setSelectedPowerDisplay(powerDisplay.hourly);
        setSelectedFaultView(faultView.current);
        setSelectedSortKey(sortKeys.name);
        setSelectedSortOrder(sortOrder.ascending);
        setDevicesStats({});
      }
    });
    Hub.listen('pubsub',  (data) => {
      const { payload } = data;
      console.log("pubsub:", payload);
      if (payload.event === CONNECTION_STATE_CHANGE) 
      {
        const newConnectionState = payload.data.connectionState;
        console.log("Connenction State Changed:", newConnectionState);
        const ready = (newConnectionState === ConnectionState.Connected);
        const state = ConnectionState[newConnectionState];
        setConnectionState({ state, ready });
      }
    })
  }, []);

  useEffect(() => {
    console.log("loginId: changed");
    
    if(loginId.length !== 0) {
      async function currentSession() {
        
        try {
          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
          const groups = accessToken.payload["cognito:groups"];

          if(groups !== undefined && groups !== null && groups.length > 0) {
            setUserGroups(groups);
          } else {
            console.log("accessToken:", accessToken, "idToken:", idToken);
          }
    
        } catch(err) {
          console.log(err);
        }
      }
      currentSession();
      fetchDeviceAccess();
    } else {
      doGetTheSignedInUser();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loginId]);



  const handleViewDevice = (device) =>
  {
    console.log ( "View Device", device)
    setHistoryFaults([]);
    setEditOff(true);
    setNotesData([]);
    setActiveKey(tabItems.home);
    setSelectedDateRange([startOfYesterday(), endOfToday()]);
    handleSelectedDevice(device); 
   
    //needs to clear the data 

    fetchSetPointData(device, selectedDateRange);
    fetchLogData(device, selectedDateRange);
    fetchNotes(device);
    setShowDevice(true)
    setCurrentDevice(devices.find((e) => e.uniqueDeviceId === device))
  }

  const handleReceivedData = (topic, data) => {
    
    const topfromData2 = data.topicSymbol
    const topicFromData =  data[Object.getOwnPropertySymbols(data)[0]] //data.value.topic;
    console.log("data Rec", data,   topicFromData );

    if ( (topicFromData === topic) )      //|| (topfromData2 === topic) ){
    {
      const { messageId, modbusReg, modbusVal } = data;
      console.log("received message", messageId, modbusReg, modbusVal);

      const rawData = {};
      let isReadAll = false;
      if ((messageId === "read") && (modbusReg === 1) && (modbusVal.length % 2 === 0)) {
        for (let i = 0; i < modbusVal.length; i += 2) {
          const address = modbusVal[i];
          const value = modbusVal[i + 1];
          rawData[address] = value;

        }
        isReadAll  = true;
      } 
      else 
      {
        for (let i = 0; i < modbusVal.length; i++) {
          const address = modbusReg + i;
          const value = modbusVal[i];
          rawData[address] = value;
          console.log("rawData", rawData[address],"set to ", value);
        }
      }

      for (let i = 0; i < registerData.length; i++) {
        const address = registerData[i].address;
      
        if (address in rawData) 
        {
          const fmtVal = format(registerData[i], rawData);
          const enabledVal = getEnabledState(registerData[i],rawData)
          const lightNumber = getLightNumber(registerData[i], rawData)

          if ( isReadAll === false)
          {
            if ( address === REGS.FILTER_TIME_ENABLE)
            {  /// update the FILTER times ENABLED based on the new enabled times
                // setFilterEnabledValues( rawData, registerData)
              // export const setFilterEnabledValues = (regData, registerData) => {
                  const value = rawData[REGS.FILTER_TIME_ENABLE];
                  var timerEnabled = false;
                  var do_update = false;
                  for (let i = 0; i < registerData.length; i++) 
                  {
                    let theTimerAddress = registerData[i].address;
                    do_update = false;
                    switch ( theTimerAddress)
                    {
                      case REGS.FILTER_TIME_1_START_TIME:
                        timerEnabled = ((value & 1) === 1) ? true:false;
                        do_update = true;
                        console.log("setFilterEnabledValues 1",timerEnabled, rawData, value, registerData[i])
                      break;
                
                      case REGS.FILTER_TIME_2_START_TIME:
                        timerEnabled = ((value & 2) === 2) ? true:false;
                        do_update = true;
                        console.log("setFilterEnabledValues 2",timerEnabled, rawData, value, registerData[i])
                      break;
                
                      case REGS.FILTER_TIME_3_START_TIME:
                        timerEnabled = ((value & 4) === 4) ? true:false;
                        do_update = true;
                        console.log("setFilterEnabledValues 3",timerEnabled, rawData, value, registerData[i])
                      break;
                
                      case REGS.FILTER_TIME_4_START_TIME:
                        timerEnabled = ((value & 8) === 8) ? true:false;
                        do_update = true;
                        console.log("setFilterEnabledValues 2",timerEnabled, rawData, value, registerData[i])
                      break;
                      default:
                        break;
                    }
                    if ( do_update)
                    {
                      const ourUpdate = timerEnabled
                      console.log( "called set fileter ... ",registerData[i], timerEnabled);
                      setRegisterData(prevState => {
                        const newState = prevState.map(obj => {
                          if (obj.address === theTimerAddress) {
                            console.log("Found Address:", theTimerAddress, ourUpdate, obj)
                            return { ...obj, enabled:ourUpdate};
                          }
                          return obj;
                        });
                        return newState;
                      });

                      console.log( "called set fileter ... ",registerData[i], timerEnabled);
                    }
                  }
                 
                // }

                
            }

            

          }
         
          
          setRegisterData(prevState => {
            const newState = prevState.map(obj => {

              if ( obj.address === REGS.FWUPDATE)
              {
                
                if ( ( obj.value === upgradeResultCodes_t.UPGRADE_COMPLETE)
                || (obj.value === upgradeResultCodes_t.UPGRADE_ERROR))
                {
                  console.log ( "FW Update state", obj.value)
                  setUpdateInProgress(false)
                }
              }
              else 
              if ( obj.address === REGS.FWUPDATE_EXPANSION_PLUS)
              {
               
                if ( ( obj.value === upgradeResultCodes_t.UPGRADE_COMPLETE)
                || (obj.value === upgradeResultCodes_t.UPGRADE_ERROR))
                {
                  console.log ( "AQuachem FW Update state", obj.value)
                  setACUpdateInProgress(false)
                }
              }
              else if (( obj.address === REGS.FWUPDATE_EXP4_SLAVE1)
                || ( obj.address === REGS.FWUPDATE_EXP4_SLAVE2))
                {
                 
                  if ( ( obj.value === upgradeResultCodes_t.UPGRADE_COMPLETE)
                  || (obj.value === upgradeResultCodes_t.UPGRADE_ERROR))
                  {
                    console.log ( "EXP4 FW Update state", obj.value)
                    setSH1UpdateInProgress(false)
                    setSH2UpdateInProgress(false)
                  }
                }

              if (obj.address === address) 
              {
                if ( lightNumber.isFound)
                {
                  // console.log ( "Found a light number for ", address, lightNumber.number)
                  return { ...obj, value: rawData[address], display: fmtVal , enabled:enabledVal, light_number:lightNumber.number };
                }
                else
                {
                  return { ...obj, value: rawData[address], display: fmtVal , enabled:enabledVal };
                }
              }
              return obj;
            });
            return newState;
          });
        }
      }
      if(isReadAll) {
        // setLoaded(true);
        setSyncing(false);
      }
      
    } else {
      console.log("topic mismatch, expected", topic, "received", topfromData2);
    }
  }

  const Content = () => {
    return (
       <Grid 
        numItems={1}
        className="gap-2 sm:p-4"
      >
      {
        (showDevice === false) &&
        <HomeView
            devices={devices}
            userGroups={userGroups}
            accessLevel={accessLevel}
            selectedDevices={selectedDevices}
            selectedStatus={selectedStatus}
            selectedFilter={selectedFilter}
            selectedModel={selectedModel}
            selectedView={selectedView}
            selectedSortKey={selectedSortKey}
            selectedSortOrder={selectedSortOrder}
            showArchivedDevices ={showArchivedDevices} 
            loading ={loading}
            progress={progress}
            onSelectedDevicesChanged={updateSelectedDevices}
            onSelectedStatusChanged={setSelectedStatus}
            onSelectedFilterChanged={setSelectedFilter}
            onSelectedModelTypeChanged={setselectedModel}
            onSelectedViewChanged={setSelectedView}
            onSelectedSortChanged={handlerSortChanged}
            onRefresh={fetchDevices}
            onToggleArchivedDevices={setShowArchivedDevices}
            onUpdateDeviceName = {updateDeviceName}
            onUpdateUserDeviceName = {updateUserDeviceName}
            onUpdateDeviceLocalArchiveStatus = {updateDeviceLocalArchiveStatus}
            
            loginId = {loginId}
            devicesStats = {devicesStats}
            // onViewTrends={handleViewTrends}
            // onViewFaults={handleViewFaults}
            // onViewDashboard={handleViewDashboard}
            onViewDevice={handleViewDevice}
        />
      }
      {
          (showDevice === true) &&
          <DeviceView
            device={currentDevice}
            notes_data={notes_data}
            selectedDevice={selectedDevice}
            selectedDevices={selectedDevices}
            selectedDateRange={selectedDateRange}
            onSelectedModelTypeChanged={setselectedModel}
            // device={selectedDevice}
            devices={devices}
            historyFaults={historyFaults}
            logData={logData}

            logDevice={logDevice}
            plottableParams={plottableParams}
            plottableConfigParams={plottableConfigParams}
            pumpTrackerData={pumpTrackerData}
            // applianceDataArray={applianceDataArray}
            onReadAll={doReadAll}
            onSaveData = {doSaveData}
            onLoadData = {doLoadData}
            // latestFaults={latestFaults}
            ORPPHLabels={ORPPHLabelsData}
            ORP={ORPData}
            PH={PHData}
            ORPRT={ORPRTData}
            PHRT={PHRTData}
            configData={configData}
            selectedParams={selectedParams}
            
            syncing = {syncing}
            phSelection={phSelection}
            ORPSelection={ORPSelection}
            loading={loading}
            progress={progress}
            PSWUpgradeURLS ={PSWUpgradeURLS}
            onSelectedDateRangeChanged={refreshDataByDate}
            onSelectedFaultDateRangeChanged={refreshFaultDataByDate}
            onSelectedParamsChanged={setSelectedParams}
            onSelectedDevicesChanged={updateSelectedDevices}
            onSelectedStatusChanged={setSelectedStatus}
            onSelectedViewChanged={setSelectedView}
            isLoadingOrNoDevice={is_loading_or_no_device()}
            onSetSelectedFaultView={setSelectedFaultView}
            onSetSelectedHistoryFaults={setSelectedHistoryFaults}

            onFetchDevices={fetchDevices}
            onRefresh={refreshData}
            onRefreshFaults={refreshFaultsData}
   
            onSetSyncing = {setSyncing}
            applianceTrackerDataArray={applianceTrackerDataArray}
            onFetchNotes={fetchNotes}
            
            onSetActiveKey={setActiveKey}
            activeKey = {activeKey}
            onSetEditOff= {setEditOff}
            editOff= {editOff}
            onSetSelectedDevice={handleSelectedDevice}
            onBackPressed={handleBackFromDevice}

            selectedShortID={selectedShortID}

            selectedHistoryFaults= {selectedHistoryFaults}
            selectedFaultView={selectedFaultView}
            isApplianceConfigured={isApplianceConfigured}
            getAppliances_with_id={getTrackerdataForAppliances_with_id}
            connectionState={ connectionState}
            registerData= {registerData}
            onWriteRegister= {writeRegister}
            onWritePasswordRegister={writePasswordRegister}
            onwriteValueStringRegister= { writeValueStringRegister}
            formatTemperature={formatTemperature}
            installers = {installers}
            macAddress = {macAddress}
            upgradeFile = {upgradeFile}
            onSetUpgradeFile={setUpgradeFile}
            upgradeACFile = {upgradeACFile}
            onSetUpgradeACFile = {setUpgradeACFile}
            onSetUpgradeSHFile = {setUpgradeSHFile}
            upgradeSHFile = {upgradeSHFile}
            onSetUpgradeInProgres= {setUpdateInProgress}
            updateInProgress = {updateInProgress}
            onUpdateDeviceName = {updateDeviceName}
            onUpdateUserDeviceName = {updateUserDeviceName}
            onSetACUpdateInProgress = {setACUpdateInProgress}
            onSetSH1UpdateInProgress = { setSH1UpdateInProgress}
            onSetSH2UpdateInProgress = { setSH2UpdateInProgress}
            updateInProgressAC = {updateInProgressAC}
            updateInProgressSH1 = {updateInProgressSH1}
            updateInProgressSH2 = {updateInProgressSH2}
            onaddPSWUrl = {addPSWUrl}
            onaddACUrl = {addACUrl}
            AquaChemUpgradeURLS = {AquaChemUpgradeURLS}
            setNotesData = {setNotesData}
          />

        }
      </Grid>
    )
  };

  return (
    <Authenticator
      components={authComponents}
      hideSignUp={true}
      variation="modal"
    >
      {({ signOut }) =>
      (
        <main className="bg-neutral-100 p-2 sm:p-4">
        
        <TopNavigation
          i18nStrings={i18nStrings}
          identity={{
            href: '',
            title: '',
            logo: { src: banner_w, alt: 'Dontek App logo' },
          }}
          // search={
          //   <Input
          //     ariaLabel="Input field"
          //     clearAriaLabel="Clear"
          //     value={searchValue}
          //     type="search"
          //     placeholder="Search"
          //     onChange={({ detail }) => setSearchValue(detail.value)}
          //   />
          // }
          utilities={[
            // {
            //   type: '',
            //   iconName: 'notification',
            //   ariaLabel: 'Notifications',
            //   badge: true,
            //   disableUtilityCollapse: true,
            // },
            // { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },
            {
              type: 'menu-dropdown',
              text: "Welcome:" + loginId,
              description: loginId,
              iconName: 'user-profile',
              items: profileActions,
            },
            {
              type: 'button',
              text: "Sign Out",
              // iconName: 'notification',
              ariaLabel: 'Notifications',
              badge: false,
              disableUtilityCollapse: true,
              onClick: signOut
            },
          ]}
        />
      {/* <Sidebar>
          <Menu>
     
            <MenuItem> Documentation </MenuItem>
            <MenuItem> Calendar </MenuItem>
          </Menu>
        </Sidebar> */}
      
        

        <AppLayout
          stickyNotifications
          toolsHide={true}
          headerSelector="#header"
          disableContentPaddings={true}
          ariaLabels={{ navigationClose: 'close' }}
          // navigation={
          
          // //   <SideNavigation
          // //   header={{
          // //     href: '#',
          // //     text: 'Service name',
          // //   }}
          // //   items={[{ 
          // //     type: 'button', 
          // //     text: `Paage #1`, 
          // //     href: `#` }]}
          // // />

            
          //   // <SideNavigation activeHref="#/pages" items={navItems} />
          // }
            navigationHide = {true}
            // breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
            contentType="table"
            content={<Content signOut={signOut} />}
          // notifications={<Notifications />}
        />
        </main>
      )
      }

    </Authenticator>
  );
}

export default App;





