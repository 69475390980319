/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      recordType
      recordID
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      appState14
      caseTemp
      connectionStatus
      controllerStatus
      createdAt
      customMac
      customUniqueDeviceId
      datetime
      faults
      heaterState
      heater2State
      macAddress
      modelType
      notes
      ORP
      ORP_RT
      PH
      PH_RT
      PoolTemp
      pumpState
      RoofTemp
      updatedAt
      userName
      userID
      WaterTemp
      group
      acidDosing
      chlorineDosing
      serial_number
      versionNumber
      archived
      productionTest
      date
      ioVersion
      exp1Version
      exp2Version
      chemVersion
      errorCode
      OtherTemp
      poolSP
      spaSP
      pool2SP
      spa2SP
      solarSP
      ORPSP
      PHSP
      version
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      recordType
      recordID
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      appState14
      caseTemp
      connectionStatus
      controllerStatus
      createdAt
      customMac
      customUniqueDeviceId
      datetime
      faults
      heaterState
      heater2State
      macAddress
      modelType
      notes
      ORP
      ORP_RT
      PH
      PH_RT
      PoolTemp
      pumpState
      RoofTemp
      updatedAt
      userName
      userID
      WaterTemp
      group
      acidDosing
      chlorineDosing
      serial_number
      versionNumber
      archived
      productionTest
      date
      ioVersion
      exp1Version
      exp2Version
      chemVersion
      errorCode
      OtherTemp
      poolSP
      spaSP
      pool2SP
      spa2SP
      solarSP
      ORPSP
      PHSP
      version
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      recordType
      recordID
      appState0
      appState1
      appState2
      appState3
      appState4
      appState5
      appState6
      appState7
      appState8
      appState9
      appState10
      appState11
      appState12
      appState13
      appState14
      caseTemp
      connectionStatus
      controllerStatus
      createdAt
      customMac
      customUniqueDeviceId
      datetime
      faults
      heaterState
      heater2State
      macAddress
      modelType
      notes
      ORP
      ORP_RT
      PH
      PH_RT
      PoolTemp
      pumpState
      RoofTemp
      updatedAt
      userName
      userID
      WaterTemp
      group
      acidDosing
      chlorineDosing
      serial_number
      versionNumber
      archived
      productionTest
      date
      ioVersion
      exp1Version
      exp2Version
      chemVersion
      errorCode
      OtherTemp
      poolSP
      spaSP
      pool2SP
      spa2SP
      solarSP
      ORPSP
      PHSP
      version
      __typename
    }
  }
`;
