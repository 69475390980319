import React, { useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  // Link,
  Pagination,
  Table,
  TextFilter,
} from '@cloudscape-design/components';

import {
    MdQrCode2 as IconQRCode,
    MdOutlineEdit as IconEdit,
} from "react-icons/md";
  
import { Button as RsuiteButton,
  Col,
  FlexboxGrid } from 'rsuite';
import { rColours } from '../constants';

import {
    // getControllerIconText ,
    getControllerIconText_FROM_STRING,
    GetDeviceBadgeFromStatus,
    GetBadgeForversion
  } from "./DisplayItems";
import { GiHammerBreak as IconHammer } from "react-icons/gi";
import { COLOURS} from "../styles"
export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatDate(date) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 resources' },
    { value: 20, label: '20 resources' },
    { value: 50, label: '50 resources' },
    { value: 100, label: '100 resources' },
    { value: 200, label: '200 resources' },
  ],
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference: {
  options: [
        { id: '#',        label: "#", visible: true },
        { id: 'id',       label: "id", visible: true, alwaysVisible: true},
        { id: 'modelType',label: "model", visible: true },
        { id: 'name',     label: "name",visible: true },
        { id: 'user_name',     label: "User Name",visible: true },
        { id: 'serial_number', label: "serial", visible: true},
        { id: 'status',   label: "status", visible: true },
        { id: 'version',   label: "version", visible: true },
        { id: 'qr',       label: "qr", visible: true },
        { id: 'lastModifield', label: "lastModifield", visible: true }
  ]
  },
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};


function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}

      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export function CollectionHooksTable(props) 
{
  const [preferences, setPreferences] = useState({ pageSize: 50, visibleContent: ['id', 'modelType', 'name','user_name', 'serial_number', 'status','dateMade','version','qr' ] });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    props.devices,
    {
      filtering: {
        empty: <EmptyState title="No instances" action={<Button>Create instance</Button>} />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );
  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
  
      header={
        <Header
          counter={selectedItems.length ? `(${selectedItems.length}/${props.devices.length})` : `(${props.devices.length})`}
        >
          Instances
        </Header>
      }
      stickyHeader
      stripedRows
      resizableColumns
      variant="embedded"
      // onSelectionChange={({ item })=> props.onViewDevice(item.uniqueDeviceId)}
      columnDefinitions={
        [{
        id: '#',
        header: '#',
        cell: item => item.index,
        ariaLabel: createLabelFunction('#'),
        sortingField: 'index',
        isRowHeader: true,
      },
      {
        id: 'id',
        header: 'ID',
        cell: item => (
          <Button
              variant="inline-link"
              ariaLabel={`Download ${item.name}`}
              onClick={() =>props.onViewDevice(item.uniqueDeviceId)}>
              {item.uniqueDeviceId}
            </Button>
        ),
        // <Link onClick={() => handleViewDevice(item.uniqueDeviceId)} >{item.uniqueDeviceId}  </Link>,
        ariaLabel: createLabelFunction('id'),
        sortingField: 'uniqueDeviceId',
        isRowHeader: true,
      },
      {
        id: 'modelType',
        header: 'Model',
        cell: item => 

        <FlexboxGrid justify="start">
          <FlexboxGrid.Item as={Col} colspan={6} md={6}>
          <div className="tooltip">
          <img 
              src={getControllerIconText_FROM_STRING(item.modelType).icon} 
              alt="Controller Icon" 
              width="30" 
              height="30"/>
            <span className="tooltiptext">{item.modelType}</span> 
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={6} md={6}>
          {item.modelType}
          </FlexboxGrid.Item>
        </FlexboxGrid>,
    
        // cell: item => getControllerIconText_FROM_STRING(item.modelType).icon ,
        ariaLabel: createLabelFunction('Model'),
        sortingField: 'modelType',
      },
      {
        id: 'name',
        header: 'Name',
        cell: item => item.userName,
        ariaLabel: createLabelFunction('Name'),
        sortingField: 'userName',
      },
      {
        id: 'user_name',
        header: 'User Name',
        cell: item => item.usersDeviceName,
        ariaLabel: createLabelFunction('User Name'),
        sortingField: 'usersDeviceName',
      },
      {
        id: 'serial_number',
        header: 'Serial',
        cell: item => "SN:" + item.serialNumber,
        ariaLabel: createLabelFunction('Serial'),
        sortingField: 'serial',
      },
      {
        id: 'status',
        header: 'Status',
        cell: item => GetDeviceBadgeFromStatus(item),
        ariaLabel: createLabelFunction('State'),
        sortingField: 'status',
      },
      {
        id: 'dateMade',
        header: 'Date Made',
        cell: item => 
          <FlexboxGrid justify="start">
            <FlexboxGrid.Item as={Col} colspan={6} md={6}>
              <IconHammer  color={rColours.default} />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={6} md={6}>
            {item.dateMade}
            </FlexboxGrid.Item>
          </FlexboxGrid>,
          
          
        ariaLabel: createLabelFunction('DateMade'),
        sortingField: 'dateMade',
      },
      {
        id: 'version',
        header: 'Version',
        cell:item => GetBadgeForversion(item), //"Ver:" + item.versionNumber,
        ariaLabel: createLabelFunction('Version'),
        sortingField: 'version',
      },
      {
        id: 'qr',
        header: '...',
        cell: item => ( 

          <FlexboxGrid justify="start">
          <FlexboxGrid.Item as={Col} colspan={6} md={6}>
          <RsuiteButton
            startIcon={<IconQRCode/>}
            appearance="primary"
            // icon={IconQRCode}
            color={rColours.default}
            size="sm"
            onClick={() => props.onQRCodeClick(item) }
          /> 
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={6} md={6}>
          <RsuiteButton
              startIcon={<IconEdit/>}
              appearance="primary"
              
              color={rColours.default}
              size="sm"
              onClick={() => props.onEditDevice(item)}
            />
          </FlexboxGrid.Item>
       
      </FlexboxGrid>

        
          
        ),
        // ariaLabel: createLabelFunction('Last modified'),
        // sortingComparator: (a, b) => a.lastModified.valueOf() - b.lastModified.valueOf(),
      },
      
      
      {
        id: 'lastModifield',
        header: 'Last modified',
        cell: item => formatDate(item.lastModified),
        ariaLabel: createLabelFunction('Last modified'),
        sortingComparator: (a, b) => a.lastModified.valueOf() - b.lastModified.valueOf(),
      }]}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <CollectionPreferences
          {...collectionPreferencesProps}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
    />
        //   loading
      //   selectionType="multi"
  );
}