import {

  Text,
  Flex,
 
  Card,
  Grid,
  Bold,

} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPumpSpeed,
  Display2ValueToggleItem,
  DisplayToggleItem,
  DisplaySelectPickerItem,
  DisplayTimePickerItem,
  DisplayTemperatureSliderItem,
 
} from "./DisplayItems";

import {
 
  MdOutlineThermostat as IconThermometer,
 
  MdRoofing as IconSolar,
 
  MdSpeed as IconSpeed,
 
  MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  GiWinterHat as IconWinter,
  GiThermometerCold as IconFrozen,
} from "react-icons/gi";

import {
  TbEngine as IconPump,
} from "react-icons/tb";


import { COLOURS} from "../styles"
import {
  
  offOnOptions,
  DisableEnableOptions,
 solarSensorLocations,
  solarTypeOptions,
} from "../constants";

import {

  REGS,

} from "../registers";

export const SolarView = props => {
    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        
      <Card
      decoration="bottom"
      decorationColor={COLOURS.cardDecoration}
      className="mt-3">
        <div className="max-w-none mt-2 space-y-4 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={COLOURS.default}><Bold>Solar Status</Bold></Text>
          </Flex>

          <Grid
            numItems={2}
            numItemsSm={2}
            numItemsLg={4}
            className="gap-y-4 gap-x-4"
          >
            <DisplayItem register={props.onGetRegisterData(REGS.SOLAR_STATE)} icon={IconSolar} />
            <DisplayItem register={props.onGetRegisterData(REGS.SOLAR_INIT_TEMP)} icon={IconSolar} />
            <DisplayItem register={props.onGetRegisterData(REGS.SOLAR_WINTER_NEXT_MINS)} icon={IconWinter} />
          </Grid>
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2 " >
            <Text color={COLOURS.default}><Bold>Solar Setup</Bold></Text>
          </Flex>
          <Grid
            numItems={2}
            numItemsSm={2}
            numItemsLg={3}
            className="gap-y-4 gap-x-4"
          >
            
            <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.SOLAR_TYPE)} icon={IconPump} options={solarTypeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SOLAR_WINTER_MODE)} icon={IconWinter} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SOLAR_RUN_FROM_HEATER_PUMP)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <DisplayPumpSpeed register={props.onGetRegisterData(REGS.SOLAR_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff} />
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOLAR_START_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} disabled={props.editOff}/>
            <DisplayTimePickerItem register={props.onGetRegisterData(REGS.SOLAR_END_TIME)} icon={IconClock} onChange={props.handleTimesUpdate} disabled={props.editOff}/>
            <DisplayToggleItem register={props.onGetRegisterData(REGS.SOLAR_SENSOR_LOCATION)} icon={IconThermometer} options={solarSensorLocations} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.ANTIFREEZE_ACTIVE)} icon={IconFrozen} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SOLAR_FORCE_RUN_ON)} icon={IconRun} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            
          </Grid>
          <Grid
                numItems={1}
                numItemsSm={1}
                numItemsLg={2}
                className="gap-y-4 gap-x-4"
              >
            <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.SOLAR_SET_TEMPERATURE)} icon={IconSolar} min={10} max={40} step={1} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
            <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.ANTIFREEZE_TEMPERATURE)} icon={IconFrozen} min={0} max={5} step={1} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
            <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.SOLAR_TURN_ON_DIFFERENCE)} icon={IconThermometer} min={6} max={20} step={1} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
            <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.SOLAR_TURN_OFF_DIFFERENCE)} icon={IconThermometer} min={4} max={16} step={1} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
          </Grid>

          </div>
        </Card>
      </Grid>
    )
}