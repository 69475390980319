export const COLOURS = {
  default: "sky",
  cardDecoration: "slate",
  tableHeader: "neutral",

  good: "green",
  error: "red",
  warning: "yellow",

  unknown: "slate",
  other: "zinc",
  neutral: "neutral",

  heating: "orange",
  purging: "indigo",
  standby: "teal",

  apiAccessOff: "stone",
  apiAccessData: "lime",
  apiAccessControl: "emerald",

  modelThrealux: "cyan",
  modelOASIS: "amber",
  modelAquatek: "green",
  modelAqualight: "violet",
  modelReclaim: "stone",
  modelPooltek: "lime",
  modelHenden: "pink",

  modelList: [ "red", "cyan", "amber", "green", "violet", "stone", "lime", "pink"],

  primary: "#3498FF",

  buttonTextSelected: "#fff",
  buttonTextUnselected: "#888",

  border: '#d1d5db',
  goodHex: '#66BB6A',
  errorHex: '#ef4444',
  warningHex: '#f59e0b',
  cardDecorationHex: '#737373',
  titleHex: '#78716c',
  defaultHex: "#0ea5e9",

  faultsList: [
    'red', 'orange', 'amber', 'yellow',
    'indigo', 'violet', 'purple', 'fuschia',
    'lime', 'pink', 'emerald', 'neutral',
    'amber', 'sky', 'red', 'orange'
  ],

  connected: "#009933",
  connecting: "#cccc00",
  disconnected: "#cc3300",
  waiting: "#ff9966"
};

export const csvLinkStyle = {
  backgroundColor: COLOURS.primary,
  borderRadius: '6px',
  color: COLOURS.buttonTextSelected,
  fontSize: 12,
  fontWeight: 500,
  padding: '6px'
};

export const buttonGroupStyle = {
  backgroundColor: 'white',
  borderColor: COLOURS.border,
  borderRadius: '7px',
  borderStyle: 'solid',
  borderWidth: '1px'
};

export const defaultCardStyle = {
  backgroundColor: 'white',
  borderColor: COLOURS.border,
  borderRadius: '6px',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '1em'
};

export const headerCardStyle = {
  backgroundColor: 'white',
  borderColor: COLOURS.border,
  borderRadius: '6px',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '0.5em'
};

export const progressCardStyle = {
  backgroundColor: 'white',
  borderColor: COLOURS.border,
  borderRadius: '6px',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '1em',
  marginTop: 8
};

export const gridCardStyle = {
  backgroundColor: 'white',
  borderColor: COLOURS.border,
  borderRadius: '6px',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '1.1em',
  marginTop: 12
};