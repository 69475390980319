import {
  Card,
  Text,
  Flex,
  // Grid,
  Col,
} from "@tremor/react";

import {
  // MdOutlineArrowBack as IconBack,
  // MdOutlineSync as IconSync,
  // MdOutlineSensors as IconConnected,
  // MdOutlineSensorsOff as IconDisconnected,
  MdOutlineThermostat as IconThermometer,
  // MdPool as IconPool,
  // MdHotTub as IconSpa,
  // MdRoofing as IconSolar,
  // MdWbTwighlight as IconLight,
  // MdOutlineTimer as IconTimer,
  MdFormatListNumbered as IconList,
  MdOutlineInfo as IconInfo,

  // MdSpeed as IconSpeed,
  // MdOutlineWifi as IconWifi,
  // MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  MdOutlineWarningAmber as IconWarning
  // MdOutlineSettings as IconSettings,
} from "react-icons/md";

import {
  TbPlug as IconSocket,
} from "react-icons/tb";
import {
  BiReset as IconReset

}from "react-icons/bi"
import { 
  
  DisplayController,
  DisplayNameInputItem,
  DisplayItem,
  DisplayTextItem,
  DisplayFaults,
  DisplayToggleItem,
  DisplayListAccordian,
  DisplayItemWithReset,
  DisplaySelectPickerItem,
  DisplayToggleButtonItem,
  Display2ValueToggleItem,
  getWifiIconFromRssi
} from "./DisplayItems";

import { COLOURS} from "../styles"
import {
  // offOnOptions,
  ResetOptionsSelect,
  logIntervalOptions,
  logDisableOptions,
  loggingRemoteDisableEnableOptions,
  offOnOptions,
  locationsOptions,
} from "../constants";

// import {
//   TbPlug as IconSocket,
// } from "react-icons/tb";


import {

  REGS,
} from "../registers";

export const SystemInfo = props => {
  
    // const get_app_data = (offset) => {
    //   var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

    //   console.log ( "app tracker items:", appliance_data);
  
  
    //   return appliance_data;
    // }

    

    return (
      
      
            <Card
              decoration="bottom"
              decorationColor={COLOURS.cardDecoration}
              className="mt-3">
              <div
                className="max-w-none mt-2 space-y-2 text-left"
                >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className="space-x-2" >
                  <Text color={COLOURS.default}>System Info</Text>
                </Flex>

                <div className="grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-5 gap-4">
                  <div className="col-span-1 xl:col-span-3">
                    <DisplayNameInputItem register={props.onGetRegisterData(REGS.DEVICE_NAME_START)} icon={IconSocket} onChange= {props.onNameRegisterUpdate} />
                  </div>
                  <DisplayController register={props.onGetRegisterData(REGS.MODEL)}  />
                  <DisplayItem register={props.onGetRegisterData(REGS.WIFI_RSSI)} tc={ COLOURS.default } icon={getWifiIconFromRssi(props.onGetRegisterData(REGS.WIFI_RSSI).value)} />
                  <DisplayItem register={props.onGetRegisterData(REGS.RTC_YEAR)} tc={ COLOURS.default } icon={IconClock} />
                  {/* <DisplayItem icon={IconClock} register={props.onGetRegisterData(REGS.LOCATION)} /> */}
                  <DisplayItem register={props.onGetRegisterData(REGS.AMBIENT_TEMPERATURE)} tc={ COLOURS.default } icon={IconThermometer} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.FIRMWARE_VERSION)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.HARDWARE_VERSION)} />

                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.EXP1_VERSION)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.EXP2_VERSION)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.EXPANSION_PLUS_VERSION)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.IOCTRL_VERSION)} />
                  <DisplayListAccordian icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.OUTPUTS_STATUS)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.INPUTS_STATUS)} />
                  <DisplayFaults icon={IconWarning} tc={COLOURS.default} text="Faults:" register={props.onGetRegisterData(REGS.FAULT_FLAGS)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.TOTAL_HOURS)} />

                  <DisplayItemWithReset icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.RESTART_COUNT)}  onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  <DisplayItemWithReset icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.BOOTUP_COUNT)} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  <DisplayTextItem theTitle = "MacAddr:" tc={ COLOURS.default } theText= {props.macAddress} icon={IconInfo}> </DisplayTextItem>
                </div>


                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.AUTO_SET_TIME)} tc={ COLOURS.default } icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.LOCATION)} tc={ COLOURS.default } icon={IconClock} options={locationsOptions} onChange={props.onPrivateRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  <DisplayToggleButtonItem register={props.onGetRegisterData(REGS.RESET_OPTIONS)} tc={ COLOURS.default } icon={IconReset} options={ResetOptionsSelect} disabled={props.editOff} onChange={props.onPrivateRegisterUpdate} />
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.ADJUST_DST)} tc={ COLOURS.default } icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LOGGING_ENABLE)} tc={ COLOURS.default } icon={IconList} options={loggingRemoteDisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.LOGGING_INTERVAL)} tc={ COLOURS.default } icon={IconClock} options={logIntervalOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  

                  <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.LOGGING_DISABLE_REGS)} tc={ COLOURS.default } icon={IconInfo} options={logDisableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.LOGGING_DISABLE_REGS)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.READ_HEAP_FREE)} />
                  <DisplayItem icon={IconInfo} tc={ COLOURS.default } register={props.onGetRegisterData(REGS.READ_HEAP_MIN)} />
                  
                </div>
              </div>
          </Card>
        
    )
}