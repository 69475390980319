import React, { useEffect } from "react";

import {

  // Badge,
  Button,
  Card,
  // Grid,
  Flex,
  // MultiSelect,
  // MultiSelectItem,
  // // Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableHeaderCell,
  // TableRow,
  Text as TextTremor,

  // TabList,
  // TabGroup,
  // Tab,

} from "@tremor/react";

import CsvLink from "./CsvLink";

import {
  build_fault_string,
  SelectDateRange,
  getDateTimeFromRecordID
} from "./Common";
// import DateRangePicker from "rsuite/DateRangePicker";

import { FaultsDisplayTable  } from "./FaultsTable";

import { COLOURS} from "../styles"
import {
  datetimeFormats,
  // fault_strings_idx,
} from "../constants";

import {
  lightFormat,
} from "date-fns";
import
{
  LoadingView
} from "./DisplayItems";

export const HistoryView = props => {
  const [noData, setNoData] = React.useState(false);
  const [formattedHistoryData, setFormattedHistoryData] = React.useState([]);
  
  const refreshFaultView = () => {
   
    console.log("refresh fault view clicked", props.selectedDateRange)
    props.onSelectedFaultDateRangeChanged(props.selectedDateRange);
  }




  const formatHistoryFaults = ( faultsToFormat) =>
  {
      var formatted = []
      var itr = 1;
      var tempFaultRecord = {}
      console.log("formatting History Faults")
      faultsToFormat.forEach((faultRecord) => {
        tempFaultRecord = {}
        let date_time = getDateTimeFromRecordID( faultRecord.recordID)
        tempFaultRecord.dateTime = lightFormat(date_time, datetimeFormats.long);
        // tempFaultRecord.faults = faultRecord.faults;
        let largeValue = parseInt( faultRecord.faults, 10)
        
        let built_fault_string = build_fault_string( largeValue, false,  "All Faults Cleared");

        tempFaultRecord.faults = built_fault_string.string;
        tempFaultRecord.colour = built_fault_string.colour;
        tempFaultRecord.errorCode = faultRecord.errorCode;
        tempFaultRecord.version = faultRecord.version;
        tempFaultRecord.id = itr;
        itr++;
        console.log("built up a fault record",faultRecord, date_time, tempFaultRecord, built_fault_string, largeValue)
        formatted.push(tempFaultRecord);
      })
    // console.log("Formatted:", formatted);
    setFormattedHistoryData(formatted)
  }

  useEffect(() => {
    console.log("history Faults Changed - ", props.historyFaults)
    
    setNoData( ((props.historyFaults.length === 0) ? true: false) );

    formatHistoryFaults(props.historyFaults);
   }, [props.historyFaults]);
  
   const headingStyle = {
    zIndex: 1000,
    // Higher z-index value
    padding: '10px',
    borderRadius: '5px',
    margin: '0',
    textShadow: '2px 2px 4px white',
  };
  

  return (
    <div className="mt-3" >
     
      <Flex 
        justifyContent="center"
        alignItems="center"
        className="space-x-2"

      >
        <SelectDateRange
          range={props.selectedDateRange}
          maxDays={30}
          onChange={props.onSelectedFaultDateRangeChanged}
          style={headingStyle}
        />
        <Button
          color={COLOURS.default}
          size="sm"
          onClick={refreshFaultView}
          disabled={(props.selectedDevice.length === 0) || props.loading}
        >Refresh</Button>
      </Flex>

      {
        (props.loading ? (<LoadingView progress={props.progress} />) : (props.selectedDevice.length === 0 ? (
          <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <TextTremor color={COLOURS.warning}>Select a controller</TextTremor>
          </Card>
        ) : (
          (noData === true) ? (
            <TextTremor color={COLOURS.good}>No faults found</TextTremor>
          ) : (
          <div>
            <Flex justifyContent="end" alignItems="center" className="space-x-2">
              <CsvLink
                text="Download"
                data={formattedHistoryData}
                headers={["id","dateTime", "faults", "errorCode", "version"]}
                device={props.selectedDevice}
                itemType="faults"
                format={datetimeFormats.long}
              />
            </Flex>
        
            <FaultsDisplayTable
            formattedHistoryFaults={formattedHistoryData}
            />
          </div> 
          )
        )
        ))
      }
    </div>
  )

    
  };

  