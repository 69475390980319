import React, { useState, useEffect } from "react";

import {
  Grid,
  DonutChart,
  LineChart,
} from "@tremor/react"; // TODO

import {
  Button,
  ButtonToolbar,
  FlexboxGrid,
  List,
  Stack
} from "rsuite";

import {
  MdOutlineAddCircle as IconMore,
  MdOutlineRemoveCircle as IconLess,
  MdOutlineSync as IconRefresh
} from "react-icons/md";

import {
  APP_CONFIG,
  modelTypeDetails,
} from "../constants";

import { COLOURS} from "../styles"
import {
  defaultCardStyle,
  headerCardStyle,
  progressCardStyle
} from "../styles";

import ResizeObserver from 'resize-observer-polyfill'
import { LoadingView } from "./DisplayItems";

const StatCard = ({title, data, colors, showList, totals}) => {
  const [sum, setSum] = useState(0);

  useEffect(() => {
    if( totals !== undefined &&  totals !== 0)
    {
      setSum(totals);
    }
    else
    {
      const temp = data.reduce((acc, obj) => acc + obj.value, 0);
      setSum(temp);
    }
  }, [data, totals]);

  return (
    (sum !== 0) &&
    <Stack
      alignItems="stretch"
      justifyContent="flex-start"
      direction="column"
      spacing={16}
      style={defaultCardStyle}
    >
      <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
        {title + " (" + sum + ")"}
      </span>
      <DonutChart
        data={data}
        category="value"
        index="name"
        colors={colors}
        showAnimation={false}
        showLabel={false}
        variant="pie"
        height={showList ? "h-56" : "h-80"}
      />
      {(showList === true) &&
      <List hover size="sm">
        {data.map((item) => (
          <List.Item key={item.name}>
            <FlexboxGrid
              align="middle"
              justify="space-between"
              style={{ fontSize: '0.9em' }}
            >
              <FlexboxGrid.Item>{item.name + " (" + item.value + ")"}</FlexboxGrid.Item>
              <FlexboxGrid.Item>{(item.value*100/sum).toFixed(1) + "%"}</FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>}
    </Stack>
  );
}



export const StatsView = (props) => {

  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }

  const buildCategories = (dataToMap) => {
    var categoriesBuilt = dataToMap.map((item) => item.label);
    categoriesBuilt.push( "Total");
    return categoriesBuilt;
  }
  
  const buildColours = (dataToMap) => {
    var coloursBuilt = dataToMap.map((item) => item.colour);
    coloursBuilt.push( COLOURS.apiAccessOff);
    return coloursBuilt;
  }
  

  return (
    <Stack
      alignItems="stretch"
      justifyContent="flex-start"
      direction="column"
      style={{ marginTop: 8 }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        spacing={8}
        style={headerCardStyle}
      >
        <Stack.Item>
          <span style={{
            color: COLOURS.primary,
            fontSize: 16,
            marginLeft: 8
          }}>
            {props.loading ? ("Loading " + props.progress + "%") : (props.stats.devicesTotal + " Controllers")}
          </span>
        </Stack.Item>
        <Stack.Item alignSelf="flex-end">
          <ButtonToolbar>
            {(props.loading === false) &&
            <Button
              appearance="primary"
              color="blue"
              size="sm"
              endIcon={props.showList ? <IconLess /> : <IconMore />}
              onClick={props.onShowListChanged}
            >
              {props.showList ? "Less" : "More"}
            </Button>}
            <Button
              appearance="primary"
              color="blue"
              size="sm"
              endIcon={<IconRefresh />}
              loading={props.loading}
              onClick={props.onRefresh}
            >
              Refresh
            </Button>
          </ButtonToolbar>
        </Stack.Item>
      </Stack>
      {
        (props.loading === true) ? <LoadingView progress={props.progress} />:
        <Stack.Item>
        <Grid
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={3}
        className="gap-4  mt-2"
        >
          <StatCard
            title="Production"
            data={[
              { name: "Registered", value: props.stats.devicesRegistered },
              { name: "Not Registered", value: props.stats.devicesNotRegistered },
              { name: "Un Registered", value: props.stats.devicesUnRegistered },
              { name: "In Production", value: props.stats.devicesinProduction }
            ]}
            colors={[COLOURS.default, COLOURS.unknown, COLOURS.other]}
            showList={props.showList}
          />
          
           <StatCard
            title="Model Type"
            data={props.stats.modelType}
            colors={props.stats.modelType.map((item) =>item.colour)}
            showList={props.showList}
          />

          <StatCard
            title="Controller Status"
            data={[
              { name: "Good", value: props.stats.devicesStatusGood },
              { name: "Fault", value: props.stats.devicesStatusFault },
              { name: "Offline", value: props.stats.devicesStatusOffline },
              { name: "Unknown", value: props.stats.devicesStatusUnknown }
            ]}
            colors={[COLOURS.good, COLOURS.error, COLOURS.warning, COLOURS.unknown]}
            showList={props.showList}
          />
          <StatCard
            title="Faults"
            data={props.stats.faults}
            colors={props.stats.faults.map((item) => item.colour)}
            showList={props.showList}
            totals = {0}
          />
          
          <StatCard
            title="Versions"
            data={props.stats.versions}
            colors={props.stats.versions.map((item) => item.colour)}
            showList={props.showList}
          />
          
        </Grid>

        <LineChart
        className="h-80"
        data={props.stats.createdMonth}
        index="name"
        categories={buildCategories(modelTypeDetails)}
        colors={buildColours(modelTypeDetails)}
        // valueFormatter={dataFormatter}
        yAxisWidth={60}
        showLegend={true}
        />
        </Stack.Item>
      }
    </Stack>
  );
}