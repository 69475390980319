export function isBitSet(number, position) {
  return (number >> position) & 1;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const checkNameValid = (name) => {
  const regex = new RegExp("^([-A-Za-z0-9_() ]){1,20}$");
  return regex.test(name);
}


export const checkUserNameValid = (name) => {
  
  const regex = new RegExp("^([-A-Za-z0-9_:() ]){1,200}$");
  let result = regex.test(name);
  console.log("Check User Name:", name , "result = ", result)
  return result
}

export const checkIdValid = (id) => {
  const regex = new RegExp("^([0-9]){17,}$");
  return regex.test(id);
}

export const insertSpace = (text) => {
  if(text) {
    return text.match(/.{1,3}/g).join(' ');
  }
  return '';
}

export const isAsciiPrintable = (ch) => {
  return (ch >= 0x20 && ch <= 0x7E);
}
