import {
  Col,
  Text,
  Flex,
 
  Card,
  //Grid,
  Bold,

} from "@tremor/react";

import { 
  DisplayControlPumpSpeed,
  DisplayToggleButtonItem,
  DisplaySocketValveVFStatusItem,
} from "./DisplayItems";

import {
  MdPool as IconPool,
  MdHotTub as IconSpa,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  TbEngine as IconPump,
} from "react-icons/tb";

import {
  GiValve as IconValve,
} from "react-icons/gi";

import {
 
  TbPlug as IconSocket,
} from "react-icons/tb";
import { 

  Panel,

} from "rsuite";

import { COLOURS} from "../styles"
import {
  spaModeOptions,
//   offOnOptions,
//   DisableEnableOptions,
//  solarSensorLocations,
//   solarTypeOptions,

} from "../constants";

import {

  REGS,

} from "../registers";

export const ControlView = props => {
  
  return (
    <div className="grid grid-cols-1 gap-4">
      <Card
        decoration="bottom"
        decorationColor={COLOURS.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-4 space-y-2 text-center" >
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={COLOURS.default}><Bold>Control</Bold></Text>
          </Flex>
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-4">
            <div className="col-span-0 lg:col-span-1"></div>
            <div className="col-span-1 lg:col-span-2">
              {/* <Panel bordered={ true }  style={{ backgroundColor: 'white' }}> */}
                {/* <Flex
                  justifyContent="between"
                  alignItems="center"
                  className="space-x-2" > */}
                  <DisplayToggleButtonItem register={props.onGetRegisterData(REGS.SPA_MODE)} icon={IconSpa} iconOff={IconPool} options={spaModeOptions} disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_1_SETTING)} onChange={props.handleRegisterUpdate} />
            </div>
            <div className="col-span-1 lg:col-span-2">
                  <DisplayControlPumpSpeed register={props.onGetRegisterData(REGS.PUMP_MANUAL_ON_OFF)} icon= { IconPump} onChange={props.handleRegisterUpdate} disabled={props.editOff} borderOff={false} ></DisplayControlPumpSpeed>
                {/* </Flex> */}
              {/* </Panel> */}
            </div>
            <div className="col-span-0 lg:col-span-1"></div>
            <div className="col-span-1 lg:col-span-6"></div>
          </div>
        </div>
        <div className="gap-y-4 gap-x-4"></div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-4">
        {/* <Grid
            numItems={1}
            numItemsSm={1}
            numItemsLg={3}
            className="gap-y-4 gap-x-4"
          > */}
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_1_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_1_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE1_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_1_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_1_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_1_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_1_RUN_ONCE_STOP_TIME)} icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_1_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {1} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_2_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_2_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE2_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_2_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_2_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_2_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_2_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_2_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {2} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_3_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_3_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE3_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_3_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_3_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_3_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_3_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_3_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {3} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
    
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_4_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_4_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE4_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_4_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_4_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_4_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_4_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_4_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {4} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_5_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_5_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE5_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_5_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_5_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_5_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_5_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_5_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {5} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_6_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_6_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE6_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_6_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_6_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_6_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_6_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_6_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {6} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>

            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_7_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_7_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE7_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_7_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_7_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_7_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_7_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_7_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {7} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.SOCKET_8_STATUS)} mode_register={props.onGetRegisterData(REGS.SOCKET_8_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE8_NAME_START)} type_register = {props.onGetRegisterData(REGS.SOCKET_8_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.SOCKET_8_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.SOCKET_8_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.SOCKET_8_RUN_ONCE_STOP_TIME)}  icon={IconSocket} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.SOCKET_8_SETTING)} onChange={props.handleRegisterUpdate} socketNumber = {8} displayType="SOCKET" duty_cycle_secs_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_SECS_LEFT)} duty_cycle_status_reg={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE_STATUS)}/>
          
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VALVE_1_STATUS)} mode_register={props.onGetRegisterData(REGS.VALVE_1_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE9_NAME_START)}  type_register = {props.onGetRegisterData(REGS.VALVE_1_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VALVE_1_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VALVE_1_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VALVE_1_RUN_ONCE_STOP_TIME)} icon={IconValve} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VALVE_1_SETTING)} onChange={props.handleRegisterUpdate}  displayType="VALVE"/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VALVE_2_STATUS)} mode_register={props.onGetRegisterData(REGS.VALVE_2_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE10_NAME_START)} type_register = {props.onGetRegisterData(REGS.VALVE_2_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VALVE_2_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VALVE_2_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VALVE_2_RUN_ONCE_STOP_TIME)} icon={IconValve} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VALVE_2_SETTING)} onChange={props.handleRegisterUpdate} displayType="VALVE"/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VALVE_3_STATUS)} mode_register={props.onGetRegisterData(REGS.VALVE_3_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE11_NAME_START)} type_register = {props.onGetRegisterData(REGS.VALVE_3_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VALVE_3_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VALVE_3_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VALVE_3_RUN_ONCE_STOP_TIME)} icon={IconValve} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VALVE_3_SETTING)} onChange={props.handleRegisterUpdate}  displayType="VALVE"/>
            <DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VALVE_4_STATUS)} mode_register={props.onGetRegisterData(REGS.VALVE_4_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE12_NAME_START)} type_register = {props.onGetRegisterData(REGS.VALVE_4_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VALVE_4_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VALVE_4_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VALVE_4_RUN_ONCE_STOP_TIME)} icon={IconValve} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VALVE_4_SETTING)} onChange={props.handleRegisterUpdate}  displayType="VALVE"/>
          
        </div>
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-4 gap-x-4">
            <div className="col-span-1 lg:col-span-6"></div>
            <div className="col-span-0 lg:col-span-1"></div>
            <div className="col-span-1 lg:col-span-2"><DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VF_CONTACT_1_STATUS)} mode_register={props.onGetRegisterData(REGS.VFCONTACT_1_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE13_NAME_START)} type_register = {props.onGetRegisterData(REGS.VFCONTACT_1_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VFCONTACT_1_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VFCONTACT_1_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VFCONTACT_1_RUN_ONCE_STOP_TIME)} icon={IconSettings} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VFCONTACT_1_SETTING)} onChange={props.handleRegisterUpdate} displayType="VF"/>
            </div>
            <div className="col-span-1 lg:col-span-2"><DisplaySocketValveVFStatusItem status_register={props.onGetRegisterData(REGS.VF_CONTACT_2_STATUS)} mode_register={props.onGetRegisterData(REGS.VFCONTACT_2_MODE)} name_register= {props.onGetRegisterData(REGS.APPLIANCE12_NAME_START)} type_register = {props.onGetRegisterData(REGS.VFCONTACT_2_TYPE)} run_once_enable_reg= {props.onGetRegisterData(REGS.VFCONTACT_2_RUN_ONCE_ENABLE)} run_once_start_reg={props.onGetRegisterData(REGS.VFCONTACT_2_RUN_ONCE_START_TIME)} run_once_stop_reg={props.onGetRegisterData(REGS.VFCONTACT_2_RUN_ONCE_STOP_TIME)} icon={IconSettings} tc={ COLOURS.default } disabled={props.editOff} socket_setting={props.onGetRegisterData(REGS.VFCONTACT_2_SETTING)} onChange={props.handleRegisterUpdate} displayType="VF"/>
            </div>
            <div className="col-span-0 lg:col-span-1"></div>
        </div>
      </Card>
    </div>
    // </Grid>
  )
}