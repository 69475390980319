import {
 
  Text,
  Flex,

  Card,
  Grid,
  Title,
} from "@tremor/react";

import { 
  Display2ValueToggleItem,
  DisplayItem,
  DisplaySensorOffsetSliderItem,
  DisplaySliderItem,
  DisplayStepperItem,
  DisplayToggleButtonItem,
  DisplayNameInputItem,
  DisplayToggleItem,
  DisplayNumberInputItem,
  DisplaySelectPickerItem,
  getWifiIconFromRssi,
} from "./DisplayItems";

import {

  MdOutlineThermostat as IconThermometer,
  MdPool as IconPool,
  MdHotTub as IconSpa,

  MdWbTwighlight as IconLight,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
  MdOutlineInfo as IconInfo,
} from "react-icons/md";

import {
  BiReset as IconReset

}from "react-icons/bi"

import {
  TbEngine as IconPump,
  TbPlug as IconSocket,
} from "react-icons/tb";

import { COLOURS} from "../styles"
import {
  offOnOptions,
  DisableEnableOptions,
  thermistorTypeOptions,
  ResetWifiSelect,
  modelTypeDetails,

} from "../constants";

import {
  REGS,
} from "../registers";

import { 

  temperatureValueToString,
  // convertSensorOffset,
} from "./RegistersHandler";


export const formatTempSensorOffset = (value, unit) => {
  const formated = temperatureValueToString(value - 15, unit);
  console.log("formatTempSensorOffset", value, value-15, formated)
  return formated
}

export const SystemSettingsView = (props) => {
  
  return (
    <Grid
    numItems={1}
    numItemsSm={1}
    numItemsLg={1}
    className="gap-4"
    >
      <Card
        decoration="bottom"
        decorationColor={COLOURS.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-0 space-y-4 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={COLOURS.default}>System Setup</Text>
          </Flex>

          <Grid
          numItems={3}
          numItemsSm={2}
          numItemsLg={2}
          numItemsMd={2}
          numItemsXl={3}
          className="gap-4"
          >
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.DEVICE_NAME_START)} icon={IconSocket} onChange= {props.onNameRegisterUpdate} />
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.POOL_NAME_START)} icon={IconPool} onChange= {props.onNameRegisterUpdate} />
            <DisplayNameInputItem register={props.onGetRegisterData(REGS.SPA_NAME_START)} icon={IconSpa} onChange= {props.onNameRegisterUpdate} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_1_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_2_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <DisplayToggleItem register={props.onGetRegisterData(REGS.THERMISTOR_3_TYPE)} icon={IconThermometer} options={thermistorTypeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <DisplaySensorOffsetSliderItem register={props.onGetRegisterData(REGS.THERMISTOR_1_OFFSET)} icon={IconThermometer} min={-5} max={5} step={0.5} onChange={props.handleRegisterUpdate} disabled={props.editOff} />
            <DisplaySensorOffsetSliderItem register={props.onGetRegisterData(REGS.THERMISTOR_2_OFFSET)} icon={IconThermometer} min={-5} max={5} step={0.5} onChange={props.handleRegisterUpdate} disabled={props.editOff} />
            <DisplaySensorOffsetSliderItem register={props.onGetRegisterData(REGS.THERMISTOR_3_OFFSET)} icon={IconThermometer} min={-5} max={5} step={0.5}  onChange={props.handleRegisterUpdate} disabled={props.editOff} />
            <DisplaySensorOffsetSliderItem register={props.onGetRegisterData(REGS.THERMISTOR_4_OFFSET)} icon={IconThermometer} min={-5} max={5} step={0.5}  onChange={props.handleRegisterUpdate} disabled={props.editOff} />
            <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_CALL_ENABLE)} icon={IconThermometer} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <DisplayStepperItem register={props.onGetRegisterData(REGS.PUMP_CALL_RUN_ON_TIME)} icon={IconClock} step={5} min={0} max={30} onChange={props.handleRegisterUpdate}/>
            <DisplayToggleItem register={props.onGetRegisterData(REGS.PUMP_CALL_ENABLED_ITEMS)} icon={IconThermometer} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          </Grid>

          
          <Grid
                numItems={2}
                numItemsSm={2}
                numItemsLg={3}
                className="gap-4"
              >
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SLAVE_1_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SLAVE_2_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.SLAVE_3_ENABLE)} icon={IconSettings} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            
            
            
            <DisplayItem register={props.onGetRegisterData(REGS.EXP1_TYPE)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.EXP2_TYPE)} icon={IconSettings} />
            <div> </div>
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_1_ONLINE_STATE)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_2_ONLINE_STATE)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_3_ONLINE_STATE)} icon={IconSettings} />

            <DisplayNumberInputItem register={props.onGetRegisterData(REGS.SLAVE_1_ADDRESS)} icon={IconSettings} onChange={props.handleRegisterUpdate} />
            <DisplayNumberInputItem register={props.onGetRegisterData(REGS.SLAVE_2_ADDRESS)} icon={IconSettings} onChange={props.handleRegisterUpdate} />
            <div> </div>
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_1_CONFIGURE)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_2_CONFIGURE)} icon={IconSettings} />
            <div> </div>
            

            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_1_ONLINE_COUNT)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_2_ONLINE_COUNT)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.SLAVE_3_ONLINE_COUNT)} icon={IconSettings} />
            <DisplayItem register={props.onGetRegisterData(REGS.EXPANSION_PLUS_WIFI_RSSI)} tc={ COLOURS.default } icon={getWifiIconFromRssi(props.onGetRegisterData(REGS.EXPANSION_PLUS_WIFI_RSSI).value)} />
            

            <div> </div>
            <DisplayItem register={props.onGetRegisterData(REGS.HEATER_EXPANSION_PRESENT)} icon={IconSettings} />
            <div> </div>
          </Grid>
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={COLOURS.default}>Lights</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_1_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_2_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_3_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_1_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_2_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_3_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_LINKED)} icon={IconLight} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          </Grid>
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={COLOURS.default}>Other</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.ENABLE_UUID)}     icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.OTA_AUTH)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.OTA_HTTPS_ONLY)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.TEST_OUTPUT)}     icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.TEST_420MA)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>

          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.FILTER_TIMEn_ENABLE)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          {/* <Display2ValueToggleItem register={props.onGetRegisterData(REGS.FWUPDATE_EXPANSION_PLUS)}        icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/> */}
         
          <DisplayItem icon={IconInfo} register={props.onGetRegisterData(REGS.FWUPDATE_EXPANSION_PLUS)} borderOff={false} />
          <DisplayItem icon={IconInfo} register={props.onGetRegisterData(REGS.EXPANSION_PLUS_FWUPDATE_ERROR)} borderOff={false} />
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.TEST_DISPLAY)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <DisplayNumberInputItem register={props.onGetRegisterData(REGS.DEVICE_PASSCODE)} icon={IconSettings} onChange={props.onPrivateRegisterUpdate} />
          <DisplayNumberInputItem register={props.onGetRegisterData(REGS.INSTALLER_MENU_CODE)} icon={IconSettings} onChange={props.onPrivateRegisterUpdate} />
         
          
          
          <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LOGGING_ENABLE)}  icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <DisplayToggleButtonItem register={props.onGetRegisterData(REGS.RESET_OPTIONS)} tc={ COLOURS.default } icon={IconReset} options={ResetWifiSelect} disabled={props.editOff} onChange={props.onPrivateRegisterUpdate} />
          <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.SET_MODEL)} icon={IconPump} options={modelTypeDetails} onChange={props.onPrivateRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          <DisplaySliderItem register={props.onGetRegisterData(REGS.LCD_CONTRAST)} icon={IconLight} min={0} max={60} step={5} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
          </Grid>

          </div>
      </Card>
        
    </Grid>
  );
}