/* global BigInt */
import React, { useState, useEffect } from "react";
import {QRCodeSVG} from 'qrcode.react';
import { Text, TextField } from "@aws-amplify/ui-react";
import { CRC8 , 
  CRC8_POLY} from "../other/crc8";
import {
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  MultiSelect,
  MultiSelectItem,
  // Select,
  // SelectItem,
  Subtitle,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextInput,
  Title,
} from "@tremor/react";

import {checkUserNameValid,
  checkIdValid,
  insertSpace} from "../utils/utilities";
import DateRangePicker from "rsuite/DateRangePicker";
import Modal from "rsuite/Modal";
import Progress from "rsuite/Progress";
import QRTest from "./qRScanner";
import {
  MdOutlineAddCircleOutline as IconAdd,
  MdOutlineEdit as IconEdit,
  MdQrCode2 as IconQRCode,
  MdOutlineArrowDownward as IconArrowDown,
  MdOutlineArrowUpward as IconArrowUp,
  MdOutlineSort as IconSort,
  MdDelete as IconRubbish
} from "react-icons/md";
import { SelectPicker } from 'rsuite';
import { 
  addMinutes,
  differenceInMinutes,
  getHours,
  getMinutes,
  getSeconds,
  // isSameDay, 
  lightFormat, 
  // parse , 
  parseISO,
  setDate,
} from "date-fns";

import { COLOURS} from "../styles"
import { 
  controller_t,
  modelTypeDetails,
  datetimeFormats, 
  runOnceTimerOptions,
  sortOrder,
  SocketTypesSelect,
  faultTable,
} from "../constants";

import { 
  getControllerBrandIcon_FROM_STRING,
  convertDeviceToIconNumber,
  getControllerIconText_FROM_STRING
} from "./DisplayItems"


import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


export const DebounceAddressSelection = (value, { handleClose }) => 
{
  const [address, setAddress] = useState(value);

  // constructor(props) {
  //   super(props);
  //   this.state = { address: '' };
  // }

  const handleChange = (theAddress) => {
    setAddress( theAddress );
  };

  const handleSelect = (theAddress) => {
    setAddress( theAddress )
     handleClose(theAddress );
    geocodeByAddress(theAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  return (
    
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onPlaceSelect={ () => handleClose(address)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

const { combine, allowedMaxDays, afterToday } = DateRangePicker;

export const getUIDFromDb = (recordID) => {
  var built_UID = recordID;

  if ( undefined !== built_UID)
  {
    if ( built_UID.startsWith("UID"))
    {
      built_UID = built_UID.substring(4,21);
    }
    // built_UID.replace( \|+$/g, '');
    while(built_UID.charAt(built_UID.length-1)==='_') {
      built_UID = built_UID.substring(0,built_UID.length-1);
    }

  }
  // console.log ( "Built_UID:; ",built_UID);
  //  464142203461
  //  46414220346172531

  return built_UID
}


// var toggler = 0;

export const getShortIDFromUID = ( UID ) =>
{
 
  var built_UID1 = "0x" + getMacAddressFromUID(UID);
  let built_UID =  BigInt(built_UID1).toString(10);
  // if ( ++toggler %251 == 0)
  // {
  //   console.log ( "getShortIDFromUID", UID, built_UID1, built_UID);
  // }
  return built_UID
}

export const getIDSFromDb = (recordID) => {
  var built_long_UID = getUIDFromDb(recordID);
  // var built_UID = getShortIDFromUID(built_long_UID);
  return {shortUID: 0, longUID: built_long_UID};
}


export const getRecordIDFromUIDWithDate = (value) => {
  var built_recordID = "UID_";
  if ( undefined !== value)
  {
    built_recordID+= value+"_"+getDateTimeForRecordID()
  }
  //console.log ( "built_recordID:; ",built_recordID);
  //  464142203461
  //  46414220346172531

  return built_recordID
}


export const getRecordIDFromUIDNoDT = (value) => {
  var built_recordID = "UID_";
  if ( undefined !== value)
  {
    built_recordID+= value;
  }
  // console.log ( "built_recordID:; ",built_recordID);
  return built_recordID
}
export const getRecordIDFromUID = (value) => {
  var built_recordID = "UID_";
  if ( undefined !== value)
  {
    built_recordID+= value+"_DT_XXXX"
  }
  // console.log ( "built_recordID:; ",built_recordID);
  //  464142203461
  //  46414220346172531

  return built_recordID
}

export const doIhaveAccessToController = () =>
{
}

export const getDateTimeForRecordID = () =>
{
  var builtDateTime = "DT_"; // 2023-07-27T22:32:09.096Z
  try {
    let curr_date = new Date().toISOString();
    builtDateTime = builtDateTime + curr_date;
  } catch (error) {
    console.log("could not convert time date correctly");
  }
  
  return builtDateTime
}


export const getDateTimeFromRecordID_DEBUG = (recordID) =>
{
  var builtDateTime = getDateTimeFromRecordID(recordID); // 2023-07-27T22:32:09.096Z
  if ( builtDateTime === undefined)
  {
    console.log("date time undefined")
  }
  else
  {
    console.log ( "get date time: ",recordID,  " built: ", builtDateTime ," LF: ", lightFormat( builtDateTime, datetimeFormats.mid));
  }
  return builtDateTime
}

export const getDateTimeFromRecordID = (recordID) =>
{
  var builtDateTime = undefined; // 2023-07-27T22:32:09.096Z
  if ( recordID !== undefined)
  {
    try {
      var myRecordSplut = recordID.split("DT_")[1];
      //console.log( myRecordSplut)
      builtDateTime = parseISO( myRecordSplut);
    } catch (error) {
      console.log(" could not get time date from recordID", recordID)
    }
    
  }
  return builtDateTime
}

export const AddDevice = ({ open, handleClose }) => {
  const [id, setId] = useState('');
  const [idValid, setIdValid] = useState(false);
  const [devType, setType] = useState(controller_t.NONE)
  const [textValue, setTextValue] = useState('');
  

  const [usersDevName, setUsersDevName] = useState('Unknown');

  const validateUsersName = (e) => {
    console.log("Validating UsersNames")
    setUsersDevName(e.currentTarget.value);
   //setValid(checkUserNameValid(e.currentTarget.value));
  };

  const doValidateId = (value) =>
  {
    setTextValue(value);
    console.log(value);
    if ( value.length === 17) //UID
    {
        //Need to determine the type from the UID
        setType( getModelTypeValueFromUID(value).type );
    }
    else if ( value.length === 12 ) // MAC address
    {
      //Convert value to MAC address.
      value = encode_mac_address(value,controller_t.AQUATEK );
    }

    console.log ( " length:", value.length,"value encoded: ", value )
    
    setId(value);
    setIdValid(checkIdValid(value))
  }
  const validateId = (e) => {
  
    var value = e.currentTarget.value
    doValidateId(value)
  };


  const handleScan = (e) => {
    console.log("HandleResult",e)
    doValidateId(e.text)
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconAdd} color={COLOURS.default} />
            <Subtitle color={COLOURS.default}>Add new controller</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-2">
          <TextField
            label="ID"
            placeholder="Enter a valid ID"
            maxLength={17}
            size="small"
            required
            value={textValue}
            onChange={validateId}
          />
         
          < QRTest
          onHandleScan = {handleScan}
          >
          </QRTest>
          {/* <TextField
            label="Name"
            placeholder="Enter a valid Name"
            maxLength={20}
            size="small"
            required
            onSelect={validateName}
          /> */}

          <TextField
            label="Custom User Name"
            defaultValue={'Unknown'}
            placeholder="Enter a valid name"
            maxLength={300}
            size="small"
            required
            onChange={validateUsersName}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
          <Button color={COLOURS.default} size="sm" onClick={() => handleClose({ id,  devType, usersDevName })} disabled={!idValid }>Confirm</Button>
          <Button color={COLOURS.default} size="sm" variant="secondary" onClick={() => handleClose(null)}>Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}


export const getMacAddressFromUID = ( UID) =>
{
  var mac = 0;
  if ( UID !== undefined)
  {
    let hexString = BigInt(getUIDFromDb(UID)).toString(16).padStart(14, '0');
    mac = hexString.substring(0,12);
  }
  return mac;
}
  
   

function convertToHexString(integer) {
  var str = Number(integer).toString(16);
  return str.length === 1 ? "0" + str : str;
};


export const encode_mac_address = (topicString, controllerType ) => 
{
    var encoded;
    var sting = String(topicString)
    var byte_array = sting.split('').map(function(x){return x.charCodeAt(0)})
    var crc_poly = CRC8_POLY.AQUATEK;

    const themodel = modelTypeDetails.find( model => model.value === controllerType)
    if ( themodel !== undefined)
    {
      crc_poly = themodel.crc_value;
    }
   
    
    var crc8 = new CRC8(crc_poly,0)
    var checksum = crc8.checksum(byte_array);
    let crc_hex_string = convertToHexString(checksum);
    let combined_string = "0x"+topicString+crc_hex_string;
    var int2_value = BigInt(combined_string) //, radix: 16) ?? 0
    encoded = String(int2_value).padStart( 17, '0');
    //  console.log ( "encode mac address:", controllerType, topicString, checksum, combined_string, encoded, "int", int2_value);
    return encoded;
}

export const getModelTypeValueFromUID = ( UID  ) => 
{
    var controllerType = controller_t.NONE;
    var macAddrString = getMacAddressFromUID(UID);
    var typeStr = "None";
    

    var result = false;
    modelTypeDetails.every(theModel => {
      result = true;
      if ( theModel.value !== controller_t.NONE )
      {
        const encodedStr = encode_mac_address(macAddrString, theModel.value);
        if ( encodedStr === UID)
        {
          controllerType = theModel.value;
          typeStr = theModel.label;
          result = false;
        }
      }
      return result;
    });
    return { type: controllerType, string: typeStr }
}


// export const speedHasElementSID = (arr, shortID) => arr.some(el => el.shortDeviceId === shortID);
export const hasElement = (arr, shortID) => arr.some(el => getIDSFromDb(el.recordID).shortUID === shortID);
export const speedHasElement = (arr, uniqueDeviceId) => arr.some(el => el.uniqueDeviceId === uniqueDeviceId);

export const getModelTypeStringFromValue = (modelType) =>
{
  var str = "None"
  const found = modelTypeDetails.find(model => model.value === modelType)
  if ( found)
  {
    str = found.label;
  }
  return str;
}

// export const getDeviceFaults = (device) => {
//   let str = device.ctrlStatDateTooltip;

//   const idx = device.faults[0].indexOf(" ");
//   if(idx > 0) {
//     str = str + device.faults[0].substring(idx);
//   } else {
//     str = str + " " + device.faults[0];
//   }
//   if(device.faults.length > 1) {
//     str = str + " ..";
//   }
//   return str;
// }

export const isALight = (type_register) =>
{
  var isALight = false;
  if ( type_register.value === SocketTypesSelect[5].value
    || type_register.value === SocketTypesSelect[6].value)
  {
    isALight = true;
  }

  return isALight;
}


export const isASanitiser = ( type_register) =>
{
  var ASanitiser = false;
  if ( type_register.value === SocketTypesSelect[1].value)
  {
    ASanitiser = true;
  }

  return ASanitiser;
}

export const getLightNumber = ( socketNumber) =>
{
  var lightCount = 0


  return lightCount;
}


export const isDeviceInFault = (device) => {
  var result = false;
  if ( device !== undefined)
  {
    if ( isCurrentFaultsARealFault(device))
    {
        result = true
    }
  }
  else
  {
    console.log("device is undefined")
  }
   return result;
};


export const isCurrentFaultsARealFault = (device) => 
{
  var result = false;
  if ( device.currentFaults !== 0 
  && (device.currentFaults !== "0")
  && (device.currentFaults !== null)
  && (device.currentFaults !== "2147483648") )
  {
    result = true;
  }
  return result;
}




export const isDeviceGood = (device) => 
{
  var result = false;
  if ( device !== undefined)
  {
    if ( device.connStatDesc === "ONLINE") 
    {
      if (device.ctrlStatDesc === "GOOD")
      { 
        result = true
      }
    }
  }
  return result;
};

export const isDeviceOffline = (device) => 
{
  var result = true;
  if ( device !== undefined)
  {
    if ( device.connStatDesc === "ONLINE") 
    {
      result = false
    }
  } 
  return result;
};

export const ShowDeviceQR = ({ open, device, handleClose }) => {
  const textStyle = {
    textAlign: 'center',
    fontFamily: 'monospace',
    fontSize: 'larger',
    fontWeight: '600'
  };
  const generateQR = (e) => {

   var mac_address = encode_mac_address( getMacAddressFromUID(e.uniqueDeviceId) , convertDeviceToIconNumber(e.modelType))
  
   return mac_address;
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
      size="xs"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
         
           <div className="space-y-2" >
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-1"
            > 
              <Icon icon={IconQRCode} color={COLOURS.default} />
              <Title color={COLOURS.default}>{device.userName}  </Title>
            </Flex>
           
            </div>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-1"
          >
          <QRCodeSVG value={generateQR(device)}
                     size={256}
                     fgColor={"#0056d6"}
                      level={"L"}
                      includeMargin={false}
                      imageSettings={{
                        src:  getControllerBrandIcon_FROM_STRING( device.modelType ) ,
                        x: undefined,
                        y: undefined,
                        height: 48,
                        width: 48,
                        excavate: true,
                      }}
                      />

        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <div style={textStyle}>
          {insertSpace(device.uniqueDeviceId)}
        </div>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2"
        >
          <Button color={COLOURS.default}
            size="sm"
            onClick={() => handleClose({ device })}
            disabled={false}
          >Ok</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export const AddInstaller = ({ open,  handleClose }) => {
  const [name, setName] = useState("Installer Name");
  const [idValid, setIdValid] = useState(false);
  const [phone, setPhone] = useState("(xx)xxxxxxxxx");
  const [address, setAddress] = useState("Address");
  const [id, setId] = useState(getUUID_ForInstaller());
  // const [filterByCountryCode, setFilterByCountryCode] = useState('au');
  const [countryCodes, setCountryCodes] = useState(['au', 'ch']);
 
  const validate = (e) => {
    setName(e.currentTarget.value);
  
  };

  const onPlaceSelect = (value) =>{
    console.log(value);
    // if ( value.length)
    // {
    //   setAddress( value[0].formatted)
    // }
  }
  const onSuggestionChange = (value) =>{
    console.log(value);
    
  }

  
  const validateId = (e) => {

    var value = e.currentTarget.value
    console.log ( "validateId:", e.currentTarget.value, " length:", value.length,"value encoded: ", value )
    //setId(value);
    setIdValid(checkIdValid(value))
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconEdit} color={COLOURS.default} />
            <Subtitle color={COLOURS.default}>Add Installer</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-3">
          <TextField
            label="ID"
            placeholder="Enter a valid ID"
            maxLength={17}
            size="small"
            required
            onChange={validateId}
            value={ id}
          />
          <TextField
            label="Name"
            placeholder="Enter a Installers name"
            maxLength={30}
            size="small"
            required
            onChange={validate}
          />
           <TextField
            label="Phone"
            placeholder="Enter a Installers phone"
            maxLength={30}
            size="small"
            required
            onChange={validate}
          />
          <DebounceAddressSelection value={address} handleClose={onPlaceSelect} ></DebounceAddressSelection>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
           {/* <Button color={COLOURS.default} size="sm" onClick={() => handleClose({  })} disabled={!idValid || !valid }>Confirm</Button> */}
          <Button
            
            color={COLOURS.default}
            size="sm"
            onClick={() => handleClose({ id, name, phone, address})}
            // disabled={!idValid}
          >Confirm</Button>
          <Button
            color={COLOURS.default}
            size="sm"
            variant="secondary"
            onClick={() => handleClose(null)}
          >Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};



export const ShowDeviceDeleteConfirmation = ({ open, device, handleClose }) => {
  const [id, setId] = useState(device.uniqueDeviceId);


  useEffect(() => {
      setId(device.uniqueDeviceId);
  }, [device.uniqueDeviceId]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>
         
           <div className="space-y-2" >
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-1"
            > 
              <Icon icon={IconRubbish} color={COLOURS.default} />
              <Title color={COLOURS.default}>Confirm Remove</Title>
              
             
            </Flex>
            <Title color={COLOURS.default}>{device.userName}  </Title>
            <Title color={COLOURS.default}>{device.uniqueDeviceId}  </Title>
            
            </div>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-1"
          >
         

        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2"
        >
          <Button color={COLOURS.default}
            size="sm"
            onClick={() => handleClose({ id})}
            disabled={false}
          >Remove</Button>
           <Button color={COLOURS.default}
            size="sm"
            onClick={() => handleClose( null )}
            disabled={false}
          >Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

const showModelDeviceWithCheckBox = (modelType, model_type ,validateType) => {

return (
  <Flex
    justifyContent="start"
    alignItems="center"
    className="space-x-1"
  >
    <img src={getControllerIconText_FROM_STRING( getModelTypeStringFromValue(modelType)).icon} alt="Controller Icon" width="40" height="40" />
    <input type="radio" name="controllers" value= {getModelTypeStringFromValue(modelType)} checked={model_type === getModelTypeStringFromValue(modelType)} onChange={validateType}/><Text size="small">{getModelTypeStringFromValue(modelType)}</Text>
  </Flex>
);
}


export const AddDeviceInfo = ({ open,  handleClose }) => {
  const [name, setName] = useState("Device Name");
  const [id, setId] = useState('');
  const [idValid, setIdValid] = useState(false);
  const [valid, setValid] = useState(true);
  const [model_type, setType] = useState('');
  const validateId = (e) => {

    var value = e.currentTarget.value
   
    if ( value.length === 17) //UID
    {
        //Need to determine the type from the UID
        setType( getModelTypeValueFromUID(value).type );
    }
    else if ( value.length === 12 ) // MAC address
    {
      //Convert value to MAC address.
      value = encode_mac_address(value,controller_t.AQUATEK );
    }

    console.log ( "validateId:", e.currentTarget.value, " length:", value.length,"value encoded: ", value )
    setId(value);
    setIdValid(checkIdValid(value))
  };

  const validate = (e) => {
    setName(e.currentTarget.value);
    setValid(checkUserNameValid(e.currentTarget.value));
  };

  const validateUserName = (e) => {
    setName(e.currentTarget.value);
    setValid(checkUserNameValid(e.currentTarget.value));
  };

  const validateType = (e) => {
    setType(e.currentTarget.value);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconEdit} color={COLOURS.default} />
            <Subtitle color={COLOURS.default}>Edit controller</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-3">
         
          <TextField
            label="ID"
            placeholder="Enter a valid ID"
            maxLength={17}
            size="small"
            required
            onChange={validateId}
          />
          <TextField
            label="Name"
            defaultValue={name}
            placeholder="Enter a valid name"
            maxLength={200}
            size="small"
            required
            onChange={validate}
          />

          <TextField
            label="User Name"
            defaultValue={name}
            placeholder="Enter a valid name"
            maxLength={200}
            size="small"
            required
            onChange={validateUserName}
          />
          {/* <Text size="small" >Controller Type</Text>
          <Card  decoration="bottom"
                decorationColor={COLOURS.cardDecoration}
                className="mt-4">
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={3}
            className="gap-4"
          >
            { showModelDeviceWithCheckBox(controller_t.AQUATEK,model_type,validateType)}
            { showModelDeviceWithCheckBox(controller_t.THERALINK,model_type,validateType)}
            { showModelDeviceWithCheckBox(controller_t.OASIS,model_type,validateType)}
            { showModelDeviceWithCheckBox(controller_t.AQUALIGHT,model_type,validateType)}
            { showModelDeviceWithCheckBox(controller_t.POOLTEK,model_type,validateType)}
            { showModelDeviceWithCheckBox(controller_t.HENDEN,model_type,validateType)}
          </Grid>
          </Card> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
           {/* <Button color={COLOURS.default} size="sm" onClick={() => handleClose({  })} disabled={!idValid || !valid }>Confirm</Button> */}
          <Button
            color={COLOURS.default}
            size="sm"
            onClick={() => handleClose({ id, name, modeltype: model_type})}
            disabled={!idValid || !valid}
          >Confirm</Button>
          <Button
            color={COLOURS.default}
            size="sm"
            variant="secondary"
            onClick={() => handleClose(null)}
          >Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export const EditDevice = ({ open, device, handleClose }) => {
  const [name, setName] = useState(device.userName);
  const [usersDevName, setUsersDevName] = useState(device.usersDeviceName);
  const [valid, setValid] = useState(true);
  const [model_type, setType] = useState(device.modelType);

  const validate = (e) => {
    setName(e.currentTarget.value);
    setValid(checkUserNameValid(e.currentTarget.value));
  };

  const validateUsersName = (e) => {
    console.log("Validating UsersNames")
    setUsersDevName(e.currentTarget.value);
   //setValid(checkUserNameValid(e.currentTarget.value));
  };

  const validateType = (e) => {
    setType(e.currentTarget.value);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-1"
          >
            <Icon icon={IconEdit} color={COLOURS.default} />
            <Subtitle color={COLOURS.default}>Edit controller</Subtitle>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-3">
          <TextField
            label="ID"
            defaultValue={device.uniqueDeviceId}
            isDisabled={true}
            size="small"
          />
          <TextField
            label="Name"
            defaultValue={device.userName}
            placeholder="Enter a valid name"
            maxLength={300}
            size="small"
            required
            onChange={validate}
          />

          <TextField
            label="Custom User Name"
            defaultValue={device.usersDeviceName}
            placeholder="Enter a valid name"
            maxLength={300}
            size="small"
            required
            onChange={validateUsersName}
          />

          {/* <Text size="small" >Controller Type</Text>
          <Card  decoration="bottom"
                decorationColor={COLOURS.cardDecoration}
                className="mt-4">
          <Grid
            numItemsSm={1}
            numItemsMd={3}
            numItemsLg={3}
            className="gap-4"
          >
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Aquatek").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Aquatek" checked={model_type === "Aquatek"} onChange={validateType}/><Text size="small">Aquatek</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Theralink").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Theralink" checked={model_type === "Theralink"} onChange={validateType}/><Text size="small">Theralink</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-2"
            >
              <img src={getControllerIconText_FROM_STRING("Oasis").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Oasis" checked={model_type === "Oasis"} onChange={validateType}/>
              <Text>Oasis</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Aqualight").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Aqualight" checked={model_type === "Aqualight"} onChange={validateType} /><Text size="small">Aqualight</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Pooltek").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Pooltek" checked={model_type === "Pooltek"} onChange={validateType}/><Text size="small">Pooltek</Text>
            </Flex>
            <Flex
              justifyContent="start"
              alignItems="center"
              className="space-x-1"
            >
              <img src={getControllerIconText_FROM_STRING("Henden").icon} alt="Controller Icon" width="40" height="40" />
              <input type="radio" name="controllers" value="Henden" checked={model_type === "Henden"} onChange={validateType}/><Text size="small">Henden</Text>
            </Flex>
          </Grid>
          </Card> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="end"
          alignItems="center"
          className="space-x-2"
        >
          <Button
            color={COLOURS.default}
            size="sm"
            onClick={() => handleClose( device, name, usersDevName, model_type )}
            disabled={!valid}
          >Confirm</Button>
          <Button
            color={COLOURS.default}
            size="sm"
            variant="secondary"
            onClick={() => handleClose(null)}
          >Cancel</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}


export const ProgressBar = props => {
  return (
    <Progress.Line
      percent={props.percentage}
      showInfo={false}
      strokeColor={COLOURS.default} //"#ec4899"
    />
  );
}

export const SelectDateRange = props => {
  return (
    <DateRangePicker 
      value={props.range}
      onChange={(value) => props.onChange(value)}
      onOk={(value) => props.onChange(value)}
      character=" &#8211; "
      cleanable={false}
      shouldDisableDate={combine(allowedMaxDays(props.maxDays), afterToday())}
      // disabledDate={}
      editable={false}
      format={datetimeFormats.picker}
      isoWeek
      showOneCalendar
      className="rs-picker-daterange-popper"
    />
  );
}

export const SelectManyDevices = props => {
  return (
    <MultiSelect
      defaultValue={props.selected}
      onValueChange={(value) => props.onSelectedChanged(value)}
      placeholder="Select controller(s)"
      className="max-w-none" >
      {props.devices.map((device) => (
        <MultiSelectItem
          key={device.uniqueDeviceId}
          value={device.uniqueDeviceId}
          text={device.userName} />
      ))}
    </MultiSelect>
  );
}

export const SelectOneDevice = props => {
  return (
    <SelectPicker
    defaultValue={props.selected}
    onChange={(value) => props.onSelectedChanged(value)}
    placeholder="Select controller"
    data={props.devices.map((device) => (
    { label: device.userName, value: device.uniqueDeviceId }))}    
    >
    </SelectPicker>

  );
}
 
const greater10items = [{ label: 'Show 10 per page', value: 10 }, { label: 'Show 15 per page', value: 15 }, { label: 'Show 30 per page', value: 130 }]
const greater5items = [{ label: 'Show 5 per page', value: 5 },{ label: 'Show 10 per page', value: 10 }]

export const SelectItemsPerPage = ({ totalItems, itemsPerPage, onChange }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    // if((itemsPerPage > totalItems) && (itemsPerPage > 10)) { 
    //   let newVal = itemsPerPage > 10 ? 10 : 5;
    //   console.log("update items/page:", itemsPerPage, " -> ", newVal);
    //   onChange(newVal);
    // } else {
      setValue(itemsPerPage);
    // }
  }, [totalItems, itemsPerPage, onChange]);

  return (
    (totalItems > 10) ?

    <SelectPicker
    value={value}
    onChange={onChange}

    data={greater10items}    
    >
    </SelectPicker>: (totalItems > 5) ?
      <SelectPicker
      value={value}
      onChange={onChange}

      data={greater5items}    
      >
      </SelectPicker> :  <></>



    //   <Select className="max-w-none"
    //     value={value}
    //     onValueChange={onChange}
    //   >
    //     <SelectItem value={10} >Show 10 per page </SelectItem>
    //     <SelectItem value={15} >Show 15 per page</SelectItem>
    //     <SelectItem value={30} >Show 30 per page</SelectItem>
    //   </Select>
    // :
    //   (totalItems > 5) ?
    //     <Select className="max-w-none"
    //       value={value}
    //       onValueChange={onChange}
    //     >
    //       <SelectItem value={5}>Show 5 per page</SelectItem> 
    //       <SelectItem value={10}>Show 10 per page</SelectItem> 
    //     </Select>
    //   :
    //     <></>
  );
}
// accessLevel,
export const TableHeaderRow = ({data,  selectedSortKey, selectedSortOrder, onChange, justify}) => 
{
  const getHeaderCell = (item) => 
  {
    if((item.id === null) 
    // || (accessLevel !== accessLevels.super)
    ) 
    {
      return (
        <Button
          variant="light"
          text={item.name}
          color={COLOURS.unknown}
          size="md"
        >{item.name}</Button>
      );
    } else {
      return (

        <div className="headercell">
          <TextInput />
           <div className="headercell">
          <Flex justifyContent={justify} alignItems={justify}>
            <Button
              variant="light"
              text={item.name}
              icon={item.id !== selectedSortKey ? IconSort : (selectedSortOrder === sortOrder.ascending ? IconArrowDown : IconArrowUp)}
              iconPosition="right"
              color={COLOURS.unknown}
              size="md"
              onClick={() => onChange(item.id, selectedSortKey, selectedSortOrder)}
            >
            {item.name}
            </Button>
          </Flex>
          </div>
        </div>
      );
    }
  }

  return (
    <TableHead>
      <TableRow>
        {data.map((item) => (
          // (accessLevel >= item.accessLevel) &&
          <TableHeaderCell key={item.name}>{getHeaderCell(item)}</TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export const findShortUIDInListWithShortRecordID = (UIDToFind, listItemstoCheck) => 
{ 
  let ShortID = getShortIDFromUID(UIDToFind)
  return ( listItemstoCheck.filter ( (element) => getIDSFromDb(element.recordID).shortUID === ShortID ) )
};

export const findUIDInListWithRecordID = (UIDToFind, listItemstoCheck) => { 
  return ( listItemstoCheck.filter ( (element) => getUIDFromDb(element.recordID) === UIDToFind) )
};

export const findUIDInListWithUIDS = (UIDToFind, listItemstoCheck) => { 
  return ( listItemstoCheck.filter ( (element) => element.UID === UIDToFind) )
};



export const getClosestRunOnInterval = ( start, stop) =>
{
    var interval  = runOnceTimerOptions[0];

    let hh =  ((start & 0xFF00) >> 8) ;
    let mm =  (start & 0x00FF) ;

    let hh2 = ((stop & 0xFF00) >> 8) ;
    let mm2 = (stop & 0x00FF) ;

    let date1 = new Date(2000, 1, 1, hh,mm, 0);
    let date2 = new Date(2000, 1, 1, hh2,mm2, 0);

    // var result = setMinutes(, 45)


    // date1.setTime .setTime(hour: hh, min: mm, sec: 00);
    // var date2 = Date().setTime(hour: hh2, min: mm2, sec: 00);

    if ( start > stop)
    {
        date2 = setDate( date2, 2);
        // date2 = Calendar.current.date(byAdding: .day, value: 1, to: date2) ?? Date();
    }

    //Calculate difference
    // let cal = Calendar.current
    // let components = cal.dateComponents([.minute], from: date1, to: date2)
    // let diff = components.minute!

    let diff = differenceInMinutes(date2,date1);

    
    if ( (diff >= 1) 
    && (diff <= runOnceTimerOptions[1].value))
    {
        interval = runOnceTimerOptions[1];
    }
    else
    {
        runOnceTimerOptions.forEach( ( timer, index) =>
        {
            if ( diff >= timer.value)
            {
                interval = timer;
            }
        })
    }
    // console.log("Diff:",diff ,"Interval found:",interval);
    return interval;
}
 

export const getRunonStartStopTimesForInterval = ( interval  ) => 
{
    let date1 = new Date();
    var date2 = date1;

    let sec = getSeconds(date1);
    
    if ( interval !== 0 )
    {
        let valueToAdd = interval + ( (sec >= 24 ) ? 1:0 );
        date2 = addMinutes(date2, valueToAdd);
    }

    // let hh_start = getHours(date1);
    // let mm_start = getMinutes(date1);
   
    // let hh_end = getHours(date2);
    // let mm_end = getMinutes(date2);
    let start_time = ( (getHours(date1)<<8) | getMinutes(date1) )
    let end_time = ( (getHours(date2)<<8) | getMinutes(date2) )
    
    console.log("interval",interval ,"run_on Start: ",date1,start_time,"End: ",date2,end_time);

    return { start: start_time, stop: end_time}
}

export const getUUID_ForInstaller = () =>
{
  const short = require('short-uuid');

  // Quick start with flickrBase58 format
  let UUID = short.generate(); // 73WakrfVbNJBaAmhQtEeDv
  
  console.log ('UUID', UUID);
  return 'IID_'+UUID;
}


export const build_fault_string = ( valueToBuildFrom, exclude_system_restart, no_faults_description, use_short_strings) =>
{
  var build_from_value = 0;
  if (typeof valueToBuildFrom === 'string' || valueToBuildFrom instanceof String)
  {
    build_from_value = parseInt( valueToBuildFrom, 10)
  }
  // it's a string
  else
  {
    build_from_value = valueToBuildFrom;
  }
  // it's something else
  var colour = COLOURS.warning;
  let str = ""
  let number_faults = 0;
  let idx_max = exclude_system_restart === true ? 31:32;
  for (let index = 0; index < idx_max; index++) {
    const bit_field = (1 << index)
    if ((build_from_value & bit_field) === bit_field)  
    {
      let theFault = getFaultTableItem(bit_field);
      if ( null !== theFault)
      {
        if ( number_faults !== 0)
        {
          str += " , "
        }
        if ( use_short_strings === true)
        {
          str += theFault.short_label;
        }
        else
        {
          str += theFault.label;
        }
        number_faults++;
      }
    }


    // if ((build_from_value & bit_field) === bit_field) {
      
    //   if ( number_faults !== 0)
    //   {
    //     str += " , "
    //   }

    //   if ( use_short_strings === true)
    //   {
    //     str += fault_strings_short_idx[index+1] ;//+ ",\r\n";
    //   }
    //   else
    //   {
    //     str += fault_strings_idx[index+1] ;//+ ",\r\n";
    //   }
    //   number_faults++;
    //   // console.log( "Number_faults", number_faults, str)
    // }
  }
 
  if (str === "")
  {
    str = no_faults_description;
    colour = COLOURS.good;
  }
  var result = {}
  result.string = str;
  result.colour = colour;
  result.number_faults = number_faults;
  return result;

}


export const getFaultTableItem = ( faultBit ) =>
{
  var theResult = null;
  const theFault = faultTable.find( (item) => item.value === faultBit );
  if ( theFault)
  {
    theResult = theFault;
  }
  return theResult;
}

export const getMonthYearFromDate = ( dateToProcess) =>
{
  var theMonth = 0;
  var theYear = 0;
  var theLabel = "Undefined"
  if ( undefined === dateToProcess || null === dateToProcess)
  {
    console.log("Convert to Date Time Undefined", dateToProcess);
  }
  else
  {
    var split = dateToProcess.split("T")
    var parts = split[0].split("-");
    var theDate = 0;
    if ( parts[0] > 2000)
    {
      theMonth = parts[1]-1;
      theYear = parts[0];
      theDate = parts[2];
    }
    else 
    {
      theMonth = parts[1]-1;
      theYear = parts[2];
      theDate = parts[0];
    }
    
    


    const date = new Date(theYear, theMonth, theDate);  // 2009-11-10
    const monthStr = date.toLocaleString('default', { month: 'short' });

    theLabel = String(monthStr + "-" + theYear );

    // console.log("Convert to Date Time", split,parts, theMonth,theYear, theLabel);
  }
  return {label: theLabel ,Month: theMonth-1, Year: theYear }
}