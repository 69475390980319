import {

  Text,
  Flex,
 
  Card,
  Grid,


} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPumpSpeed,
  Display2ValueToggleItem,
  Display2ValueToggleItemBitField,
  DisplayToggleItem,
  DisplaySelectPickerItem,
  DisplayTimePickerItem,
  DisplayStartStopTimePickerItem,
  DisplayTrackingTemperatureSliderItem,
  DisplayTemperatureSliderItem
} from "./DisplayItems";

import {
  MdOutlineThermostat as IconThermometer,
  MdPool as IconPool,
  MdHotTub as IconSpa,
  MdRoofing as IconSolar,
 
  MdOutlineInfo as IconInfo,
  MdSpeed as IconSpeed,
  MdWarningAmber as IconWarning,
  MdDirectionsRun as IconRun,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
} from "react-icons/md";


import {
  GiBubbles as IconBubbles,
 
  GiWinterHat as IconWinter,
  GiThermometerCold as IconFrozen,
  GiHeatHaze as IconHeat,
} from "react-icons/gi";

import {
  TbEngine as IconPump,
} from "react-icons/tb";

import { COLOURS} from "../styles"
import {
  
  offOnOptions,
  DisableEnableOptions,
  heaterOffOnOptions,
  heatingPumpTypes,
  sheaterFunc,
  sheaterTypes,
  vfTypesEnum
} from "../constants";

import {

  REGS,

} from "../registers";

export const HeatingView = props => {
  

    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        <Card
          decoration="bottom"
          decorationColor={COLOURS.cardDecoration}
          className="mt-3">
          { (props.onGetRegisterData(REGS.VFCONTACT_1_TYPE).value !== vfTypesEnum.NONE  || props.onGetRegisterData(REGS.VFCONTACT_2_TYPE).value !== vfTypesEnum.NONE) &&
            
              <div
                className="max-w-none mt-2 space-y-4 text-left"
                >

                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className="space-x-2 " >
                  <Text color={COLOURS.default}>Heating Status</Text>
                </Flex>
                <Grid
                  numItems={2}
                  numItemsSm={2}
                  numItemsLg={2}
                  numItemsMd={2}
                  className="gap-y-4 gap-x-4"
                >
                  <DisplayItem register={props.onGetRegisterData(REGS.HEATER_STATE)} icon={IconInfo} />
                  <DisplayItem register={props.onGetRegisterData(REGS.HEATER_2_STATE)} icon={IconInfo} />
                  </Grid>
                  <Grid
                  numItems={2}
                  numItemsSm={2}
                  numItemsLg={4}
                  numItemsMd={3}
                  className="gap-y-4 gap-x-4"
                >
                  <DisplayItem register={props.onGetRegisterData(REGS.POOL_TEMPERATURE)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.ROOF_TEMPERATURE)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.WATER_TEMPERATURE)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SM_HEATER_TEMPERATURE)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SM_HEATER_OUTLET_TEMP)} icon={IconThermometer} />
                  
                  <DisplayItem register={props.onGetRegisterData(REGS.THERMISTOR_1)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.THERMISTOR_2)} icon={IconThermometer} />
                  <DisplayItem register={props.onGetRegisterData(REGS.THERMISTOR_3)} icon={IconThermometer} />
                
                </Grid>
                { (props.onGetRegisterData(REGS.GAS_HEATER_ENABLE).enabled === true || props.onGetRegisterData(REGS.HEAT_PUMP_ENABLE).enabled === true) &&
                  <div>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="space-x-4" >
                    <Text color={COLOURS.default}>Heating Setup</Text>
                  </Flex>
                  <Grid
                    numItems={1}
                    numItemsSm={1}
                    numItemsXl={2}
                    className="gap-y-4 gap-x-4"
                  >
                    <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.POOL_SET_TEMPERATURE)} icon={IconPool} min={0} max={40} step={0.5} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                    <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.SPA_SET_TEMPERATURE)} icon={IconSpa} min={0} max={40} step={0.5} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  
                  </Grid>
                  <Grid
                    numItems={2}
                    numItemsSm={1}
                    numItemsLg={3}
                    className="gap-y-4 gap-x-4"
                  >
                    {props.onGetRegisterData(REGS.GAS_HEATER_ENABLE).enabled === true && <DisplayToggleItem register={props.onGetRegisterData(REGS.GAS_HEATER_ENABLE)} icon={IconSettings} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} /> }
                    {props.onGetRegisterData(REGS.HEAT_PUMP_ENABLE).enabled === true && <DisplayToggleItem register={props.onGetRegisterData(REGS.HEAT_PUMP_ENABLE)} icon={IconSettings} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}  /> }
                  </Grid>
               
                    <Grid
                      numItems={2}
                      numItemsSm={1}
                      numItemsLg={2}
                      className="gap-y-4 gap-x-4"
                    >
                      <Display2ValueToggleItemBitField register={props.onGetRegisterData(REGS.HEAT_DEMAND_ENABLE)} icon={IconHeat} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff } bit_number={0}  />
                      <DisplayStartStopTimePickerItem register={props.onGetRegisterData(REGS.HEAT_DEMAND_START_TIME)} register2={props.onGetRegisterData(REGS.HEAT_DEMAND_STOP_TIME)} icon={IconClock} onChange={props.onTimesUpdate} onChange2={props.onTimesUpdate} disabled={props.editOff}/>
                      <Display2ValueToggleItemBitField register={props.onGetRegisterData(REGS.HEAT_DEMAND_ENABLE)} icon={IconHeat} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}  bit_number={1} />
                      <DisplayStartStopTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_START_TIME)} register2={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_STOP_TIME)} icon={IconClock} onChange={props.onTimesUpdate} onChange2={props.onTimesUpdate} disabled={props.editOff}/>
                    </Grid>
                    <Grid
                      numItems={2}
                      numItemsSm={1}
                      numItemsLg={3}
                      className="gap-y-4 gap-x-4"
                    >
                      <DisplayToggleItem register={props.onGetRegisterData(REGS.HEATING_PUMP_TYPE)} icon={IconPump} options={heatingPumpTypes}  onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEAT_DEM_SANITISER_EN)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff} />
                      <DisplayPumpSpeed  register={props.onGetRegisterData(REGS.HEATING_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_SOLAR_PRIORITY)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff} />
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_USES_WATER)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_HYDROTHERAPY_MODE)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_CHILL_FUNC)} icon={IconWinter} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>

                      <DisplayTimePickerItem register={props.onGetRegisterData(REGS.GAS_HEATER_COOL_DOWN_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                      <Display2ValueToggleItem register={props.onGetRegisterData(REGS.RUN_TILL_HEATED_FUNC)} icon={IconRun} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                    
                      { props.onGetRegisterData(REGS.EXPANSION4_PRESENT).value !== 0 &&
                        <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_1_USES_SH_SENS)} icon={IconThermometer} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                      }   
                    </Grid>
                    <Grid
                    numItems={1}
                    numItemsSm={1}
                    numItemsXl={2}
                    className="gap-y-4 gap-x-4"
                  >
                    <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.HEATER_ROOFTEMP_CUTOFF)} icon={IconThermometer} min={20} max={70} step={1} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  </Grid>
                </div> 
              }
              </div>
            
          }
          {props.onGetRegisterData(REGS.VFCONTACT_2_TYPE).value !== vfTypesEnum.NONE &&
              <div className="max-w-none mt-2 space-y-4">
                
                <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-4" >
                <Text color={COLOURS.default}>Heating 2 Setup</Text>
                </Flex>
                <Grid
                  numItems={2}
                  numItemsSm={1}
                  numItemsLg={3}
                  className="gap-y-4 gap-x-4"
                >
                  {props.onGetRegisterData(REGS.HEAT_PUMP_2_ENABLE).enabled === true &&  <DisplayToggleItem register={props.onGetRegisterData(REGS.HEAT_PUMP_2_ENABLE)} icon={IconSolar} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/> }
                  {props.onGetRegisterData(REGS.GAS_HEATER_2_ENABLE).enabled === true &&  <DisplayToggleItem register={props.onGetRegisterData(REGS.GAS_HEATER_2_ENABLE)} icon={IconSolar} options={heaterOffOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/> }
                  
                </Grid>
                <Grid
                  numItems={2}
                  numItemsSm={1}
                  numItemsLg={2}
                  className="gap-y-4 gap-x-4"
                >
                  
                  <Display2ValueToggleItemBitField register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_ENABLE)} icon={IconHeat} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff } bit_number={0}  />
                  <DisplayStartStopTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_START_TIME)} register2={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_STOP_TIME)} icon={IconClock} onChange={props.onTimesUpdate} onChange2={props.onTimesUpdate} disabled={props.editOff}/>
                  <Display2ValueToggleItemBitField register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_ENABLE)} icon={IconHeat} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}  bit_number={1} />
                  <DisplayStartStopTimePickerItem register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_START_TIME)} register2={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_STOP_TIME)} icon={IconClock} onChange={props.onTimesUpdate} onChange2={props.onTimesUpdate} disabled={props.editOff}/>
                </Grid>
                <Grid
                  numItems={2}
                  numItemsSm={1}
                  numItemsLg={3}
                  className="gap-y-4 gap-x-4"
                >
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_2_USES_WATER)} icon={IconSettings} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  <DisplayToggleItem register={props.onGetRegisterData(REGS.HEATER_2_PUMP_TYPE)} icon={IconPump} options={heatingPumpTypes}  onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_2_HYDROTHERAPY_MODE)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
                  <DisplayTimePickerItem register={props.onGetRegisterData(REGS.GAS_HEAT2_COOL_DOWN_TIME)} icon={IconClock} onChange={props.onTimesUpdate} />
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.CHILL_2_FUNCTION)} icon={IconFrozen} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEAT_DEM_2_SANITISER_EN)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                  <DisplayPumpSpeed register={props.onGetRegisterData(REGS.HEAT_2_SPEED)} icon={IconSpeed} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_2_SOLAR_PRIORITY)} icon={IconSolar} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                  
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_2_TRACKING_ENABLE)} icon={IconInfo} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                  <Display2ValueToggleItem register={props.onGetRegisterData(REGS.RUN_TILL_HEATED_2_FUNC)} icon={IconRun} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default}  disabled={props.editOff}/>
                  { props.onGetRegisterData(REGS.EXPANSION4_PRESENT).value !== 0 &&
                    <Display2ValueToggleItem register={props.onGetRegisterData(REGS.HEATER_2_USES_SH_SENS)} icon={IconThermometer} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                   
                  }   
                </Grid>
                

                <Grid
                  numItems={1}
                  numItemsSm={1}
                  numItemsXl={2}
                  className="gap-y-4 gap-x-4"
                >
                  <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.POOL_2_SET_TEMPERATURE)} icon={IconPool} min={0} max={40} step={0.5} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.SPA_2_SET_TEMPERATURE)} icon={IconSpa} min={0} max={40} step={0.5} onFormat={props.formatTemperature} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  <DisplayTemperatureSliderItem register={props.onGetRegisterData(REGS.HEATER_2_ROOFTEMP_CUTOFF)} icon={IconThermometer} min={20} max={70} step={2}  onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                   <DisplayTrackingTemperatureSliderItem register={props.onGetRegisterData(REGS.HEATER_2_TRACKING_OFFSET)} icon={IconPool} min={-15} max={0} step={0.5} onChange={props.handleRegisterUpdate} disabled={props.editOff}/>
                  
                </Grid>
              
              </div>
            // </Card>
          }
          { props.onGetRegisterData(REGS.EXPANSION4_PRESENT).value === 0 ? "" :
            // <Card
            //   decoration="bottom"
            //   decorationColor={COLOURS.cardDecoration}
            //   className="mt-3">
              <div className="max-w-none mt-2 space-y-4">
                <Flex
                justifyContent="center"
                alignItems="center"
                className="space-x-4" >
                <Text color={COLOURS.default}>Smart Heater Setup</Text>
                </Flex>
                <Grid
                  numItems={2}
                  numItemsSm={1}
                  numItemsLg={3}
                  className="gap-y-4 gap-x-4"
                >
                  <DisplayItem register={props.onGetRegisterData(REGS.EXPANSION4_PRESENT)} icon={IconInfo} />
                  <DisplayToggleItem register={props.onGetRegisterData(REGS.SMART_HEATER_FUNCTION)} icon={IconInfo} options={sheaterFunc} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
                  <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.EXP4_SLAVE1_TYPE)} icon={IconPump} options={sheaterTypes} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
                  {/* <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.EXP4_SLAVE2_TYPE)} icon={IconPump} options={sheaterTypes} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/> */}
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_1_STATE)} icon={IconInfo} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_ERROR_BITMAP1)} icon={IconInfo} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_ERROR_BITMAP2)} icon={IconInfo} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_ERROR_BITMAP3)} icon={IconInfo} />
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_ERROR_BITMAP4)} icon={IconInfo} />
                  {/* <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_2_STATE)} icon={IconInfo} /> */}
                  <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_1_FAULTS)} icon={IconWarning} />
                  {/* <DisplayItem register={props.onGetRegisterData(REGS.SMART_HEATER_2_FAULTS)} icon={IconWarning} /> */}
                </Grid>
              
              </div>
            // </Card>
          }
        </Card>
        </Grid>
    )
}