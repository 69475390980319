import {
 
  Text,
  Flex,

  Card,
  Grid,
  Title,
  Bold,
} from "@tremor/react";

import { 
  Display2ValueToggleItem,
  DisplaySocketItem,
  DisplayToggleItem,
} from "./DisplayItems";

import {
  MdWbTwighlight as IconLight,
} from "react-icons/md";

import {
  GiValve as IconValve,
} from "react-icons/gi";

import {
  TbPlug as IconSocket,
} from "react-icons/tb";

import {
  MdOutlineSettings as IconSettings,
} from "react-icons/md";

import { COLOURS} from "../styles"
import {
  offOnOptions,
  DisableEnableOptions,
  SocketTypesSelect,
  ValveTypesSelect,
  VFTypesSelect,
  waterFeatureType,

} from "../constants";

import {
  REGS,
} from "../registers";

export const ApplianceSetupView = (props) => {
  
  return (
    <Grid
    numItems={1}
    numItemsSm={1}
    numItemsLg={1}
    className="gap-4"
    >
      <Card
        decoration="bottom"
        decorationColor={COLOURS.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-0 space-y-4 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Text color={COLOURS.default}><Bold>Appliance Setup</Bold></Text>
          </Flex>
          <Flex
          justifyContent="center"
          alignItems="top"
          className="space-x-2" >
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE1_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_1_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_1_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_1_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_1_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_1_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_2_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE2_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_2_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_2_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_2_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_2_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_2_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_2_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
                
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                  <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_3_TYPE)} 
                  name_register ={props.onGetRegisterData(REGS.APPLIANCE3_NAME_START)} 
                  options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate}
                  disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_3_SETTING)}
                  time_enable_register={props.onGetRegisterData(REGS.SOCKET_3_TIME_ENABLE)}
                  time_1_start = {props.onGetRegisterData(REGS.SOCKET_3_TIME_1_START_TIME)}
                  time_1_stop = {props.onGetRegisterData(REGS.SOCKET_3_TIME_1_STOP_TIME)}
                  time_2_start = {props.onGetRegisterData(REGS.SOCKET_3_TIME_2_START_TIME)}
                  time_2_stop = {props.onGetRegisterData(REGS.SOCKET_3_TIME_2_STOP_TIME)}
                  onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_4_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE4_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} time_enable_register={props.onGetRegisterData(REGS.SOCKET_2_TIME_ENABLE)}
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_4_SETTING)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_4_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_4_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_4_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_4_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_5_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE5_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_5_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_5_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_5_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_5_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_5_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_5_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_6_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE6_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_6_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_6_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_6_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_6_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_6_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_6_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
            </div>
            </Card>
          </Flex>


          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_7_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE7_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_7_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_7_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_7_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_7_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_7_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_7_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.SOCKET_8_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE8_NAME_START)} options={SocketTypesSelect} icon={IconSocket} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.SOCKET_8_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.SOCKET_8_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.SOCKET_8_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.SOCKET_8_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.SOCKET_8_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.SOCKET_8_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
            </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
            <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE9_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VALVE_1_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.VALVE_1_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.VALVE_1_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.VALVE_1_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.VALVE_1_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.VALVE_1_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE10_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VALVE_2_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.VALVE_2_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.VALVE_2_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.VALVE_2_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.VALVE_2_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.VALVE_2_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_3_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE11_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                   disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VALVE_3_SETTING)}
                  time_enable_register={props.onGetRegisterData(REGS.VALVE_3_TIME_ENABLE)}
                  time_1_start = {props.onGetRegisterData(REGS.VALVE_3_TIME_1_START_TIME)}
                  time_1_stop = {props.onGetRegisterData(REGS.VALVE_3_TIME_1_STOP_TIME)}
                  time_2_start = {props.onGetRegisterData(REGS.VALVE_3_TIME_2_START_TIME)}
                  time_2_stop = {props.onGetRegisterData(REGS.VALVE_3_TIME_2_STOP_TIME)}
                  onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VALVE_4_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE12_NAME_START)} options={ValveTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VALVE_4_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.VALVE_4_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.VALVE_4_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.VALVE_4_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.VALVE_4_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.VALVE_4_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >

              
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_1_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE13_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                 disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VFCONTACT_1_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.VFCONTACT_1_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.VFCONTACT_1_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.VFCONTACT_1_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.VFCONTACT_1_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}/>
              </div>
            </Card>
            <Card
            decoration="bottom"
            decorationColor={COLOURS.cardDecoration}
            className="mt-3">
              <div className="max-w-none mt-4 space-y-4 text-left">
                <DisplaySocketItem type_register={props.onGetRegisterData(REGS.VFCONTACT_2_TYPE)} name_register ={props.onGetRegisterData(REGS.APPLIANCE14_NAME_START)} options={VFTypesSelect} icon={IconValve} onChange={props.handleRegisterUpdate} onChangeName={props.onNameRegisterUpdate} 
                disabled = {props.editOff}
                  socket_setting = {props.onGetRegisterData(REGS.VFCONTACT_2_SETTING)}
                time_enable_register={props.onGetRegisterData(REGS.VFCONTACT_2_TIME_ENABLE)}
                time_1_start = {props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_START_TIME)}
                time_1_stop = {props.onGetRegisterData(REGS.VFCONTACT_2_TIME_1_STOP_TIME)}
                time_2_start = {props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_START_TIME)}
                time_2_stop = {props.onGetRegisterData(REGS.VFCONTACT_2_TIME_2_STOP_TIME)}
                onTimeChange={props.onTimesUpdate}
                DisplaySocketItem/>
              </div>
            </Card>
          </Flex>
   
          
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={COLOURS.default}>Lights</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_1_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_2_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHT_3_REINIT)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_1_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_2_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_3_SYNC)} icon={IconLight} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
            <Display2ValueToggleItem register={props.onGetRegisterData(REGS.LIGHTS_LINKED)} icon={IconLight} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          </Grid>
          <Flex justifyContent="center" alignItems="center" className="space-x-2" > <Title color={COLOURS.default}>Other</Title></Flex>
          <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={3}
              className="gap-4"
            >
            <DisplayToggleItem register={props.onGetRegisterData(REGS.WATER_FEATURE_TYPE)} icon={IconSettings} options={waterFeatureType}  onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
          </Grid>
          </div>
      </Card>
        
    </Grid>
  );
}