import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
//import config from './aws-exports';


// import { AWSIoTProvider } from "@aws-amplify/pubsub";

import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

// import awsExports from './aws-exports';
// Amplify.configure(awsExports);
 
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
if(process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
