import React, { useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  // Link,
  Pagination,
  Table,
  TextFilter
} from '@cloudscape-design/components';

import {
      MdCheckCircleOutline,
      MdErrorOutline,
} from "react-icons/md";
  
import {
  Badge,
} from "@tremor/react";
import { COLOURS} from "../styles"
import {  FlexboxGrid} from 'rsuite';

import {sheaterFaults} from "../constants"


export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

// function formatDate(date) {
//   const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
//   const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
//   return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
// }

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 resources' },
    { value: 20, label: '20 resources' },
    { value: 50, label: '50 resources' },
    { value: 100, label: '100 resources' },
    { value: 200, label: '200 resources' },
  ],
};
export const collectionPreferencesProps = {
  pageSizePreference,
  // contentDisplayPreference: {
  // options: [
  //       { id: '#',        label: "#", visible: true },
  //       { id: 'dateTime',       label: "id", visible: true, alwaysVisible: true},
  //       { id: 'modelType',label: "model", visible: true },
  //       { id: 'faults',     label: "faults",visible: true },
       
  // ]
  // },
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};
const get_error_text_for_code = (code) =>
{
  var str = "";
  const none_string = " - None";

  let value = parseInt(code)

  if ( value === 0)
  {
    str = none_string;
  }
  else
  {
    let lookup =  sheaterFaults.find(element => element.value === value)
    console.log ( "COde:", code, "lookup:", lookup)
    if ( lookup !== undefined)
    {
      str = " - " + lookup.label
    }
  }
  console.log ( "COde:", code, "value:", value, (value=== 0 )?"true":"false")
 
  return ( (code === undefined)  ? 
  (
    <Badge
      icon={MdErrorOutline}
      color={COLOURS.warning}
    ></Badge>
  ):(value === 0 || str === "" || str === none_string) ? (
      <Badge
        icon={MdCheckCircleOutline}
        color={COLOURS.good}
      >{code + str}
      </Badge>
    ) : (
      <Badge
        icon={MdErrorOutline}
        color={COLOURS.warning}
      >{code + str}
      </Badge>
    )
  
  )

}

function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}

      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export function FaultsDisplayTable(props) 
{
  const [preferences, setPreferences] = useState({ pageSize: 50, visibleContent: ['#', 'dateTime', 'faults', 'errorCode', 'version'] });
  const { items, actions,  filteredItemsCount, collectionProps,  filterProps, paginationProps } = useCollection(
    props.formattedHistoryFaults,
    {
      filtering: {
        empty: <EmptyState title="No Faults" action={<Button>Create instance</Button>} />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );
  const { selectedItems } = collectionProps;




  return (
    <Table
      {...collectionProps}
  
      header={ 
        <Header  z-index="10"
          counter={selectedItems.length ? `(${selectedItems.length}/${props.formattedHistoryFaults.length})` : `(${props.formattedHistoryFaults.length})`}
        >
          Fault Records
        </Header>
      }
      stickyHeader
      stripedRows
      resizableColumns
      variant="borderless"
     
      columnDefinitions={
        [{
        id: '#',
        header: '#',
        cell: item => < FlexboxGrid align="middle"
        justify="center"
        style={{ fontSize: '0.9em' }}> <FlexboxGrid.Item>{item.id}</FlexboxGrid.Item></FlexboxGrid>,
        ariaLabel: createLabelFunction('#'),
        sortingField: 'id',
        isRowHeader: true,
        width: 80,
        minWidth:70,
      },
      {
        id: 'dateTime',
        header: 'Date/Time',
        cell: item => item.dateTime,
        ariaLabel: createLabelFunction('dateTime'),
        sortingField: 'dateTime',
        isRowHeader: true,
        width: 250,
        minWidth:200,
      },
      {
        id: 'faults',
        header: 'Faults',
        cell: item => <div className="tooltip">

          { item.colour === COLOURS.good ? (
            <Badge
              icon={MdCheckCircleOutline}
              color={COLOURS.good}
            >{item.faults}
            </Badge>
          ) : (
            <Badge
              icon={MdErrorOutline}
              color={item.colour}
            >{item.faults}
            </Badge>
          )
        }
        </div>,
    
        // cell: item => getControllerIconText_FROM_STRING(item.modelType).icon ,
        ariaLabel: createLabelFunction('faults'),
        sortingField: 'faults',
        isRowHeader: true,
        width: 250,
        minWidth:200,
      },
      {
        id: 'errorCode',
        header: 'Error Code',
        cell: item =><div className="tooltip"> { get_error_text_for_code( item.errorCode)}</div>,
        ariaLabel: createLabelFunction('errorCode'),
        sortingField: 'errorCode',
        isRowHeader: true,
        width: 250,
        minWidth:200,
      },
      {
        id: 'version',
        header: 'Version',
        cell: item =>item.version,
        ariaLabel: createLabelFunction('version'),
        sortingField: 'version',
        isRowHeader: true,
        width: 250,
        minWidth:200,
      },
      ]}
      // columnDisplay={preferences.visibleContent}
      // visibleColumns={preferences.visibleContent}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />
      }
      preferences={
        <CollectionPreferences
          {...collectionPreferencesProps}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
    />
        //   loading
      //   selectionType="multi"
  );
}