
import {
  Card,
  Flex,
  Grid,
  Title,
} from "@tremor/react";

import { 
  DisplayFilterTimeItem,
  DisplaySliderItem
} from "./DisplayItems";

import { COLOURS} from "../styles"
import {
  MdPool as IconPool,
} from "react-icons/md";
import {
  REGS,
} from "../registers";

export const FilterView = (props) => {
  return (
    <Grid
    numItems={1}
    numItemsSm={1}
    numItemsLg={1}
    className="gap-y-4 gap-x-4"
    >
    <Card
        decoration="bottom"
        decorationColor={COLOURS.cardDecoration}
        className="mt-3">
        <div className="max-w-none mt-2 space-y-2 text-left">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <Title color={COLOURS.default}>Filter</Title>
          </Flex>
          {/* <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2" >
            <DisplayToggleItem register={props.onGetRegisterData(REGS.FILTER_TIME_ENABLE)} icon={IconClock} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
          </Flex> */}
          <Grid
            numItems={2}
            numItemsSm={1}
            numItemsLg={2}
            className="gap-y-4 gap-x-4"
            >
       
           <DisplayFilterTimeItem  timeStartRegister = {props.onGetRegisterData(REGS.FILTER_TIME_1_START_TIME)} 
                                    timeStopRegister = {props.onGetRegisterData(REGS.FILTER_TIME_1_STOP_TIME)} 
                                    timerSpeedRegister = {props.onGetRegisterData(REGS.FILTER_TIME_1_SPEED)} 
                                    onEnabledChange={props.handleFilterTimerEnableChange} 
                                    onTimesChange= { props.onTimesUpdate}
                                    onSpeedChange= { props.handleRegisterUpdate} 
                                    disabled={props.editOff} />
         
            <DisplayFilterTimeItem  timeStartRegister = {props.onGetRegisterData(REGS.FILTER_TIME_2_START_TIME)} 
                                    timeStopRegister = {props.onGetRegisterData(REGS.FILTER_TIME_2_STOP_TIME)} 
                                    timerSpeedRegister = {props.onGetRegisterData(REGS.FILTER_TIME_2_SPEED)} 
                                    onEnabledChange={props.handleFilterTimerEnableChange} 
                                    onTimesChange= { props.onTimesUpdate}
                                    onSpeedChange= { props.handleRegisterUpdate} 
                                    disabled={props.editOff} />
        

       
            <DisplayFilterTimeItem  timeStartRegister = {props.onGetRegisterData(REGS.FILTER_TIME_3_START_TIME)} 
                                    timeStopRegister = {props.onGetRegisterData(REGS.FILTER_TIME_3_STOP_TIME)} 
                                    timerSpeedRegister = {props.onGetRegisterData(REGS.FILTER_TIME_3_SPEED)} 
                                    onEnabledChange={props.handleFilterTimerEnableChange} 
                                    onTimesChange= { props.onTimesUpdate}
                                    onSpeedChange= { props.handleRegisterUpdate} 
                                    disabled={props.editOff} />
       

            <DisplayFilterTimeItem  timeStartRegister = {props.onGetRegisterData(REGS.FILTER_TIME_4_START_TIME)} 
                                    timeStopRegister = {props.onGetRegisterData(REGS.FILTER_TIME_4_STOP_TIME)} 
                                    timerSpeedRegister = {props.onGetRegisterData(REGS.FILTER_TIME_4_SPEED)} 
                                    onEnabledChange={props.handleFilterTimerEnableChange} 
                                    onTimesChange= { props.onTimesUpdate}
                                    onSpeedChange= { props.handleRegisterUpdate} 
                                    disabled={props.editOff} />
        
          </Grid>
          <DisplaySliderItem register={props.onGetRegisterData(REGS.CHLOR_DUTY_CYCLE)} icon={IconPool} min={0} max={100} step={2} onChange={props.handleRegisterUpdate} disabled={props.editOff} />
        </div>
      </Card>
    </Grid>
  );
}