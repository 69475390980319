import { PubSub } from '@aws-amplify/pubsub';
// import { useState } from "react";
export const REGION = 'ap-southeast-2';
export const ENDPOINT = 'wss://a219g53ny7vwvd-ats.iot.ap-southeast-2.amazonaws.com/mqtt';

export const pubsub = new PubSub({
    region: REGION,
    endpoint: ENDPOINT,
});


// export function SubscriptionWrapper( dataHandler) {

//   const [subscription, setSubscription] = useState(null);
//   const [subscribedTopic, setSubscribedTopic] = useState('');


//   function subscribe(subTopic) {

//     if (!subscription) {
//       console.log('subscribing');
//       setSubscribedTopic(subTopic);
//       let ourSubscription = pubsub.subscribe({ topics: subTopic }).subscribe({
//         next: data => dataHandler( subscribedTopic, data) ,  //console.log('data', JSON.stringify(data, null, 2)),
//         error: error => console.log('err',JSON.stringify(error, null, 2)),
//         complete: () => console.log('Done'),
//       }
     
//       );
//       setSubscription( ourSubscription)
//       console.log("Subscription:", ourSubscription)
//     }
//     else 
//     {
//       console.log("Siubscription Set:", subscription)
//     }
//   }

//   function unsubscribe() {
//     if (subscription) 
//     {
//       console.log('unsubscribing', subscription);
//       subscription.unsubscribe();
//       setSubscription( null) ;
//       setSubscribedTopic( null);
//     }
//     else
//     {
//       console.log("no Subscriptions, ",subscription, subscribedTopic )
//     }
//   }

//   function setTopic( subTopic)
//   {
//     unsubscribe();
//     subscribe(subTopic);
//   }

//   function resubscribe() {
//     unsubscribe();
//     subscribe();
//   }
//   return {
//     subscribe,
//     unsubscribe,
//     resubscribe,
//     setTopic
//   }
// }