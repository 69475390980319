import { findUIDInListWithUIDS } from "./Common";

export const canIaccessController = (device, deviceAccess, groups) =>
{
    var accessResult = {}
    accessResult.canIaccessController = false;
    accessResult.item = null;

    if ( deviceAccess !== undefined)
    {
        const itemFound = findUIDInListWithUIDS(device.uniqueDeviceId , deviceAccess)
        
        if ( itemFound.length !== 0 )
        {
            // console.log("Found Matchin device",itemFound, " device: ", device.shortDeviceId);
            accessResult.canIaccessController = true; 
            accessResult.item = itemFound[0];
        }
        else
        {
            let num_grps = groups.length;
            for (let grp_itr = 0; grp_itr < num_grps; grp_itr++) 
            {
                let grp_item = groups[grp_itr]
                // if ( count_test++ < 20)
                // {
                //     console.log("Checking group ",grp_item, " device: ", device.modelType);
                // }
                if ( grp_item.toLowerCase()  === device.modelType.toLowerCase())
                {
                    console.log("Found matching group ",grp_item, " device: ", device.modelType);
                    accessResult.canIaccessController = true;
                }
            }
        }
        // let n = deviceAccess.length;
        // for (let i = 0; i < n; i++) 
        // {
        //     let item = deviceAccess[i];
        //     // if ( count_test2++ < 20)
        //     // {
        //     //     console.log("Checking",item, " device: ", device.shortDeviceId);
        //     // }
        //     if ( item === device.shortDeviceId)
        //     {
        //         console.log("Found Matchin device",item, " device: ", device.shortDeviceId);
        //         return true;
        //     }
        //     else
        //     {
                
        //     }
        // }
    }
    return accessResult;
}