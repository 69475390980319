


import {
  autoChlorStates,
  dosingOptions,
  heaterOffOnOptions, 
  offOnOptions,
  heatingPumpTypes,
  solarSensorLocations,
  pumpModelOptionsSelect,
  pumpManualOnOff,
  sheaterStates,
  sheaterFaults,
  expansionTypes,
  sheaterFunc,
  sheaterTypes,
  phLevelsLookup,
  orpLevelsLookup,
  loggingEnabledLookup,
  upgradeStatusTable,
  ota_error_codeTable,
  waterFeatureType
} from "./constants";

export const readAllMessage = {
  messageId: "read",
  modbusReg: 1,
  modbusVal: [1]
};



export const REGS =
{
  ALL								:     1,
  DEVICE_ID:              0x0010,
  HARDWARE_VERSION:       0x0011,
  FIRMWARE_VERSION:       0x0012,
  FIRMWARE_BUILDS:        0x0013,
  IOCTRL_VERSION:         0x0014,
  IOCTRL_BUILD:           0x0015,
  EXP1_VERSION:           0x0016,
  EXP1_BUILD:             0x0017,
  EXP2_VERSION:           0x0018,
  EXP2_BUILD:             0x0019,
  EXPANSION_PLUS_VERSION: 0x001A,
  EXPANSION_PLUS_BUILD:   0x001B,

  FAULT_FLAGS:            0x0030,
  FAULT_FLAGS_2:          0x0031,
  AMBIENT_TEMPERATURE:    0x0032,
  MODEL:                  0x0033,
  ROOF_TEMPERATURE:           54,
  POOL_TEMPERATURE:           55,
  ROOF_TEMP_LAST_PUMP_RUN:    56,
  POOL_TEMP_LAST_PUMP_RUN:    57,

  TOTAL_HOURS:            0x0040,

  SOLAR_STATE: 0x004B,
  SOLAR_INIT_TEMP: 0x004C,

  INPUTS_STATUS: 0x004D,
  OUTPUTS_STATUS: 0x004E,
  THERMISTOR_1: 0x004F,
  THERMISTOR_2: 0x0050,

  HEATER_STATE: 0x0051,

  SOLAR_WINTER_NEXT_MINS: 0x0052,
  PV_INPUT_STATE:0x053,
  AUTO_CHLOR_STATE: 0x054,

  CHLOR_INHIBITED: 0x055,
	CHLOR_LIMIT:     0x056,

  PUMP_STATE: 92,
  PUMP_TRIGGER: 93,
  PUMP_LAST_RUN_TIME: 94,

  // 	 WIFI_SSID_START 					= 0x0064: 1,
  // 	 WIFI_SSID_END                		= ( WIFI_SSID_START+15): 1,		// (SSID max length = 32 bytes => 16 registers)

  // 	 WIFI_PASSWORD_START: 1,
  // 	 WIFI_PASSWORD_END					= ( WIFI_PASSWORD_START+31): 1,	// (Password max length = 64 bytes => 32 registers)

  WIFI_STATUS: 0x0094,
  WIFI_RSSI:   0x0095,
  WIFI_CHANNEL: 0x0096,

  // 	 AWS_CERTID_START: 1,
  // 	 AWS_CERTID_END						= ( AWS_CERTID_START+2): 1,		// (5bytes value -> 40bits => 3 registers)

  FWUPDATE_ERROR: 154,

  EXPANSION_PLUS_FWUPDATE_ERROR: 155,
  EXPANSION_PLUS_WIFI_RSSI: 156,

  SOCKET_1_STATUS: 0x00A0,
  SOCKET_2_STATUS: 0x00A1,
  SOCKET_3_STATUS: 0x00A2,
  SOCKET_4_STATUS: 0x00A3,
  SOCKET_5_STATUS: 0x00A4,
  SOCKET_6_STATUS: 0x00A5,
  SOCKET_7_STATUS: 0x00A6,
  SOCKET_8_STATUS: 0x00A7,
  VALVE_1_STATUS: 0x00A8,
  VALVE_2_STATUS: 0x00A9,
  VALVE_3_STATUS: 0x00AA,
  VALVE_4_STATUS: 0x00AB,
  VF_CONTACT_1_STATUS: 0x00AC,

  PH_MEAS: 0x00AD,
  ORP_MEAS: 0x00AE,

  ACID_PUMP_LAST_RUN_TIME: 0x00AF,
  CHLORINE_PUMP_LAST_RUN_TIME: 0x00B0,
 
  THERMISTOR_3: 177,

  ACID_DOSING_STATUS: 178,
  CHLORINE_DOSING_STATUS: 179,

  PH_LEVEL: 180,
  ORP_LEVEL: 181,

  PH_CURR: 182,
  ORP_CURR: 183,

  HEATER_2_STATE: 184,
  VF_CONTACT_2_STATUS: 185,
  WATER_TEMPERATURE: 186,
  SPA_ON_TIMER: 188,


  EXPANSION_4_FWUPDATE_ERROR_SLAVE1:  0x00BC,
  EXPANSION_4_FWUPDATE_ERROR_SLAVE2:  0x00BD,
  EXPANSION_4_WIFI_RSSI_SLAVE1:       0x00BE,
  EXPANSION_4_WIFI_RSSI_SLAVE2:       0x00BF,
  SMART_HEATER_1_STATE: 0x00C0,       //192
  //SMART_HEATER_2_STATE: 0x00C1,       //193
  SMART_HEATER_ERROR_BITMAP1: 0x012e,
  SMART_HEATER_ERROR_BITMAP2: 0x012F,
  SMART_HEATER_ERROR_BITMAP3: 0x0130,
  SMART_HEATER_ERROR_BITMAP4: 0x0131,
  SM_HEATER_TEMPERATURE: 0x132,

  SMART_HEATER_1_FAULTS: 0x0136,      //310
 // SMART_HEATER_2_PRIMARY_PROBLEM_INDEX  = 0x0137,

  SLAVE_1_ONLINE_STATE: 0x0138,
  SLAVE_2_ONLINE_STATE: 0x0139,
  SLAVE_3_ONLINE_STATE: 0x013A,

  SLAVE_1_ONLINE_COUNT: 0x013B,
  SLAVE_2_ONLINE_COUNT: 0x013C,
  SLAVE_3_ONLINE_COUNT: 0x013D,
  EXP1_TYPE: 0x013E,
  EXP2_TYPE: 0x013F,
  CHLOR_DUTY_CYCLE_STATUS: 0x0140,
  CHLOR_DUTY_CYCLE_SECS_LEFT: 0x0141,
  SM_HEATER_OUTLET_TEMP: 0x0142,
  READ_HEAP_FREE: 0x143,
	READ_HEAP_MIN: 0x144,
  // SMART_HEATER_2_FAULTS: 0x0137,      //311

  // 	 Last								=  SPA_ON_TIMER: 1,

  // 	 MAX								= 0x0100: 1,

  // 	/* Start new write-enable register range */
  // 	 RECLAIM_BASE						= 40960: 1,	// 0xA000
  // 	 RECLAIM_First						=  RECLAIM_BASE: 1,
  // 	/* Start new write-enable register range */
  LOGGING_INTERVAL					: 40993,
  LOGGING_REMOTE					: 65290,

  // 	 RECLAIM_Last						=  LOGGING_REMOTE: 1,

  // 	PSW_REG_WRITE2_RECLAIM_MAX						= 45055: 1,	// 0xAFFF

  // 	/* Start new write-enable register range */
  // 	 BASE2								= 0xE000: 1,
  // 	 ENABLE2_First						=  BASE2: 1,

  APPLIANCE1_NAME_START	:0xE000,
  APPLIANCE1_NAME_2	    :0xE001,
  APPLIANCE1_NAME_3	    :0xE002,
  APPLIANCE1_NAME_4	    :0xE003,
  APPLIANCE1_NAME_5	    :0xE004,
  APPLIANCE1_NAME_6	    :0xE005,
  APPLIANCE1_NAME_7	    :0xE006,
  APPLIANCE1_NAME_END	  :0xE007,
  APPLIANCE2_NAME_START	:0xE008,
  APPLIANCE2_NAME_2	    :0xE009,
  APPLIANCE2_NAME_3	    :0xE00A,
  APPLIANCE2_NAME_4	    :0xE00B,
  APPLIANCE2_NAME_5	    :0xE00C,
  APPLIANCE2_NAME_6	    :0xE00D,
  APPLIANCE2_NAME_7	    :0xE00E,
  APPLIANCE2_NAME_END	  :0xE00F,
  
  APPLIANCE3_NAME_START	:0xE010,
  APPLIANCE3_NAME_2	    :0xE011,
  APPLIANCE3_NAME_3	    :0xE012,
  APPLIANCE3_NAME_4	    :0xE013,
  APPLIANCE3_NAME_5	    :0xE014,
  APPLIANCE3_NAME_6	    :0xE015,
  APPLIANCE3_NAME_7	    :0xE016,
  APPLIANCE3_NAME_END	  :0xE017,
  APPLIANCE4_NAME_START	:0xE018,
  APPLIANCE4_NAME_2	    :0xE019,
  APPLIANCE4_NAME_3	    :0xE01A,
  APPLIANCE4_NAME_4	    :0xE01B,
  APPLIANCE4_NAME_5	    :0xE01C,
  APPLIANCE4_NAME_6	    :0xE01D,
  APPLIANCE4_NAME_7	    :0xE01E,
  APPLIANCE4_NAME_END	  :0xE01F,
  APPLIANCE5_NAME_START	:0xE020,
  APPLIANCE5_NAME_2	    :0xE021,
  APPLIANCE5_NAME_3	    :0xE022,
  APPLIANCE5_NAME_4	    :0xE023,
  APPLIANCE5_NAME_5	    :0xE024,
  APPLIANCE5_NAME_6	    :0xE025,
  APPLIANCE5_NAME_7	    :0xE026,
  APPLIANCE5_NAME_END	  :0xE027,
  APPLIANCE6_NAME_START	:0xE028,
  APPLIANCE6_NAME_2	    :0xE029,
  APPLIANCE6_NAME_3	    :0xE02A,
  APPLIANCE6_NAME_4	    :0xE02B,
  APPLIANCE6_NAME_5	    :0xE02C,
  APPLIANCE6_NAME_6	    :0xE02D,
  APPLIANCE6_NAME_7	    :0xE02E,
  APPLIANCE6_NAME_END	  :0xE02F,
  APPLIANCE7_NAME_START	:0xE030,
  APPLIANCE7_NAME_2	    :0xE031,
  APPLIANCE7_NAME_3	    :0xE032,
  APPLIANCE7_NAME_4	    :0xE033,
  APPLIANCE7_NAME_5	    :0xE034,
  APPLIANCE7_NAME_6	    :0xE035,
  APPLIANCE7_NAME_7	    :0xE036,
  APPLIANCE7_NAME_END	  :0xE037,

  APPLIANCE8_NAME_START	:0xE038,
  APPLIANCE8_NAME_2	    :0xE039,
  APPLIANCE8_NAME_3	    :0xE03A,
  APPLIANCE8_NAME_4	    :0xE03B,
  APPLIANCE8_NAME_5	    :0xE03C,
  APPLIANCE8_NAME_6	    :0xE03D,
  APPLIANCE8_NAME_7	    :0xE03E,
  APPLIANCE8_NAME_END	  :0xE03f,
  APPLIANCE9_NAME_START	:0xE040,
  APPLIANCE9_NAME_2	    :0xE041,
  APPLIANCE9_NAME_3	    :0xE042,
  APPLIANCE9_NAME_4	    :0xE043,
  APPLIANCE9_NAME_5	    :0xE044,
  APPLIANCE9_NAME_6	    :0xE045,
  APPLIANCE9_NAME_7	    :0xE046,
  APPLIANCE9_NAME_END	  :0xE047,
  APPLIANCE10_NAME_START	:0xE048,
  APPLIANCE10_NAME_2	    :0xE049,
  APPLIANCE10_NAME_3	    :0xE04A,
  APPLIANCE10_NAME_4	    :0xE04B,
  APPLIANCE10_NAME_5	    :0xE04C,
  APPLIANCE10_NAME_6	    :0xE04D,
  APPLIANCE10_NAME_7	    :0xE04E,
  APPLIANCE10_NAME_END	  :0xE04F,
  APPLIANCE11_NAME_START	:0xE050,
  APPLIANCE11_NAME_2	    :0xE051,
  APPLIANCE11_NAME_3	    :0xE052,
  APPLIANCE11_NAME_4	    :0xE053,
  APPLIANCE11_NAME_5	    :0xE054,
  APPLIANCE11_NAME_6	    :0xE055,
  APPLIANCE11_NAME_7	    :0xE056,
  APPLIANCE11_NAME_END	  :0xE057,
  APPLIANCE12_NAME_START	:0xE058,
  APPLIANCE12_NAME_2	    :0xE059,
  APPLIANCE12_NAME_3	    :0xE05A,
  APPLIANCE12_NAME_4	    :0xE05B,
  APPLIANCE12_NAME_5	    :0xE05C,
  APPLIANCE12_NAME_6	    :0xE05D,
  APPLIANCE12_NAME_7	    :0xE05E,
  APPLIANCE12_NAME_END	  :0xE05F,
  APPLIANCE13_NAME_START	:0xE060,
  APPLIANCE13_NAME_2	    :0xE061,
  APPLIANCE13_NAME_3	    :0xE062,
  APPLIANCE13_NAME_4	    :0xE063,
  APPLIANCE13_NAME_5	    :0xE064,
  APPLIANCE13_NAME_6	    :0xE065,
  APPLIANCE13_NAME_7	    :0xE066,
  APPLIANCE13_NAME_END	  :0xE067,

  SOCKET_1_LINK_INDEX: 0xE068,
  SOCKET_2_LINK_INDEX: 0xE069,
  SOCKET_3_LINK_INDEX: 0xE06A,
  SOCKET_4_LINK_INDEX: 0xE06B,
  SOCKET_5_LINK_INDEX: 0xE06C,
  SOCKET_6_LINK_INDEX: 0xE06D,
  SOCKET_7_LINK_INDEX: 0xE06E,
  SOCKET_8_LINK_INDEX: 0xE06F,
  VALVE_1_LINK_INDEX: 0xE070,
  VALVE_2_LINK_INDEX: 0xE071,
  VALVE_3_LINK_INDEX: 0xE072,
  VALVE_4_LINK_INDEX: 0xE073,
  VFCONTACT_1_LINK_INDEX: 0xE074,

  WIFI_BLE_ENABLE:      0xE075,

  APPLIANCE14_NAME_START	:0xE076,
  APPLIANCE14_NAME_2	    :0xE077,
  APPLIANCE14_NAME_3	    :0xE078,
  APPLIANCE14_NAME_4	    :0xE079,
  APPLIANCE14_NAME_5	    :0xE07A,
  APPLIANCE14_NAME_6	    :0xE07B,
  APPLIANCE14_NAME_7	    :0xE07C,
  APPLIANCE14_NAME_END	  :0xE07D,
  APPLIANCE15_NAME_START	:0xE07E,
  APPLIANCE15_NAME_2	    :0xE07F,
  APPLIANCE15_NAME_3	    :0xE080,
  APPLIANCE15_NAME_4	    :0xE081,
  APPLIANCE15_NAME_5	    :0xE082,
  APPLIANCE15_NAME_6	    :0xE083,
  APPLIANCE15_NAME_7	    :0xE084,
  APPLIANCE15_NAME_END	  :0xE085,
  APPLIANCE16_NAME_START	:0xE086,
  APPLIANCE16_NAME_2	    :0xE087,
  APPLIANCE16_NAME_3	    :0xE088,
  APPLIANCE16_NAME_4	    :0xE089,
  APPLIANCE16_NAME_5	    :0xE08A,
  APPLIANCE16_NAME_6	    :0xE08B,
  APPLIANCE16_NAME_7	    :0xE08C,
  APPLIANCE16_NAME_END	  :0xE08D,
  APPLIANCE17_NAME_START	:0xE08E,
  APPLIANCE17_NAME_2	    :0xE08F,
  APPLIANCE17_NAME_3	    :0xE090,
  APPLIANCE17_NAME_4	    :0xE091,
  APPLIANCE17_NAME_5	    :0xE092,
  APPLIANCE17_NAME_6	    :0xE093,
  APPLIANCE17_NAME_7	    :0xE094,
  APPLIANCE17_NAME_END	  :0xE095,
  APPLIANCE18_NAME_START	:0xE096,
  APPLIANCE18_NAME_2	    :0xE097,
  APPLIANCE18_NAME_3	    :0xE098,
  APPLIANCE18_NAME_4	    :0xE099,
  APPLIANCE18_NAME_5	    :0xE09A,
  APPLIANCE18_NAME_6	    :0xE09B,
  APPLIANCE18_NAME_7	    :0xE09C,
  APPLIANCE18_NAME_END	  :0xE09D,
  APPLIANCE19_NAME_START	:0xE09E,
  APPLIANCE19_NAME_2	    :0xE09F,
  APPLIANCE19_NAME_3	    :0xE0A0,
  APPLIANCE19_NAME_4	    :0xE0A1,
  APPLIANCE19_NAME_5	    :0xE0A2,
  APPLIANCE19_NAME_6	    :0xE0A3,
  APPLIANCE19_NAME_7	    :0xE0A4,
  APPLIANCE19_NAME_END	  :0xE0A5,

  VFCONTACT_2_TYPE: 57510,
  // 	 EXP_1_TYPE: 57511,
  // 	 EXP_2_TYPE: 57512,
  // 	 EXP_3_TYPE: 57513,
  // 	 EXP_4_TYPE: 57514,
  // 	 EXP_5_TYPE: 57515,
  // 	 EXP_6_TYPE: 57516,

  VFCONTACT_2_MODE: 57517,
  // 	 EXP_1_MODE: 57518,
  // 	 EXP_2_MODE: 57519,
  // 	 EXP_3_MODE: 57520,
  // 	 EXP_4_MODE: 57521,
  // 	 EXP_5_MODE: 57522,
  // 	 EXP_6_MODE: 57523,

  VFCONTACT_2_SETTING: 57524,
  // 	 EXP_1_SETTING: 57525,
  // 	 EXP_2_SETTING: 57526,
  // 	 EXP_3_SETTING: 57527,
  // 	 EXP_4_SETTING: 57528,
  // 	 EXP_5_SETTING: 57529,
  // 	 EXP_6_SETTING: 57530,

  VFCONTACT_2_TIME_ENABLE: 57531,
  // 	 EXP_1_TIME_ENABLE: 57532,
  // 	 EXP_2_TIME_ENABLE: 57533,
  // 	 EXP_3_TIME_ENABLE: 57534,
  // 	 EXP_4_TIME_ENABLE: 57535,
  // 	 EXP_5_TIME_ENABLE: 57536,
  // 	 EXP_6_TIME_ENABLE: 57537,

  VFCONTACT_2_TIME_1_START_TIME: 57538,
  // 	 EXP_1_TIME_1_START_TIME: 57539,
  // 	 EXP_2_TIME_1_START_TIME: 57540,
  // 	 EXP_3_TIME_1_START_TIME: 57541,
  // 	 EXP_4_TIME_1_START_TIME: 57542,
  // 	 EXP_5_TIME_1_START_TIME: 57543,
  // 	 EXP_6_TIME_1_START_TIME: 57544,

  VFCONTACT_2_TIME_1_STOP_TIME: 57545,
  // 	 EXP_1_TIME_1_STOP_TIME: 57546,
  // 	 EXP_2_TIME_1_STOP_TIME: 57547,
  // 	 EXP_3_TIME_1_STOP_TIME: 57548,
  // 	 EXP_4_TIME_1_STOP_TIME: 57549,
  // 	 EXP_5_TIME_1_STOP_TIME: 57550,
  // 	 EXP_6_TIME_1_STOP_TIME: 57551,
  VFCONTACT_2_TIME_2_START_TIME: 57552,
  // 	 EXP_1_TIME_2_START_TIME: 57553,
  // 	 EXP_2_TIME_2_START_TIME: 57554,
  // 	 EXP_3_TIME_2_START_TIME: 57555,
  // 	 EXP_4_TIME_2_START_TIME: 57556,
  // 	 EXP_5_TIME_2_START_TIME: 57557,
  // 	 EXP_6_TIME_2_START_TIME: 57558,
  VFCONTACT_2_TIME_2_STOP_TIME: 57559,
  // 	 EXP_1_TIME_2_STOP_TIME: 57560,
  // 	 EXP_2_TIME_2_STOP_TIME: 57561,
  // 	 EXP_3_TIME_2_STOP_TIME: 57562,
  // 	 EXP_4_TIME_2_STOP_TIME: 57563,
  // 	 EXP_5_TIME_2_STOP_TIME: 57564,
  // 	 EXP_6_TIME_2_STOP_TIME: 57565,

  HEAT_PUMP_2_ENABLE: 57566,
  GAS_HEATER_2_ENABLE: 57567,
  GAS_HEAT2_COOL_DOWN_TIME: 57568,
  CHILL_2_FUNCTION: 57569,
  HEAT_DEM_2_SANITISER_EN: 57570,
  HEAT_2_SPEED: 57571,
  HEATER_2_SOLAR_PRIORITY: 57572,
  HEATER_2_ROOFTEMP_CUTOFF: 57573,
  HEATER_2_PUMP_TYPE: 57574,
  POOL_2_SET_TEMPERATURE: 57575,
  SPA_2_SET_TEMPERATURE: 57576,
  RUN_TILL_HEATED_2_FUNC: 57577,
  HEATER_2_TRACKING_ENABLE: 57578,
  HEATER_2_TRACKING_OFFSET: 57579,
  THERMISTOR_3_TYPE: 57580,
  THERMISTOR_3_OFFSET: 57581,
  HEATER_USES_WATER: 57582,
  HEATER_2_USES_WATER: 57583,
  SOLAR_RUN_FROM_HEATER_PUMP: 57584,

  HEATER_EXPANSION_PRESENT: 57585,
  HEATER_HYDROTHERAPY_MODE: 57586,
  HEATER_2_HYDROTHERAPY_MODE: 57587,
  ANTIFREEZE_ACTIVE: 57588,
  ANTIFREEZE_TEMPERATURE: 57589,

  POOL_NAME_START: 57590,
  POOL_NAME_2: 57591,
  POOL_NAME_3: 57592,
  POOL_NAME_4: 57593,
  POOL_NAME_5: 57594,
  POOL_NAME_6: 57595,
  POOL_NAME_7: 57596,
  POOL_NAME_END:    57597,

  SPA_NAME_START: 57598,
  SPA_NAME_2: 57599,
  SPA_NAME_3: 57600,
  SPA_NAME_4: 57601,
  SPA_NAME_5: 57602,
  SPA_NAME_6: 57603,
  SPA_NAME_7: 57604,
  SPA_NAME_END:   57605,

  SPA_TIMER_2_ENABLE: 57606,
  SPA_TIMER_2_START_TIME: 57607,
  SPA_TIMER_2_STOP_TIME: 57608,

  SOLAR_USES_WATER: 57609,

  LIGHT_1_REINIT: 57610,
  LIGHT_2_REINIT: 57611,
  LIGHT_3_REINIT: 57612,

  // 	/* new run once enabled*/
  SOCKET_1_RUN_ONCE_ENABLE: 57613,
  SOCKET_2_RUN_ONCE_ENABLE: 57614,
  SOCKET_3_RUN_ONCE_ENABLE: 57615,
  SOCKET_4_RUN_ONCE_ENABLE: 57616,
  SOCKET_5_RUN_ONCE_ENABLE: 57617,
  SOCKET_6_RUN_ONCE_ENABLE: 57618,
  SOCKET_7_RUN_ONCE_ENABLE: 57619,
  SOCKET_8_RUN_ONCE_ENABLE: 57620,
  VALVE_1_RUN_ONCE_ENABLE:  57621,
  VALVE_2_RUN_ONCE_ENABLE:  57622,
  VALVE_3_RUN_ONCE_ENABLE:  57623,
  VALVE_4_RUN_ONCE_ENABLE:  57624,
  VFCONTACT_1_RUN_ONCE_ENABLE: 57625,
  VFCONTACT_2_RUN_ONCE_ENABLE: 57626,
  EXP_2_RUN_ONCE_ENABLE: 57627,
  EXP_3_RUN_ONCE_ENABLE: 57628,
  EXP_4_RUN_ONCE_ENABLE: 57629,
  FILTER_RUN_ONCE_ENABLE: 57630,
  SOLAR_RUN_ONCE_ENABLE: 57631,
  SPA_RUN_ONCE_ENABLE: 57632,
  SOCKET_1_RUN_ONCE_START_TIME: 57633,
  SOCKET_2_RUN_ONCE_START_TIME: 57634,
  SOCKET_3_RUN_ONCE_START_TIME: 57635,
  SOCKET_4_RUN_ONCE_START_TIME: 57636,
  SOCKET_5_RUN_ONCE_START_TIME: 57637,
  SOCKET_6_RUN_ONCE_START_TIME: 57638,
  SOCKET_7_RUN_ONCE_START_TIME: 57639,
  SOCKET_8_RUN_ONCE_START_TIME: 57640,
  VALVE_1_RUN_ONCE_START_TIME:  57641,
  VALVE_2_RUN_ONCE_START_TIME:  57642,
  VALVE_3_RUN_ONCE_START_TIME:  57643,
  VALVE_4_RUN_ONCE_START_TIME:  57644,
  VFCONTACT_1_RUN_ONCE_START_TIME: 57645,
  VFCONTACT_2_RUN_ONCE_START_TIME: 57646,
  EXP_2_RUN_ONCE_START_TIME:       57647,
  EXP_3_RUN_ONCE_START_TIME: 57648,
  EXP_4_RUN_ONCE_START_TIME: 57649,
  FILTER_RUN_ONCE_START_TIME: 57650,
  SOLAR_RUN_ONCE_START_TIME: 57651,
  SPA_RUN_ONCE_START_TIME:        57652,

  SOCKET_1_RUN_ONCE_STOP_TIME: 57653,
  SOCKET_2_RUN_ONCE_STOP_TIME: 57654,
  SOCKET_3_RUN_ONCE_STOP_TIME: 57655,
  SOCKET_4_RUN_ONCE_STOP_TIME: 57656,
  SOCKET_5_RUN_ONCE_STOP_TIME: 57657,
  SOCKET_6_RUN_ONCE_STOP_TIME: 57658,
  SOCKET_7_RUN_ONCE_STOP_TIME: 57659,
  SOCKET_8_RUN_ONCE_STOP_TIME: 57660,
  VALVE_1_RUN_ONCE_STOP_TIME: 57661,
  VALVE_2_RUN_ONCE_STOP_TIME: 57662,
  VALVE_3_RUN_ONCE_STOP_TIME: 57663,
  VALVE_4_RUN_ONCE_STOP_TIME: 57664,
  VFCONTACT_1_RUN_ONCE_STOP_TIME: 57665,
  VFCONTACT_2_RUN_ONCE_STOP_TIME: 57666,
  EXP_2_RUN_ONCE_STOP_TIME: 57667,
  EXP_3_RUN_ONCE_STOP_TIME: 57668,
  EXP_4_RUN_ONCE_STOP_TIME: 57669,
  FILTER_RUN_ONCE_STOP_TIME: 57670,
  SOLAR_RUN_ONCE_STOP_TIME: 57671,
  SPA_RUN_ONCE_STOP_TIME: 57672,

  BLOWER_TIMEOUT_ENABLED: 57673,
  SOLAR_FORCE_RUN_ON: 57674,
  SOLAR_TURN_ON_DIFFERENCE: 57675,
  SOLAR_TURN_OFF_DIFFERENCE: 57676,
  MODEL_LABEL: 57677,
  PUMP_CALL_DEBOUNCE: 57678,
  ACID_DOSE_DAILY_LIMIT: 57679,
  CHLOR_DOSE_DAILY_LIMIT: 57680,
  CHLOR_DUTY_CYCLE: 57681,
  LOGGING_DISABLE_REGS: 57682,
  // 	 ENABLE2_Last						= 0xFEFF: 1,
  // 	/* End new write-enable register range */

  FWUPDATE:							0xFF00,
  

  // BASE3								= 
  FWUPDATE_EXP4_SLAVE1:      0xFE00,
  // PRIVATE2_First				    = FWUPDATE_EXP4_SLAVE1,
  FWUPDATE_EXP4_SLAVE2:      0xFE01,
  SMART_HEATER_FUNCTION:            0xFE02,
  HEATER_1_USES_SH_SENS:  0xFE03,
  HEATER_2_USES_SH_SENS:  0xFE04,
  HEATER_1_MOVE_ON:                 0xFE05,
  HEATER_2_MOVE_ON:                 0xFE06,
  EXP4_SLAVE1_TYPE:                 0xFE07,
  THERMISTOR_4_OFFSET:              0xFE08,
  // EXP4_SLAVE2_TYPE:                 0xFE08,
  EXPANSION4_PRESENT:               0xFE09,
  // PRIVATE2_Last						= 0xFEFF,

  // 	 PRIVATE_First
  RESET_OPTIONS:          0xFF01,
  ENABLE_UUID:            0xFF02,
  OTA_AUTH:               0xFF03,
  OTA_HTTPS_ONLY:         0xFF04,
  LCD_CONTRAST:           0xFF05,
  TEST_OUTPUT:            0xFF06,
  TEST_420MA:             0xFF07,
  TEST_DISPLAY:           0xFF08,
  DEVICE_PASSCODE:        0xFF09,
  LOGGING_ENABLE:         0xFF0A,
  FILTER_TIMEn_ENABLE:     0xFF0B,
  FWUPDATE_EXPANSION_PLUS: 0xFF0C,
  SET_MODEL:              0xFF0D,
  BOOTUP_COUNT:           0xFF0E,
  RESTART_COUNT:          0xFF0F,
  // PRIVATE_Last				

  RTC_YEAR: 65296,
  RTC_MONTH: 65297,
  RTC_DAY: 65298,
  RTC_HOUR: 65299,
  RTC_MIN: 65300,

  TIMEZONE: 65301,
  AUTO_SET_TIME: 65302,
  LOCATION: 65303,
  ADJUST_DST: 65304,
  RTC_SEC: 65305,

  OPERATING_MODE: 65312,
  SPA_MODE: 65313,

  THERMISTOR_1_TYPE: 65314,
  THERMISTOR_2_TYPE: 65315,
  THERMISTOR_1_OFFSET: 65316,
  THERMISTOR_2_OFFSET: 65317,

  FILTER_TIME_ENABLE: 65318,
  FILTER_TIME_1_START_TIME: 65319,
  FILTER_TIME_1_STOP_TIME: 65320,
  FILTER_TIME_2_START_TIME: 65321,
  FILTER_TIME_2_STOP_TIME: 65322,

  SOCKET_1_TYPE: 65323,
  SOCKET_2_TYPE: 65324,
  SOCKET_3_TYPE: 65325,
  SOCKET_4_TYPE: 65326,
  SOCKET_5_TYPE: 65327,
  SOCKET_6_TYPE: 65328,
  SOCKET_7_TYPE: 65329,
  SOCKET_8_TYPE: 65330,
  VALVE_1_TYPE: 65331,
  VALVE_2_TYPE: 65332,
  VALVE_3_TYPE: 65333,
  VALVE_4_TYPE: 65334,
  VFCONTACT_1_TYPE: 65335,

  SOCKET_1_MODE:    65336,
  SOCKET_2_MODE:    65337,
  SOCKET_3_MODE:    65338,
  SOCKET_4_MODE: 65339,
  SOCKET_5_MODE: 65340,
  SOCKET_6_MODE: 65341,
  SOCKET_7_MODE: 65342,
  SOCKET_8_MODE: 65343,
  VALVE_1_MODE: 65344,
  VALVE_2_MODE: 65345,
  VALVE_3_MODE: 65346,
  VALVE_4_MODE: 65347,
  VFCONTACT_1_MODE: 65348,

  SOCKET_1_SETTING: 65349,
  SOCKET_2_SETTING: 65350,
  SOCKET_3_SETTING: 65351,
  SOCKET_4_SETTING: 65352,
  SOCKET_5_SETTING: 65353,
  SOCKET_6_SETTING: 65354,
  SOCKET_7_SETTING: 65355,
  SOCKET_8_SETTING: 65356,
  VALVE_1_SETTING:  65357,
  VALVE_2_SETTING:  65358,
  VALVE_3_SETTING:  65359,
  VALVE_4_SETTING:  65360,
  VFCONTACT_1_SETTING: 65361,

  SOCKET_1_TIME_ENABLE:       65362,
  SOCKET_2_TIME_ENABLE:       65363,
  SOCKET_3_TIME_ENABLE:       65364,
  SOCKET_4_TIME_ENABLE:       65365,
  SOCKET_5_TIME_ENABLE:       65366,
  SOCKET_6_TIME_ENABLE:       65367,
  SOCKET_7_TIME_ENABLE:       65368,
  SOCKET_8_TIME_ENABLE:       65369,
  VALVE_1_TIME_ENABLE:        65370,
  VALVE_2_TIME_ENABLE:        65371,
  VALVE_3_TIME_ENABLE:        65372,
  VALVE_4_TIME_ENABLE:        65373,
  VFCONTACT_1_TIME_ENABLE:    65374,

  SOCKET_1_TIME_1_START_TIME: 65375,
  SOCKET_2_TIME_1_START_TIME: 65376,
  SOCKET_3_TIME_1_START_TIME: 65377,
  SOCKET_4_TIME_1_START_TIME: 65378,
  SOCKET_5_TIME_1_START_TIME: 65379,
  SOCKET_6_TIME_1_START_TIME: 65380,
  SOCKET_7_TIME_1_START_TIME: 65381,
  SOCKET_8_TIME_1_START_TIME: 65382,
  VALVE_1_TIME_1_START_TIME:  65383,
  VALVE_2_TIME_1_START_TIME:  65384,
  VALVE_3_TIME_1_START_TIME:  65385,
  VALVE_4_TIME_1_START_TIME:  65386,
  VFCONTACT_1_TIME_1_START_TIME: 65387,

  SOCKET_1_TIME_1_STOP_TIME:  65388,
  SOCKET_2_TIME_1_STOP_TIME:  65389,
  SOCKET_3_TIME_1_STOP_TIME:  65390,
  SOCKET_4_TIME_1_STOP_TIME:  65391,
  SOCKET_5_TIME_1_STOP_TIME:  65392,
  SOCKET_6_TIME_1_STOP_TIME:  65393,
  SOCKET_7_TIME_1_STOP_TIME:  65394,
  SOCKET_8_TIME_1_STOP_TIME:  65395,
  VALVE_1_TIME_1_STOP_TIME:   65396,
  VALVE_2_TIME_1_STOP_TIME:   65397,
  VALVE_3_TIME_1_STOP_TIME:   65398,
  VALVE_4_TIME_1_STOP_TIME:   65399,
  VFCONTACT_1_TIME_1_STOP_TIME: 65400,

  SOCKET_1_TIME_2_START_TIME: 65401,
  SOCKET_2_TIME_2_START_TIME: 65402,
  SOCKET_3_TIME_2_START_TIME: 65403,
  SOCKET_4_TIME_2_START_TIME: 65404,
  SOCKET_5_TIME_2_START_TIME: 65405,
  SOCKET_6_TIME_2_START_TIME: 65406,
  SOCKET_7_TIME_2_START_TIME: 65407,
  SOCKET_8_TIME_2_START_TIME: 65408,
  VALVE_1_TIME_2_START_TIME:  65409,
  VALVE_2_TIME_2_START_TIME:  65410,
  VALVE_3_TIME_2_START_TIME:  65411,
  VALVE_4_TIME_2_START_TIME:  65412,
  VFCONTACT_1_TIME_2_START_TIME: 65413,

  SOCKET_1_TIME_2_STOP_TIME:  65414,
  SOCKET_2_TIME_2_STOP_TIME:  65415,
  SOCKET_3_TIME_2_STOP_TIME:  65416,
  SOCKET_4_TIME_2_STOP_TIME:  65417,
  SOCKET_5_TIME_2_STOP_TIME:  65418,
  SOCKET_6_TIME_2_STOP_TIME:  65419,
  SOCKET_7_TIME_2_STOP_TIME:  65420,
  SOCKET_8_TIME_2_STOP_TIME:  65421,
  VALVE_1_TIME_2_STOP_TIME:   65422,
  VALVE_2_TIME_2_STOP_TIME:   65423,
  VALVE_3_TIME_2_STOP_TIME:   65424,
  VALVE_4_TIME_2_STOP_TIME:   65425,
  VFCONTACT_1_TIME_2_STOP_TIME: 65426,

  LIGHTS_1_SYNC:              65427,
  LIGHTS_2_SYNC:              65428,

  PUMP_ENABLE:          65429,
  PUMP_SPEED_TYPE:      65430,
  PUMP_MODEL:           65431,
  PUMP_SPEED_1:         65432,
  PUMP_SPEED_2:         65433,
  PUMP_SPEED_3:         65434,
  PUMP_SPEED_4:         65435,

  SOLAR_MODE:           65436,
  SOLAR_TYPE:           65437,
  SOLAR_SET_TEMPERATURE: 65438,
  SOLAR_SPEED: 65439,
  SOLAR_START_TIME: 65440,

  SPA_SET_TEMPERATURE: 65441,
  SPA_BLOWER_ENABLE: 65442,
  SPA_LIMIT_OFFSET: 65443,
  SPA_CHLORINATOR_ENABLE: 65444,
  SPA_CHLORINATOR_DUTY_CYCLE: 65445,
  SPA_LIGHT: 65446,

  POOL_SET_TEMPERATURE: 65447,
  PUMP_CALL_ENABLE: 65448,
  HEAT_PUMP_ENABLE: 65449,
  GAS_HEATER_ENABLE: 65450,
  GAS_HEATER_COOL_DOWN_TIME: 65451,

  WATER_FEATURE_TYPE: 65452,

  SLAVE_1_ENABLE: 65453,
  SLAVE_2_ENABLE: 65454,
  SLAVE_1_ADDRESS: 65455,
  SLAVE_2_ADDRESS: 65456,
  SLAVE_1_CONFIGURE: 65457,
  SLAVE_2_CONFIGURE: 65458,
  SPA_SPEED: 65459,
  SPA_JETPUMP_ENABLE: 65460,

  WATER_FEATURE_SPEED: 65461,
  HEATING_SPEED: 65462,
  PUMP_DEFAULT_SPEED: 65463,
  PUMP_VSP_MODE: 65464,
  HEAT_DEMAND_ENABLE: 65465,
  HEAT_DEMAND_START_TIME: 65466,
  HEAT_DEMAND_STOP_TIME: 65467,

  LIGHTS_3_SYNC: 65468,
  FILTER_TIME_3_START_TIME: 65469,
  FILTER_TIME_3_STOP_TIME: 65470,
  FILTER_TIME_4_START_TIME: 65471,
  FILTER_TIME_4_STOP_TIME: 65472,

  FILTER_TIME_1_SPEED: 65473,
  FILTER_TIME_2_SPEED: 65474,
  FILTER_TIME_3_SPEED: 65475,
  FILTER_TIME_4_SPEED: 65476,

  HEATING_PUMP_CALL_SPEED: 65477,
  PUMP_SPEED_PERCENTAGE_1: 65478,
  PUMP_SPEED_PERCENTAGE_2: 65479,
  PUMP_SPEED_PERCENTAGE_3: 65480,
  PUMP_SPEED_PERCENTAGE_4: 65481,

  SPA_ENABLE: 65482,

  PUMP_MANUAL_ON_TIME: 65483,
  PUMP_MANUAL_OFF_TIME: 65484,
  PUMP_MANUAL_ON_OFF: 65485,

  SPA_LIMIT_OFFSET_ENABLE: 65486,

  INSTALLER_MENU_CODE: 65487,

  DEVICE_NAME_START: 65488,
  DEVICE_NAME_2: 65489,
  DEVICE_NAME_3: 65490,
  DEVICE_NAME_4: 65491,
  DEVICE_NAME_5: 65492,
  DEVICE_NAME_6: 65493,
  DEVICE_NAME_7: 65494,
  DEVICE_NAME_END:  	65495,

  SOLAR_END_TIME: 65496,
  SPA_SLEEP_TIMER: 65497,
  SOLAR_SENSOR_LOCATION: 65498,
  HEATING_PUMP_TYPE: 65499,
  RUN_TILL_HEATED_FUNC: 65500,
  HEAT_DEM_SANITISER_EN: 65501,

  ORP_SET_POINT:              65502,
  PH_SET_POINT:               65503,
  SLAVE_3_ENABLE:             65504,
  SOLAR_WINTER_MODE:          65505,
  HEATER_ROOFTEMP_CUTOFF:     65506,
  HEATER_SOLAR_PRIORITY:      65507,
  PH_SENSOR_CONNECTED:        65508,
  ORP_SENSOR_CONNECTED:       65509,

  POOL_SIZE:                  65510,
  SPA_SIZE:                   65511,

  OVERRIDE_SENSOR_SETTLING:   65512,

  PH_SENSOR_OFFSET:           65513,
  ORP_SENSOR_OFFSET:          65514,

  DOSING_OPTION:              65515,
  PRIME_DOSING_PUMP:          65516,

  SPA_TIMER_ENABLE:           65517,
  SPA_TIMER_START_TIME:       65518,
  SPA_TIMER_STOP_TIME:        65519,

  PH_SET_POINT_INSTALLER:     65520,
  ORP_SET_POINT_INSTALLER:    65521,

  LIGHTS_LINKED:              65522,

  HEATER_CHILL_FUNC:          65523,
  PUMP_CALL_RUN_ON_TIME:      65524,
  PUMP_CALL_ENABLED_ITEMS:    65525,
  PUMP_PRIME_DISABLE:         65526,
}

export const regType = {
  temperature: 0,
  time: 1,
  duration: 2,
  other: 3,
  name: 4,
  off_on: 5,
  dis_en:6 ,
  pump_speed: 7,
  lookup: 8,
  start_stop_time: 9
};

export const RT =
{
    Input:0,
    Holding:1,
    not_used:2,
}

export const registersInit = [
  { address: REGS.TOTAL_HOURS,          				type: regType.other,          title: "Uptime",              			unit: "hours",          value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.ROOF_TEMPERATURE,     				type: regType.temperature,    title: "Roof Temperature",    			unit: "\u00B0C",        value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.POOL_TEMPERATURE,     				type: regType.temperature,    title: "Pool Temperature",    			unit: "\u00B0C",        value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SPA_MODE,             				type: regType.other,          title: "POOL/SPA Mode",       			unit: "",               value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.WATER_TEMPERATURE,    				type: regType.temperature,    title: "Water Temperature",   			unit: "\u00B0C",        value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SM_HEATER_TEMPERATURE,    		type: regType.temperature,    title: "SMART Temperature",   			unit: "\u00B0C",        value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.AMBIENT_TEMPERATURE,  				type: regType.temperature,    title: "Ambient Temperature", 			unit: "\u00B0C",        value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOLAR_MODE,           				type: regType.other,          title: "Solar Mode",          			unit: "",               value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_TYPE, 			    				type: regType.other, 			    title: "Solar Type",          			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_SPEED, 			    				type: regType.pump_speed, 		title: "Solar Speed",         			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_START_TIME, 		    		type: regType.time, 			    title: "Solar Start Time", 					unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_BLOWER_ENABLE, 			  		type: regType.dis_en,         title: "SPA Blower Enable",   			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_ENABLE, 			        		type: regType.dis_en,         title: "SPA Enable",          			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_LIMIT_OFFSET,		      		type: regType.temperature, 	  title: "SPA Limit",           			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_LIMIT_OFFSET_ENABLE, 			type: regType.dis_en,         title: "At Temperature",      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_SIZE, 	              		type: regType.other, 			    title: "Pool Size",           			unit: "L", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_SIZE, 	              		type: regType.other, 			    title: "SPA Size",            			unit: "L", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.SPA_CHLORINATOR_ENABLE, 			type: regType.dis_en,         title: "Sanitiser",           			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_CHLORINATOR_DUTY_CYCLE,		type: regType.other, 			    title: "Duty Cycle",          			unit: "%", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_LIGHT, 	              		type: regType.other, 			    title: "SPA Light",           			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_ON_TIMER, 	          		type: regType.other, 			    title: "SPA Timer",           			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LOGGING_INTERVAL, 	      		type: regType.other, 			    title: "Logging Interval",    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LOGGING_REMOTE, 	        		type: regType.lookup,         title: "Logging Enabled",     			unit: "", 							value: 0, 					display: "" , lookup_table: loggingEnabledLookup , reg_type: RT.Holding},
  { address: REGS.HEATER_ROOFTEMP_CUTOFF, 			type: regType.temperature, 		title: "Mimimum roof Temperature", 	unit: "\u00B0C", 	      value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_SOLAR_PRIORITY, 	  		type: regType.off_on, 			  title: "Heater Solar Priority",			unit: "", 							value: 0, 				  display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.SOLAR_SET_TEMPERATURE,				type: regType.temperature, 	  title: "Solar Set Point",     			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_SET_TEMPERATURE, 					type: regType.temperature, 	  title: "SPA Set Temperature", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_SET_TEMPERATURE, 				type: regType.temperature, 		title: "Pool Set Temperature",			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PH_SET_POINT, 			  				type: regType.other, 			  	title: "pH Set Point",        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ORP_SET_POINT, 			  				type: regType.other, 			  	title: "ORP Set Point", 						unit: "mV", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PH_SET_POINT_INSTALLER, 			type: regType.other, 			  	title: "pH Set Point Installer",    unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ORP_SET_POINT_INSTALLER, 			type: regType.other, 			  	title: "ORP Set Point Installer", 	unit: "mV", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ORP_CURR, 			      				type: regType.other, 			  	title: "ORP RealTime", 			  			unit: "mV", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.ORP_LEVEL, 			      				type: regType.lookup, 			  title: "ORP Level", 			    			unit: "", 						  value: 0, 					display: "" , lookup_table: orpLevelsLookup , reg_type: RT.Input},
  { address: REGS.AUTO_CHLOR_STATE, 			  		type: regType.lookup, 			  title: "Chlorination State", 				unit: "", 						  value: 0, 					display: "" , lookup_table: autoChlorStates , reg_type: RT.Input},
  { address: REGS.ORP_MEAS, 			      				type: regType.other, 			  	title: "ORP MEas", 			      			unit: "mV", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.PUMP_LAST_RUN_TIME, 					type: regType.time, 			    title: "Pump Last Run", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.ACID_PUMP_LAST_RUN_TIME, 			type: regType.time, 					title: "ACID Last Run", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.CHLORINE_PUMP_LAST_RUN_TIME, 	type: regType.time, 		      title: "ORP last Run", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.PH_CURR, 			        				type: regType.other, 			  	title: "pH RealTime", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.PH_LEVEL, 			      				type: regType.lookup, 			  title: "pH Level", 			      			unit: "", 							value: 0, 					display: "" , lookup_table: phLevelsLookup , reg_type: RT.Input},
  { address: REGS.PH_MEAS, 			        				type: regType.other, 			  	title: "pH Meas", 			      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.HARDWARE_VERSION, 						type: regType.other, 			  	title: "HW:", 			          			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.FIRMWARE_VERSION, 						type: regType.other, 			  	title: "Firmware Version:", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.FIRMWARE_BUILDS, 							type: regType.other, 			  	title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.IOCTRL_VERSION, 							type: regType.other, 			  	title: "IO Ctrl:",            			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXP1_VERSION, 			  				type: regType.other, 			  	title: "Exp 1:", 			        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXP1_BUILD, 			    				type: regType.other, 			  	title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXP2_VERSION, 			  				type: regType.other, 			    title: "Exp 2:", 			        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXP2_BUILD, 			    				type: regType.other, 			    title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXPANSION_PLUS_VERSION, 			type: regType.other, 			    title: "AquaChem:", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.EXPANSION_PLUS_BUILD, 				type: regType.other, 			    title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.FAULT_FLAGS, 			    				type: regType.other, 			    title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.MODEL, 			          				type: regType.other, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.PUMP_STATE, 			    				type: regType.other, 					title: "Pump State", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.PUMP_ENABLE, 			    				type: regType.other, 					title: "Pump Enable", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_TYPE, 							type: regType.off_on, 				title: "Pump Multi-Speed", 					unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_MODEL, 			    				type: regType.lookup, 				title: "Pump model", 			    			unit: "", 							value: 0, 					display: "" , lookup_table:pumpModelOptionsSelect , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_1, 			  				type: regType.other, 					title: "Pump Speed 1", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_2, 			  				type: regType.other, 					title: "Pump Speed 2", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_3, 			  				type: regType.other, 					title: "Pump Speed 3", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_4, 			  				type: regType.other, 					title: "Pump Speed 4", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_STATE, 			    				type: regType.other, 					title: "Solar State", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOLAR_INIT_TEMP, 							type: regType.temperature, 		title: "Solar init Temp", 					unit: "\u00B0C", 				value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.TOTAL_HOURS, 			    				type: regType.other, 					title: "Uptime:", 			      			unit: "hrs", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.INPUTS_STATUS, 			  				type: regType.other, 					title: "Inputs", 						  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.OUTPUTS_STATUS, 							type: regType.other, 					title: "Outputs", 									unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.THERMISTOR_1, 			  				type: regType.temperature, 		title: "Ther 1:", 									unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.THERMISTOR_2, 			  				type: regType.temperature, 		title: "Therm 2:", 									unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.THERMISTOR_3, 			  				type: regType.temperature, 		title: "Therm 3:", 									unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.HEATER_STATE, 			  				type: regType.other, 					title: "Heater State:", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.HEATER_2_STATE, 							type: regType.other, 					title: "Heater 2 State:", 					unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOLAR_WINTER_NEXT_MINS, 			type: regType.other, 					title: "WINTER MODE NEXT:", 				unit: "mins", 					value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_3_ENABLE, 							type: regType.dis_en, 	      title: "EXP 3 Enable", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_WINTER_MODE, 						type: regType.dis_en, 	      title: "Winter Mode", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_TRIGGER, 			  				type: regType.other, 					title: "Pump Activated By", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.THERMISTOR_1_TYPE, 						type: regType.other, 					title: "Thermistor 1 Type", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_2_TYPE, 						type: regType.other, 					title: "Thermistor 2 Type", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_3_TYPE, 						type: regType.other, 					title: "Thermistor 3 Type", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_1_OFFSET, 					type: regType.temperature, 		title: "Thermistor 1 offset", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_2_OFFSET, 					type: regType.temperature, 		title: "Thermistor 2 offset", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_3_OFFSET, 					type: regType.temperature, 		title: "Thermistor 3 offset", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.THERMISTOR_4_OFFSET, 					type: regType.temperature, 		title: "Thermistor 4 offset", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PH_SENSOR_CONNECTED, 					type: regType.off_on, 				title: "Ph Probe Attached", 				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ORP_SENSOR_CONNECTED, 				type: regType.off_on, 				title: "ORP Probe Attached", 				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},


  /****SOcket Statuses ******/
  { address: REGS.SOCKET_1_STATUS, 							type: regType.other, 					title: "S1 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_2_STATUS, 							type: regType.other, 					title: "S2 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_3_STATUS, 							type: regType.other, 					title: "S3 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_4_STATUS, 							type: regType.other, 					title: "S4 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_5_STATUS, 							type: regType.other, 					title: "S5 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_6_STATUS, 							type: regType.other, 					title: "S6 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_7_STATUS, 							type: regType.other, 					title: "S7 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_8_STATUS, 							type: regType.other, 					title: "S8 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VALVE_1_STATUS, 							type: regType.other, 					title: "V1 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VALVE_2_STATUS, 							type: regType.other, 					title: "V2 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VALVE_3_STATUS, 							type: regType.other, 					title: "V3 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VALVE_4_STATUS, 							type: regType.other, 					title: "V4 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VF_CONTACT_1_STATUS, 					type: regType.other, 					title: "VF1 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.VF_CONTACT_2_STATUS, 					type: regType.other, 					title: "VF2 Status", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SOCKET_1_TYPE, 			  				type: regType.other, 					title: "Skt 1 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_2_TYPE, 			  				type: regType.other, 					title: "Skt 2 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_3_TYPE, 			  				type: regType.other, 					title: "Skt 3 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_4_TYPE, 			  				type: regType.other, 					title: "Skt 4 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_5_TYPE, 			  				type: regType.other, 					title: "Skt 5 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_6_TYPE, 			  				type: regType.other, 					title: "Skt 6 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_7_TYPE, 			  				type: regType.other, 					title: "Skt 7 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.SOCKET_8_TYPE, 			  				type: regType.other, 					title: "Skt 8 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding, light_number: 0},
  { address: REGS.VALVE_1_TYPE, 			  				type: regType.other, 					title: "Vlv 1 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_TYPE, 			  				type: regType.other, 					title: "Vlv 2 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_TYPE, 			  				type: regType.other, 					title: "Vlv 3 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_TYPE, 			  				type: regType.other, 					title: "Vlv 4 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_TYPE, 						type: regType.other, 					title: "VF 1 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_TYPE, 						type: regType.other, 					title: "VF 2 Type", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_MODE, 			  				type: regType.other, 					title: "Skt 1 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_MODE, 			  				type: regType.other, 					title: "Skt 2 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_MODE, 			  				type: regType.other, 					title: "Skt 3 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_MODE, 			  				type: regType.other, 					title: "Skt 4 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_MODE, 			  				type: regType.other, 					title: "Skt 5 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_MODE, 			  				type: regType.other, 					title: "Skt 5 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_MODE, 			  				type: regType.other, 					title: "Skt 6 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_MODE, 			  				type: regType.other, 					title: "Skt 7 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_MODE, 			  				type: regType.other, 					title: "Vlv 1 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_MODE, 			  				type: regType.other, 					title: "Vlv 2 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_MODE, 			  				type: regType.other, 					title: "Vlv 3 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_MODE, 			  				type: regType.other, 					title: "Vlv 4 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_MODE, 						type: regType.other, 					title: "VF 1 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_MODE, 						type: regType.other, 					title: "VF 2 Mode", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_SETTING,         		type: regType.other, 					title: "Skt 1 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_SETTING,         		type: regType.other, 					title: "Skt 2 Setting",							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_SETTING,         		type: regType.other, 					title: "Skt 3 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_SETTING,         		type: regType.other, 					title: "Skt 4 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_SETTING,         		type: regType.other, 					title: "Skt 5 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_SETTING,         		type: regType.other, 					title: "Skt 6 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_SETTING,         		type: regType.other, 					title: "Skt 7 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_SETTING,         		type: regType.other, 					title: "Skt 8 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_SETTING,          		type: regType.other, 					title: "Vlv 1 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_SETTING,          		type: regType.other, 					title: "Vlv 2 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_SETTING,          		type: regType.other, 					title: "Vlv 3 Setting",							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_SETTING,          		type: regType.other, 					title: "Vlv 4 Setting", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_SETTING,      		type: regType.other, 					title: "VF 1 Setting",							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_SETTING,      		type: regType.other, 					title: "VF 2 Setting",							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATING_PUMP_CALL_SPEED, 			type: regType.pump_speed, 		title: "Heat Call Spd", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_PERCENTAGE_1, 			type: regType.other, 					title: "Pump Percentage 1", 				unit: "%", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_PERCENTAGE_2, 			type: regType.other, 					title: "Pump Percentage 2", 				unit: "%", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_PERCENTAGE_3, 			type: regType.other, 					title: "Pump Percentage 3",					unit: "%", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_SPEED_PERCENTAGE_4, 			type: regType.other, 					title: "Pump Percentage 4",	  			unit: "%", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_MANUAL_ON_TIME, 	    		type: regType.time, 					title: "Pump Manual On Time",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_MANUAL_OFF_TIME, 	  		type: regType.time, 					title: "Pump Manual Off Time",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_MANUAL_ON_OFF, 	    		type: regType.lookup, 				title: "Pump Manual On/Off", 				unit: "", 							value: 0, 					display: "" , lookup_table: pumpManualOnOff , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_ENABLE, 	    		type: regType.other, 					title: "Filter Enable", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_1_START_TIME, 		type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , enabled: false , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_1_STOP_TIME, 			type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_2_START_TIME, 		type: regType.time, 					title: "Start Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , enabled: false , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_2_STOP_TIME, 			type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_3_START_TIME, 		type: regType.time, 					title: "Start Time 3", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , enabled: false , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_3_STOP_TIME, 			type: regType.time, 					title: "Stop Time 3", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_4_START_TIME, 		type: regType.time, 					title: "Start Time 4", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , enabled: false , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_4_STOP_TIME, 			type: regType.time, 					title: "Stop Time 4", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_1_SPEED, 					type: regType.pump_speed, 		title: "Speed", 										unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_2_SPEED, 					type: regType.pump_speed, 		title: "Speed", 										unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_3_SPEED, 					type: regType.pump_speed, 		title: "Speed", 										unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_TIME_4_SPEED, 					type: regType.pump_speed, 		title: "Speed", 										unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.RTC_YEAR, 			          		type: regType.other, 					title: "Clock:", 										unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.LOCATION, 			          		type: regType.other, 					title: "Location:", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.AUTO_SET_TIME,            		type: regType.off_on,         title: "Auto Set Time",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ADJUST_DST,               		type: regType.off_on,         title: "Auto Adjust DST",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_CALL_ENABLE, 			  		type: regType.other, 					title: "Pump Call Enabled:", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.GAS_HEATER_ENABLE, 			  		type: regType.lookup, 		    title: "Gas Heater Enabled:", 			unit: "", 							value: 0, 					display: "" , lookup_table: heaterOffOnOptions , enabled: true , reg_type: RT.Holding},
  { address: REGS.HEAT_PUMP_ENABLE, 			  		type: regType.lookup, 		    title: "Heat Pump Enabled:",  			unit: "", 							value: 0, 					display: "" , lookup_table: heaterOffOnOptions , enabled: true , reg_type: RT.Holding},
  
  { address: REGS.GAS_HEATER_COOL_DOWN_TIME,		type: regType.time, 					title: "Gas Heater CoolDown:", 			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SLAVE_1_ENABLE,           		type: regType.dis_en, 	      title: "Exp 1 Enable",        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SLAVE_2_ENABLE,           		type: regType.dis_en, 	      title: "Exp 2 Enable",        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SLAVE_1_ADDRESS,          		type: regType.other, 					title: "Exp 1 Address",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_2_ADDRESS,          		type: regType.other, 					title: "Exp 2 Address",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_1_CONFIGURE,        		type: regType.other, 					title: "Exp 1 Configure",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SLAVE_2_CONFIGURE,        		type: regType.other, 					title: "Exp 2 Configure",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_SPEED,                		type: regType.pump_speed, 		title: "SPA Speed",           			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_JETPUMP_ENABLE,       		type: regType.other, 					title: "SPA Jet Pump Enable", 			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.RESET_OPTIONS, 			      		type: regType.other, 					title: "Reset Options", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.ENABLE_UUID, 			        		type: regType.other, 					title: "Enable UUID", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.OTA_AUTH, 			          		type: regType.other, 					title: "OTA Auth", 			      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.OTA_HTTPS_ONLY, 			    		type: regType.other, 					title: "OTA HTTPS Only", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LCD_CONTRAST, 			      		type: regType.other, 					title: "LCD Contrast", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.TEST_OUTPUT, 			        		type: regType.other, 					title: "Test Output", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.TEST_420MA, 			        		type: regType.other, 					title: "Test 420mA",  			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.TEST_DISPLAY, 			      		type: regType.other, 					title: "Test Display", 			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_PASSCODE, 			    		type: regType.other, 					title: "Device Passcode",		  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.INSTALLER_MENU_CODE, 					type: regType.other, 					title: "Menu Passcode",		    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  
  { address: REGS.LOGGING_ENABLE, 			    		type: regType.lookup, 	      title: "Logging Enabled", 					unit: "", 							value: 0, 					display: "" , lookup_table: loggingEnabledLookup , reg_type: RT.Holding},
  { address: REGS.FILTER_TIMEn_ENABLE, 					type: regType.other, 					title: "Filter Timen Enable", 			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FWUPDATE_EXPANSION_PLUS, 			type: regType.lookup, 				title: "FW Update Exp+", 						unit: "", 							value: 0, 					display: "" , lookup_table: upgradeStatusTable , reg_type: RT.Holding},
  { address: REGS.FWUPDATE_EXP4_SLAVE1,     		type: regType.lookup, 				title: "FW Update SH 1", 						unit: "", 							value: 0, 					display: "" , lookup_table: upgradeStatusTable , reg_type: RT.Holding},
  { address: REGS.FWUPDATE_EXP4_SLAVE2,     		type: regType.lookup, 				title: "FW Update SH 2", 						unit: "", 							value: 0, 					display: "" , lookup_table: upgradeStatusTable , reg_type: RT.Holding},
  { address: REGS.SET_MODEL, 			          		type: regType.other, 					title: "Set Model", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.BOOTUP_COUNT, 			      		type: regType.other, 					title: "Boot Count",  			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.RESTART_COUNT, 			      		type: regType.other, 					title: "Restart Count",			  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
 
  { address: REGS.APPLIANCE1_NAME_START, 				type: regType.name, 					title: "Skt1 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE1_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE2_NAME_START, 				type: regType.name, 					title: "Skt2 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE2_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE3_NAME_START, 				type: regType.name, 					title: "Skt3 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE3_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE4_NAME_START, 				type: regType.name, 					title: "Skt4 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE4_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE5_NAME_START, 				type: regType.name, 					title: "Skt5 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE5_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE6_NAME_START, 				type: regType.name, 					title: "Skt6 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE6_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  
  { address: REGS.APPLIANCE7_NAME_START, 				type: regType.name, 					title: "Skt7 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE7_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE8_NAME_START, 				type: regType.name, 					title: "Skt8 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE8_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE9_NAME_START, 				type: regType.name, 					title: "Vlv1 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_2, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_3, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_4, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_5, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_6, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_7, 		    		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE9_NAME_END, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE10_NAME_START, 			type: regType.name, 					title: "Vlv2 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE10_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE11_NAME_START, 			type: regType.name, 					title: "Vlv3 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE11_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE12_NAME_START, 			type: regType.name, 					title: "Vlv4 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE12_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE13_NAME_START, 			type: regType.name, 					title: "VF 1 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE13_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE14_NAME_START, 			type: regType.name, 					title: "Vf 2 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE14_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE15_NAME_START, 			type: regType.name, 					title: "App15 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE15_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE16_NAME_START, 			type: regType.name, 					title: "App16 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE16_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE17_NAME_START, 			type: regType.name, 					title: "App17 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE17_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE18_NAME_START, 			type: regType.name, 					title: "App18 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE18_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.APPLIANCE19_NAME_START, 			type: regType.name, 					title: "App19 Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_2, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_3, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_4, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_5, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_6, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_7, 		  		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.APPLIANCE19_NAME_END, 				type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.DEVICE_NAME_START, 	      		type: regType.name, 					title: "Dev Name", 			      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_2, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_3, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_4, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_5, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_6, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_7, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DEVICE_NAME_END, 		      		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.POOL_NAME_START, 			    		type: regType.name, 					title: "Pool Name", 			    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_2, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_3, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_4, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_5, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_6, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_7, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.POOL_NAME_END, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  

  { address: REGS.SPA_NAME_START, 			    		type: regType.name, 					title: "SPA Name", 			      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_2, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_3, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_4, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_5, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_6, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_7, 		          		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_NAME_END, 		        		type: regType.name, 					title: "", 			              			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
 
  { address: REGS.HEAT_PUMP_2_ENABLE, 					type: regType.lookup, 		    title: "Heat Pump Enable", 					unit: "", 							value: 0, 					display: "" , lookup_table: heaterOffOnOptions , reg_type: RT.Holding},
  { address: REGS.GAS_HEATER_2_ENABLE, 					type: regType.lookup, 		    title: "Gas Heater Enable", 				unit: "", 							value: 0, 					display: "" , lookup_table: heaterOffOnOptions , reg_type: RT.Holding},
  
  { address: REGS.GAS_HEAT2_COOL_DOWN_TIME, 		type: regType.time, 		      title: "GH2 Cool Down Time",  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.CHILL_2_FUNCTION, 			  		type: regType.off_on, 		    title: "Heater 2 Chilling",   			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_DEM_2_SANITISER_EN,  		type: regType.dis_en,         title: "HD Sanitiser 2 Enable",			unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_2_SPEED,             		type: regType.pump_speed, 		title: "Heating 2 Speed",     			unit: "", 				      value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.HEATER_2_SOLAR_PRIORITY,  		type: regType.dis_en, 	      title: "Heater 2 Solar Priority",		unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_ROOFTEMP_CUTOFF, 		type: regType.temperature, 		title: "Minimum Roof temperature", 	unit: "\u00B0C", 	      value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_PUMP_TYPE,       		type: regType.lookup, 			  title: "Heater 2 Pump Type",  			unit: "", 							value: 0, 					display: "" , lookup_table: heatingPumpTypes , reg_type: RT.Holding},
  { address: REGS.POOL_2_SET_TEMPERATURE,   		type: regType.temperature, 		title: "Heater 2 Pool Set Temp", 		unit: "\u00B0C", 		    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_2_SET_TEMPERATURE,    		type: regType.temperature, 		title: "Heater 2 SPA Set Temp", 		unit: "\u00B0C", 			  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_2_SET_TEMPERATURE,    		type: regType.temperature, 		title: "Heater 2 SPA Set Temp", 		unit: "\u00B0C", 			  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.RUN_TILL_HEATED_2_FUNC,   		type: regType.other, 		      title: "Run Till Heated 2",   			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_TRACKING_ENABLE, 		type: regType.other, 		      title: "Heater 2 tracking",   			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_TRACKING_OFFSET, 		type: regType.other, 		      title: "Heater 2 Track offset",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_END_TIME,           		type: regType.time, 		      title: "Solar End Time",        		unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_SENSOR_LOCATION,    		type: regType.lookup, 		    title: "Solar Sensor Location",			unit: "", 							value: 0, 					display: "" , lookup_table: solarSensorLocations , reg_type: RT.Holding},
  { address: REGS.SPA_SLEEP_TIMER,          		type: regType.other, 		      title: "SPA Sleep Timer",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATING_PUMP_TYPE,        		type: regType.lookup, 		    title: "Heating Pump Type",   			unit: "", 							value: 0, 					display: "" , lookup_table: heatingPumpTypes , reg_type: RT.Holding},
  { address: REGS.RUN_TILL_HEATED_FUNC,     		type: regType.other, 		      title: "Run Till Heated",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_DEM_SANITISER_EN,    		type: regType.lookup, 		    title: "HD Sanitiser Enable", 			unit: "", 							value: 0, 					display: "" , lookup_table: offOnOptions , reg_type: RT.Holding},
  { address: REGS.HEATER_USES_WATER, 			  		type: regType.lookup, 				title: "Heater Uses Water", 				unit: "", 							value: 0, 					display: "" , lookup_table: offOnOptions , reg_type: RT.Holding},
  { address: REGS.HEATER_2_USES_WATER, 					type: regType.lookup, 				title: "Heater 2 Uses Water", 			unit: "", 							value: 0, 					display: "" , lookup_table: offOnOptions , reg_type: RT.Holding},
  { address: REGS.SOLAR_RUN_FROM_HEATER_PUMP,		type: regType.off_on, 				title: "Solar uses Heating Pump",		unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_EXPANSION_PRESENT, 		type: regType.off_on,					title: "HEater Exp Pres",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_HYDROTHERAPY_MODE, 		type: regType.off_on, 				title: "Heater Hydro",        			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_HYDROTHERAPY_MODE, 		type: regType.off_on, 			title: "Heater 2 Hydro",      			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
 
  { address: REGS.ANTIFREEZE_ACTIVE,        		type: regType.dis_en,	        title: "Antifreeze",          			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ANTIFREEZE_TEMPERATURE,   		type: regType.temperature  , 	title: "Antifreeze Temp" ,    			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.SPA_TIMER_2_ENABLE,       		type: regType.dis_en,	        title: "SPA 2 Timer Enable",  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_TIMER_ENABLE,         		type: regType.dis_en,         title: "SPA Timer Enable",    			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SPA_TIMER_2_START_TIME,   		type: regType.time, 					title: "SPA Start Time 2", 	  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_TIMER_2_STOP_TIME, 	  		type: regType.time, 					title: "SPA Stop Time 2", 					unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_TIMER_START_TIME,     		type: regType.time, 					title: "SPA Start Time 1", 					unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_TIMER_STOP_TIME, 	    		type: regType.time, 					title: "SPA Stop Time 1", 					unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.OVERRIDE_SENSOR_SETTLING, 		type: regType.dis_en,         title: "Override Probe Settling",   unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PH_SENSOR_OFFSET,         		type: regType.other, 		      title: "pH offset",           			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.ORP_SENSOR_OFFSET,        		type: regType.other, 		      title: "ORP offset",          			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.DOSING_OPTION,            		type: regType.other, 		      title: "Dosing Option",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PRIME_DOSING_PUMP,        		type: regType.lookup , 		    title: "Prime Dosing Pump",   			unit: "", 							value: 0, 					display: "" , lookup_table: dosingOptions, reg_type: RT.Holding},
  
  { address: REGS.WATER_FEATURE_SPEED,      		type: regType.pump_speed, 		title: "Water Feature Speed", 			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATING_SPEED,            		type: regType.pump_speed, 		title: "Heating Speed",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_DEFAULT_SPEED,       		type: regType.pump_speed, 		title: "Pump Default Speed",  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_VSP_MODE,            		type: regType.other, 		      title: "Pump VSP Mode",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_DEMAND_ENABLE,       		type: regType.dis_en,	        title: "Heat Demand Enable",  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_DEMAND_START_TIME,   		type: regType.time, 					title: "Heat Demand",         			unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEAT_DEMAND_STOP_TIME, 	  		type: regType.time, 					title: "Heat Demand Stop Time",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.LIGHTS_1_SYNC, 	          		type: regType.other, 					title: "Lights 1 Sync",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHTS_2_SYNC, 	          		type: regType.other, 					title: "Lights 2 Sync",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHTS_3_SYNC, 	          		type: regType.other, 					title: "Lights 3 Sync",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHT_1_REINIT, 	        		type: regType.other, 					title: "Lights 1 Reinit",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHT_2_REINIT, 	        		type: regType.other, 					title: "Lights 2 Reinit",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHT_3_REINIT, 	        		type: regType.other, 					title: "Lights 3 Reinit",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.LIGHTS_LINKED, 	          		type: regType.other, 					title: "Lights Linked",       			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_CHILL_FUNC, 	      		type: regType.off_on, 				title: "Heater Chilling",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_CALL_RUN_ON_TIME, 	  		type: regType.other, 					title: "Run On Time",         			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.PUMP_CALL_ENABLED_ITEMS, 			type: regType.other, 					title: "Pump Call Items",     			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.BLOWER_TIMEOUT_ENABLED,   		type: regType.dis_en,         title: "SPA Blower Timeout",  			unit: "mins", 				  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_FORCE_RUN_ON,       		type: regType.dis_en,         title: "Solar force RUN-ON",  			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_TURN_ON_DIFFERENCE, 		type: regType.temperature,    title: "Solar turn ON Diff",  			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_TURN_OFF_DIFFERENCE,		type: regType.temperature,    title: "Solar turn OFF Diff", 			unit: "\u00B0C", 				value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.SOCKET_1_TIME_ENABLE, 	    	type: regType.dis_en,		      title: "Socket 1 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_2_TIME_ENABLE, 	    	type: regType.dis_en, 	      title: "Socket 2 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_3_TIME_ENABLE, 	    	type: regType.dis_en, 	      title: "Socket 3 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_4_TIME_ENABLE, 	    	type: regType.dis_en, 	      title: "Socket 4 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_5_TIME_ENABLE, 	    	type: regType.other, 					title: "Socket 5 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_6_TIME_ENABLE, 	    	type: regType.other, 					title: "Socket 6 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_7_TIME_ENABLE, 	    	type: regType.other, 					title: "Socket 7 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_8_TIME_ENABLE, 	    	type: regType.other, 					title: "Socket 8 Time Enable",			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_TIME_1_START_TIME, 	type: regType.time, 					title: "Start Time 1 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_TIME_1_STOP_TIME, 		type: regType.time, 					title: "Stop Time 1", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_TIME_2_START_TIME, 	type: regType.time, 					title: "Start Time 2 ", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_TIME_2_STOP_TIME, 		type: regType.time, 					title: "Stop Time 2", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_1_TIME_ENABLE, 	        type: regType.other, 			  	title:"Valve 1 Time Enable", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_TIME_1_START_TIME,    type: regType.time, 					title:"Start Time 1 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_TIME_1_STOP_TIME,     type: regType.time, 					title:"Stop Time 1", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_TIME_2_START_TIME,    type: regType.time, 					title:"Start Time 2 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_TIME_2_STOP_TIME,     type: regType.time, 					title:"Stop Time 2", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  

  { address: REGS.VALVE_2_TIME_ENABLE, 	        type: regType.other, 					title:"Valve 2 Time Enable", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_TIME_1_START_TIME,    type: regType.time, 					title:"Start Time 1 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_TIME_1_STOP_TIME,     type: regType.time, 			  	title:"Stop Time 1", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_TIME_2_START_TIME,    type: regType.time, 					title:"Start Time 2 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_TIME_2_STOP_TIME,     type: regType.time, 					title:"Stop Time 2", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_3_TIME_ENABLE, 	        type: regType.other, 					title:"Valve 3 Time Enable", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_TIME_1_START_TIME,    type: regType.time, 					title:"Start Time 1 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_TIME_1_STOP_TIME,     type: regType.time, 					title:"Stop Time 1", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_TIME_2_START_TIME,    type: regType.time, 					title:"Start Time 2 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_TIME_2_STOP_TIME,     type: regType.time, 					title:"Stop Time 2", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_4_TIME_ENABLE, 	        type: regType.other, 					title:"Valve 4 Time Enable", 				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_TIME_1_START_TIME,    type: regType.time, 					title:"Start Time 1 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_TIME_1_STOP_TIME,     type: regType.time, 					title:"Stop Time 1", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_TIME_2_START_TIME,    type: regType.time, 					title:"Start Time 2 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_TIME_2_STOP_TIME,     type: regType.time, 					title:"Stop Time 2", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VFCONTACT_1_TIME_ENABLE, 	    type: regType.other, 					title:"VF 1 Time Enable",    				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_TIME_1_START_TIME,type: regType.time, 					title:"Start Time 1 ", 							unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_TIME_1_STOP_TIME, type: regType.time, 					title:"Stop Time 1", 								unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_TIME_2_START_TIME,type: regType.time, 					title:"Heat Demand Time 2 ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_TIME_2_STOP_TIME, type: regType.time, 					title:"Heat Demand Stop Time 2",		unit: "", 						value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  
  { address: REGS.VFCONTACT_2_TIME_ENABLE, 	    type: regType.other, 					title:"Heat Demand 2 Enable",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_TIME_1_START_TIME,type: regType.time, 					title:"Heat Demand 2 Time 1",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_TIME_1_STOP_TIME, type: regType.time, 					title:"Heat Demand 2 Stop Time 1",	unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_TIME_2_START_TIME,type: regType.time, 					title:"Heat Demand 2 Time 2", 			unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_TIME_2_STOP_TIME, type: regType.time, 					title:"Heat Demand 2 Stop Time 2",	unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  

  { address: REGS.SMART_HEATER_FUNCTION, 	      type: regType.lookup, 				title:"SHeater Allocated",    			unit: "", 							value: 0, 					display: "" , lookup_table: sheaterFunc, reg_type: RT.Holding},
  { address: REGS.HEATER_1_USES_SH_SENS,        type: regType.dis_en,       	title:"Heater 1 Uses SH sense",			unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_USES_SH_SENS,        type: regType.dis_en,       	title:"Heater 2 Uses SH sense",			unit: "", 	            value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_1_MOVE_ON,             type: regType.off_on, 				title:"Heat 1 Move On", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.HEATER_2_MOVE_ON,             type: regType.off_on, 				title:"Heat 2 Move On", 						unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.EXP4_SLAVE1_TYPE,             type: regType.lookup, 				title:"sHeater Type", 		    			unit: "", 							value: 0, 					display: "" , lookup_table:sheaterTypes, reg_type: RT.Holding},
  // { address: REGS.EXP4_SLAVE2_TYPE,          type: regType.lookup, 				title:"sHeater 2 Type", 		  			unit: "", 							value: 0, 					display: "" , lookup_table:sheaterTypes , reg_type: RT.Holding},
  { address: REGS.EXPANSION4_PRESENT,           type: regType.off_on, 				title:"Smart Heater Present",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},

  { address: REGS.SMART_HEATER_1_STATE,         type: regType.lookup, 				title:"Smart Heater State",  				unit: "", 							value: 0, 					display: "" , lookup_table: sheaterStates , reg_type: RT.Input},
  { address: REGS.SMART_HEATER_ERROR_BITMAP1,   type: regType.other, 			  	title:"Smart error 1",       				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SMART_HEATER_ERROR_BITMAP2,   type: regType.other, 			  	title:"Smart error 2",       				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SMART_HEATER_ERROR_BITMAP3,   type: regType.other, 			  	title:"Smart error 3",       				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SMART_HEATER_ERROR_BITMAP4,   type: regType.other, 			  	title:"Smart error 4",       				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  // { address: REGS.SMART_HEATER_2_STATE,      type: regType.lookup, 				title:"Smart Heater 2 State",				unit: "", 							value: 0, 					display: "" , lookup_table: sheaterStates , reg_type: RT.Input},
  { address: REGS.SMART_HEATER_1_FAULTS,        type: regType.lookup, 				title:"Smart Heater 1 Faults",			unit: "", 							value: 0, 					display: "" , lookup_table: sheaterFaults , reg_type: RT.Input},
  // { address: REGS.SMART_HEATER_2_FAULTS,     type: regType.lookup, 		  	title:"Smart Heater 2 Faults",			unit: "", 							value: 0, 					display: "" , lookup_table: sheaterFaults , reg_type: RT.Input},

  { address: REGS.SLAVE_1_ONLINE_STATE,         type: regType.off_on, 				title:"Exp 1 Online",        				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_2_ONLINE_STATE,         type: regType.off_on, 				title:"Exp 2 Online",        				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_3_ONLINE_STATE,         type: regType.off_on, 				title:"Exp 3 Online",        				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},

  { address: REGS.SLAVE_1_ONLINE_COUNT,         type: regType.other, 			  	title:"Exp 1 Count",         				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_2_ONLINE_COUNT,         type: regType.other, 			  	title:"Exp 2 Count",         				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.SLAVE_3_ONLINE_COUNT,         type: regType.other, 			  	title:"Exp 3 Count",         				unit: "", 					    value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},

  { address: REGS.EXP1_TYPE,                    type: regType.lookup, 				title:"Exp 1 Type",          				unit: "", 					    value: 0, 					display: "" , lookup_table: expansionTypes , reg_type: RT.Input},
  { address: REGS.EXP2_TYPE,                    type: regType.lookup, 				title:"Exp 2 Type",          				unit: "", 					    value: 0, 					display: "" , lookup_table: expansionTypes , reg_type: RT.Input},
  
  { address: REGS.WIFI_STATUS,                  type: regType.other,        	title:"WiFi Status",         				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.WIFI_RSSI,                    type: regType.other,        	title:"WiFi RSSI",           				unit: "dBm",            value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.WIFI_CHANNEL,                 type: regType.other,        	title:"WiFi Channel",        				unit: "dBm",            value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Input},

  { address: REGS.SOCKET_1_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt1 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt1 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_1_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt1 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_2_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt2 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt2 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_2_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt2 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_3_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt3 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt3 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_3_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt3 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_4_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt4 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt4 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_4_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt4 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_5_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt5 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt5 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_5_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt5 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_6_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt6 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt6 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_6_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt6 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_7_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt7 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt7 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_7_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt7 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOCKET_8_RUN_ONCE_ENABLE,     type: regType.dis_en,       	title:"Skt8 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_RUN_ONCE_START_TIME, type: regType.time, 					title:"Skt8 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOCKET_8_RUN_ONCE_STOP_TIME,  type: regType.time, 					title:"Skt8 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_1_RUN_ONCE_ENABLE,      type: regType.dis_en,       	title:"Vlv1 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_RUN_ONCE_START_TIME,  type: regType.time, 					title:"Vlv1 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_1_RUN_ONCE_STOP_TIME,   type: regType.time, 					title:"Vlv1 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_2_RUN_ONCE_ENABLE,      type: regType.dis_en,       	title:"Vlv2 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_RUN_ONCE_START_TIME,  type: regType.time, 					title:"Vlv2 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_2_RUN_ONCE_STOP_TIME,   type: regType.time, 					title:"Vlv2 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_3_RUN_ONCE_ENABLE,      type: regType.dis_en,       	title:"Vlv3 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VALVE_3_RUN_ONCE_START_TIME,  type: regType.time, 					title:"Vlv3 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_3_RUN_ONCE_STOP_TIME,   type: regType.time, 					title:"Vlv3 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_RUN_ONCE_ENABLE,      type: regType.dis_en,       	title:"Vlv4 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_RUN_ONCE_START_TIME,  type: regType.time, 					title:"Vlv4 RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VALVE_4_RUN_ONCE_STOP_TIME,   type: regType.time, 					title:"Vlv4 RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VFCONTACT_1_RUN_ONCE_ENABLE,  type: regType.dis_en,       	title:"Vf1  Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_RUN_ONCE_START_TIME,type: regType.time, 				title:"Vf1  RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_1_RUN_ONCE_STOP_TIME,type: regType.time, 					title:"Vf1  RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.VFCONTACT_2_RUN_ONCE_ENABLE,  type: regType.dis_en,       	title:"Vf2  Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_RUN_ONCE_START_TIME,type: regType.time, 				title:"Vf2  RO Start Time  ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.VFCONTACT_2_RUN_ONCE_STOP_TIME,type: regType.time, 					title:"Vf2  RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.EXP_2_RUN_ONCE_ENABLE,        type: regType.dis_en,       	title:"Exp2 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_2_RUN_ONCE_START_TIME,    type: regType.time, 			  	title:"Exp2  RO Start Time ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_2_RUN_ONCE_STOP_TIME,     type: regType.time, 					title:"Exp2  RO Stop Time  ",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  
  { address: REGS.EXP_3_RUN_ONCE_ENABLE,        type: regType.dis_en,       	title:"Exp3 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_3_RUN_ONCE_START_TIME,    type: regType.time, 			  	title:"Exp3  RO Start Time ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_3_RUN_ONCE_STOP_TIME,     type: regType.time, 					title:"Exp3  RO Stop Time  ",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  
  { address: REGS.EXP_4_RUN_ONCE_ENABLE,        type: regType.dis_en,       	title:"Exp4 Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_4_RUN_ONCE_START_TIME,    type: regType.time, 			  	title:"Exp4  RO Start Time ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  { address: REGS.EXP_4_RUN_ONCE_STOP_TIME,     type: regType.time, 					title:"Exp4  RO Stop Time  ",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.not_used},
  
  { address: REGS.FILTER_RUN_ONCE_ENABLE,       type: regType.dis_en,       	title:"Filter Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_RUN_ONCE_START_TIME,   type: regType.time, 			  	title:"Exp5  RO Start Time ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.FILTER_RUN_ONCE_STOP_TIME,    type: regType.time, 					title:"Exp5  RO Stop Time  ",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SOLAR_RUN_ONCE_ENABLE,        type: regType.dis_en,       	title:"Solr Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_RUN_ONCE_START_TIME,    type: regType.time, 			  	title:"Solr  RO Start Time ",				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SOLAR_RUN_ONCE_STOP_TIME,     type: regType.time, 					title:"Solr  RO Stop Time  ",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  
  { address: REGS.SPA_RUN_ONCE_ENABLE,          type: regType.dis_en,       	title:"SPA  Run Once Enable",				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_RUN_ONCE_START_TIME,      type: regType.time, 			  	title:"SPA  RO Start Time " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.SPA_RUN_ONCE_STOP_TIME,       type: regType.time, 					title:"SPA  RO Stop Time  " ,				unit: "", 							value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.CHLOR_INHIBITED,              type: regType.off_on, 				title:"Chlorination Inhibited",			unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.CHLOR_LIMIT,                  type: regType.off_on, 				title:"Chlorination @ Limit",				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined , reg_type: RT.Input},
  { address: REGS.FWUPDATE,                     type: regType.lookup, 				title:"Firmware Upgrade", 					unit: "", 						  value: 0, 					display: "" , lookup_table:upgradeStatusTable, reg_type: RT.Input},
  { address: REGS.FWUPDATE_ERROR,               type: regType.lookup, 				title:"Firmware Error", 		  			unit: "", 						  value: 0, 					display: "" , lookup_table:ota_error_codeTable, reg_type: RT.Input},
  { address: REGS.EXPANSION_PLUS_FWUPDATE_ERROR,type: regType.lookup, 				title:"Exp Upgrade Error", 					unit: "", 						  value: 0, 					display: "" , lookup_table:ota_error_codeTable, reg_type: RT.Input},
  { address: REGS.EXPANSION_PLUS_WIFI_RSSI,     type: regType.other, 			  	title:"Exp + Wifi RSSI", 						unit: "dBm", 						value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},

  { address: REGS.MODEL_LABEL,                  type: regType.other, 			  	title:"Mode Label", 		      			unit: "", 						  value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.PUMP_CALL_DEBOUNCE,           type: regType.other, 			  	title:"PC DeBounce", 		    				unit: "", 						  value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Holding},
  { address: REGS.ACID_DOSE_DAILY_LIMIT,        type: regType.other, 			  	title:"Acid Daily Limit", 					unit: "", 					    value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Holding},
  { address: REGS.CHLOR_DOSE_DAILY_LIMIT,       type: regType.other, 			  	title:"Chlorine Daily Limit",				unit: "", 			        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Holding},
  { address: REGS.CHLOR_DUTY_CYCLE,             type: regType.other, 			  	title:"Chlorination Duty Cycle",		unit: "",   		        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Holding},
  { address: REGS.CHLOR_DUTY_CYCLE_STATUS,      type: regType.off_on, 				title:"Chlorine Duty Cycle Status",	unit: "", 			        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.CHLOR_DUTY_CYCLE_SECS_LEFT,   type: regType.other, 			  	title:"Chlorine Duty Sec Left",			unit: "", 			        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.SM_HEATER_OUTLET_TEMP,        type: regType.temperature,  	title:"Smart Heater Outlet", 				unit: "\u00B0C",        value: 0,           display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.PUMP_PRIME_DISABLE,           type: regType.other,        	title:"Pump Priming",        				unit: "",               value: 0,           display: "" , lookup_table: undefined , reg_type: RT.Holding},
  { address: REGS.WATER_FEATURE_TYPE,           type: regType.lookup,        	title:"Water Feature Type",  				unit: "",               value: 0,           display: "" , lookup_table: waterFeatureType , reg_type: RT.Holding},
  { address: REGS.READ_HEAP_FREE,               type: regType.other, 			  	title:"Heap Mem Free", 					    unit: "", 					    value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.READ_HEAP_MIN,                type: regType.other, 			  	title:"Heap Mem Min",				        unit: "", 			        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Input},
  { address: REGS.LOGGING_DISABLE_REGS,         type: regType.other, 			  	title:"Logs Disabled",				      unit: "", 			        value: 0, 					display: "" , lookup_table: undefined, reg_type: RT.Holding},
  
  
];

