


import React, { useState, useEffect } from "react";

import {
  Grid,
  DonutChart,
  LineChart,
} from "@tremor/react"; // TODO
import {
  
  getRecordIDFromUID,
  
} from "./Common"

import {client, timer} from "../App"
import {
  Button,
  ButtonToolbar,
  FlexboxGrid,
  List,
  Stack
} from "rsuite";

import {
  MdOutlineAddCircle as IconMore,
  MdOutlineRemoveCircle as IconLess,
  MdOutlineSync as IconRefresh
} from "react-icons/md";

import {
  apiLimit,
  APP_CONFIG,
  modelTypeDetails,
  RECORD_TYPES,
} from "../constants";

import { COLOURS} from "../styles"
import {
  defaultCardStyle,
  headerCardStyle,
  progressCardStyle
} from "../styles";
import ResizeObserver from 'resize-observer-polyfill'
import { LoadingView } from "./DisplayItems";

import {getDevice,listDevices} from "../graphql/queries"
export const UserView = (props) => {

  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }

  return (
    <Stack
      alignItems="stretch"
      justifyContent="flex-start"
      direction="column"
      style={{ marginTop: 8 }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        spacing={8}
        style={headerCardStyle}
      >
        <Stack.Item>
          Users
        </Stack.Item>
        <Stack.Item alignSelf="flex-end">
          {/* <ButtonToolbar>
            {(props.loading === false) &&
            <Button
              appearance="primary"
              color="blue"
              size="sm"
              endIcon={props.showList ? <IconLess /> : <IconMore />}
              onClick={props.onShowListChanged}
            >
              {props.showList ? "Less" : "More"}
            </Button>}
            <Button
              appearance="primary"
              color="blue"
              size="sm"
              endIcon={<IconRefresh />}
              loading={props.loading}
              onClick={props.onRefresh}
            >
              Refresh
            </Button>
          </ButtonToolbar> */}
        </Stack.Item>
      </Stack>
      {
        (props.loading === true) ? <LoadingView progress={props.progress} />:
        <Stack.Item>
            Users
        </Stack.Item>
      }
    </Stack>
  );
}

async function fetchUsers(loginId)
{
    let nextToken = null;
    let UserItems = [];
    do {
        try {
        const eventFromAPI = await client.graphql({
            query: listDevices,
            variables: {
            recordType: RECORD_TYPES.ACCESS + loginId,
            nextToken
            }
        });
        UserItems = UserItems.concat(eventFromAPI.data.listDevices.items);
        nextToken = eventFromAPI.data.listDevices.nextToken;
        } catch(error) {
        console.log("fetch Users", error);
        await timer(1000);
        }
    } while(nextToken != null);
    console.log("FetchingUsers", UserItems);

}


export async function fetchUserAccessRecord(loginId, deviceUID)
{
    // let nextToken = null;
    let UserItems = null;
    // do {
      let record_ID = getRecordIDFromUID( deviceUID);
        try {
        const UARFromAPI = await client.graphql({
            query: getDevice,
            variables: {
        
              recordType: RECORD_TYPES.ACCESS + loginId,
              recordID: record_ID, 
              limit: apiLimit
            // nextToken
            }
        });
        UserItems = UARFromAPI.data.getDevice;

        // nextToken = UARFromAPI.data.getDevice.nextToken;
        
        } catch(error) {
          console.log("Error: fetch User Access Record for ID",deviceUID,record_ID, error);
          await timer(250);
        }
    // } while(nextToken != null);
    console.log("Fetching User Access for",record_ID, UserItems);
    return UserItems;
}


export function listUsers()
{
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
        region: "eu-central-1",
        credentials: Auth.essentialCredentials(credentials)
    });
    var params = {
        UserPoolId: environment.amplify.Auth.userPoolId, /* required */
        AttributesToGet: [
        "email",
        ],
        Limit: 0
    };
    cognitoidentityserviceprovider.listUsers(params, function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
    });
}