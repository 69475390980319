import { MdOutlineDeleteForever as DeleteForeverOutlinedIcon} from "react-icons/md";
import { Callout } from "@tremor/react";
import { getDateTimeFromRecordID_DEBUG } from "./../Common"
import { datetimeFormats } from "../../constants";
import { ErrorBoundary } from "react-error-boundary";
import { lightFormat } from "date-fns";

function Note({ id, text, notesDeleteNote }) {
 
  const getNoteDateTime = (id) => 
  {
    var result = ""
    const date_from_record_id = getDateTimeFromRecordID_DEBUG(id);
    if ( date_from_record_id !== undefined)
    {
      result = lightFormat( date_from_record_id, datetimeFormats.mid) ;
    }
    return result;
  }


  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Callout className="mt-4" key={id} title={"Note: " + getNoteDateTime(id) }  color="yellow"  >
        <span className="note__body">{text}</span>
        <span className="note__footer" style={{ justifyContent: "flex-end" }}>
        <DeleteForeverOutlinedIcon
            className="note__delete"
            onClick={() => notesDeleteNote(id)}
            aria-hidden="true"
          ></DeleteForeverOutlinedIcon> 
        </span>
      </Callout>
    </ErrorBoundary>
  );
}

export default Note;
