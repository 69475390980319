import { useState, useEffect } from "react";
import "./css/Note.css";
import CreateNote from "./CreateNote";
import Note from "./Note";
// import { v4 as uuid } from "uuid";
import { Grid } from "@tremor/react";
// import { Grid } from "rsuite";
import {getRecordIDFromUIDWithDate} from "./../Common"
import { Placeholder } from "rsuite";

export function Notes( props) {
  //states
  const [notes, setNotes] = useState(props.notes_data);
  const [inputText, setInputText] = useState("");
  const [saving, setsaving] = useState(false);
  // get text and store in state
  const textHandler = (e) => {
    setInputText(e.target.value);
  };

  // add new note to the state array
  const saveHandler = () => {
    setsaving(true);
    let record_ID = getRecordIDFromUIDWithDate( props.device.uniqueDeviceId);
    if ( notes !== undefined)
    {
      setNotes((prevState) => [
      ...prevState,
      {
        id: record_ID,
        text: inputText
      }
      ]);
    }

    props.onAddNote(record_ID, inputText)
    //clear the textarea
    setInputText("");
    setsaving(false);
  };

  //delete note function
  const notesDeleteNote = (id) => {
    const filteredNotes = notes.filter((note) => note.id !== id);
    setNotes(filteredNotes);
    props.onDeleteNote(id);
    props.onFetchNotes(props.device.uniqueDeviceId);
  };

  useEffect(() => {
    
    setNotes(props.notes_data)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notes_data]);

  return (
    (saving === true)? ( <Placeholder graph ></Placeholder>):( 
    <div className="notes">
    <Grid  numItems={3}
    numItemsSm={3}
    numItemsLg={3}
    className="gap-4"
    >
    { (notes?.map((thenote) => (
          <Note key={thenote.recordID} id={thenote.recordID} text={thenote.notes} notesDeleteNote={notesDeleteNote} />
        )
        ) )
    }
    <CreateNote
      textHandler={textHandler}
      saveHandler={saveHandler}
      inputText={inputText}
    />
    
    </Grid>
    </div>
    ) 
  );
}

// export default Notes;
