

import React, { useState, useEffect } from "react";
// import { CirclePicker } from 'react-color';
// import { REGS } from "./registers"
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Badge,
  Bold,
  Button,
  // Card,
  // ,
  Flex,
  
} from "@tremor/react";

import {
  build_fault_string,
  isDeviceInFault,
  // getDeviceFaults,
  getClosestRunOnInterval,
  getRunonStartStopTimesForInterval,
  isDeviceOffline,
  isALight,
  isASanitiser,
  ProgressBar,
} from "./Common";



import 'rsuite/dist/rsuite.min.css';
import { 
  Button as rButton,
   Radio, RadioGroup, 
   Col,
   DatePicker,
   FlexboxGrid,
   IconButton,
   InputGroup,
   InputNumber,
   InputPicker,
   Input,
   MaskedInput,
   Panel,
   PanelGroup,
   Slider,
   Stack,
   Text,
   Toggle,
   VStack,
 } from "rsuite";

 import {  Icon  }  from '@rsuite/icons';
  
 import {
  defaultCardStyle,
  headerCardStyle,
  progressCardStyle
} from "../styles";

 import {

  MdOutlineCheck as IconConfirm,
  MdOutlineEdit as IconEdit,
  MdOutlineWifi as IconWifi,
  MdSignalWifi0Bar as IconWifi0,
  MdSignalWifi1Bar as IconWifi1,
  MdSignalWifi2Bar as IconWifi2,
  MdSignalWifi3Bar as IconWifi3,
  MdSignalWifi4Bar as IconWifi4,
  MdSpeed as IconSpeed,
  MdOutlineSchedule as IconClock,
  MdOutlineSettings as IconSettings,
  MdDirectionsRun as IconRun,
  MdOutlineAddCircle as IconMore,
  MdOutlineRemoveCircle as IconLess,
  MdOutlineInfo as IconInfo,
} from "react-icons/md";
import { RxReset as IconReset} from "react-icons/rx";

import IconAquatek from '../images/aquatek.png'
import IconPooltek from '../images/waterco.png'
import IconOasis from '../images/oasis.png'
import IconTheralink from '../images/theralux.png'
import IconAqualight from '../images/aqualight.png'
import IconHenden from '../images/henden_box.png'
import IconUnknown from '../images/unknown.png'


import IconBrandAquatek from '../images/logo_aquatek.png'
import IconBrandWaterCo from '../images/logo_waterco.png'
import IconBrandHenden from '../images/logo_henden.png'
import IconBrandOasis from '../images/logo_oasis.png'
import IconBrandTheralux from '../images/logo_theralink.png'
import IconBrandAqualight from '../images/logo_aqualight.png'
import IconBrandUnknown from '../images/unknown.png'

import { COLOURS} from "../styles"

import {
  ControlPumpSpeedOptions,
  PumpSpeedOptions,
  offOnAutoOptions,
  modelTypeDetails,
  controller_t,
  coloursHex,
  coloursAstral,
  coloursAquaQuip,
  coloursAquaQuipInstaTouch,
  coloursAquatight,
  coloursAquatightSupa,
  coloursJandy,
  coloursLEDPro,
  coloursModLite,
  coloursSingleColour,
  coloursSpaElectrics,
  coloursSpaElectricsMultiPlus,
  coloursWaterCo,
  //coloursHex,
  DisableEnableOptions,
  lightBrandOptionsSelect,
  runOnceTimerOptions,
  SocketTypesSelect,
  ValveTypesSelect,
  VFTypesSelect,
  light_brand_t,
  offOnOptions,
  // solar_type_t,
  
} from "../constants";
import { REGS } from "../registers";




export const DisplayIcon = ({icon}) =>
{
  return ( <div style={
    icon!==undefined?
    {border:"1px solid",borderColor: "lightgray", borderRadius:"5px", padding:"5px", boxShadow:"1px -1px 0 lightgray"  }:{border:"none"}}> {icon!==undefined?(<Icon as={icon} color={coloursHex.default} size="2em"/>):' '} </div>);
}

export const DisplayIconNoBorder = ({icon}) =>
{
  return ( <>{icon!==undefined?(<Icon as={icon} color={coloursHex.default} size="2em"/>):' '} </>);
}

export const DisplayItem = ({ register,tc, icon , borderOff }) => {
  return (
   
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate w-1/2 space-y-2" style={{ display: "block", width: "50%" }}>
          <Text maxLines={2} style={{ color: tc}} >{register.title}</Text>
        </div>
        <div className="truncate w-1/2 space-y-1" style={{ 
          display: "block",
          width: "50%" }}>
          <Flex
              justifyContent="end"
              className="space-x-4 "
          >
            <Text maxLines={2} ><Bold>{register.display}</Bold></Text>
          </Flex>
        </div>
      </Flex>
    </Panel>
  );
}


export const LoadingView = ( {progress}) =>
{
  return (
    <Stack
          alignItems="stretch"
          justifyContent="flex-start"
          direction="column"
          style={{ marginTop: 8 }}
        >
        <Stack.Item style={progressCardStyle}>
          <ProgressBar percentage={progress}/>
        </Stack.Item>
    </Stack>
  )
}


export const DisplayItemWithReset = ({ register,tc, icon , borderOff, onChange, disabled }) => {
  
  return (
   
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
      {/* <FlexboxGrid.Item as={Col} colspan={24} md={6}> */}
        <DisplayIcon icon={icon}/>


        <div className="truncate space-y-0.5 w-1/4"style={{
          display: "block",
          width: "50%" }}>
           <Text color={tc} >{register.title}</Text>
           <Text><Bold>{register.display}</Bold></Text>
        </div>
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
          justifyContent="end"
          className="space-x-4 "
          >
            <Button color={tc} icon={IconReset}onClick={() =>  onChange(register, 0xFFFF)}  disabled={disabled}></Button>
         </Flex>
        </div>

      
      </Flex>
    </Panel>
  );
}

export const DisplayTextItem = ({ theText,tc, theTitle, icon , onClick, borderOff}) => {
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
    <Flex
      justifyContent="start"
      className="space-x-4"
    >
      <DisplayIcon icon={icon}/>
      <div className="space-y-0.5">
        <Text color={tc}>{theTitle}</Text>
        <Text color={tc}><Bold>{theText}</Bold></Text>
      </div>
    </Flex>
    </Panel>
  );
}


export const DisplayLightSettingsNonPaneled = ({ register, tc, icon , onChange, lightNumber, disabled, ignoreBrand}) => {
  
  const [brandValue, setBrandValue] = useState(null);
  const [colourValue, setColourValue] = useState(null);

  const updateBrandValue = (value, event) => {

    var newValue = ((value << 8 ) | colourValue)
    onChange(register, newValue, true);
  };

  const updateColourValue = (value, event) => {
    var newValue = ((brandValue << 8 ) | value)
    onChange(register, newValue);
  };

  const updateBrightnessSetting = (value, event) =>
  {
    var newValue = 2
    
    var the_brightness_register = REGS.LIGHT_1_REINIT;
    if ( lightNumber === 1 )
    {
      the_brightness_register = REGS.LIGHT_1_REINIT;
    }
    else if ( lightNumber === 2)
    {
      the_brightness_register = REGS.LIGHT_2_REINIT;
    }
    else if ( lightNumber === 3)
    {
      the_brightness_register = REGS.LIGHT_3_REINIT;
    }
    onChange(the_brightness_register, newValue, true);
 
  }
  const isBrightnessAdjustable = () =>
{
  var brightNessAdjustbale = false;
  switch (brandValue)
  {
    case light_brand_t.SINGLE_COLOUR:break;
    case light_brand_t.AQUAQUIP_INSTATOUCH:break;
    case light_brand_t.AQUAQUIP: break;
    case light_brand_t.AQUATIGHT:  break; 
    case light_brand_t.AQUATIGHT_SUPA:break;
    case light_brand_t.ASTRAL:break;
    case light_brand_t.JANDY:break;
    case light_brand_t.LED_PRO: break;
    case light_brand_t.SPA_ELECTRICS: break;
    case light_brand_t.SPA_ELECTRICS_MULTIPLUS: brightNessAdjustbale = true;break;
    case light_brand_t.MODLITE: break;
    case light_brand_t.WATERCO: break;
    default: break;
  }
  return brightNessAdjustbale;
}

  const getCurrentColours = () => {
    var culors = [{value: 0, label: "None"}];
    switch (brandValue)
    {
      case light_brand_t.SINGLE_COLOUR: culors = coloursSingleColour;break;
      case light_brand_t.AQUAQUIP_INSTATOUCH: culors = coloursAquaQuipInstaTouch;break;
      case light_brand_t.AQUAQUIP: culors = coloursAquaQuip;break;
      case light_brand_t.AQUATIGHT:  culors = coloursAquatight;break; 
      case light_brand_t.AQUATIGHT_SUPA: culors = coloursAquatightSupa;break;
      case light_brand_t.ASTRAL: culors = coloursAstral;break;
      case light_brand_t.JANDY:culors = coloursJandy;break;
      case light_brand_t.LED_PRO: culors = coloursLEDPro;break;
      case light_brand_t.SPA_ELECTRICS: culors = coloursSpaElectrics;break;
      case light_brand_t.SPA_ELECTRICS_MULTIPLUS: culors = coloursSpaElectricsMultiPlus;break;
      case light_brand_t.MODLITE: culors = coloursModLite;break;
      case light_brand_t.WATERCO: culors = coloursWaterCo;break;
      default: break;
    }
    return culors;
  }

  useEffect(() => {
    var brand = ((register.value &0xFF00) >>8 )
    setBrandValue(brand);
    var colour = ((register.value &0x00FF)     )
    setColourValue(colour);
  }, [register.value]);

  return (
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        {ignoreBrand!==true && 
          <div>
            <Text>Brand:</Text>
            <InputPicker
            block
            cleanable={false}
            searchable={false}
            placement="autoVerticalStart"
            data={lightBrandOptionsSelect}
            value={brandValue}
            disabled={disabled}
            onChange={updateBrandValue}
            style={{ width: 224 }}
          />
          </div>
        }
        <Text color={tc}><Bold>Colour:</Bold></Text>
        <Flex justifyContent="between"
          className="space-x-4">
          <InputPicker
            block
            cleanable={false}
            searchable={false}
            placement="autoVerticalStart"
            data={getCurrentColours()}
            value={colourValue}
            disabled={disabled}
            onChange={updateColourValue}
            style={{ width: 224 }}
          />
         { isBrightnessAdjustable() && <Button icon={IconSettings}onClick={updateBrightnessSetting}  disabled={disabled}></Button>}
        </Flex>
      </Flex>

  );
}

export const DisplayLightSettings = ({ register, icon , onChange, lightNumber, disabled, ignoreBrand, borderOff}) => {
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <DisplayLightSettingsNonPaneled register={register} icon={icon} onChange={onChange} lightNumber={lightNumber} disabled={disabled} ignoreBrand={ignoreBrand} ></DisplayLightSettingsNonPaneled>
    </Panel>
  );
}


export const DisplayText = ({ tc, register, text }) => {
  return (
    <div className="truncate">
      <Text color={tc}><Bold>{text} {register.title} </Bold>{register.display}</Text>
    </div>
  );
}

export const DisplayFaults = ({ icon, tc, register, text ,borderOff}) => {
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate">
        <Text className="display-linebreak" color={tc}><Bold>{text} {register.title} </Bold>{register.display}</Text>
        </div>
      </Flex>
   </Panel>
  );
}

export const convertDeviceToIconNumber = (controllerString) =>
{
  var the_icon = 254;
  if ( controllerString!== undefined)
  {
    var controllerUC = "Unknown";
    try
    {
      controllerUC  =  controllerString.toUpperCase();
    }
    catch( error)
    {
      console.log( "convertDeviceToIconNumber failed", controllerString);
    }
    for ( let itr = 0; itr < modelTypeDetails.length; ++itr)
    {
      if ( modelTypeDetails[itr].label.toUpperCase() === controllerUC )
      { 
          the_icon = modelTypeDetails[itr].value;
      }
    }
  }
  return the_icon;
}

export const getControllerIconText_FROM_STRING = (controllerString) =>
{
  var the_icon = convertDeviceToIconNumber(controllerString);
  return getControllerIconText(the_icon);
}

export const getControllerIconText = (controllerValue) =>
{ 
    var theIcon = IconUnknown
    var theText = "Unknown";
    switch (controllerValue) {
        case controller_t.AQUATEK: theIcon = IconAquatek; theText = "Aquatek"; break;
        case controller_t.THERALINK: theIcon = IconTheralink; theText = "Theralink"; break;
        case controller_t.OASIS: theIcon = IconOasis; theText = "Oasis Smart"; break;
        case controller_t.AQUALIGHT: theIcon = IconAqualight; theText = "Aqualight"; break;
        case controller_t.POOLTEK: theIcon = IconPooltek; theText = "Pooltek"; break;
        case controller_t.HENDEN: theIcon = IconHenden; theText = "Henden"; break;
        default: break;
    }
    return { icon: theIcon, Text: theText }
};

export const getControllerBrandIcon_FROM_STRING = ( controllerString) =>
{
  var the_icon = convertDeviceToIconNumber(controllerString);
  return getControllerBrandIcon(the_icon);
}


export const getWifiIconFromRssi = (rSSIregisterValue) => {
  let icon = IconWifi;
  console.log("wifiRSSI", rSSIregisterValue)
  // const regDat = registerData.find(item => item.address === REGS.WIFI_RSSI);
  // if(registerValue !== undefined) {
    let rssi = new Uint16Array(1);
    rssi[0] = rSSIregisterValue;
    rssi = (rssi > 0x7FFF) ? (rssi - 0x10000) : rssi;
    if(rssi >= -50) {
      icon = IconWifi4;
    } else if(rssi >= -60) {
      icon = IconWifi3;
    } else if(rssi >= -70) {
      icon = IconWifi2;
    } else if(rssi >= -80) {
      icon = IconWifi1;
    } else {
      icon = IconWifi0;
    }
  // }
  return icon;
}


export const getControllerBrandIcon = (controllerValue) =>
{ 
    var brandIcon = IconBrandUnknown
   
    switch (controllerValue) {
        case controller_t.AQUATEK: brandIcon = IconBrandAquatek; break;
        case controller_t.THERALINK: brandIcon = IconBrandTheralux; break;
        case controller_t.OASIS: brandIcon = IconBrandOasis; break;
        case controller_t.AQUALIGHT: brandIcon = IconBrandAqualight;  break;
        case controller_t.POOLTEK: brandIcon = IconBrandWaterCo; break;
        case controller_t.HENDEN: brandIcon = IconBrandHenden; break;
        default: break;
    }
    // console.log("the ionc", brandIcon)
    return brandIcon;
  };


export const DisplayController = ({ register , borderOff}) => {
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >
        <div style={{border:"1px solid",borderColor: "lightgray", borderRadius:"5px", padding:"5px", boxShadow:"1px -1px 0 lightgray" }}>
          <img src={getControllerIconText(register.value).icon} alt="Controller Icon" width="30" height="30" />
        </div>
        <Text><Bold>{getControllerIconText(register.value).Text}</Bold></Text>
      </Flex>
    </Panel>
  );
}


const addLineBreak = (str) =>
  str.split('\n').map((subStr) => {
    return (
      <>
        {subStr}
        <br />
      </>
    );
  });

export const DisplayListAccordian = ({ register, icon,borderOff }) => {

  const getNewLInes = (value) => {

    var theString = "";
    if (value) {
      theString = value.replaceAll("|", "\n");
    }
    return (addLineBreak(theString));
  };

  const getTriggers = (value) => {
    var theString2 = ""
    if (value) {
      theString2 = value.replaceAll("|", ",");
    }
    return (theString2);
  };

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >
        <DisplayIcon icon={icon}/>
        <Accordion>
          <AccordionHeader>{register.title}</AccordionHeader>
          <AccordionBody>
            {getNewLInes(register.display)}
          </AccordionBody>
        </Accordion>

      </Flex>
    </Panel>
  );
}

export const DisplayTrackingTemperatureSliderItem = ({ register,tc, icon, min, max, step, onFormat, onChange, disabled, borderOff }) => {
  const [value, setValue] =  useState( [register.value,0]);
 
  const forMatValue = () =>
  {
    return value+"°C"
  }

  const convertToSliderValue = (theValue) =>
  {
    theValue = 0-theValue;
    return (theValue)/2;
  };

  const convertFromSliderValue = (sValue) =>
  {
    sValue = 0-sValue
    return (sValue * 2)
  }

  const updateValue = (value, event) => {
    const convertedValue =  convertFromSliderValue( value);
    console.log("converted from slider =", convertedValue, event, value);
    onChange(register, convertedValue);
  };

  useEffect(() => {
    const convertedValue = [ convertToSliderValue (register.value),0]
    setValue( convertedValue);
    console.log( "temperature value updated to :", convertedValue)
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value }
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={ forMatValue}
            disabled = {disabled}
            graduated
            renderMark={mark => {
              if ( mark%5===0 ){
                return <span style={markLinkStyle}>{mark}°C</span>;
              }
              return null;
            }}
         />
        </div>
      </Flex>
    </Panel>
  );
}

export const DisplayTemperatureSliderItem = ({ register,tc, icon, min, max, step, onFormat, onChange, disabled, borderOff }) => {
  const [value, setValue] =  useState(register.value);
 
  // const formatValue = (value) => {
  //   if (onFormat !== undefined) {
  //     return onFormat(value, "");
  //   } else {
  //     return value;
  //   }
  // };


  const forMatValue = () =>
  {
    return value+"°C"
  }

  const convertToSliderValue = (theValue) =>
  {
    return (theValue)/2;
  };

  const convertFromSliderValue = (sValue) =>
  {
    return (sValue * 2)
  }

  const updateValue = (value, event) => {
   
    const convertedValue =  convertFromSliderValue( value);
    console.log("converted from slider =", convertedValue, event, value);
    onChange(register, convertedValue);
  };

  useEffect(() => {
    const convertedValue = convertToSliderValue (register.value)
    setValue( convertedValue);
    console.log( "temperature value updated to :", convertedValue)
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value }
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={ forMatValue}
            disabled = {disabled}
            graduated
            renderMark={mark => {
              if ( mark%5===0 ){
                return <span style={markLinkStyle}>{mark}°C</span>;
              }
              return null;
            }}
         />
        </div>
      </Flex>
    </Panel>
  );
}


export const DisplayORPPhSliderItem = ({ register, tc, icon, min, max, step,  onChange, disabled , ispH,borderOff}) => {
  const [value, setValue] =  useState(register.value);
  const [displayPh, setdisplayPh] =  useState(ispH);
  // const formatValue = (value) => {
  //   if (onFormat !== undefined) {
  //     return onFormat(value, "");
  //   } else {
  //     return value;
  //   }
  // };


  const forMatValue = () =>
  {
    return (value );
  }

  const convertToSliderValue = (theValue) =>
  {
    let theSetValue = theValue;
    if ( displayPh)
    {
      theSetValue = (value / 10);
    }
    else
    {
      theSetValue = theValue*10;
    }
    return theSetValue;
  };

  const convertFromSliderValue = (sValue) =>
  {
    let theSetValue = sValue;
   
    if ( displayPh)
    {
      theSetValue = sValue*10;
    }
    else
    {
      theSetValue = sValue/10;
    }
    console.log("converted from slider =", sValue, theSetValue, displayPh);
    return (theSetValue )
  }

  const updateValue = (value, event) => {
   
    const convertedValue =  convertFromSliderValue( value);
    console.log("converted from slider =", convertedValue, event, value);
    onChange(register, convertedValue);
  };

  useEffect(() => {
    const convertedValue = convertToSliderValue (register.value)
    setValue( convertedValue);
    console.log( "temperature value updated to :", convertedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value }
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={ forMatValue}
            disabled = {disabled}
            graduated
            renderMark={mark => {
               if ( displayPh)
               {
                if ( [7.0,7.2,7.4,7.6,7.8,8].includes(mark)) {
                return <span style={markLinkStyle}>{mark}</span>;
                }
              } 
              else
              {
                if ( mark%20===0 ){
                    return <span style={markLinkStyle}>{mark}</span>;
                }
              }
              return null;
            }}
         />
        </div>
      </Flex>
    </Panel>
  );
}


export const DisplayORPPhSensorOffsetSliderItem = ({ register,tc, icon, min, max, step,  onChange, disabled , ispH ,borderOff}) => {
  const [value, setValue] =  useState(register.value);
  const forMatValue = () =>
  {
    return value
  }

  const doSetValue = ( newValue) =>
  {
    if ( ispH)
    {
      setValue(newValue.toFixed(1))
    }
    else
    {
      setValue(newValue.toFixed(0))
    }
  }

  const convertToSliderValue = (theValue) =>
  {
    var aCalcValue = (theValue-30);
    var bcalcValue = aCalcValue;

    if ( ispH)
    {
      bcalcValue = aCalcValue/10;
     // console.log( "Ph Offset value: ", theValue," A :", aCalcValue, "b:", bcalcValue)
    }
    else
    {
      bcalcValue = aCalcValue*10;
     // console.log( "ORP Offset value: ", theValue," A :", aCalcValue, "b:", bcalcValue)
    }
    return bcalcValue
  };

  const convertFromSliderValue = (sValue) =>
  {

    var calcValue = sValue;
    if ( ispH)
    {
      calcValue = calcValue*10;
    }
    else
    {
      calcValue = calcValue/10;
    }

    return (calcValue)+30
  }


  const updateValue = (value, event) => {
   
    const convertedValue =  convertFromSliderValue( value);
    // if ( ispH)
    // {
    //   console.log( "Ph Offset converted from slider = ", convertedValue, event, value);
    // }
    // else
    // {
    //   console.log( "ORP Offset converted from slider = ", convertedValue, event, value);
    // }
    onChange(register, convertedValue);
  };

  useEffect(() => {
    const convertedValue = convertToSliderValue (register.value)
    setValue( convertedValue);
    // if ( ispH)
    //   {
    //     console.log( "Ph Offset value updated to :", convertedValue)
    //   }
    //   else
    //   {
    //     console.log( "ORPOffset value updated to :", convertedValue)
    //   }
    
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{value}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5,
          alignItems: "flex-end",
          zIndex: 1
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value }
            onChange={doSetValue}
            onChangeCommitted={updateValue}
            renderTooltip={ forMatValue}
            disabled = {disabled}
            graduated
            renderMark={mark => {

              if ( ispH)
              {
               if ( ((mark*10)%2 === 0) || [-1.5,1.5].includes(mark) ) {
               return <span style={markLinkStyle}>{mark}</span>;
               }
             } 
             else
             {
               if ( (mark%20===0 )|| [-150,150].includes(mark) ){
                return <span style={markLinkStyle}>{mark}</span>;
              }
             }
              return null;
            }}
         />
        </div>
      </Flex>
    </Panel>
  );
}


export const DisplaySliderItem = ({ register, tc, icon, min, max, step, onFormat, onChange, sliderValueConvert,disabled ,borderOff}) => {
  const [value, setValue] = useState(register.value);
  const formatValue = (value) => {
    if (onFormat !== undefined) {
      return onFormat(value, "");
    } else {
      return value;
    }
  };

  const convertSliderValue = (value) =>
  {
    if ( sliderValueConvert !== undefined)
    {
      return sliderValueConvert(value);
    }
    else
    {
      return value;
    }
  };

  const updateValue = (value, event) => {
    console.log("update Value", event, value);
    onChange(register, value);
  };


  useEffect(() => {
    setValue(register.value);
    
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={convertSliderValue(value)}
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={formatValue}
            disabled = {disabled}
          />
        </div>
      </Flex>
   </Panel>
  );
}


const markLinkStyle = {
  fontSize: 10,
  fontWeight: 500,
  padding: "2px"
};


export const DisplaySensorOffsetSliderItem = ({ register,tc, icon, min, max, step,  onChange, disabled ,borderOff}) => {
  const [value, setValue] =  useState(register.value);
 
  const forMatValue = () =>
  {
    return value+"°C"
  }

  const convertToSliderValue = (theValue) =>
  {
    return (theValue-15)/2;
  };

  const convertFromSliderValue = (sValue) =>
  {
    return (sValue * 2)+15
  }


  const updateValue = (value, event) => {
   
    const convertedValue =  convertFromSliderValue( value);
    console.log("converted from slider =", convertedValue, event, value);
    onChange(register, convertedValue);
  };

  useEffect(() => {
    const convertedValue = convertToSliderValue (register.value)
    setValue( convertedValue);
    console.log( "temperature value updated to :", convertedValue)
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate space-y-1 w-1/2" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{value}°C</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 5
        }}>
          <Slider
            progress
            barClassName="slideritem"
            min={min}
            max={max}
            step={step}
            value={value }
            onChange={setValue}
            onChangeCommitted={updateValue}
            renderTooltip={ forMatValue}
            disabled = {disabled}
            graduated
            renderMark={mark => {
              if ([-5,-4,-3,-2,-1,0,1,2,3,4,5].includes(mark)) {
                return <span style={markLinkStyle}>{mark}°C</span>;
              }
              return null;
            }}
         />
        </div>
      </Flex>
    </Panel>
  );
}




export const DisplayStepperItem = ({ register, tc, icon, min, max, step, onChange ,borderOff}) => {
  const [value, setValue] = useState(min);
 
  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value / step);
  };

  useEffect(() => {

      setValue(register.value * step);
  }, [register.value, step]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputNumber
            min={min}
            max={max}
            step={step}
            scrollable={false}
            postfix={register.unit}
            value={value}
            onChange={updateValue}
          />
        </div>
      </Flex>
   </Panel>
  );
}


export const DisplayPumpSpeed = ({ register,tc, icon, onFormat, onChange, disabled , borderOff}) => {
  const [value, setValue] = useState(1);
 
  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate space-y-0.5 w-1/4"style={{
          display: "block",
          width: "50%" }}>
          <Text color={tc} className="">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
          justifyContent="end"
          className="space-x-4 "
          >
            <RadioGroup name="radioList" inline appearance="picker" onChange={updateValue} value={value} defaultValue={value}>
                        {PumpSpeedOptions.map((item) => ( 
                          <Radio appearance="primary"
                            key={item.value+1} 
                            value={item.value}
                            disabled={disabled}> {item.label} </Radio>
                          ))}
            </RadioGroup> 
         </Flex>
        </div>
      </Flex>
    </Panel>
    // </div>
  );
}

export const makeDateFromTime = (data)=>
{
  var Date_made = new Date( 2023, 0, 1, get_high_low(data).high, get_high_low(data).low, 21 , 22)
  // console.log("New Date", DAte, data);
  return Date_made;
}

export const DisplayStartStopTimePickerItem  = ({ register, tc, register2, icon, onChange, onChange2, disabled ,borderOff}) => {
  const [value, setValue] = useState(null);
  const [value2, setValue2] = useState(null);

  const updateValue = (value, event) => {
    console.log("Date time start picker Changed", event, value);
    setValue(value);
    onChange(register, value);
  };
  const updateValue2 = (value, event) => {
    console.log("Date time stop Changed", event, value);
    setValue2(value);
    onChange2(register2, value);
  };

  useEffect(() => {
    setValue2(register2.value);
  }, [register2.value]);

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate space-y-0.5 w-1/4" >
          <Text color={tc} className="truncate">{register.title}</Text>
        </div>
      
        <div className="truncate space-y-0.5 w-3/4 ">
        <Flex
        justifyContent="end"
        className="space-x-4"
        >
          <Text color={tc} className="">Start: </Text>
          <DatePicker
            format="HH:mm"
            ranges={[]}
            size="sm"
            value={makeDateFromTime( value) }
            defaultValue={makeDateFromTime( value) }
            onChange={updateValue}
            disabled={disabled}
          />
          <Text color={tc} className="">Stop: </Text> 
          <DatePicker
            format="HH:mm"
            ranges={[]}
            size="sm"
            value={makeDateFromTime( value2) }
            defaultValue={makeDateFromTime( value2) }
            onChange={updateValue2}
            disabled={disabled}
          />
        </Flex>
        </div>
      </Flex>
    </Panel>
  );
}

export const DisplayTimePickerItem = ({ register,tc, icon, onChange, borderOff, disabled}) => {

  const [value, setValue] = useState(null);

  const updateValue = (value, event) => {
    console.log("Date time picker Changed", event, value);
    setValue(value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
    {/* <div style={ borderOff === true ?({border:"none"}):({border:"1px solid",borderColor: "lightgray", borderRadius:"5px", padding:"5px", boxShadow:"1px -1px 0 lightgray"  })}  >  */}
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        <DisplayIcon icon={icon} />
        
        <div style={{
          display: "block",
          width: "40%"
        }} className="truncate space-y-0.5 w-1/4" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
      
        <div style={{
          display: "block",
          width: "60%"
        }} >
          <Flex
          justifyContent="end"
          className="space-x-4"
          >
            <DatePicker
              format=" HH:mm "
              ranges={[]}
              // size="sm"
              value={makeDateFromTime( value) }
              defaultValue={makeDateFromTime( value) }
              onChange={updateValue}
              disabled= {disabled}
              // hideSeconds={true}
            />
          </Flex>
        </div>
      </Flex>
    </Panel>
    // </div>
  );
}




export const DisplaySocketValveVFStatusItem = ({ mode_register, status_register, name_register, 
                                                 type_register, run_once_enable_reg,
                                                 run_once_start_reg,
                                                 run_once_stop_reg,
                                                 icon, tc, disabled, socket_setting, onChange , displayType, borderOff, 
                                                 duty_cycle_status_reg,duty_cycle_secs_reg}) => {
  
  const [modeValue, setModeValue] = useState(0);
  const [runOnceValue, setRunOnceValue] = useState( 0);
  const [showMore, setShowMore] = useState(false);

  const updateModeValue = (theValue, event) => {
    console.log("Updated Mode Value", event);
    setModeValue(theValue);
    onChange(mode_register, theValue, true);
  };

  const updateRunOnceTime = ( theValue, event) =>
  {
    setRunOnceValue(theValue);
    console.log("the Intervae", theValue);
   let interval =  getRunonStartStopTimesForInterval(theValue);
   onChange(run_once_start_reg, interval.start);
   onChange(run_once_stop_reg, interval.stop);

  }

  const isNameSet = () => {
    var return_value = true;
    console.log(
      "name", name_register
    )
    if (name_register===undefined ||
      name_register.display === "" 
      || name_register.display === "\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000")
    {
      return_value = false;
    }
    return return_value;
  }


  const getFormattedTextColor = () => {
    var return_value = "green"
    if ( status_register.display==='ON')
    {
      return_value = "green";

    }
    else if ( status_register.display === 'OFF')
    {
      return_value="red"
    }
    else
    {
      return_value="orange"
    }
    return return_value;
    // (?(<Text className="align-middle" color="green"><Bold>{status_register.display}</Bold> </Text>):(status_register.display==='OFF'?(<Text color="red"><Bold>{status_register.display}</Bold> </Text>):<Text color="orange" ><Bold>{status_register.display}</Bold> </Text>))
  }

  const getNameString = () => {
    var name = ""
    if ( isNameSet())
    {
      name = name_register.display;
    }
    else
    {
      if (type_register!== undefined )
      {
        switch (displayType)
        {
          case "VALVE": name = ValveTypesSelect[type_register.value].label; break;
          case "SOCKET": name = SocketTypesSelect[type_register.value].label; break;
          case "VF": name = VFTypesSelect[type_register.value].label; break;
          default: break;
        }
       }
    }
    return name;
  }

  useEffect(() => {
    const convertRunOnceToValue = ( ) =>
    {
      const interval = getClosestRunOnInterval(run_once_start_reg.value , run_once_stop_reg.value);
      setRunOnceValue( interval.value);
    }
    convertRunOnceToValue();
  }, [run_once_start_reg, run_once_stop_reg]);


  useEffect(() => {
    setModeValue(mode_register.value);
    // console.log("Mode Value for:" + mode_register.title + " set to:",mode_register.value);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode_register]);

  return (
    <PanelGroup bordered={ !borderOff }  style={{ backgroundColor: 'white' }} >
        <Panel bordered={ true }  style={{ backgroundColor: 'white' }}
        header={
          <Stack justifyContent="space-between">
            <span>{status_register.title}</span>
          
            <IconButton
              appearance="primary"
              color="blue"
              size="sm"
              
              icon={showMore ? <IconLess /> : <IconMore />}
              onClick={() => setShowMore(!showMore)}
            />
          </Stack> 
          }>
          <Flex justifyContent="start"
            className="space-x-4 align-middle">
              <DisplayIcon icon={icon}/>
              <Flex
                justifyContent="space between"
                className="space-x-4 align-middle"
              >
                <Flex justify="start" >
                    <Text color={tc} className="truncate"> <Bold>{getNameString()}</Bold></Text>
                    <Text color={ getFormattedTextColor() } ><Bold>{status_register.display}</Bold> </Text>

                    {/* {(status_register.display==='ON'?(<Text className="align-middle" color="green"><Bold>{status_register.display}</Bold> </Text>):(status_register.display==='OFF'?(<Text color="red"><Bold>{status_register.display}</Bold> </Text>):<Text color="orange" ><Bold>{status_register.display}</Bold> </Text>))} */}
                </Flex>
            
                <Text color={tc}><Bold>Mode:</Bold></Text>
                <RadioGroup name="radioList" inline appearance="picker" onChange={updateModeValue} value={modeValue} defaultValue={modeValue}>
                  {offOnAutoOptions.map((item) => ( 
                    <Radio appearance="primary"
                      key={item.value+1} 
                      value={item.value}
                      disabled={disabled}> {item.label} </Radio>
                    ))}
                </RadioGroup>
              </Flex>   
          </Flex> 
        </Panel>
        
        {isALight(type_register)&&displayType==="SOCKET" && showMore &&
          <Panel bordered={ true }  style={{ backgroundColor: 'white' }} >
            <DisplayLightSettingsNonPaneled register={socket_setting} tc= {tc} icon={IconSettings} onChange={onChange} lightNumber={type_register.light_number} disabled={disabled} ignoreBrand={true}/>
          </Panel>
        }
        {isASanitiser(type_register)&&displayType==="SOCKET" && showMore &&
          <Panel bordered={ true }  style={{ backgroundColor: 'white' }} >
            <Display2ValueToggleItemNonPaneled icon={IconInfo} tc={tc} register = { duty_cycle_status_reg} options = {offOnOptions} disabled = {true}  onChange = {onChange} borderOff={true} panelOff={true}/> 
            <DisplayItem register={duty_cycle_secs_reg} icon={IconInfo} borderOff={true} />
          </Panel>
        }

        { showMore &&
          <Panel bordered={ true }  style={{ backgroundColor: 'white' }}  >
            <Flex
              justifyContent="start"
              className="space-x-4"
            >
              <DisplayIcon icon={IconRun} />
              <Text color={tc}><Bold>Run Once:</Bold></Text>
              <div className="truncate w-1/2 space-y-1" style={{
                display: "block",
                width: "100%" }}>
                <Flex
                  justifyContent="end"
                  className="space-x-4"
                >
                  <InputPicker
                        block
                        cleanable={false}
                        searchable={false}
                        placement="autoVerticalStart"
                        data={runOnceTimerOptions}
                        value={runOnceValue}
                        onChange={updateRunOnceTime}
                        style={{ width: 224 }}
                        disabled = {disabled}
                  />
                </Flex>
              </div>
            </Flex>
          </Panel>
        }
        {showMore &&
          <Panel bordered={ true }  style={{ backgroundColor: 'white' }} >
            <Flex
                justifyContent="start"
                className="space-x-4"
              >
              <Display2ValueToggleItemNonPaneled icon={IconRun} tc={tc} register = { run_once_enable_reg} options = {DisableEnableOptions} disabled = {disabled}  onChange = {onChange} borderOff={true} panelOff={true}/> 
            </Flex>
          </Panel>
        }
    </PanelGroup>
  );
}

/*!
displays the Sockt type
*/
export const DisplaySocketItem = ({  name_register, tc, type_register, icon, options, 
  onChange, onChangeName, disabled, time_enable_register, socket_setting,
  time_1_start,time_1_stop,
  time_2_start,time_2_stop ,onTimeChange
}) => {
  const [value, setValue] = useState(null);
  const [showMore, setShowMore] = useState(false);
  
  // const [Modevalue, setModeValue] = useState(null);
  const updatePickerValue = (value, event) => {
    console.log(event, value);
    setValue(value);
    onChange(type_register, value);
  };



  useEffect(() => {
    setValue(type_register.value);
  }, [type_register.value]);

  return (
    <>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <Text >{type_register.title}</Text>

        <div style={{
          display: "block",
          width: "30%"
        }}>
          <InputPicker
            block
            cleanable={false}
            searchable={false}
            placement="autoVerticalStart"
            data={options}
            value={value}
            onChange={updatePickerValue}
            enabled={disabled==true?'false':'true'}
            disabled = {disabled}
          />
    
        </div>
        <div style={{
          display: "block",
          width: "70%"
        }}>
        {name_register!==undefined?(<DisplayNameInputItem register={name_register} onChange={onChangeName} borderOff={true}/>):' '}
        </div>
        <IconButton
          appearance="primary"
          color="blue"
          size="sm"
          
          icon={showMore ? <IconLess /> : <IconMore />}
          onClick={() => setShowMore(!showMore)}
        />
      </Flex>
      {( isALight(type_register))  ?
      (showMore) && <DisplayLightSettings register={socket_setting} icon={IconSettings} onChange={onChange} disabled={disabled}/>:
      (showMore) && <DisplayItem register={socket_setting} icon={IconSettings} onClick={onTimeChange} />}

      {(showMore) && <Display2ValueToggleItem register={time_enable_register} icon={IconClock} options={DisableEnableOptions} onChange={onChange} colour={COLOURS.default} disabled={disabled}/>}

      {/* <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2" >
          <div style={{
            display: "block",
            width: "50%"
          }}>
            {isALight()?}
          </div>
          <div style={{
            display: "block",
            width: "50%"
          }}>
            
          </div>
      </Flex> */}

      
      {(showMore) && <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2" >
          <div style={{
            display: "block",
            width: "50%"
          }}>
            <DisplayTimePickerItem register={time_1_start} icon={IconClock} onChange={onTimeChange} disabled= {disabled} />
          </div>
          <div style={{
            display: "block",
            width: "50%"
          }}>
            <DisplayTimePickerItem register={time_1_stop} onChange={onTimeChange} disabled= {disabled}/>
          </div>
      </Flex>}
      {(showMore) && <Flex
          justifyContent="center"
          alignItems="center"
          className="space-x-2" >
              <div style={{
            display: "block",
            width: "50%"
          }}>
            <DisplayTimePickerItem register={time_2_start} icon={IconClock} onChange={onTimeChange} disabled= {disabled}/>
          </div>
          <div style={{
            display: "block",
            width: "50%"
          }}>
            <DisplayTimePickerItem register={time_2_stop} onChange={onTimeChange} disabled= {disabled}/>
          </div>
      </Flex>}

     
    </>
  );
}



export const DisplaySelectPickerItem = ({ register, tc, icon, options, onChange, disabled ,borderOff}) => {
  const [value, setValue] = useState(null);

  const updateValue = (value, event) => {
    console.log(event, value);
    setValue(value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
      
        <Flex
          justifyContent="between"
          className="space-x-4"
        >
          <div >
            <Text color={tc} className="w-2,truncate">{register.title}</Text>
            <Text color={tc} className="w-2, truncate"><Bold>{register.display}</Bold></Text>
          </div>
          <div style={{
            display: "block",
            width: "50%"
          }}> 
            <InputPicker
              block
              cleanable={false}
              searchable={false}
              placement="autoVerticalStart"
              data={options}
              value={value}
              disabled={disabled}
              onChange={updateValue}
            />
          </div>
        </Flex>
      </Flex>
    </Panel>
  );
}

export const DisplayNameInputItem = ({ register,tc,  icon, onChange, borderOff }) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  
  // const mask =  [/.+ /, /.+ /, /.+ /, /.+ /,/.+ /, /.+ /, /.+ /, /.+ /,/.+ /, /.+ /, /.+ /, /.+ /,/.+ /, /.+ /, /.+ /, /.+ /];
  const updateValue = () => {
    if(disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
      onChange(register, value);
      console.log("name change", value);
    }
  };

  useEffect(() => {

    if ( register.display !== undefined)
    {
      setValue(register.display);
    }
  }, [register.display]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
   
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div style={{
          display: "block",
          width: "40%"
        }} className="truncate" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate" color={COLOURS.default} ><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "60%"
        }}>
          <InputGroup inside>
            <Input
              value={value}
              // mask={mask}
              disabled={disabled}
              // placeholderChar = " "
              onChange={setValue}
              maxLength={17}
            />
            <InputGroup.Button onClick={updateValue}>
              <DisplayIconNoBorder icon={disabled ? IconEdit : IconConfirm} />
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Flex>
    </Panel>
  );
}

export const DisplayNumberInputItem = ({ register, tc, icon, onChange, borderOff }) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  const mask = [/\d/, /\d/, /\d/, /\d/, /\d/];

  const updateValue = () => {
    if(disabled) {
      setDisabled(false);
    } else {
      setDisabled(true);
      const registerValue = parseInt(value, 10);
      if(!isNaN(registerValue)) {
        onChange(register, registerValue);
      }
    }
  };

  useEffect(() => {
    setValue(register.display);
  }, [register.display]);

  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
         <DisplayIcon icon={icon}/>
        <div className="truncate" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div style={{
          display: "block",
          width: "100%"
        }}>
          <InputGroup inside>
            <MaskedInput
              value={value}
              mask={mask}
              disabled={disabled}
              onChange={setValue}
            />
            <InputGroup.Button onClick={updateValue}>
            <DisplayIconNoBorder icon={disabled ? IconEdit : IconConfirm} />
             
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Flex>
    </Panel>
  );
}


export const DisplayFilterTimeItem = (props) =>
{
  return (
   <Panel bordered={ !props.borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
          justifyContent="start"
          className="space-x-4 "
        >
          <Flex
            justifyContent="start"
            className="space-x-4 "
          >
            <DisplayTimePickerItem register={props.timeStartRegister} icon={IconClock}  onChange={props.onTimesChange} borderOff={true}/>
            <DisplayTimePickerItem register={props.timeStopRegister} onChange={props.onTimesChange} borderOff={true} />
          </Flex>

          <Flex
            justifyContent="end"
            className="space-x-4 "
          >
            <DisplayPumpSpeed register={props.timerSpeedRegister} icon={IconSpeed} onChange={props.onSpeedChange} borderOff={true} disabled = {props.disabled} /> 
            <DisplayTimerEnabledToggleItem register={props.timeStartRegister} onChange ={props.onEnabledChange } disabled = {props.disabled} />
          </Flex>
      </Flex>
    </Panel>
  );

}



export const Display2ValueToggleItemNonPaneled = ({ register,tc, icon, onChange, options, inhibitText, disabled }) => {
  const [value, setValue] = useState(register.value);
  const updateValue = (value) => {
    console.log (" Display2ValueToggleItemNonPaneled ",value)

    var ourValue = ((value === false) ? options[0].value : options[1].value);
    setValue(ourValue);
    onChange(register, ourValue);
  };


  const isChecked = () =>
  {
    var result = false;
    if ( value == options[1].value)
    {
        result = true;
    }
    return result;
  }

  useEffect(() => {
    const theValue = register.value;
    setValue(theValue);
    console.log("Display2ValueToggleItemNonPaneled", theValue);
  }, [register.value]);
  
  return (
      
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        <DisplayIcon icon={icon}/>
        {inhibitText===undefined &&(<div className="truncate space-y-1 w-1/2" ><Text color={tc} className="truncate">{register.title}</Text><Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text> </div>)}
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
            justifyContent="end"
            className="space-x-4 "
          >
            <Toggle checkedChildren={options[1].label }
            unCheckedChildren={options[0].label}
            size="lg"
            checked={isChecked(value)} 
            disabled={disabled} 
            onChange={updateValue}/>
          </Flex>
        </div>
      </Flex>
      
    );
}

export const Display2ValueToggleItem = ({ register,tc, icon, onChange, options,  disabled, borderOff }) => {
  return (
      <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
        < Display2ValueToggleItemNonPaneled register = { register} tc={tc} icon={icon} options = {options} disabled = {disabled}  onChange = {onChange}></Display2ValueToggleItemNonPaneled>
      </Panel>
    );
}


export const Display2ValueToggleItemBitField = ({ register,tc, icon, onChange, options,inhibitText,  disabled, borderOff , bit_number}) => {
  
  const [value, setValue] = useState(options[0].value);

  const get_value_from_bitField = ()=>
  {
    var result = false;
    const bit_field = (1 << bit_number)
    if ((register.value & bit_field) === bit_field)  
    {
      result = true;
    }
    return result;
  }
  
  const updateValue = (value) => {
    console.log (" Display2ValueToggleItemBitField ",value)

    var ourValue = ((value === false) ? options[0].value : options[1].value);

    setValue(ourValue);
    
    const bit_field = (1 << bit_number)
    const bit_field_mask =  ~bit_field;
    var theValue =  register.value & bit_field_mask;
    if ( value)
    {
      theValue |= bit_field;
    }

    onChange(register, theValue);
  };


  const isChecked = () =>
  {
    var result = false;
    if ( value == options[1].value)
    {
        result = true;
    }
    return result;
  }

  useEffect(() => {
    const theValue = get_value_from_bitField() ? options[1].value: options[0].value;

    setValue(theValue);
    console.log("Display2ValueToggleItemNonBitField", theValue);
  }, [register.value]);
  
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        <DisplayIcon icon={icon}/>
        {inhibitText===undefined &&(<div className="truncate space-y-1 w-1/2" ><Text color={tc} className="truncate">{register.title+"("+bit_number+")"}</Text><Text color={tc} className="truncate"><Bold>{isChecked()?options[1].label:options[0].label}</Bold></Text> </div>)}
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
            justifyContent="end"
            className="space-x-4 "
          >
            <Toggle checkedChildren={options[1].label }
            unCheckedChildren={options[0].label}
            size="lg"
            checked={isChecked(value)} 
            disabled={disabled} 
            onChange={updateValue}/>
          </Flex>
        </div>
      </Flex>
      </Panel>
    );
}





export const DisplayTimerEnabledToggleItem = ({ register,  onChange,  disabled }) => {
  const [value, setValue] = useState(register.enabled);
  const updateValue = (value) => {
    setValue(value);
    onChange(register, value);
  };
  useEffect(() => {
    const theValue = register.enabled;
    setValue(theValue);
    console.log("timer enabled changed", theValue);
  }, [register.enabled]);
  return (
      <Toggle checkedChildren="Enabled" unCheckedChildren="Disabled" checked={value} disabled={disabled} onChange={updateValue}/>
  );
}



export const DisplayToggleItem = ({ register, icon, onChange, tc, options, inhibitText, disabled , borderOff}) => {
  const [value, setValue] = useState(register.value);

  const updateValue = (value) => {
    setValue(value);
    console.log("toggled", value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);
  return (
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4 "
      >
        <DisplayIcon icon={icon}/>
        {inhibitText===undefined?(<div className="truncate space-y-1 w-1/2" ><Text color={tc} className="truncate">{register.title}</Text><Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text> </div>):' '}
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
            justifyContent="end"
            className="space-x-4 "
          >
            <RadioGroup name="radioList" inline appearance="picker" onChange={updateValue} value={value} defaultValue={value}>
                        {options.map((item) => ( 
                          <Radio appearance="primary"
                            key={item.value+1} 
                            value={item.value}
                            disabled={disabled}> {item.label} </Radio>
                          ))}
            </RadioGroup>
          </Flex>
        </div>
      </Flex>
      </Panel>
  );
}


export const DisplayPrimeButtonItem = ({ register, tc,iconOn, iconOff, icon2On, icon2Off, onChange , disabled, altText, altText2, borderOff}) => {
  const [value, setValue] = useState(register.value);
  const [value2, setValue2] = useState(register.value);
  
  const updateValue = () => {
    let tempValue = value;
    

    if (register.value !== 1) {
      tempValue = 1;
    }
    else {
      tempValue = 0;
    }
    setValue(tempValue);
    onChange(register, tempValue);
  };

  const updateValue2 = () => {
    let tempValue = value2;
    if (register.value !== 2) {
      tempValue = 2;
    }
    else {
      tempValue = 0;
    }
    setValue2(tempValue);
    onChange(register, tempValue);
  };

  useEffect(() => {
    setValue(register.value);
    setValue2(register.value);
  }, [register.value]);



  return (
    
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >

        <Button icon={register.value !== 0 ? iconOn : iconOff} disabled={disabled} onClick={updateValue} > { ((altText===undefined)?register.display:altText)} </Button>
        <Button icon={register.value !== 0 ? icon2On : icon2Off} disabled={disabled} onClick={updateValue2} > { ((altText2===undefined)?register.display:altText2)} </Button>

        <div className="truncate" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>

      </Flex>
    </Panel>
  );
}

export const DisplayButtonItem = ({ register,tc,  iconOn, iconOff, textOn, textOff, onChange , disabled, sendValue, altText}) => {
  const [value, setValue] = useState(register.value);
  const updateValue = () => {
    let tempValue = value;
    let value_to_send = sendValue === undefined ? 1:sendValue;

    if (register.value !== value_to_send) {
      tempValue = value_to_send;
    }
    else {
      tempValue = 0;
    }
    setValue(tempValue);
    onChange(register, tempValue);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);



  return (

    
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-2"
      >

        <Button icon={register.value !== 0 ? iconOn : iconOff} disabled={disabled} onClick={updateValue} > { ((altText===undefined)?register.display:altText)} </Button>

        <div className="truncate" >
          <Text color={tc} className="truncate">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>

      </Flex>
    </Panel>
  );
}

export const get_high_low = (value) => {
  const hh = (value & 0xFF00) >> 8;
  const ll = (value & 0x00FF);

  return {
    high: hh,
    low: ll
  };
}

export const DisplayToggleButtonItem = ({ register,tc,  icon, options, disabled, onChange, borderOff }) => {
  const [writeValue, setWriteValue] = useState(0);

  const updateValue = () => {
    console.log("Button Update Value", register, "Options: ", options[writeValue].value);
    onChange(register, options[writeValue].value);
  };

  useEffect(() => {
    setWriteValue(register.value === 0 ? 1 : 0);
  }, [register.value]);

  return (

    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Text color={tc} truncate={true}>{register.title}</Text>
          <Text color={tc} truncate={true}><Bold>{register.display}</Bold></Text>
        </div >
      
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
            <Flex
            justifyContent="end"
            className="space-x-4 "
          >
        <Button
          color={COLOURS.default}
          size="sm"
          disabled={disabled}
          onClick={updateValue}
        >{options[writeValue].label}</Button>
         </Flex>
        </div>
       
      </Flex>
      </Panel>
  );
}


export const DisplayControlPumpSpeed = ({ register,tc,  icon, onChange, disabled , borderOff}) => {
  const [value, setValue] = useState(1);
 
  const updateValue = (value, event) => {
    console.log(event, value);
    onChange(register, value);
  };

  useEffect(() => {
    setValue(register.value);
  }, [register.value]);

  return (
    
    <Panel bordered={ !borderOff }  style={{ backgroundColor: 'white' }}>
      <Flex
        justifyContent="start"
        className="space-x-4"
      >
        <DisplayIcon icon={icon}/>
        <div className="truncate space-y-0.5 w-1/4"style={{
          display: "block",
          width: "50%" }}>
          <Text color={tc} className="">{register.title}</Text>
          <Text color={tc} className="truncate"><Bold>{register.display}</Bold></Text>
        </div>
        <div className="truncate w-1/2 space-y-1" style={{
          display: "block",
          width: "50%" }}>
          <Flex
          justifyContent="end"
          className="space-x-4 "
          >
            <RadioGroup name="radioList" inline appearance="picker" onChange={updateValue} value={value} defaultValue={value}>
                        {ControlPumpSpeedOptions.map((item) => ( 
                          <Radio appearance="primary"
                            key={item.value+1} 
                            value={item.value}
                            disabled={disabled}> {item.label} </Radio>
                          ))}
            </RadioGroup> 
         </Flex>
        </div>
      </Flex>
    </Panel>
    // </div>
  );
}

export const GetBadgeForversion = (device) =>
{
  return (
    <Badge 
      //  icon={device.ctrlStatIcon} 
        color={device.verColor}
        tooltip={ device.verDesc }>   
        {"Ver:" + device.verDesc}
    </Badge>
  )
} //"Ver:" + item.versionNumber,

export const GetDeviceBadgeFromStatus = ( device) =>
{ 
  return (
  <>
  { 
    isDeviceOffline(device) ?
    (
      <Badge 
        icon={device.connStatIcon} 
        color={device.connStatColor}
        tooltip={device.connStatDate}>
          {device.connStatDesc}
      </Badge>
    ) : isDeviceInFault(device) ? 
    (
      <Badge 
        icon={device.ctrlStatIcon} 
        color={device.ctrlStatColor}
        tooltip={ build_fault_string( device.currentFaults , true, "Nil").string }>   
        {device.ctrlStatDesc}
      </Badge>
    ) : 
    ( !isDeviceInFault(device) && !isDeviceOffline(device) &&
      (
        <Badge
          icon={device.connStatIcon} 
          color={device.connStatColor}
          tooltip={device.connStatDate > device.ctrlStatDate ? device.connStatDate : device.ctrlStatDate}>
          GOOD
        </Badge>
      ) 
    )
  }
  </>
  )
}

