import React, {useState} from "react";
import QrReader from 'react-qr-scanner';


const QRTest = (props) => {
    const [data, setData] = useState('No result');
    const handleScan = (result) => {
        if ( result !== null)
        {
            props.onHandleScan(result)
            setData( result.Text)
        }
    }
    const handleError= (err) =>
    {
        console.error(err);
    }
    return (
      <>
        <QrReader
            
        //   onResult={(result, error) => {
        //     if (!!result) {
        //       setData(result?.text);
        //     }
  
        //     if (!!error) {
        //       console.info(error);
        //     }
        //   }}
          onError={handleError}
          onScan={ handleScan}
          style={{ width: '100%' }}
        />
        <p>{data}</p>
      </>
    );
  };

// class QrContainer extends Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             result: "Hold Qr Code Steady and Clear to Scan",
//         }
//         this.handleScan = this.handleScan.bind(this)
//     }

//     handleScan(result)
//     {
//         this.setState({
//             result: data
//         })
//     }
//     handleError(err)
//     {
//         console.error(err);
//     }
//     render(){
//         const previewStyle = 
//         {
//             height: 700,
//             width: 1000,
//             display: 'flex',
//             "justify-content": "center"
//         }

//         const camStyle = {
//             display: 'flex',
//             justifyContent: "center",
//             marginTop: '-50px'
//         }
//         const textStyle = 
//         {
//             fontSize: '30px',
//             "text-align": 'center',
//             marginTop: '-50px'
//         }

//         return(
//             <React.Fragment>
//                 <div style = {camStyle} >
//                     <QrReader
//                         delay={100}
//                         style={previewStyle}
//                         onError={this.handleError}
//                         onScan={this.handleScan}
//                     />
//                 </div>
//                 <p style={textStyle}>
//                     {this.state.result}
//                 </p>
//             </React.Fragment>
//         )
//     }
// }

export default QRTest;
// export default QrContainer;