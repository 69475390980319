
import { COLOURS} from "./styles"
import { 
  CRC8_POLY} from "./other/crc8";

export const datetimeFormats = {
  "long": "dd/MM/yyyy HH:mm:ss",
  "short": "d/M H:mm",
  "powerDaily": "d/M",
  "picker": "dd/MM/yyyy",
  "filename": "yyMMdd",
  "filename_long": "yyMMdd_HH:mm",
  "mid": "dd/MMM/yyyy HH:mm",
};


export const APP_CONFIG = {
  ADD_NEW_CONTROLLER : false,
  SHOW_ALL_FIELDS: false,
};


export const RECORD_TYPES =
{
  ACCESS: 'access#',
  INSTALLER: 'installer',
  NOTES: 'notes',
  INFO: 'info',
  EVENT: 'event',
  CONFIG: 'config',
  DATA: 'data',
  FAULTS: 'faults',
};

export const FILTER_OPTIONS = {
  statusGroup: 1,
  statusDeviceOffline: 2,
  statusDeviceInFault: 3,
  statusDeviceGood: 4,
  statusDeviceUnknown: 5,

  statusDeviceFaultsAny: 6,
  statusDeviceFaultsInternalCommsIssue:7,
  statusDeviceFaultsTherm1ShortCircuit:8,
  statusDeviceFaultsTherm1OpenCircuit: 9,
  statusDeviceFaultsTherm2ShortCircuit:10,
  statusDeviceFaultsTherm2OpenCircuit: 11,
  statusDeviceFaultsPumpCommunicationsFault: 12,
  statusDeviceFaultsExpansion1Fault: 13,
  statusDeviceFaultsExpansion2Fault: 14,
  statusDeviceFaultsAmbientTemperatureFault: 15,
  statusDeviceFaultsPhOrpExpansionFault:16,
  statusDeviceFaultsTherm3ShortCircuit:17,
  statusDeviceFaultsTherm3OpenCircuit: 18,
  statusDeviceFaultsUnused13: 19,
  statusDeviceFaultsUnused14: 20,
  statusDeviceFaultsUnused15: 21,
  statusDeviceFaultsUnused16: 22,
  statusDeviceFaultsUnused17: 23,
  statusDeviceFaultsUnused18: 24,
  statusDeviceFaultsUnused19: 25,
  statusDeviceFaultsUnused20: 26,
  statusDeviceFaultsUnused21: 27,
  statusDeviceFaultsUnused22: 28,
  statusDeviceFaultsUnused23: 29,
  statusDeviceFaultsUnused24: 30,
  statusDeviceFaultsUnused25: 31,
  statusDeviceFaultsUnused26: 32,
  statusDeviceFaultsUnused27: 33,
  statusDeviceFaultsUnused28: 34,
  statusDeviceFaultsUnused29: 35,
  statusDeviceFaultsUnused30: 36,
  statusDeviceFaultsUnused31: 37,
  statusDeviceFaultsSystemRestarted:38,
  
  modelTypeGroup:100,
  modelTypeGroupAquatek:101,
  modelTypeGroupTheralink:102,
  modelTypeGroupOasis:103,
  modelTypeGroupPoolTek:104,
  modelTypeGroupHenden:105,
  modelTypeGroupAqualight:106,

  versionGroup: 90,
  versionUnknown: 91,
  version115B14: 92,
  version115B15: 93,
  version115B82: 94,
  versionBeta:95
 
};

export const FIRMWARE_VERSIONS = [

  { name: "1.15B14", colour: COLOURS.good },       //grren
  { name: "1.15B15", colour: COLOURS.heating },    //orange
  { name: "1.15B82", colour: COLOURS.warning },    //orange
  { name: "Beta", colour: COLOURS.warning },    //orange
  // { name: "1.01B2", color: "orange" },
  // { name: "1.02B1", color: "amber" },
  // { name: "1.03B1", color: "green" }
];

export const statusItems = {
  all: 0,
  offline: 1,
  fault: 2,
  good: 3
};

export const orpPhValues = {
  orp: 0,
  ph: 1
  
};

export const displayViews = 
{
  list:0,
  grid:1,
};

// export const COLOURS = {
//   "good": "green",
//   "error": "red",
//   "warning": "amber",
//   "cardDecoration": "neutral",
//   "title": "stone",
//   "default": "sky",
// };


export const rColours = {
  "good": "green",
  "error": "red",
  "warning": "amber",
  "cardDecoration": "neutral",
  "title": "stone",
  "default": 'blue',
};

export const coloursHex = {
  "good": '#66BB6A',
  "error": 0xef4444,
  "warning": 0xf59e0b,
  "cardDecoration": 0x737373,
  "title": 0x78716c,
  "default": "#0ea5e9",
};

export const coloursPrime = {
  "good": "lawngreen",
  "error": "red",
  "warning": "yellow",
  "cardDecoration": "darkgrey",
  "title": "SlateGray",
  "default": "royalblue",

}

export const tabItems = {
  home:0,
  trends: 1,
  appliances: 2,
  faults: 3,
  control: 4,
  heating: 5,
  solar: 6,
  chemical: 7,
  spa:8 ,
  pump:9 ,
  applianceSetup:10,
  system:11,
  filter:12
};


export const dashTabItems = {
  control: 0,
  heating: 1,
  solar: 2,
  chemical: 3,
  pump: 4,
  spa:5,
  system:6,
  filter:7,
  systemInfo:8,
};


export const faultView = {
  current: 0,
  history: 1
};

export const spaModeStates = ["Pool Mode", "SPA Mode"];

export const spaModeOptions = [
  { value: 0, label:"Pool Mode"}, { value: 1, label: "SPA Mode"}];

// export const offOn = ["Off", "On"];
export const offOnOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" }
];


export const heaterOffOnOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" },
  { value: 2, label: "Pool & SPA" },
  { value: 3, label: "Pool" },
  { value: 4, label: "SPA" },
];

export const DisableEnableOptions = [
  { value: 0, label: "Disabled" },
  { value: 1, label: "Enabled" }
];

export const ReverseDisableEnableOptions = [
  { value: 0, label: "Enabled" },
  { value: 1, label: "Disabled" },
];


export const loggingRemoteDisableEnableOptions = [
  { value: 0xAD, label: "Disabled" },
  { value: 0xBA, label: "Enabled" }
];

export const offOnAutoOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "On" },
  { value: 2, label: "Auto" }
];


export const ControlPumpSpeedOptions = [
  { value: 0, label: "Off" },
  { value: 0x0101, label: "1" },
  { value: 0x0201, label: "2" },
  { value: 0x0301, label: "3" },
  { value: 0x0401, label: "4" },
  { value: 0xFFFF, label: "Auto" }
];

export const PumpSpeedOptions = [
  { value: 0, label: "1" },
  { value: 1, label: "2" },
  { value: 2, label: "3" },
  { value: 3, label: "4" }

];
export const offAutoOptions = [
  { value: 0, label: "Off" },
  { value: 2, label: "Auto" }
];


export const spaBlowerTimeOutOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "30 Mins" },
  { value: 2, label: "1 Hr" },
  { value: 3, label: "2 Hrs" }

]

export const thermistorTypeOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "Pool" },
  { value: 2, label: "Roof" },
  { value: 3, label: "Water" }
];


export const solarSensorLocations = [
  { value: 0, label: "Filter Line" },
  { value: 1, label: "Heater Line" },
]

export const pump_states_t =
{
  "STARTUP": 0,
  "IDLE": 1,
  "CHECK POWERED POLL": 2,
  "CHECK POWERED": 3,
  "WAIT BEFORE STARTING": 4,
  "PRIME": 5,
  "SET SPEED": 6,
  "CHECK SPEED POLL": 7,
  "CHECK SPEED": 8,
  "SET ON": 9,
  "CHECK ON POLL": 10,
  "CHECK ON": 11,
  "RUNNING": 12,
  "RUN ON": 13,
  "POWER DOWN": 14,
  "VERIFY OFF POLL": 15,
  "VERIFY OFF": 16,
  "PUMP FAULT": 17,
  "SET PRIME OFF": 18,
  "RUN ON HEARTBEAT": 19,


  0: "STARTUP",
  1: "IDLE",
  2: "CHECK POWERED POLL",
  3: "CHECK POWERED",
  4: "WAIT BEFORE STARTING",
  5: "PRIME",
  6: "SET SPEED",
  7: "CHECK SPEED POLL",
  8: "CHECK SPEED",
  9: "SET ON",
  10: "CHECK ON POLL",
  11: "CHECK ON",
  12: "RUNNING",
  13: "RUN ON",
  14: "POWER DOWN",
  15: "VERIFY OFF POLL",
  16: "VERIFY OFF",
  17: "PUMP FAULT",
  18: "SET PRIME OFF",
  19: "RUN ON HEARTBEAT"
};
export const solar_states_t =
{
  "OFF": 0,
  "INACTIVE": 1,
  "WAITING": 2,
  "SAMPLING": 3,
  "SAMPLING 1": 4,
  "CHECKING": 5,
  "STARTING UP": 6,
  "ACTIVE": 7,
  "ACTIVE 1": 8,
  "ACTIVE 2": 9,
  "LIMIT": 10,
  "WAITING 1": 11,
  "SAMPLING 2": 12,
  "ACTIVE 3": 13,
  "ACTIVE 4": 14,
  "ANTIFREEZE": 15,
  "ANTIFREEZE 1": 16,
  "ANTIFREEZE 2": 17,
  "_NONE": 18,


  0: "OFF",
  1: "INACTIVE",
  2: "WAITING",
  3: "SAMPLING",
  4: "SAMPLING",
  5: "CHECKING",
  6: "STARTING UP",
  7: "ACTIVE",
  8: "ACTIVE",
  9: "ACTIVE",
  10: "LIMIT",
  11: "WAITING",
  12: "SAMPLING",
  13: "ACTIVE",
  14: "ACTIVE",
  15: "ANTIFREEZE",
  16: "ANTIFREEZE",
  17: "ANTIFREEZE",
  18: "_NONE",


};



export const pump_triggers_t =
{
  0x0001: "RUNNING",
  0x0002: "CHLORINATOR",
  0x0004: "SOLAR",
  0x0008: "INSNRG_HEATER",
  0x0010: "HEATER",
  0x0020: "SOLAR_TEST",
  0x0040: "BACKWASH",
  0x0080: "MANUAL_ON",
  0x0100: "SPA",
  0x0200: "ANTIFREEZE",
  0x0400: "FILTER",
  0x0800: "PUMP_CALL",
  0x1000: "HEATER_2",
  0x2000: "WATER_FEATURE",
  0x4000: "INFLOOR",
  0x8000: "RUN_ONCE",

  "RUNNING": 0x0001,
  "CHLORINATOR": 0x0002,
  "SOLAR": 0x0004,
  "INSNRG_HEATER": 0x0008,
  "HEATER": 0x0010,
  "SOLAR_TEST": 0x0020,
  "BACKWASH": 0x0040,
  "MANUAL_ON": 0x0080,
  "SPA": 0x0100,
  "ANTIFREEZE": 0x0200,
  "FILTER": 0x0400,
  "PUMP_CALL": 0x0800,
  "HEATER_2": 0x1000,
  "WATER_FEATURE": 0x2000,
  "INFLOOR": 0x4000,
  "RUN_ONCE": 0x8000,
};

export const appliance_status_t =
{
  0: "OFF",
  1: "ON",
  2: "NOT SETUP",

  "OFF": 0,
  "ON": 1,
  "NOT SETUP": 2,

};


export const heater_state_t =
{
  0x0000: "INIT",
  0x0001: "CHECK TEMPERATURE",
  0x0002: "HEATING",
  0x0003: "HEATER RUN ON",
  0x0004: "LIMIT",
  0x0005: "CHECK ON",
  0x0006: "CHECK POOL TEMPERATURE",
  0x0007: "CHECK SPA TEMPERATURE",
  0x0008: "STOP",
  0x0009: "CONFIRM STOPPED",
  0x000A: "HEATER FAULT",
  0x000B: "RUN ON",
  0x000C: "WAIT NEXT",

  "INIT": 0x0000,
  "CHECK TEMPERATURE": 0x0001,
  "HEATING": 0x0002,
  "HEATER RUN ON": 0x0003,
  "LIMIT": 0x0004,
  "CHECK ON": 0x0005,
  "CHECK POOL TEMPERATURE": 0x0006,
  "CHECK SPA TEMPERATURE": 0x0007,
  "STOP": 0x0008,
  "CONFIRM STOPPED": 0x0009,
  "HEATER FAULT": 0x000A,
  "RUN ON": 0x000B,
  "WAIT NEXT": 0x000C,
};


export const heater_display_state_t =
{
  0x0000: "OFF",
  0x0001: "WAITING",
  0x0002: "SAMPLING",
  0x0003: "SAMPLING-CHECK",
  0x0004: "ACTIVE",
  0x0005: "RUN-ON",
  0x0006: "LIMIT",
  0x0007: "STOPPING",
  0x0008: "HEATER FAULT",
  0x0009: "SOLAR PRIORITY",
  0x000A: "CHILLING",
  0x000B: "OFF IN POOL",
  0x000C: "OFF IN SPA",
  0x000D: "STARTING",

  "OFF": 0x0000,
  "WAITING": 0x0001,
  "SAMPLING": 0x0002,
  "SAMPLING-CHECK": 0x0003,
  "ACTIVE": 0x0004,
  "RUN-ON": 0x0005,
  "LIMIT": 0x0006,
  "STOPPING": 0x0007,
  "HEATER FAULT": 0x0008,
  "SOLAR PRIORITY": 0x0009,
  "CHILLING": 0x000A,
  "OFF IN POOL": 0x000B,
  "OFF IN SPA": 0x000C,
  "STARTING": 0x000D,
};

export const solarTypeOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "Separate" },
  { value: 2, label: "Valve" },
  { value: 3, label: "Boosted" }
];

export const heatingPumpTypes = [
  { value: 0, label: "Filter" },
  { value: 1, label: "Independent" },
  { value: 2, label: "Independent with Sensor" },
]

export const solar_type_t =
{
  "NONE": 0,
  "Separate": 1,
  "Valve": 2,
  "Boosted": 3,
  "OUTRange": 4,

  0: "NONE",
  1: "Separate",
  2: "Valve",
  3: "Boosted",
  4: "OUTRange",

};

export const thermistor_t =
{
  "NONE": 0,
  "POOL": 1,
  "ROOF": 2,
  "WATER": 3,
  "OUTRange": 4,

  0: "NONE",
  1: "POOL",
  2: "ROOF",
  3: "WATER",
  4: "OUTRange",

};

export const spaSizeOptions = [
{ value: 0, label: "None"},
{ value: 1, label: "1000"},
{ value: 2, label: "1500"},
{ value: 3, label: "2000"},
{ value: 4, label: "2500"},
{ value: 5, label: "3000"},
{ value: 6, label: "4000"},
{ value: 7, label: "5000"},
{ value: 8, label: "6000"},
{ value: 9, label: "7000"},
{ value: 10, label: "8000"},
{ value: 11, label: "9000"},
];


export const poolSizeOptions = [
  { value: 0, label: "None"},
  { value: 1, label: "10000"},
  { value: 2, label: "15000"},
  { value: 3, label: "20000"},
  { value: 4, label: "25000"},
  { value: 5, label: "30000"},
  { value: 6, label: "35000"},
  { value: 7, label: "40000"},
  { value: 8, label: "45000"},
  { value: 9, label: "50000"},
  { value: 10, label: "55000"},
  { value: 11, label: "60000"},
  { value: 12, label: "65000"},
  { value: 13, label: "70000"},
  { value: 14, label: "75000"},
  { value: 15, label: "80000"},
  { value: 16, label: "85000"},
  { value: 17, label: "90000"},
  { value: 18, label: "95000"},
  { value: 19, label: "100000"},
  ];
	

export const logIntervalOptions = [
  { value: 0, label: "Off" },
  { value: 1, label: "1 Min" },
  { value: 5, label: "5 Mins" },
  { value: 10, label: "10 Mins" },
  { value: 15, label: "15 Mins" },
  { value: 30, label: "30 Mins" },
  { value: 60, label: "1 Hour" }
];

export const logDisableOptions = [
  { value: 0, label: "All Enabled" },
  { value: 1, label: "Status Disabled" },
  { value: 2, label: "Config Disabled" },
  { value: 4, label: "Data Disabled" },
  { value: 8, label: "Fault Disabled" },
];


export const startTimeOptions = [
  { value: 0x0000, label: "00:00" },
  { value: 0x0100, label: "01:00" },
  { value: 0x0200, label: "02:00" },
  { value: 0x0300, label: "03:00" },
  { value: 0x0400, label: "04:00" },
  { value: 0x0500, label: "05:00" },
  { value: 0x0600, label: "06:00" },
  { value: 0x0700, label: "07:00" },
  { value: 0x0800, label: "08:00" },
  { value: 0x0900, label: "09:00" },
  { value: 0x0A00, label: "10:00" },
  { value: 0x0B00, label: "11:00" },
  { value: 0x0C00, label: "12:00" },
  { value: 0x0D00, label: "13:00" },
  { value: 0x0E00, label: "14:00" },
  { value: 0x0F00, label: "15:00" },
  { value: 0x1000, label: "16:00" },
  { value: 0x1100, label: "17:00" },
  { value: 0x1200, label: "18:00" },
  { value: 0x1300, label: "19:00" },
  { value: 0x1400, label: "20:00" },
  { value: 0x1500, label: "21:00" },
  { value: 0x1600, label: "22:00" },
  { value: 0x1700, label: "23:00" }
];

export const vfTypesEnum = {
  NONE:         0,
  GAS_HEATER:   1,
  HEAT_PUMP:    2,
  SMART_HEATER: 3,
};


export const VFTypesSelect = [
  { value: vfTypesEnum.NONE,        label: "None" },
  { value: vfTypesEnum.GAS_HEATER,  label: "Gas Heater" },
  { value: vfTypesEnum.HEAT_PUMP,   label: "Heat Pump" },
  { value: vfTypesEnum.SMART_HEATER,label: "Smart Heater" },
];

export const ValveTypesSelect = [
  { value: 0, label: "None" },
  { value: 1, label: "Pool/SPA" },
  { value: 2, label: "Solar" },
  { value: 3, label: "Water Feature" },
  { value: 4, label: "InFloor" },
  { value: 5, label: "Feature From Solar" },
  { value: 6, label: "Other" },
  { value: 7, label: "Heater" },
];

export const socket_types =
{
  NONE: 0,
  SANITISER:1,
  FILTER_PUMP:2,
  CLEAN_PUMP:3,
  BLOWER:4,
  POOL_LIGHT:5,
  SPA_LIGHT:6,
  GARDEN_LIGHT:7,
  WATER_FEATURE:8,
  SOLAR:9,
  OTHER:10,
  ALWAYS_ON:11,
  JET_PUMP:12,
  IND_HEAT_PUMP:13,
  UV_SANITISER:14
};





export const SocketTypesSelect = [
  { value: socket_types.NONE, label: "None" },
  { value: socket_types.SANITISER, label: "Sanitiser" },
  { value: socket_types.FILTER_PUMP, label: "Filter Pump" },
  { value: socket_types.CLEAN_PUMP, label: "Cleaning Pump" },
  { value: socket_types.BLOWER, label: "Blower" },
  { value: socket_types.POOL_LIGHT, label: "Pool Light" },
  { value: socket_types.SPA_LIGHT, label: "Spa Light" },
  { value: socket_types.GARDEN_LIGHT, label: "Garden Light" },
  { value: socket_types.WATER_FEATURE, label: "Water Feature" },
  { value: socket_types.SOLAR, label: "Solar" },
  { value: socket_types.OTHER, label: "Other" },
  { value: socket_types.ALWAYS_ON, label: "Always On" },
  { value: socket_types.JET_PUMP, label: "Jet Pump" },
  { value: socket_types.IND_HEAT_PUMP, label: "Ind Heat Pump"},
  { value: socket_types.UV_SANITISER, label: "Uv Sanitiser"},

  // { value: 13, Label: "Pool/Spa"},
  // { value: 14, label: "Solar"},
  // { value: 15, label: "Water Feature"},
  // { value: 16, label: "Infloor"},
  // { value: 17, label: "Gas Heater"},
  // { value: 18, label: "Heat Pump"},
  
  // { value: 30, label: "Feature Solar"},
  // { value: 32, label: "Heating"


];

export const ResetOptionsSelect = [
  { value: 0, label: "None" },
  { value: 0xF101, label: "Restart" },
  { value: 0xF102, label: "Erase Wifi Data" },
  { value: 0xF103, label: "Reset Factory" },

];

export const ResetWifiSelect = [
  { value: 0, label: "None" },
  { value: 0xF102, label: "Erase Wifi Data" },
];


export const modelTypeItems = 
{
  all: 0,
  aquatek: 1,
  theralink:2,
  oasis:3,
  pooltek:4,
  henden:5,
};

export const controller_t =
{
  NONE:       0x0000,
  AQUATEK:    0x0001,
  THERALINK:  0x0002,
  OASIS:      0x0006,
  AQUALIGHT:  0x0008,
  RECLAIM:    0x0009,
  POOLTEK:    0x000A,
  HENDEN:     0x000B,
};

export const modelTypeDetails = [
  { value: controller_t.NONE,       label: "None"    ,    crc_value: CRC8_POLY.CRC8,      colour: COLOURS.error},
  { value: controller_t.AQUATEK,    label: "Aquatek" ,    crc_value: CRC8_POLY.AQUATEK,   colour: COLOURS.modelAquatek},
  { value: controller_t.THERALINK,  label: "Theralink" ,  crc_value: CRC8_POLY.THERALINK, colour: COLOURS.modelThrealux},
  { value: controller_t.OASIS,      label: "Oasis" ,      crc_value: CRC8_POLY.OASIS,     colour: COLOURS.modelOASIS},
  { value: controller_t.AQUALIGHT,  label: "Aqualight" ,  crc_value: CRC8_POLY.AQUALIGHT, colour: COLOURS.modelAqualight},
  { value: controller_t.RECLAIM,    label: "Reclaim" ,    crc_value: CRC8_POLY.RECLAIM,   colour: COLOURS.modelReclaim},
  { value: controller_t.POOLTEK,    label: "Pooltek" ,    crc_value: CRC8_POLY.POOLTEK,   colour: COLOURS.modelPooltek},
  { value: controller_t.HENDEN,     label: "Henden" ,     crc_value: CRC8_POLY.HENDEN,    colour: COLOURS.modelHenden},
];


export const pumpModelOptionsSelect = [
  { value: 0,   label: "None" },
  { value: 6,   label: "DAB" },
  { value: 16,  label: "Davey VSD" },
  { value: 5,   label: "Insnrg Qi" },
  { value: 17,  label: "Insnrg Zi" },
  { value: 3,   label: "Hayward (485)" },
  { value: 15,  label: "Hayward MaxFlo VS/TriStar VS" },
  { value: 25,  label: "Henden 280 3 Speed" },
  { value: 9,   label: "Henden 3 Speed" },
  { value: 20,  label: "Henden VSD" },
  { value: 19,  label: "Legend Ultra VS 1.0 & 1.5hp" },
  { value: 23,  label: "Madimack Inverflow"},
  { value: 24,  label: "Madimack Inverflow Pro/Ultra"},
  { value: 12,  label: "Oasis V Series" },
  { value: 4,   label: "Pentair VS/F" },
  { value: 22,  label: "Pentair EM800" },
  { value: 13,  label: "Pentair EM1500" },
  { value: 8,   label: "Reltech" },
  { value: 2,   label: "Reltech (485)" },
  { value: 11,  label: "Reltech P (485)" },
  { value: 10,  label: "Speck" },
  { value: 7,   label: "Theraflo TVS 1.0 & 1.5hp V1" },
  { value: 1,   label: "Theralux TVS Pro 1.0 & 1.5hp V2" },
  { value: 14,  label: "Theralux TVS Pro 1.25 & 1.75hp V3" },
  { value: 18,  label: "Theralux TVS Pro II 1.0 & 1.5hp V4" },
  { value: 21,  label: "Waterco Hydrostorm"},

];



export const accessLevels = {
  guest: 0,
  admin: 1,
  super: 2
};

export const groups = 
{
  Admin: 1,
  Dontek: 2,
  Oasis: 3,
  Theralux: 4,
  Henden: 5,
  Waterco: 6,
};

export const groups_table = 
{
  "admin": groups.Admin,
  "dontek": groups.Dontek,
  "oasis": groups.Oasis,
  "theralux": groups.Theralux,
  "henden": groups.Henden,
  "waterco": groups.Waterco,

  1: "admin" ,
  2: "dontek", 
  3: "oasis", 
  4: "theralux", 
  5: "henden", 
  6: "waterco"

};


export const locations = [
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
  "NZ",
];
export const locationsOptions = [
  { value: 0, label: "ACT" },
  { value: 1, label: "NSW" },
  { value: 2, label: "NT" },
  { value: 3, label: "QLD" },
  { value: 4, label: "SA" },
  { value: 5, label: "TAS" },
  { value: 6, label: "VIC" },
  { value: 7, label: "WA" },
  { value: 8, label: "NZ" }
];

export const sortKeys = {
  uid: 0,
  type: 1,
  name: 2,
  status: 3,
}

export const sortOrder = {
  ascending: 0,
  descending: 1
};



export const faults_bit_t =
{
  FAULT_INTERNAL_COMMS_BIT: 0x0001,
  FAULT_THERMISTOR_1_SHORT_BIT: 0x0002,
  FAULT_THERMISTOR_1_OPEN_BIT: 0x0004,
  FAULT_THERMISTOR_2_SHORT_BIT: 0x0008,
  FAULT_THERMISTOR_2_OPEN_BIT: 0x0010,
  FAULT_PUMP_COMMS_FAULT_BIT: 0x0020,
  FAULT_SLAVE1_COMMS_FAULT_BIT: 0x0040,
  FAULT_SLAVE2_COMMS_FAULT_BIT: 0x0080,
  FAULT_AMBIENT_TEMPERATURE_BIT: 0x00000100,
  FAULT_SLAVE3_COMMS_FAULT_BIT: 0x00000200,
  FAULT_THERMISTOR_3_SHORT_BIT: 0x00000400,
  FAULT_THERMISTOR_3_OPEN_BIT: 0x00000800,
  FAULT_SMART_HEATER_FAULT: 0x00001000,
  FAULT_CRC_FAULT: 0x00002000,
  FAULT_CRC_2_FAULT: 0x00004000,
  FAULT_SYSTEM_RESTART_BIT: 0x80000000,
};

export const faultTable = [
  { value: faults_bit_t.FAULT_INTERNAL_COMMS_BIT,       label: "Internal Comms Issue",          short_label: "Int Comms Issue",     colour: COLOURS.faultsList[0] },
  { value: faults_bit_t.FAULT_THERMISTOR_1_SHORT_BIT,   label: "Thermistor 1 Short Circuit",    short_label: "Therm 1 S/C" ,        colour: COLOURS.faultsList[1] },
  { value: faults_bit_t.FAULT_THERMISTOR_1_OPEN_BIT,    label: "Thermistor 1 Open Circuit",     short_label: "Therm 1 O/C" ,        colour: COLOURS.faultsList[2] },
  { value: faults_bit_t.FAULT_THERMISTOR_2_SHORT_BIT,   label: "Thermistor 2 Short Circuit",    short_label: "Therm 2 S/C" ,        colour: COLOURS.faultsList[3] },
  { value: faults_bit_t.FAULT_THERMISTOR_2_OPEN_BIT,    label: "Thermistor 2 Open Circuit",     short_label: "Therm 2 O/C"  ,       colour: COLOURS.faultsList[4] },
  { value: faults_bit_t.FAULT_PUMP_COMMS_FAULT_BIT,     label: "Pump Communications Fault",     short_label: "Pump Fault" ,         colour: COLOURS.faultsList[5] },
  { value: faults_bit_t.FAULT_SLAVE1_COMMS_FAULT_BIT,   label: "Expansion 1 Fault",             short_label: "Exp 1 Fault" ,        colour: COLOURS.faultsList[6] },
  { value: faults_bit_t.FAULT_SLAVE2_COMMS_FAULT_BIT,   label: "Expansion 2 Fault",             short_label: "Exp 2 Fault" ,        colour: COLOURS.faultsList[7] },
  { value: faults_bit_t.FAULT_AMBIENT_TEMPERATURE_BIT,  label: "Ambient Temperature Fault",     short_label: "Amb Temp Fault" ,     colour: COLOURS.faultsList[8] },
  { value: faults_bit_t.FAULT_SLAVE3_COMMS_FAULT_BIT,   label: "Ph/Orp Expansion Fault",        short_label: "Ph/Orp Exp Flt" ,     colour: COLOURS.faultsList[9] },
  { value: faults_bit_t.FAULT_THERMISTOR_3_SHORT_BIT,   label: "Thermistor 3 Short Circuit",    short_label: "Therm 3 S/C" ,        colour: COLOURS.faultsList[10] },
  { value: faults_bit_t.FAULT_THERMISTOR_3_OPEN_BIT,    label: "Thermistor 3 Open Circuit",     short_label: "Therm3 O/C" ,         colour: COLOURS.faultsList[11] },
  { value: faults_bit_t.FAULT_SMART_HEATER_FAULT,       label: "Smart Heater Fault",            short_label: "SH Fault" ,           colour: COLOURS.faultsList[12] },
  { value: faults_bit_t.FAULT_CRC_FAULT,                label: "Sys Config Reset",              short_label: "SysCon Fltg" ,        colour: COLOURS.faultsList[13] },
  { value: faults_bit_t.FAULT_CRC_2_FAULT,              label: "Sec Config Reset",              short_label: "SECCon Fltg" ,        colour: COLOURS.faultsList[14] },
  { value: faults_bit_t.FAULT_SYSTEM_RESTART_BIT,       label: "System Restarted",              short_label: "Sys Restart" ,        colour: COLOURS.faultsList[15] },
  
];

export const outputs_bit_t =
{

  OP_APP_1:0x0001,
  OP_APP_2:0x0002,
  OP_VLV1 :0x0004,
  OP_VLV2 :0x0008,
  OP_VLV3 :0x0010,
  OP_VLV4 :0x0020,
  OP_HEATER:0x0040,
  OP_RS485:0x0080,

  OP_MOTOR_GND:0x0100,
  OP_SPEED_1:0x0200,
  OP_SPEED_2:0x0400,
  OP_SPEED_3:0x0800,
  OP_SPEED_4:0x1000,
};

export const outputsTable = [
  { value: outputs_bit_t.OP_APP_1,        label: "Appliance 1",           short_label: "Skt 1",         colour: COLOURS.faultsList[0] },
  { value: outputs_bit_t.OP_APP_2,        label: "Appliance 2",           short_label: "Skt 2" ,        colour: COLOURS.faultsList[1] },
  { value: outputs_bit_t.OP_VLV1,         label: "Valve 1",               short_label: "Vlv 1" ,        colour: COLOURS.faultsList[2] },
  { value: outputs_bit_t.OP_VLV2,         label: "Valve 2",               short_label: "Vlv 2" ,        colour: COLOURS.faultsList[3] },
  { value: outputs_bit_t.OP_VLV3,         label: "Valve 3",               short_label: "Vlv 3" ,        colour: COLOURS.faultsList[4] },
  { value: outputs_bit_t.OP_VLV4,         label: "Valve 4",               short_label: "Vlv 4" ,        colour: COLOURS.faultsList[5] },
  { value: outputs_bit_t.OP_HEATER,       label: "HEATER",                short_label: "VF" ,           colour: COLOURS.faultsList[6] },
  { value: outputs_bit_t.OP_RS485,        label: "RS485",                 short_label: "RS485",         colour: COLOURS.faultsList[7] },
  { value: outputs_bit_t.OP_MOTOR_GND,    label: "Motor Ground",          short_label: "Mot" ,          colour: COLOURS.faultsList[8] },
  { value: outputs_bit_t.OP_SPEED_1,      label: "Speed 1",               short_label: "S1" ,           colour: COLOURS.faultsList[9] },
  { value: outputs_bit_t.OP_SPEED_2,      label: "Speed 2",               short_label: "S2" ,           colour: COLOURS.faultsList[10] },
  { value: outputs_bit_t.OP_SPEED_3,      label: "Speed 3",               short_label: "S3" ,           colour: COLOURS.faultsList[11] },
  { value: outputs_bit_t.OP_SPEED_4,      label: "Speed 4",               short_label: "S4" ,           colour: COLOURS.faultsList[12] },
];

export const expansionTypes = [
  { value: 0, label: "Standard" },
  { value: 1, label: "Heater Exp" },
  { value: 2, label: "Smart Heater" },
  { value: 0xFE, label: "Not Configured " },
];


export const sheaterStates = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Fault" },
  { value: 2, label: "Start Requested" },
  { value: 3, label: "Start Confirmed" },
  { value: 4, label: "Stop Requested" },
  { value: 5, label: "Stop Confirmed" }
];

export const sheaterFaults = [
  { value: 512, label: "Inlet water temperature sensor failure PP01" },
  { value: 513, label: "Outlet water temperature sensor failure PP02" },
  { value: 514, label: "Overheating in heating mode EE04" },
  { value: 515, label: "Gas exhaust too high protection EE05" },
  { value: 516, label: "Low ambient temperature protection PP08" },
  { value: 517, label: "Cooling pipe temperature too high protection PP10" },
  { value: 518, label: "Low pressure protection EE02" },
  { value: 519, label: "High pressure protection EE01" },
  { value: 520, label: "Ambient temperature sensor failure PP05" },
  { value: 521, label: "Water flow abnormal" },
  { value: 522, label: "Grade 1 and Grade 2 antifreeze protection in winter PP07" },
  { value: 523, label: "Software control failure EE21" },
  { value: 524, label: "Protection for too cold when cooling PP11" },
  { value: 525, label: "Heating coil temperature sensor failure PP03" },
  { value: 526, label: "Gas return temperature sensor failure PP04" },
  { value: 527, label: "Current detection circuit failure EE22" },

  { value: 528, label: "PFC module protection EE16" },
  { value: 529, label: "Exhaust temperature failure PP06" },
  { value: 530, label: "Communication failure between mainboard and driving board EE09" },
  { value: 531, label: "Controller EEPROM read and write failure EE06" },
  { value: 532, label: "PFC module tempreature sensing internal circuit failure EE18" },
  { value: 533, label: "Module board failure EE11" },
  { value: 534, label: "VDC voltage too high protection EE10" },
  { value: 535, label: "Compressor current protection EE07" },
  { value: 536, label: "DC fan motor 1 failure EE17" },
  { value: 537, label: "PFC module high temperature protection EE19" },
  { value: 538, label: "Input power failure EE20" },
  { value: 539, label: "Communication failure between controller and mainboard EE08" },
  { value: 540, label: "VDC voltage too low protection EE12" },
  { value: 541, label: "Over current protection EE13" },
  { value: 542, label: "Driving board ambient temperature bulb failure EE24" },
  { value: 543, label: "IPM module temperature too high protection EE15" },

  { value: 768, label: "E0" },
  { value: 769, label: "E1 High pressure protection" },
  { value: 770, label: "E2 Low pressure protection" },
  { value: 771, label: "E3 No water protection" },
  { value: 772, label: "E4 3 phase sequence protection (three phase only)" },
  { value: 773, label: "E5 Power supply excess operation range" },
  { value: 774, label: "E6 Excessive temp difference between inlet and outlet water (Insufficient water flow protection)" },
  { value: 775, label: "E7 Water outlet temp too high or too low protection" },
  { value: 776, label: "E8 High exhaust temp protection" },
  { value: 777, label: "E9" },
  { value: 778, label: "EA Evaporator overheat protection (only at cooling mode)" },
  { value: 779, label: "Eb Ambient temperature too high or too low protection" },
  { value: 781, label: "Ed Anti-freezing reminder" },

  { value: 784, label: "P0 Controller communication failure" },
  { value: 785, label: "P1 Water inlet temp sensor failure" },
  { value: 786, label: "P2 Water outlet temp sensor failure" },
  { value: 787, label: "P3 Gas exhaust temp sensor failure" },
  { value: 788, label: "P4 Evaporator coil pipe temp sensor failure" },
  { value: 789, label: "P5 Gas return temp sensor failure" },
  { value: 790, label: "P6 Cooling coil pipe temp sensor failure" },
  { value: 791, label: "P7 Ambient temp sensor failure" },
  { value: 792, label: "P8 Cooling plate sensor failure" },
  { value: 793, label: "P9 Current sensor failure" },
  { value: 794, label: "PA Restart memory failure" },

  { value: 800, label: "F0" },
  { value: 801, label: "F1 Compressor drive module failure" },
  { value: 802, label: "F2 PFC module failure" },
  { value: 803, label: "F3 Compressor start failure" },
  { value: 804, label: "F4 Compressor running failure" },
  { value: 805, label: "F5 Inverter board over current protection" },
  { value: 806, label: "F6 Inverter board overheat protection" },
  { value: 807, label: "F7 Current protection" },
  { value: 808, label: "F8 Cooling plate overheat protection" },
  { value: 809, label: "F9 Fan motor failure" },
  { value: 810, label: "FA PFC module over current protection (reserved)" },
  { value: 811, label: "Fb Power filter plate No-power protection" },

  { value: 1024, label: "Inlet water temperature failure P01" },
  { value: 1025, label: "Water outlet temperature failure P02" },
  { value: 1026, label: "Ambient temperature failure P04" },
  { value: 1027, label: "Coil temperature failure P05" },
  { value: 1028, label: "Suction temperature failure P07" },
  { value: 1029, label: "Discharge temperature failure P081" },
  { value: 1030, label: "Compressor Over Current Protection E051" },
  { value: 1031, label: "High pressure protection E01" },
  { value: 1032, label: "Low pressure protection E02" },
  { value: 1033, label: "Water flow failure E03" },
  { value: 1034, label: "Primary winter antifreeze protection E19" },
  { value: 1035, label: "Secondary winter antifreeze protection E29" },
  { value: 1036, label: "Antifreeze protection E07" },
  { value: 1037, label: "High discharge temperature protection of  system 1 " },
  { value: 1038, label: "Too big difference between inlet and outlet water temperature E06" },
  { value: 1039, label: "Defrosting" },

  { value: 1040, label: "Compressor overload protection more than 3 times" },
  { value: 1040, label: "High pressure protection more than 3 times" },
  { value: 1042, label: "Low pressure protection more than 3 times" },
  { value: 1043, label: "Water flow protection more than 3 times" },
  { value: 1044, label: "Antifreezing protection more than 3 times" },
  { value: 1045, label: "High discharge temperature protection more than 3 times" },
  { value: 1046, label: "MOP Drive Warning F01" },
  { value: 1047, label: "Converter Board Off-line F02" },
  { value: 1048, label: "IPM Protection F03" },
  { value: 1049, label: "Compressor Start-up Failure F04" },
  { value: 1050, label: "Converter Input Lost Phase F17" },
  { value: 1051, label: "IPM Sampling Current Failure F18" },
  { value: 1052, label: "Converter Overheat Protection F20" },
  { value: 1053, label: "Converter DC Over Voltage F07" },
  { value: 1054, label: "Converter DC Under Voltage F08" },
  { value: 1055, label: "Inout under voltage" },

  { value: 1056, label: "Input over voltage " },
  { value: 1057, label: "Sampling voltage failure" },
  { value: 1058, label: "DSP and PFC Connection Failure F12" },
  { value: 1059, label: "Radiator Temperature Sensing Failure F19" },
  { value: 1060, label: "V15V Over/Under Voltage Failure F28" },
  { value: 1061, label: "PFC Failure F27" },
  { value: 1062, label: "IPM Overheat Protection F15" },
  { value: 1063, label: "Weak-magnetic Protection F16" },
  { value: 1064, label: "Input Over Current Warning F24" },
  { value: 1065, label: "Converter Overheat Warning F22" },
  { value: 1066, label: "Input overcurrent protection" },
  { value: 1067, label: "EEPROM Error Warning F25" },
  { value: 1068, label: "IPM protection" },
  { value: 1069, label: "DC fan motor driver board protection" },
  { value: 1070, label: "Pressure sensor failure PP" },
  { value: 1071, label: "Input Over Current F26" },

  { value: 1072, label: "Antifreezing protection" },
  { value: 1073, label: "Antifreezing protection in heating mode" },
  { value: 1074, label: "EC fan motor feedback signal failure" },
  { value: 1075, label: "fan motor 1 failure" },
  { value: 1076, label: "fan motor 2 failure" },
  { value: 1077, label: "DC fan motor communication failure" },

  { value: 1280, label: "Probe 01 Alarm - Alarm status Trigger" },
  { value: 1281, label: "Probe 02 Alarm - Alarm status Trigger" },
  { value: 1282, label: "Probe 03 Alarm - Alarm status Trigger" },
  { value: 1283, label: "Probe 04 Alarm - Alarm status Trigger" },
  { value: 1284, label: "Probe 05 Alarm - Alarm status Trigger" },
  { value: 1285, label: "Probe 06 Alarm - Alarm status Trigger" },
  { value: 1286, label: "Probe 07 Alarm - Alarm status Trigger" },
  { value: 1287, label: "Probe 08 Alarm - Alarm status Trigger" },
  { value: 1288, label: "Probe 09 Alarm - Alarm status Trigger" },
  { value: 1289, label: "Probe 10 Alarm - Alarm status Trigger" },
  { value: 1290, label: "Probe 11 Alarm - Alarm status Trigger" },
  { value: 1291, label: "Probe 12 Alarm - Alarm status Trigger" },
  { value: 1292, label: "Alarm Comp 1" },
  { value: 1293, label: "Alarm Comp 2" },
  { value: 1294, label: "Alarm Comp 3" },
  { value: 1295, label: "Alarm Comp 4" },
  { value: 1296, label: "Alarm Comp 5" },
  { value: 1297, label: "Alarm Comp 6" },
  { value: 1298, label: "Compressor 1 O/L" },
  { value: 1299, label: "Compressor 2 O/L" },
  { value: 1300, label: "Compressor 3 O/L" },
  { value: 1301, label: "Compressor 4 O/L" },
  { value: 1302, label: "Compressor 5 O/L" },
  { value: 1303, label: "Compressor 6 O/L" },
  { value: 1304, label: "Temperature Delta Alarm 1 (Hot side)" },
  { value: 1305, label: "Temperature Delta Alarm 2 (Hot side)" },
  { value: 1306, label: "Temperature Delta Alarm 3 (Hot side)" },
  { value: 1307, label: "Temperature Delta Alarm 4 (Hot side)" },
  { value: 1308, label: "Temperature Delta Alarm 5 (Hot side)" },
  { value: 1309, label: "Temperature Delta Alarm 6 (Hot side)" },
  { value: 1310, label: "Temperature Delta Alarm 1 (Cool side)" },
  { value: 1311, label: "Temperature Delta Alarm 2 (Cool side)" },
  { value: 1312, label: "Temperature Delta Alarm 3 (Cool side)" },
  { value: 1313, label: "Temperature Delta Alarm 4 (Cool side)" },
  { value: 1314, label: "Temperature Delta Alarm 5 (Cool side)" },
  { value: 1315, label: "Temperature Delta Alarm 6 (Cool side)" },
  { value: 1316, label: "High Leaving Water Temperature Alarm" },

  { value: 65533, label: "BAD INTERFACE"},
  { value: 65534, label: "BUFFER TOO SMALL"},
  { value: 65535, label: "NO ERROR"}
];

export const sheaterFunc = [
  { value: 0,     label: "Unconfigured" },
  { value: 1,     label: "Main Heater"},
  { value: 2,     label: "Secondary Heater"}
];

export const dosingOptions  = [
  { value: 0,     label: "Idle" },
  { value: 1,     label: "Dosing Acid"},
  { value: 2,     label: "Dosing Chlorine"}
]

export const  sheaterTypes = [
  { value: 0,     label: "AUTO"},
  { value: 1,     label: "NONE"},
  { value: 2,     label: "THERALUX"},
  { value: 3,     label: "AQUARK"},
  { value: 4,     label: "OASIS"},
  { value: 5,     label: "RHEEM"},
  { value: 6,     label: "INVALID"}
]

export const upgradeResultCodes_t = 
{
  UPGRADE_STARTED : 6880,
  UPGRADE_COMPLETE : 6881,
  UPGRADE_ERROR : 6882
};


export const upgradeStatusTable = [
  { value: 6880,  label: "Upgrade Started"},
  { value: 6881,  label: "Upgrade Complete"},
  { value: 6882,  label: "Upgrade Error"},
  { value: 6890,  label: "Upgrade 10%"},
  { value: 6900,  label: "Upgrade 20%"},
  { value: 6910,  label: "Upgrade 30%"},
  { value: 6920,  label: "Upgrade 40%"},
  { value: 6930,  label: "Upgrade 50%"},
  { value: 6940,  label: "Upgrade 60%"},
  { value: 6950,  label: "Upgrade 70%"},
  { value: 6960,  label: "Upgrade 80%"},
  { value: 6970,  label: "Upgrade 90%"},
  { value: 6980,  label: "Upgrade 90%"},
  
]


export const ota_error_code_e =
{
	OTA_ERROR_CODE_NONE:0,
	OTA_ERROR_CODE_TIMEOUT:1,
	OTA_ERROR_CODE_COMMS_ERROR:2,
	OTA_ERROR_CODE_REQUEST_INVALID:3,
	OTA_ERROR_CODE_WIFI_CONFIG_INVALID:4,
	OTA_ERROR_CODE_OTA_IMAGE_INVALID:5,
	OTA_ERROR_CODE_OTA_CONNECT:6,
	OTA_ERROR_CODE_OTA_TRANSFER:7,
	OTA_ERROR_CODE_OTA_FINISH:8
};


export const ota_error_codeTable =
[
	{value: 0, label: "None"},
	{value: 1, label: "Timeout"},
	{value: 2, label: "COMMS_ERROR"},
	{value: 3, label: "REQUEST_INVALID"},
	{value: 4, label: "IFI_CONFIG_INVALID"},
	{value: 5, label: "IMAGE_INVALID"},
	{value: 6, label: "CONNECT"},
	{value: 7, label: "TRANSFER"},
	{value: 8, label: "FINISH"}
];


export const light_brand_t =
{
  SINGLE_COLOUR: 0,
  ASTRAL: 1,
  AQUAQUIP: 2,
  AQUAQUIP_INSTATOUCH: 3,
  SPA_ELECTRICS: 4,
  AQUATIGHT: 5,
  SPA_ELECTRICS_MULTIPLUS: 6,
  MODLITE: 7,
  JANDY: 8,
  LED_PRO: 9,
  AQUATIGHT_SUPA: 10,
  WATERCO: 11,

  // 0: SINGLE_COLOUR,
  // 1: ASTRAL,
  // 2: AQUAQUIP,
  // 3: AQUAQUIP_INSTATOUCH,
  // 4: SPA_ELECTRICS,
  // 5: AQUATIGHT,
  // 6: SPA_ELECTRICS_MULTIPLUS,
  // 7: MODLITE,
  // 8: JANDY,
  // 9: LED_PRO,
  // 10: AQUATIGHT_SUPA,

};


export const lightBrandOptionsSelect = [
  { value: 0,  label: "Single Colour" },
  { value: 1,  label: "Astral" },
  { value: 2,  label: "AquaQuip" },
  { value: 3,  label: "Aquaquip InstaTouch" },
  { value: 4,  label: "Spa Electrics" },
  { value: 5,  label: "Aquatight" },
  { value: 6,  label: "Spa Electrics Multi-plus" },
  { value: 7,  label: "ModLite" },
  { value: 8,  label: "Jandy" },
  { value: 9,  label: "LED Pro" },
  { value: 10, label: "Aquatight Supa" },
  // { value: 11, label: "WaterCo"}
];

export const coloursAquaQuipInstaTouch = [
  { value: 0,    label:"blue" },
  { value: 1,    label:"aqua" },
  { value: 2,    label:"green" },
  { value: 3,    label:"gold" },
  { value: 4,    label:"magenta" },
  { value: 5,    label:"red" },
  { value: 6,    label:"white" },
  { value: 7,    label:"seaside"},
  { value: 8,    label:"slow scroll"},
  { value: 9,    label:"fast scroll"},
  { value: 10,    label:"fireworks"},
  { value: 11,    label:"disco"},
  { value: 12,    label:"flash"},

];



export const coloursAquaQuip = [
    { value: 0,label:"pure red" },
    { value: 1,label:"deep orange" },
    { value: 2,label:"pure green" },
    { value: 3,label:"emerald" },
    { value: 4,label:"digital blue" },
    { value: 5,label:"indigo" },
    { value: 6,label:"magenta" },
    { value: 7,label:"yellow" },
    { value: 8,label:"cyan" },
    { value: 9,label:"white" },
    { value: 10,label:"pink" },
    { value: 11,label:"pastel green" },
    { value: 12,label:"pastel blue" },
    { value: 13,label:"mauve" },
    { value: 14,label:"lime green" },
    { value: 15,label:"baby blue" },
];

export const coloursAstral = [
    { value: 0, label: "blue" },
    { value: 1, label: "magenta" },
    { value: 2, label: "red" },
    { value: 3, label: "orange" },
    { value: 4, label: "green" },
    { value: 5, label: "aqua" },
    { value: 6, label: "white" },
    { value: 7, label: "custom 1" },
    { value: 8, label: "custom 2" },
    { value: 9, label: "rainbow"  },
    { value: 10, label: "ocean",   },
    { value: 11, label: "disco",  },
];

export const coloursSpaElectrics = [
    { value: 0,   label: "blue" },
    { value: 1,   label: "magenta" },
    { value: 2,   label: "red" },
    { value: 3,   label: "lime" },
    { value: 4,   label: "green" },
    { value: 5,   label: "aqua" },
    { value: 6,   label: "day white" },
    { value: 7,   label: "warm white" },
    { value: 8,   label: "slow blend" },
    { value: 9,   label: "fast change" },

];


export const coloursAquatight =
[   { value: 0, label:"season transition"},
    { value: 1, label:"daybreak transition"},
    { value: 2, label:"neutral white" },
    { value: 3, label:"rainbow"},
    { value: 4, label:"river of colours"},
    { value: 5, label:"disco"},
    { value: 6, label:"four seasons"},
    { value: 7, label:"party"},
    { value: 8, label:"sun white" },
    { value: 9, label:"red" },
    { value: 10, label:"lush green" },
    { value: 11, label:"storm blue" },

    { value: 12, label:"sky blue" },
    { value: 13, label:"sunset amber" },
    { value: 14, label:"violet" },
    { value: 15, label:"storm transition"},
    ];

export const coloursSpaElectricsMultiPlus =
[   { value: 0, label: "blue" },
    { value: 1, label: "magenta" },
    { value: 2, label: "red" },
    { value: 3, label: "lime" },
    { value: 4, label: "green" },
    { value: 5, label: "aqua" },
    { value: 6, label: "white" },
    { value: 7, label: "oceanic views" },
    { value: 8, label: "transcendence" },
    { value: 9, label: "outback" },
    { value: 10, label: "spring equinox" },

];
export const coloursModLite = 
[   { value: 0, label: "slow", },
    { value: 1, label: "white" },
    { value: 2, label: "blue" },
    { value: 3, label: "green" },
    { value: 4, label: "red" },
    { value: 5, label: "amber" },
    { value: 6, label: "magenta" },
    { value: 7, label: "fast" },
];
export const coloursJandy =
[   { value: 0, label:  "alpine white" },
    { value: 1, label:  "sky blue" },
    { value: 2, label:  "cobalt blue" },
    { value: 3, label:  "caribbean blue" },
    { value: 4, label:  "spring green" },
    { value: 5, label:  "emerald green" },
    { value: 6, label:  "emerald rose" },
    { value: 7, label:  "magenta" },
    { value: 8, label:  "violet" },
    { value: 9, label:  "slow colour"},
    { value: 10, label:  "fast colour"},
    { value: 11, label:  "america"},
    { value: 12, label:  "fat tuesday"},
    { value: 13, label:  "disco"},
]



export const coloursLEDPro =
[   { value: 0, label:  ". white" },
    { value: 1, label:  "sky blue" },
    { value: 2, label:  "cobalt blue" },
    { value: 3, label:  "caribbean blue" },
    { value: 4, label:  "spring green" },
    { value: 5, label:  "emerald green" },
    { value: 6, label:  "emerald rose" },
    { value: 7, label:  "magenta" },
    { value: 8, label:  "violet" },
    { value: 9, label:  "slow colour"},
    { value: 10, label:  "fast colour"},
    { value: 11, label:  "america"},
    { value: 12, label:  "fat tuesday"},
    { value: 13, label:  "disco"},
];

export const coloursAquatightSupa  =
[   { value: 0, label:  "green" },
    { value: 1, label:  "blue" },
    { value: 2, label:  "red green"},
    { value: 3, label:  "red blue"},
    { value: 4, label:  "green blue"},
    { value: 5, label:  "rgb"},
    { value: 6, label:  "rgb fade"},
    { value: 7, label:  "green blue fade"},
    { value: 8, label:  "red blue fade"},
    { value: 9, label:  "red green fade"},
    { value: 10, label:  "all fade" },
    { value: 11, label:  "blue fade" },
    { value: 12, label:  "green fade" },
    { value: 13, label:  "red fade" },
    { value: 14, label:  "flash" },
    { value: 15, label:  "fade" },

];

export const coloursWaterCo =
[  { value: 0, label:  "white" },
    { value: 1, label:  "slow colour"},
    { value: 2, label:  "fast colour"},
    { value: 3, label:  "blue" },
    { value: 4, label:  "magenta" },
    { value: 5, label:  "red" },
    { value: 6, label:  "gold" },
    { value: 7, label:  "green" },
    { value: 8, label:  "aqua"},
  
];


export const coloursSingleColour = 
[   { value: 0, label:  "Single Colour" },

];



export const runOnceTimerOptions = 
[
  { value: 0,  label: "OFF" },
  { value: 1,  label: "1 Min" },
  { value: 2,  label: "2 Min" },
  { value: 3,  label: "3 Min" },
  { value: 4,  label: "4 Min" },
  { value: 5,  label: "5 Min" },
  { value: 10,  label: "10 Min" },
  { value: 15,  label: "15 Min" },
  { value: 30,  label: "30 Min" },
  { value: 45,  label: "45 Min" },
  { value: 60 , label: "1 Hour" },
  { value: 90 , label: "1.5 Hours" },
  { value: 120 , label: "2 Hours" },
  { value: 180 , label: "3 Hours" },
  { value: 240 , label: "4 Hours" },
  { value: 300 , label: "5 Hours" },
  { value: 360 , label: "6 Hours" },
  { value: 420 , label: "7 Hours" },
  { value: 480 , label: "8 Hours" },
  { value: 720 , label: "12 Hours" },
]

export const phLevelsLookup = 
[
	{ value: 0,  label: "none" },
	{ value: 1,  label: "pH low" },
  { value: 2,  label: "pH High" },
  { value: 3,  label: "pH Good" },
]

export const loggingEnabledLookup =
[
  { value: 0,  label: "Disabled" },
	{ value: 1,  label: "Enabled" },
  
  { value: 0xAD, label: "Disabled (new)" },
  { value: 0xBA,  label: "Enabled (new)" },
]

export const orpLevelsLookup = 
[
  { value: 0,  label: "none" },
	{ value: 1,  label: "ORP low" },
  { value: 2,  label: "ORP High" },
  { value: 3,  label: "ORP Good" },
]

export const autoChlorStates = 
[
	{ value: 0,  label: "INIT"},
	{ value: 1,  label: "TEST SENSORS"},
	{ value: 2,  label: "IDLE"},
	{ value: 3,  label: "WAIT"},
  { value: 4,  label: "CHECK LEVEL"},
	{ value: 5,  label: "TAKING READING"},
	{ value: 6,  label: "SETTLE"},
	{ value: 7,  label: "REGULATING"},
	{ value: 8,  label: "REGULATING WAITING"},
	{ value: 9,  label: "STOP"},
	{ value: 10,  label: "LIMIT"},
	{ value: 11,  label: "OUTSIDE TIMERS"}
	
]//!< list of the states for the auto chlorinator


export const pumpManualOnOff = [
  { value: 0, label: "OFF" },
  { value: 1, label: "ON - Backwash" },
  { value: 0x0101, label: "Speed 1" },
  { value: 0x0201, label: "Speed 2" },
  { value: 0x0301, label: "Speed 3" },
  { value: 0x0401, label: "Speed 4" },
  { value: 0xFFFF, label: "Auto" }
]


export const waterFeatureType = [
  { value: 0, label: "None" },
  { value: 1, label: "Pump" },
  { value: 2, label: "Valve" },
  { value: 3, label: "Pump & Valve" },
];

export const apiLimit = 500;