  import React from "react";

  import {
    AreaChart as TremorArea,
    Button,
    Card,
    Flex,
    Text as TextTremor,
    Title,
    Tracker,
   
  } from "@tremor/react";
  import {
    SelectDateRange,
    // SelectOneDevice
  } from "./Common";
  
  import
  {
    LoadingView
  } from "./DisplayItems";

  import ResizeObserver from 'resize-observer-polyfill'
  //import { paramsColors } from "../params";

  import { COLOURS } from "../styles";
  import { Heading } from "@aws-amplify/ui-react";

  

  export const AppliancesView = props => {
  
    if (!window.ResizeObserver) {
      window.ResizeObserver = ResizeObserver
    }
    const get_app_data = (offset) => {
      // var appliance_data = props.AppTrackerData[offset];//(props.applianceDataArray, offset);

      // if ( offset === 15)
      // {
      //   console.log ( "app tracker items:", appliance_data);
      // }
  
      return appliance_data;
    }

    

    return (
      <div className="mt-3">

          <Flex
            justifyContent="center"
            alignItems="center"
            className="space-x-2"
          >
            <SelectDateRange
              range={props.selectedDateRange}
              maxDays={30}
              //disabledDate={combine(allowedMaxDays(7), afterToday())}
              onChange={props.onSelectedDateRangeChanged}
            />
            <Button
              color={COLOURS.default}
              size="sm"
              onClick={props.onSelectedDateRangeChanged}
              disabled={(props.selectedDevice.length === 0) || props.loading}
            >Refresh</Button>
          </Flex>

        {
          (props.loading ? ( <LoadingView progress={props.progress} />) : (props.selectedDevice.length === 0 ? (
            <Card
              decoration="bottom"
              decorationColor={COLOURS.cardDecoration}
              className="mt-3">
              <TextTremor color={COLOURS.warning}>Select a controller</TextTremor>
            </Card>
          ) : (
            <div></div>
          )
          )
          )
        }

        {
          props.isLoadingOrNoDevice ? (<div></div>) : (
            <>
                {(props.pumpTrackerData.length === 0) ? (<div></div>) : (
                  <Card 
                  decoration="bottom"
                  decorationColor={COLOURS.cardDecoration}
                  className="mt-3  max-w-none">

                    <Flex justifyContent="center" className="mt-4">
                    <Heading>Appliances</Heading>
                    </Flex> 
                   
                    <Title>Pump</Title>
                    <Tracker className="mt-2" 
                      data={props.pumpTrackerData}>
                    </Tracker>
                    {(( props.applianceDataArray === undefined) || (props.applianceDataArray === null) || (props.applianceDataArray.length === 0))? (<div></div>) : (
                      <div>
                        {(props.isApplianceConfigured(1) === false) ? (<div></div>) : (
                         <div>
                          <Title> Appliance 1 </Title> 
                            <TremorArea
                            data={get_app_data(1)}
                            index="dateDisplay"
                            categories={["value"]}
                            colors={["gray"]}
                            minValue={-1}
                            maxValue={6}
                            rotateLabelX={{"angle": -45,
                            "verticalShift": 15,
                            "xAxisHeight": 50}}
                            tickGap={100}
                            showAnimation={true}
                            showLegend={true}
                            showYAxis={false}
                            // startEndOnly={true}
                            className="mt-6 h-40"
                            curveType = {"monotone"}
                            // customTooltip={pumpCustomToolTip}
                          />    


                          
                          <Tracker className="mt-2" data={get_app_data(1)}/>
                         </div>
                        )}
                        {(props.isApplianceConfigured(2) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 2 </Title>
                            <Tracker className="mt-2" data={get_app_data(2)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(3) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 3 </Title>
                            <Tracker className="mt-2" data={get_app_data(3)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(4) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 4 </Title>
                            <Tracker className="mt-2" data={get_app_data(4)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(5) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 5 </Title>
                            <Tracker className="mt-2" data={get_app_data(5)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(6) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 6 </Title>
                            <Tracker className="mt-2" data={get_app_data(6)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(7) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 7 </Title>
                            <Tracker className="mt-2" data={get_app_data(7)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(8) === false) ? (<div></div>) : (
                          <div>
                            <Title> Appliance 8 </Title>
                            <Tracker className="mt-2" data={get_app_data(8)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(9) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 1 </Title>
                            <Tracker className="mt-2" data={get_app_data(9)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(10) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 2 </Title>
                            <Tracker className="mt-2" data={get_app_data(10)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(11) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 3 </Title>
                            <Tracker className="mt-2" data={get_app_data(11)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(12) === false) ? (<div></div>) : (
                          <div>
                            <Title> Valve 4</Title>
                            <Tracker className="mt-2" data={get_app_data(12)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(13) === false) ? (<div></div>) : (
                          <div>
                            <Title> VF Contact  </Title>
                            <Tracker className="mt-2" data={get_app_data(13)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(14) === false) ? (<div></div>) : (
                          <div>
                            <Title> VF Contact 2 </Title>
                            <Tracker className="mt-2" data={get_app_data(14)}/>
                          </div>
                        )}
                        {(props.isApplianceConfigured(15) === false) ? (<div></div>) : (
                          <div>
                            <Title> Acid Dosing </Title>
                            <Tracker className="mt-2" data={get_app_data(15)}/>
                          </div>
                        )}
                         {(props.isApplianceConfigured(16) === false) ? (<div></div>) : (
                          <div>
                            <Title> Chlorine Dosing </Title>
                            <Tracker className="mt-2" data={get_app_data(16)}/>
                          </div>
                        )}
                      </div>
                    )}
                  </Card>
                )}
              </>
        
          )
          
        }
      </div>
    )
  };