export const paramsColors =
[
    'blue',  'teal', 'stone', 'slate',
    'red', 'indigo', 'lime', 'fuchsia', 
    'rose', 'cyan', 'amber', 'green', 'gray',
    'pink', 'yellow',
    
];

export const paramsColorspH =
[
    'blue',  'teal','sky', 'stone', 
];

export const paramsColorsORP =
[
    'yellow', 'fuchsia', 'amber', 'stone', 
];

export const pumpStatecolours =
[
    'gray',     //'STARTUP',
    'gray',     // 'IDLE',
    'neutral',     // 'POWER P',
    'neutral',     // 'POWERED',
    'neutral',     // 'WAIT',
    'cyan',     // 'PRIME', 
    'cyan',     // 'SET SPEED',
    'cyan',     // 'SPD POLL',
    'green',     // 'CHK SPD',
    'green',     // 'SET ON',
    'green',     // 'CHK ON P',
    'green',     // 'CHK ON',
    'green',     // 'RUNNING',
    'lime',     // 'RUN ON',
    'cyan',     // 'PWR DWN',
    'cyan',     // 'V OFF P', 
    'cyan',     // 'VER OFF',
    'amber',     // 'PUMP FAULT',
    'blue',     // 'PRIME OFF',
    'gray',     // 'RUN ON', 
    'gray',     // 'POWER OFF',
    'gray',     // 'POWER ON'

];

export const applianceStatecolours =
[
    'gray',     //'STARTUP',
    'green',     // 'IDLE',
    'yellow',     // 'POWER P',
];

export const pumpStateStrings =
[
  'STARTUP',
  'IDLE',
  'POWER P',
  'POWERED',
  'WAIT',
  'PRIME', 
  'SET SPEED',
  'SPD POLL',
  'CHK SPD',
  'SET ON',
  'CHK ON P',
  'CHK ON',
  'RUN',
  'RUN ON',
  'PWR DWN',
  'V OFF P', 
  'VER OFF',
  'PUMP FAULT',
  'PRIME OFF',
  'RUN ON', 
  'POWER OFF',
  'POWER ON'
];

export const param_t = 
{
    ROOF:0,
    WATER:1,
    POOL:2,
    ORP:3,
    ORPRT:4,
    PH:5,
    PHRT:6,
    PUMP:7,
    PUMPSTATE:8,
    PUMPSPEED:9,
    APP1:10,
    APP2:11,
    APP3:12,
    APP4:13,
    APP5:14,
    APP6:15,
    APP7:16,
    APP8:17,
    APP9:18,
    APP10:19,
    APP11:20,
    APP12:21,
    APP13:22,
    APP14:23,
    ACID:24,
    CHLORINE:25,
    OTHER:26,
    POOL_SP:27,
    SPA_SP:28,
    POOL_2_SP:29,
    SPA_2_SP:30,
    SOLAR_SP:31,
    ORP_SP:32,
    PH_SP:33,
}

export const paramsNames =
{
  ROOF: 'RoofTemp',
  WATER: "WaterTemp",
  POOL: "PoolTemp",
  ORP: "ORP",
  ORPRT: "ORP_RT",
  PH: "PH",
  PHRT: "PH_RT",
  PUMP: "pumpState",
  PUMPSTATE: "PumpStateString",
  PUMPSPEED: "PumpSpeed",
  APP1: "appState0",
  APP2: "appState1",
  APP3: "appState2",
  APP4: "appState3",
  APP5: "appState4",
  APP6: "appState5",
  APP7: "appState6",
  APP8: "appState7",
  APP9: "appState8",
  APP10: "appState9",
  APP11: "appState10",
  APP12: "appState11",
  APP13: "appState12",
  APP14: "appState13",
  ACID: "acidDosing",
  CHLORINE: "chlorineDosing",
  OTHER: "OtherTemp",
  POOL_SP: "poolSP",
  SPA_SP: "spaSP",
  POOL_2_SP: "pool2SP",
  SPA_2_SP: "spa2SP",
  SOLAR_SP: "solarSP",
  ORP_SP: "ORPSP",
  PH_SP: "PHSP",
};

export const paramsConfig =
[
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.POOL, 
        'text': 'Pool Temperature (\u00B0C)',
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.ROOF,
        'text': 'Roof Temperature (\u00B0C)',
        'config': false,
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.ORP,
        'text': 'ORP (mV)',
        'config': false,
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.ORPRT,
        'text': 'ORP Curr (mV)',
        'config': false,
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PH,
        'text': 'pH',
        'config': false,
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PHRT,
        'text': 'pH Curr',
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.WATER,
        'text': 'Water Temperature(\u00B0C)',
        'config': false,
    },
    {
        'search': true,
        'selectable': false,
        'name': paramsNames.PUMP,
        'text': 'Pump State',
        'config': false,
    },
    {
        'search': false,
        'selectable': false,
        'name': paramsNames.PUMPSPEED,
        'text': 'Pump Speed',
        'config': false,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP1,
        'text': 'Appliance 1',
        'id': 1,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP2,
        'text': 'Appliance 2',
        'id': 2,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP3,
        'text': 'Appliance 3',
        'id': 3,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP4,
        'text': 'Appliance 4',
        'id': 4,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP5,
        'text': 'Appliance 5',
        'id': 5,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP6,
        'text': 'Appliance 6',
        'id': 6,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP7,
        'text': 'Appliance 7',
        'id': 7,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP8,
        'text': 'Appliance 8',
        'id': 8,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP9,
        'text': 'Appliance 9',
        'id': 9,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP10,
        'text': 'Appliance 10',
        'id': 10,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP11,
        'text': 'Appliance 11',
        'id': 11,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP12,
        'text': 'Appliance 12',
        'id': 12,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP13,
        'text': 'Heater',
        'id': 13,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.APP14,
        'text': 'Heater 2',
        'id': 14,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.ACID,
        'text': 'Acid Dosing',
        'id': 15,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.CHLORINE,
        'text': 'Chlorine Dosing',
        'id': 16,
        'config': false,
    },
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.OTHER,
        'text': 'Other Temperature',
        'id': 17,
        'config': false,
    },
    
    {
        'search': true,
        'selectable': true,
        'name': paramsNames.POOL_SP,
        'text': 'Pool Set Point (\u00B0C)',
        'id': 18,
        'config': true,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.SPA_SP,
        'text': 'Spa Set-Point (\u00B0C)',
        'id': 19,
        'config': true,
    },


    {
        'search': true,
        'selectable': true,
        'name': paramsNames.POOL_2_SP,
        'text': 'Pool 2 Set-Point (\u00B0C)',
        'id': 20,
        'config': true,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.SPA_2_SP,
        'text': 'Spa 2 Set-Point (\u00B0C)',
        'id': 21,
        'config': true,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.SOLAR_SP,
        'text': 'Solar Set-Point (\u00B0C)',
        'id': 22,
        'config': true,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.ORP_SP,
        'text': 'ORP Set-Point (mV)',
        'id': 23,
        'config': true,
    },

    {
        'search': true,
        'selectable': true,
        'name': paramsNames.PH_SP,
        'text': 'Ph SetPoint',
        'id': 24,
        'config': true,
    },
];
