// import LinearProgress from "@mui/material/LinearProgress";
import {
  
  Callout,
  // Divider,
  // Flex,
  // Text as TextTremor,
  // Title,

} from "@tremor/react";



function CreateNote({ textHandler, saveHandler, inputText }) {
  //character limit
  const charLimit = 1200;
  const charLeft = charLimit - inputText.length;
  return (
   
      <Callout className="note mt-4" title="New Note"  color="yellow">
      <textarea
        cols="40"
        rows="5"
        value={inputText}
        placeholder="Type...."
        onChange={textHandler}
        maxLength="1200"
        color="black"
      ></textarea>
      <span className="note__footer">
        <span className="label">{charLeft} left</span>
        <button className="note__save" onClick={saveHandler}>
          Save
        </button>
      </span>
      {/* <LinearProgress
        className="char__progress"
        variant="determinate"
        value={charLeft}
      /> */}
      </Callout>
  );
}

export default CreateNote;
