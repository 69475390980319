import React, { useState,
   useEffect 
  } from "react";
import { client } from "../App";
import {   createDevice, deleteDevice } from "../graphql/mutations";
import {
  REGS,
} from "../registers";

import {
  Button,
  Card,
  // Col,
  Bold,
  // Grid,
  Flex,
  Icon,
  Metric,
  Text as TextTremor,
  Title,
} from "@tremor/react";
import ResizeObserver from 'resize-observer-polyfill'

import {
  MdOutlineArrowBack as IconBack,
  MdHotTub as IconSpa,
  MdRoofing as IconSolar,
  MdOutlineEdit as IconEdit,
  MdOutlineInsertChart as IconTrends,
  MdOutlineErrorOutline as IconError,
  // MdOutlineSpaceDashboard as IconDashboard,
  MdOutlineHome as IconHome,
  // MdRadioButtonUnchecked as IconCheck,
  MdQrCode2 as IconQRCode,
  MdOutlineSensors as IconConnected,
  MdOutlineSensorsOff as IconDisconnected,
  MdOutlineSync as IconSync,
  MdOutlineSettings as IconSettings,
  MdOutlineInfo as IconInfo,
} from "react-icons/md";

import {
  // GiBubbles as IconBubbles,
  // GiChemicalDrop as IconChemical,

  // GiWinterHat as IconWinter,
  // GiValve as IconValve,
  GiThermometerCold as IconFrozen,
  GiDrippingTube as IconAcidTube,
  GiHeatHaze as IconHeat,
  // GiBoilingBubbles as IconBubbles2,

} from "react-icons/gi";

import {
  TbEngine as IconPump,
  // TbPlug as IconSocket,
} from "react-icons/tb";

import {handleEditDeviceUpdate} from "./HomeView"

import { TrendsView } from "./TrendsView";
import { SPAView } from "./SPAView";
import { Notes } from "./NoteComponents/Notes";
import { AppliancesView } from "./AppliancesView";
import { SystemInfo } from "./SystemInfo";
import { SystemSettingsView } from "./SystemSettingsView";
import { ApplianceSetupView} from "./AppliancesSetup"
import { FilterView } from "./FilterView";
import { HeatingView } from "./HeatingView";
import {
  AddInstaller,
  EditDevice,
  ShowDeviceQR,
} from "./Common";
import {
  DisplayItem,
  getControllerIconText_FROM_STRING,
  GetDeviceBadgeFromStatus
} from "./DisplayItems";

import { COLOURS} from "../styles"
import {
  locationsOptions,
  tabItems,
} from "../constants";

import { 
  InputPicker,
  Navbar, 
  Nav, 
  Panel, 
  SelectPicker
} from 'rsuite';

import 
{
  RxMixerVertical as IconControl,
}from "react-icons/rx";

import { HistoryView } from "./HistoryView";
import { SolarView } from "./SolarView";
import { ControlView } from "./ControlView";
import { ChemicalView } from "./ChemicalView";
import { PumpView } from "./PumpView";
import ScaleText from "react-scale-text";
import {

  getHours,
  getMinutes,
  // format as DTformat
} from "date-fns";

export const DeviceView = props => {

  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [showAddInstaller, setShowAddInstaller] = useState(false);
  const [restoralFile, setRestoralFile] = useState({
    selectedFile: null,
    selectedFileName: "None Selected"
  });

  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }

  const handleEditDeviceShow = (device) => {
    setDeviceToEdit(device);
    setShowEditDevice(true);
  }
  
  const handleQRCodeShow = (device) => {
    setDeviceToEdit(device);
    setShowQRCode(true);
  }

  const handleEditOff = () => {
    props.onSetEditOff(!props.editOff);
   
  }

  const handleShowAddInstaller = () => {
    setShowAddInstaller(true);
  }

  const handleAddInstaller = ( result) => {
    setShowAddInstaller(false);
    
    if (result !== null) {
      const { id, name , phone, address } = result;
      
      // createInfoRecord(id, name, modeltype);
      console.log("Add info record now please ",id, name, phone,  address);
    }
  }

  const handleSync = () => {
    props.onSetSyncing(true);
    props.onReadAll();
  }

  const handleSave = () => {
    props.onSaveData();
  }

  const handleRestoreButton = () =>
  {
    console.log( restoralFile.selectedFile);
    if ( restoralFile.selectedFile != null)
    {
      props.onLoadData(restoralFile.selectedFile);
    }
  }

  const handleRestore = (e) =>
  {
    setRestoralFile({
      selectedFile: e.target.files[0],
      //**** added line below ****//
      selectedFileName: e.target.files[0].name
    });
  }

  const handleUpgrade = (value, item , e) =>
  {
    props.onSetUpgradeFile({
      selectedFile: true,
      selectedFileName: value
     });
  }

  const handleUpgradeButton = () =>
  {
    handleValueStringRegisterUpdate(REGS.FWUPDATE, 6880, props.upgradeFile.selectedFileName);
    // props.onLoadData(restoralFile.selectedFile);
    props.onSetUpgradeInProgres(true);
  }
  
  const handleACUpgrade = (value, item , e) =>
  {

    // console.log ( e, e.target.value);
    props.onSetUpgradeACFile({
      selectedFile: true,
    //   // //**** added line below ****//
      selectedFileName: value
     });
  }

  const handleSHUpgrade = (value, item , e) =>
    {
  
      console.log ("SH Upgrade", value, item, e);
      props.onSetUpgradeSHFile({
        selectedFile: true,
      //   // //**** added line below ****//
        selectedFileName:value
       });
    }

  const handleACUpgradeButton = () =>
  {
    handleValueStringRegisterUpdate(REGS.FWUPDATE_EXPANSION_PLUS, 6880, props.upgradeACFile.selectedFileName);
    props.onSetACUpdateInProgress(true);
  }


  const handleSHUpgradeButton = (upgradeExp2) =>
  {
    console.log("SH button pressed", upgradeExp2);
    if ( upgradeExp2 === true)
    {
      
      handleValueStringRegisterUpdate(REGS.FWUPDATE_EXP4_SLAVE2, 6880, props.upgradeSHFile.selectedFileName);
      props.onSetSH2UpdateInProgress(true);
    }
    else
    {
      handleValueStringRegisterUpdate(REGS.FWUPDATE_EXP4_SLAVE1, 6880, props.upgradeSHFile.selectedFileName);
      props.onSetSH1UpdateInProgress(true);
    }
    
  }

  const doHandleEditDeviceUpdate = ( device, name , userDevName, modeltype) => {
    setShowEditDevice(false);
    handleEditDeviceUpdate(device, name , userDevName, modeltype, props.onUpdateDeviceName, props.onUpdateUserDeviceName);
  }
  // const handleEditDeviceUpdate = (result) => {
  //   setShowEditDevice(false);
  //   if (result !== null) {
  //     const { device, name , usersDevName, modeltype } = result;
  //     console.log("handleEditDeviceUpdate Result",device, name, usersDevName,  modeltype);
  //     if ( (props.device.userName !== name) || ( props.device.modelType !== modeltype ))
  //     {
  //       props.onUpdateDeviceName(props.device.uniqueDeviceId, name, modeltype);
  //       console.log("edit: name changed", name, modeltype);
  //     }
  //     if ( props.device.usersDeviceName !== usersDevName)
  //     {
  //       props.onUpdateUserDeviceName(props.device.uniqueDeviceId, usersDevName);
  //       console.log("edit: Users Dev Name changed", usersDevName, );
  //     } 
  //     else 
  //     {
  //       console.log("edit: name unchanged", name);
  //     }
      
  //   }
  // }

  const doAddDBFunction = (record_ID, text) =>
  {
    return  client.graphql({
      query: createDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.NOTES,
          recordID: record_ID,
          notes: text,
        }
      }
    })
  }

  async function addNote(record_ID, text) {
   
    const result = await doAddDBFunction(record_ID,text);
    console.log("NOTE ADDING to DB", record_ID, text, result)
    props.setNotesData([...props.notes_data, result.data.createDevice])
  }

  async function deleteNote(record_ID ) {    
    const result = await client.graphql({
      query: deleteDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.NOTES,
          recordID: record_ID
        }
      }
    });
    console.log("Deleting note from DB", record_ID, result)
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    const timer = setTimeout(() => console.log("Time to update current info record."), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleQRDeviceUpdate = () => {
    setShowQRCode(false);
  }

  function header_display()
  {
    return (
      <div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="col-span-1">
          
            <Flex
            justifyContent="start"
            alignItems="center"
            className="space-x-2">
              <Button icon={IconBack}
                color={COLOURS.default}
                size="xs"
                variant="secondary"
                onClick={handleBack}
              >Back
              </Button>
              
              <Icon  
                icon={props.connectionState.ready ? IconConnected : IconDisconnected}
                color={props.connectionState.ready ? COLOURS.good : COLOURS.error}
                size="lg"
              />
              <Metric
                color={props.connectionState.ready ? COLOURS.good : COLOURS.error}
                className="truncate"
              >
                {  (props.selectedDevice !== undefined)? ( props.connectionState.ready ? (props.connectionState.state ) : props.connectionState.state):
                (props.connectionState.state)
                }
              </Metric>
            </Flex>
          </div>
          <div className="col-span-1">
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-2">
                { (props.device.modelType!== undefined)?(<img src={getControllerIconText_FROM_STRING(props.device.modelType).icon} alt="Controller Icon" width="40" height="40" />):(<div></div>) }
              
                <Metric>{props.device.userName}</Metric>
              { GetDeviceBadgeFromStatus(props.device)}
            </Flex>
            <Flex justifyContent="center"
              alignItems="center"
              className="space-x-4">
           
            { (props.device.uniqueDeviceId!== undefined) &&
              <TextTremor>{"UID:" + props.device.uniqueDeviceId}</TextTremor>
            }
            { (props.device.usersDeviceName!== 'Undefined')&& 
            <TextTremor>{props.device.usersDeviceName}</TextTremor>
            }
            </Flex>
            
          </div>
          <div className="col-span-1">
            <Flex justifyContent="end"
              className="space-x-4">
              <Button
                icon={IconEdit}
                iconPosition="right"
                color={COLOURS.default}
                size="sm"
                variant="light"
                onClick={() => handleEditDeviceShow(props.device)}
              />
              <Button
                  icon={IconQRCode}
                  iconPosition="right"
                  color={COLOURS.default}
                  size="lg"
                  variant="light"
                  onClick={() => handleQRCodeShow(props.device)}
                />
              <Button icon={IconSync}
                appearance={props.syncing ? "primary" : "ghost"}
                color={COLOURS.default}
                size="xs"
                disabled={!props.connectionState.ready}
                onClick={handleSync}
              >Sync</Button>

              <Button
                icon={IconEdit}
                color={COLOURS.default}
                size="xs"
                variant={props.editOff ? "secondary" : "primary"}
                disabled={!props.connectionState.ready}
                onClick={handleEditOff}
              >Edit</Button>
            </Flex>
          </div>
        </div>

      <CustomNavbar appearance="inverse" activeKey={props.activeKey} onSelect={props.onSetActiveKey} />
      </div>
    );
  }


  let time = new Date().getTime();
  const setActivityTime = (e) => {
    time = new Date().getTime();
  }
  document.body.addEventListener("mousemove", setActivityTime);
  document.body.addEventListener("keypress", setActivityTime);

  // const doRefresh = () => {
  //   if (new Date().getTime() - time >= 10*60000) {
  //     (props.onRefresh())
  //  //   window.location.reload(true);
  //   } else {
  //     setTimeout(doRefresh, 10000);
  //   }
  // }
  // setTimeout(doRefresh, 10000);

  const handleRegisterUpdate = (register, value, do_refresh) => {
    const registerValue = parseInt(value, 10);
    if (registerValue !== register.value) {
      console.log(register, "=>", registerValue);
      props.onWriteRegister(register.address, registerValue);
      if ( do_refresh)
      {
        delay(2000).then(() => {
          props.onReadAll();
          console.log (" refreshing all registers now")
        });
      }
    }
  }

  const handleFilterTimerEnableChange = (register, value ) => {
    // const registerValue = parseInt(value, 10);
    var currentFilterEnable = getRegisterData(REGS.FILTER_TIME_ENABLE)
    var newFilterEnable = 0;

    console.log( "handleFilterTimerEnableChange register:", register, " value ", value)

    switch (register.address)
    {
      case REGS.FILTER_TIME_1_START_TIME:
        if ( value === false)
        {
          newFilterEnable = currentFilterEnable.value & ~1;
        }
        else
        {
          newFilterEnable = currentFilterEnable.value | 1;
        }
        console.log( "CFE", currentFilterEnable.value, ": " , newFilterEnable)
      break;
      case REGS.FILTER_TIME_2_START_TIME:
        if ( value === false)
        {
          newFilterEnable = currentFilterEnable.value & ~2;
        }
        else
        {
          newFilterEnable = currentFilterEnable.value | 2;
        }
      break;
      case REGS.FILTER_TIME_3_START_TIME:
        if ( value === false)
        {
          newFilterEnable = currentFilterEnable.value & ~4;
        }
        else
        {
          newFilterEnable = currentFilterEnable.value | 4;
        }
      break;
      case REGS.FILTER_TIME_4_START_TIME:
        if ( value === false)
        {
          newFilterEnable = currentFilterEnable.value & ~8;
        }
        else
        {
          newFilterEnable = currentFilterEnable.value | 8;
        }
      break;
      default: 
      break;
    }

    if (newFilterEnable !== currentFilterEnable.value) 
    {
      console.log( REGS.FILTER_TIME_ENABLE, "=>", newFilterEnable);
      props.onWriteRegister(REGS.FILTER_TIME_ENABLE, newFilterEnable);
    }
  }


  const handlePrivateRegisterUpdate = (register, value) => {
    const registerValue = parseInt(value, 10);
    if (registerValue !== register.value) {
      console.log("registerChanged Secure",register, "=>", registerValue);
      props.onWritePasswordRegister(register.address, registerValue);
    }
  }


  const handleValueStringRegisterUpdate = (register, value, valueString) => {
    const registerValue = parseInt(value, 10);
    if (registerValue !== register.value) {
      console.log("registerChanged With ValueString",register, "=>", registerValue);
      props.onwriteValueStringRegister(register, registerValue, valueString);
    }
  }


  const handleTimesUpdate = (register, value) => {
    let hours = getHours(value);
    let minutes = getMinutes(value);
    let timeValue = ((hours << 8) | minutes);
    console.log("Date_range_times", value, timeValue, hours, minutes);

    if (timeValue !== register.value) {
      console.log("times registerupdate", register, "=>", timeValue);
      props.onWriteRegister(register.address, timeValue);
    }
  }


  const handleNameRegisterUpdate = (register, value) =>
  {
    console.log( "handle name =>",  value);

    var name_idx = 0;
    var address_to_send = register.address;

    for (let itr = 0; itr < 8; itr++) 
    {
      let registerValue = ( value.charCodeAt(name_idx) << 8 ) | ( value.charCodeAt(name_idx+1));

      props.onWriteRegister(address_to_send, registerValue);
      console.log(address_to_send, "=>", registerValue, name_idx);
      name_idx = name_idx + 2;
      address_to_send ++;
    }
    props.onReadAll();
  }


  const getRegisterData = (address) => (
    props.registerData.find(item => item.address === address)
  )

  const handleBack = () => {
    props.onBackPressed();
  }

  const PSWEXP4Upgradedata = ['http://10.34.4.102:8000/SH_v1_02_B01.bin', 'http://10.34.4.102:8000/PSW_EXP4.bin', ].map(
    item => ({
      label: item,
      value: item,
      
    })
  );

  useEffect(() => {
    console.log("upgrade in progress changed- ", props.updateInProgress)
   
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.updateInProgress]);

   useEffect(() => {
    console.log("AquaChem upgrade in progress changed- ", props.updateInProgressAC)
   
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.updateInProgressAC]);

   useEffect(() => {
    console.log("SmartHeater upgrade in progress changed- ", props.updateInProgressSH1, props.updateInProgressSH2)
   
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.updateInProgressSH1, props.updateInProgressSH2]);

  const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
    return (
      <Navbar {...props}>
      <Nav onSelect={onSelect} activeKey={activeKey}>
        
      <Nav.Item eventKey={tabItems.home} icon={<IconHome/>}><div className="hidden md:block">Home</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.trends} icon={<IconTrends/>}><div className="hidden md:block">Trends</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.appliances} icon={<IconTrends/>}><div className="hidden md:block">Appliances</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.faults} icon={<IconError/>}><div className="hidden md:block">Faults</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.control} icon={<IconControl/>}><div className="hidden md:block">Control</div></Nav.Item>
      <Nav.Item eventKey={tabItems.heating} icon={<IconHeat/>}><div className="hidden md:block">Heating</div></Nav.Item>
      <Nav.Item eventKey={tabItems.solar} icon={<IconSolar/>}><div className="hidden md:block">Solar</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.chemical} icon={<IconAcidTube/>}><div className="hidden md:block">Chemical</div></Nav.Item>
      <Nav.Item eventKey={tabItems.spa} icon={<IconSpa/>}><div className="hidden md:block">SPA</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.pump} icon={<IconPump/>} ><div className="hidden md:block">Pump</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.applianceSetup} icon={<IconSettings/>} ><div className="hidden md:block">Appliance Setup</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.system} icon={<IconSettings/>} ><div className="hidden md:block">System</div></Nav.Item> 
      <Nav.Item eventKey={tabItems.filter} icon={<IconFrozen/>}><div className="hidden md:block">Filter</div></Nav.Item> 


      {/* <Navbar.Brand href="#">RSUITE</Navbar.Brand>
      
        <Nav.Item eventKey="1" icon={<HomeIcon />}>
          Home
        </Nav.Item>
        <Nav.Item eventKey="2">News</Nav.Item>
        <Nav.Item eventKey="3">Products</Nav.Item>
        <Nav.Menu title="About">
          <Nav.Item eventKey="4">Company</Nav.Item>
          <Nav.Item eventKey="5">Team</Nav.Item>
          <Nav.Item eventKey="6">Contact</Nav.Item>
        </Nav.Menu> */}
      </Nav>
      {/* <Nav pullRight>
        <Nav.Item icon={<CogIcon />}>Settings</Nav.Item>
      </Nav> */}
    </Navbar>
    );
  };
  

  return (
    <div className="mt-3">
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 sm:p-4">
        <div className="col-span-1">
         {header_display()}
         {
            (props.activeKey === tabItems.home) &&
              <Panel bordered bodyFill>
                {/* <Card key={props.device.uniqueDeviceId} > */}
                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                    <div className="col-span-1 lg:col-span-3">
                      <SystemInfo 
                        onGetRegisterData={getRegisterData}
                        handleRegisterUpdate={handleRegisterUpdate}
                        onNameRegisterUpdate={handleNameRegisterUpdate}
                        onTimesUpdate={handleTimesUpdate}
                        formatTemperature={props.formatTemperature}
                        onPrivateRegisterUpdate = {handlePrivateRegisterUpdate}
                        registerData= {props.registerData}
                        editOff={props.editOff}
                        macAddress={props.macAddress}
                      />  
                    </div>
                    <div className="hidden md:block">
                      {/* <Card
                        decoration="bottom"
                        decorationColor={COLOURS.cardDecoration}
                        className="mt-3 space-y-2">
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                          <Title ><Bold>Installation Details</Bold></Title>
                          </Flex>
                          <Flex
                            justifyContent="start"
                            className="space-x-4 "
                          >
                            <Title>Supplier</Title>
                            <SelectPicker data={locationsOptions}>    </SelectPicker>
                          </Flex>
                          <Flex
                            justifyContent="start"
                            className="space-x-4 "
                          >
                            <Title>Monitor</Title>
                            <SelectPicker data={locationsOptions}   > </SelectPicker>
                          </Flex>
                          <Flex
                            justifyContent="start"
                            className="space-x-4 "
                          >
                            <Title>Installer</Title>
                            <SelectPicker data={locationsOptions}  >  </SelectPicker>
                            <Button onClick={handleShowAddInstaller} >Add Installer</Button> 
                          </Flex>
                      </Card> */}

                      <Card
                        decoration="bottom"
                        decorationColor={COLOURS.cardDecoration}
                        className="mt-3 space-y-2">
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                          <Title ><Bold>Backup/Restore</Bold></Title>
                          </Flex>
                          <Flex
                            justifyContent="between"
                            className="space-x-4 "
                          >
                            <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready}
                            onClick={handleSave}
                            >Backup</Button>
                            <input type="file" onChange={handleRestore} />
                            <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready}
                            onClick={handleRestoreButton}
                            >Restore</Button> 
                          </Flex>
                      </Card>
                      <Card
                        decoration="bottom"
                        decorationColor={COLOURS.cardDecoration}
                        className="mt-3 space-y-2">
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                          <Title ><Bold>Upgrade Firmware</Bold></Title>
                          </Flex>
                          <Flex
                            justifyContent="between"
                            className="space-x-4 "
                          
                          >
                            <InputPicker
                              creatable
                              defaultValue = {props.PSWUpgradeURLS[0]}
                              data={props.PSWUpgradeURLS.map(
                                item => ({
                                  label: item,
                                  value: item,
                                  
                                })) }
                              style={{ width: "100%" }}
                              onCreate={
                                (value, item) => {
                                  console.log(value, item);
                               
                                props.onaddPSWUrl(value)}}
                              value = {props.upgradeFile.selectedFileName}
                              onSelect={handleUpgrade}
                            />

                          </Flex>
                          {(props.updateInProgress === true) && 
                          <div>
                            <DisplayItem icon={IconInfo} register={getRegisterData(REGS.FWUPDATE)} borderOff={true} />
                            <DisplayItem icon={IconInfo} register={getRegisterData(REGS.FWUPDATE_ERROR)} borderOff={true} />
                          </div>
                          }
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                            <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready && !props.updateInProgress }
                            onClick={handleUpgradeButton}
                            >Upgrade</Button> 
                          </Flex>
                      </Card>
                      <Card
                        decoration="bottom"
                        decorationColor={COLOURS.cardDecoration}
                        className="mt-3 space-y-2">
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                          <Title ><Bold>Upgrade AquaChem Firmware</Bold></Title>
                          </Flex>
                          <Flex
                            justifyContent="between"
                            className="space-x-4 "
                          
                          >

                            <InputPicker
                              creatable
                              defaultValue = {props.AquaChemUpgradeURLS[0]}
                              data={props.AquaChemUpgradeURLS.map(
                                item => ({
                                  label: item,
                                  value: item,
                                  
                                })) }
                              style={{ width: "100%" }}
                              onCreate={
                                (value, item) => {
                                  console.log(value, item);
                               
                                props.onaddACUrl(value)}}
                              value = {props.upgradeACFile.selectedFileName}
                              onSelect={handleACUpgrade}
                            />

                            {/* <datalist id="versionURLS">
                            <option value="https://dontekcontrol.com/ota/Dontek_PSW_v1_15_B14.bin" label="Aquatek Build 14"></option>
                            <option value="https://dontekcontrol.com/ota/LightController_v1_03_B03.bin" label="AquaLight V1.03 B3"></option>
                            <option value="https://dontekcontrol.com/ota/LightController_v1_03_B03.bin" label="AquaLight V1.03 B3"></option>
                            <option value="https://www.mozilla.org/" label="Mozilla"></option>
                            <option value="http://w3.org/" label="W3C"></option>
                            </datalist>
                            <input type="url" onChange={handleACUpgrade} value= { props.upgradeACFile.selectedFileName}  pattern="https://.*" list="versionURLS" size="80" required /> */}
                          </Flex>
                          {(props.updateInProgressAC === true) &&
                          <DisplayItem icon={IconInfo} register={getRegisterData(REGS.FWUPDATE_EXPANSION_PLUS)} borderOff={true} />
}
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                            <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready && !props.updateInProgressAC }
                            onClick={handleACUpgradeButton}
                            >Upgrade</Button> 
                          </Flex>
                      </Card>
                      <Card
                        decoration="bottom"
                        decorationColor={COLOURS.cardDecoration}
                        className="mt-3 space-y-2">
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                          <Title ><Bold>Upgrade SmartHeater Firmware</Bold></Title>
                          </Flex>
                          <Flex
                            justifyContent="between"
                            className="space-x-4 "
                          
                          >
                            <InputPicker
                              creatable
                              defaultValue = {PSWEXP4Upgradedata[0].value}
                              data={PSWEXP4Upgradedata}
                              style={{ width: "100%" }}
                              onCreate={(value, item) => {
                                console.log(value, item);
                              }}
                              value = {props.upgradeSHFile.selectedFileName}
                              onSelect={handleSHUpgrade}
                            />

                          </Flex>
                          {(props.updateInProgressSH1 === true) &&
                          <div>
                          <DisplayItem icon={IconInfo} register={getRegisterData(REGS.FWUPDATE_EXP4_SLAVE1)} borderOff={true} />
                          </div>
                          }
                          {(props.updateInProgressSH2 === true) &&
                          <div>
                          
                          <DisplayItem icon={IconInfo} register={getRegisterData(REGS.FWUPDATE_EXP4_SLAVE2)} borderOff={true} />
                          </div>
                          }
                          <Flex
                            justifyContent="center"
                            className="space-x-4 "
                          >
                            <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready && !props.updateInProgressSH1 }
                            onClick={() => { handleSHUpgradeButton(false) }}
                            >Upgrade EXP1</Button> 
                             <Button icon={IconSync}
                            appearance={props.saving ? "primary" : "ghost"}
                            color={COLOURS.default}
                            size="xs"
                            disabled={!props.connectionState.ready && !props.updateInProgressSH2 }
                            onClick={() => {handleSHUpgradeButton(true) }}
                            >Upgrade EXP2</Button> 
                          </Flex>
                      </Card>
                    </div>
                  </div>
                  <Card>
                    <Notes notes_data={props.notes_data}
                    device={props.device}
                    onAddNote={addNote}
                    onDeleteNote={deleteNote}
                    onFetchNotes={props.onFetchNotes} />
                  </Card>
              </Panel>
          }
          {
            (props.activeKey === tabItems.trends) &&
                <Card  >
                  <TrendsView
                    devices={props.devices}
                    logData={props.logData}
                    plottableParams={props.plottableParams}
                    plottableConfigParams={props.plottableConfigParams}
                    pumpTrackerData={props.pumpTrackerData}
                    configData={props.configData}
                    // pool={props.pool}
                    // water={props.water}
                    // roof={props.roof}
                    // poolLabels={props.poolLabels}
                    // applianceDataArray={props.applianceDataArray}
                    ORPPHLabels={props.ORPPHLabels}
                    ORP={props.ORP}
                    PH={props.PH}
                    ORPRT={props.ORPRT}
                    PHRT={props.PHRT}
                    selectedDevice={props.selectedDevice}
                    selectedDateRange={props.selectedDateRange}
                    selectedParams={props.selectedParams}
                    phSelection={props.phSelection}
                    ORPSelection={props.ORPSelection}
                    loading={props.loading}
                    progress={props.progress}
              
                    onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                    onSelectedParamsChanged={props.onSelectedParamsChanged}
                    onRefresh={props.onRefresh}
                    onRefreshFaults={props.onRefreshFaults}
                    isLoadingOrNoDevice={props.isLoadingOrNoDevice}
                    // onViewFaults={handleViewFaults}
                    // onViewDashboard={handleViewDashboard}
                  />
                </Card>
            }
            {
              (props.activeKey === tabItems.appliances) &&
                <Card  >
                  <AppliancesView
                    devices={props.devices}
                    logData={props.logData}
                    params={props.params}
                    pumpTrackerData={props.pumpTrackerData}
                    selectedDevice={props.selectedDevice}
                    selectedDateRange={props.selectedDateRange}
                    selectedParams={props.selectedParams}
                    phSelection={props.phSelection}
                    ORPSelection={props.ORPSelection}
                    loading={props.loading}
                    progress={props.progress}
                    AppTrackerData={props.applianceTrackerDataArray}
                    onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                    onSelectedParamsChanged={props.onSelectedParamsChanged}
                    isLoadingOrNoDevice={props.isLoadingOrNoDevice}
                    isApplianceConfigured={props.isApplianceConfigured}
                    getAppliances_with_id={props.getAppliances_with_id}
                   
                  />
                </Card>
              }
              {
                (props.activeKey === tabItems.faults) &&
                <Card >
                  <HistoryView
                    // selectedDevice={props.devices.find((e) => e.uniqueDeviceId === props.selectedDevice)}
                    // selectedDevices={props.selectedDevices}
                    selectedDevice={props.selectedDevice}
                    device={props.device}
                    devices={props.devices}
                    historyFaults={props.historyFaults}
       
                    progress={props.progress}
                    loading={props.loading}
                    onBackPressed={handleBack}
                    // latestFaults={props.latestFaults}
      
                    onSetSelectedFaultView={props.onSetSelectedFaultView}
                    // onSetSelectedLatestFaults={props.onSetSelectedLatestFaults}
                    onSelectedDateRangeChanged={props.onSelectedDateRangeChanged}
                    onSelectedFaultDateRangeChanged={props.onSelectedFaultDateRangeChanged}
                    selectedDateRange={props.selectedDateRange}
                    selectedFaultView={props.selectedFaultView}
                    // selectedLatestFaults ={props.selectedLatestFaults}
                    selectedHistoryFaults= {props.selectedHistoryFaults}
                    onSetSelectedHistoryFaults={props.onSetSelectedHistoryFaults}
                    onFetchDevices={props.onFetchDevices}

                  />
                </Card>
              }
              {
                (props.activeKey === tabItems.control) &&
                <ControlView 
                  editOff={props.editOff}
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  onTimesUpdate={handleTimesUpdate}
                  formatTemperature={props.formatTemperature}
                /> 
              }
              {
                (props.activeKey === tabItems.heating) &&
                <HeatingView 
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  onTimesUpdate={handleTimesUpdate}
                  formatTemperature={props.formatTemperature}
                  formatTrackingTemperature={props.formatTrackingTemperature}
                  editOff={props.editOff}
                /> 
              }
              {
                 (props.activeKey === tabItems.solar) &&
                <SolarView 
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  onTimesUpdate={handleTimesUpdate}
                  formatTemperature={props.formatTemperature}
                  editOff={props.editOff}
                /> 
              }
              {

                (props.activeKey === tabItems.chemical) &&
                <ChemicalView
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  editOff={props.editOff}
                  />
              }
              {
                 (props.activeKey === tabItems.spa) &&
              
                <SPAView 
                onGetRegisterData={getRegisterData}
                handleRegisterUpdate={handleRegisterUpdate}
                editOff={props.editOff}
                />
              }
              {
                (props.activeKey === tabItems.pump) &&
                <PumpView 
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  onTimesUpdate={handleTimesUpdate}
                  formatTemperature={props.formatTemperature}
                  editOff={props.editOff}
                  /> 
              }
              {
                 (props.activeKey === tabItems.applianceSetup) &&
                 <Panel bordered bodyFill>
                    <ApplianceSetupView 
                      onGetRegisterData={getRegisterData}
                      onNameRegisterUpdate={handleNameRegisterUpdate}
                      handleRegisterUpdate={handleRegisterUpdate}
                      onTimesUpdate={handleTimesUpdate}
                      editOff={props.editOff}
                      formatTemperature={props.formatTemperature}
                      /> 
                  </Panel>
              }
              {
                 (props.activeKey === tabItems.system) &&
                 <Panel bordered bodyFill>
                    <SystemSettingsView 
                      onGetRegisterData={getRegisterData}
                      onNameRegisterUpdate={handleNameRegisterUpdate}
                      handleRegisterUpdate={handleRegisterUpdate}
                      onPrivateRegisterUpdate = {handlePrivateRegisterUpdate}
                      onTimesUpdate={handleTimesUpdate}
                      editOff={props.editOff}
                      formatTemperature={props.formatTemperature}
                      /> 
                  </Panel>
              }
              {
               (props.activeKey === tabItems.filter) &&
                <FilterView 
                  onGetRegisterData={getRegisterData}
                  handleRegisterUpdate={handleRegisterUpdate}
                  handleFilterTimerEnableChange={handleFilterTimerEnableChange}
                  onTimesUpdate={handleTimesUpdate}
                  editOff={props.editOff}
                  formatTemperature={props.formatTemperature}
                  /> 
              }

              
        </div>
        <ShowDeviceQR
        open={showQRCode}
        device={deviceToEdit}
        handleClose={handleQRDeviceUpdate}
        />
        <EditDevice
          open={showEditDevice}
          device={deviceToEdit}
          handleClose={doHandleEditDeviceUpdate}
        />
        <AddInstaller
          open={showAddInstaller}
          device={deviceToEdit}
          handleClose={handleAddInstaller}
        />
      </div>
    </div>
  )
};