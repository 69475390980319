import {
  Text,
  Flex,
 
  Card,
  Grid,
} from "@tremor/react";

import { 
 
  DisplayItem,
  DisplayPrimeButtonItem,
  DisplayToggleItem,
  DisplayORPPhSensorOffsetSliderItem,
  Display2ValueToggleItem,
  DisplaySliderItem,
  DisplaySelectPickerItem,
  DisplayORPPhSliderItem,
} from "./DisplayItems";

import {
  MdPool as IconPool,
  MdHotTub as IconSpa,
} from "react-icons/md";

import {
  GiBubbles as IconBubbles,
  GiChemicalDrop as IconChemical,
  
  GiDrippingTube as IconAcidTube,
  
} from "react-icons/gi";

import { 
  formatORP,
  formatPH,
} from "./RegistersHandler";

import { COLOURS} from "../styles"
import {
  
  offOnOptions,

//  solarSensorLocations,
//   solarTypeOptions,
DisableEnableOptions,
spaSizeOptions, 
  poolSizeOptions,
} from "../constants";

import {

  REGS,

} from "../registers";

export const ChemicalView = props => {
    return (
      <Grid
          numItems={1}
          numItemsSm={1}
          numItemsLg={1}
          className="gap-4"
        >
        
        <Card
          decoration="bottom"
          decorationColor={COLOURS.cardDecoration}
          className="mt-3">
          <div className="max-w-none mt-2 space-y-4 text-left">
            <Flex
              justifyContent="center"
              alignItems="center"
              className="space-x-2" >
              <Text color={COLOURS.default}>Chemical</Text>
            </Flex>

            <Grid
              numItems={2}
              numItemsSm={2}
              numItemsLg={2}
              className="gap-4"
            >
              <DisplayItem register={props.onGetRegisterData(REGS.PH_CURR)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_CURR)} icon={IconBubbles} />

              <DisplayItem register={props.onGetRegisterData(REGS.PH_LEVEL)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_LEVEL)} icon={IconBubbles} />
              <DisplayItem register={props.onGetRegisterData(REGS.PH_MEAS)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.ORP_MEAS)} icon={IconBubbles} />
              
              
              <Display2ValueToggleItem register={props.onGetRegisterData(REGS.PH_SENSOR_CONNECTED)} icon={IconChemical} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
              <Display2ValueToggleItem register={props.onGetRegisterData(REGS.ORP_SENSOR_CONNECTED)} icon={IconBubbles} options={offOnOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff} />
              {/* <DisplaySliderItem register={props.onGetRegisterData(REGS.PH_SET_POINT)} icon={IconChemical} min={50} max={90} step={2} onFormat={formatPH} onChange={props.handleRegisterUpdate} disabled={props.editOff}/> */}
              
              <DisplayORPPhSliderItem register={props.onGetRegisterData(REGS.PH_SET_POINT)} icon={IconChemical} min={7} max={8} step={0.1}  onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={true} />
              <DisplayORPPhSliderItem register={props.onGetRegisterData(REGS.ORP_SET_POINT)} icon={IconBubbles} min={500} max={900} step={10} onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={false}/>
              <DisplayORPPhSliderItem register={props.onGetRegisterData(REGS.PH_SET_POINT_INSTALLER)} icon={IconChemical} min={7} max={8} step={0.1}  onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={true} />

              {/* <DisplaySliderItem register={props.onGetRegisterData(REGS.ORP_SET_POINT)} icon={IconBubbles} min={50} max={90} step={2} onFormat={formatORP} onChange={props.handleRegisterUpdate} disabled={props.editOff}/> */}
              
              <DisplayORPPhSliderItem register={props.onGetRegisterData(REGS.ORP_SET_POINT_INSTALLER)} icon={IconBubbles} min={500} max={900} step={10} onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={false}/>
             

              <DisplayORPPhSensorOffsetSliderItem register={props.onGetRegisterData(REGS.PH_SENSOR_OFFSET)} icon={IconChemical} min={-1.5} max={1.5} step={0.1} onFormat={formatPH} onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={true}/>
              <DisplayORPPhSensorOffsetSliderItem register={props.onGetRegisterData(REGS.ORP_SENSOR_OFFSET)} icon={IconBubbles} min={-150} max={150} step={10} onFormat={formatORP} onChange={props.handleRegisterUpdate} disabled={props.editOff} ispH={false}/>
              
              <DisplayItem register={props.onGetRegisterData(REGS.ACID_PUMP_LAST_RUN_TIME)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.CHLORINE_PUMP_LAST_RUN_TIME)} icon={IconBubbles} />
             
              <DisplayItem register={props.onGetRegisterData(REGS.CHLOR_INHIBITED)} icon={IconBubbles} />
              <DisplayItem register={props.onGetRegisterData(REGS.AUTO_CHLOR_STATE)} icon={IconBubbles} />
              <DisplayItem register={props.onGetRegisterData(REGS.CHLOR_LIMIT)} icon={IconBubbles} />

              <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.POOL_SIZE)} icon={IconPool} options={poolSizeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
              <DisplaySelectPickerItem register={props.onGetRegisterData(REGS.SPA_SIZE)} icon={IconSpa} options={spaSizeOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
             
              <Display2ValueToggleItem register={props.onGetRegisterData(REGS.OVERRIDE_SENSOR_SETTLING)} icon={IconChemical} options={DisableEnableOptions} onChange={props.handleRegisterUpdate} colour={COLOURS.default} disabled={props.editOff}/>
              
              <DisplayItem register={props.onGetRegisterData(REGS.DOSING_OPTION)} icon={IconBubbles} />
              <DisplayPrimeButtonItem register={props.onGetRegisterData(REGS.PRIME_DOSING_PUMP)} iconOff={IconAcidTube} iconOn={IconAcidTube}icon2On = {IconBubbles} icon2Off ={IconBubbles} onChange={props.handleRegisterUpdate}  disabled={props.editOff}  altText = "Prime Acid Pump" altText2 = "Prime Chlorine Pump"/>
              {/* <DisplayButtonItem register={props.onGetRegisterData(REGS.PRIME_DOSING_PUMP)} iconOff={IconAcidTube} iconOn={IconAcidTube} onChange={props.handleRegisterUpdate}  disabled={props.editOff} sendValue={2} altText = "Prime Chlorine Pump"/> */}
              <DisplayItem register={props.onGetRegisterData(REGS.ACID_DOSE_DAILY_LIMIT)} icon={IconChemical} />
              <DisplayItem register={props.onGetRegisterData(REGS.CHLOR_DOSE_DAILY_LIMIT)} icon={IconBubbles} />
            </Grid>

          </div>
        </Card>
      </Grid>
    )
}