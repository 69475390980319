import React, { useState } from "react";
import { client } from "../App";
import { Pagination} from "@aws-amplify/ui-react";

import { 
   Button, 
   ButtonToolbar ,
   Cascader,
  Checkbox,
  FlexboxGrid, 
  IconButton,
  List,
  // Tag,
  Tooltip,
  Whisper
}from 'rsuite';
import ScaleText from "react-scale-text";

import {
  getControllerIconText_FROM_STRING,
  getControllerIconText,
  GetDeviceBadgeFromStatus,
  GetBadgeForversion,
  LoadingView,
} from "./DisplayItems";
import {
  Badge,
  
  Button as TremorButton,
  Card,
  Col,
  Grid,
  Flex,

  TabGroup,
  TabList,
  Tab,
  Bold
} from "@tremor/react";

import { AddDevice, 
  AddDeviceInfo,

  EditDevice,
  //  SelectManyDevices, 
   ShowDeviceQR, 
   ShowDeviceDeleteConfirmation,
   isDeviceInFault,
   isDeviceGood,
   isDeviceOffline,
   getRecordIDFromUID,
   
   SelectItemsPerPage,
   build_fault_string
  } from "./Common";

import {
  MdOutlineEdit as IconEdit,
  // MdOutlineInsertChart as IconTrends,
  // MdOutlineErrorOutline as IconError,
  // MdOutlineSpaceDashboard as IconDashboard,
  // MdRadioButtonUnchecked as IconCheck,
  MdOutlineWarningAmber as IconWarning,
  MdAdd as IconMore,
  MdRemove as IconLess,
  MdQrCode2 as IconQRCode,
  MdRefresh as IconRefresh,
  MdDelete as IconRubbish,
  MdFingerprint as IconFingerPrint,
  MdOutlineInfo as IconInfo,
  MdArchive as IconArchive,
  MdOutlineArchive as IconArchive2
} from "react-icons/md";
import { FaRegHandPointer as IconPoint} from "react-icons/fa";
import { ImCheckboxUnchecked as IconUnticked,
  ImCheckboxChecked as IconTicked,} from "react-icons/im";
import { ImBarcode as IconBarcode} from "react-icons/im";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { COLOURS} from "../styles"
import { GiHammerBreak as IconHammer } from "react-icons/gi";
import { 
  // accessLevels,

  // APP_CONFIG,

  faults_bit_t,
  FILTER_OPTIONS,
  FIRMWARE_VERSIONS,
  RECORD_TYPES,
  apiLimit,
  // sortKeys,
} from "../constants";

import { createDevice, deleteDevice, updateDevice } from "../graphql/mutations";

import usePagination from "./Pagination";
import { CollectionHooksTable }from "./DevicesTable"
import { StatsView } from "./StatsView";
import { checkNameValid, getRandomIntInclusive } from "../utils/utilities";
import { UserView } from "./UserAccess";


const selectedView_t = 
{
  GRID:0,
  LIST:1,
  STATS:2,
  SETTINGS:3,
}

const getFilterOptionsData = () => {
  const filterOptionsData = [
    {
      "label": "Status",
      "value": FILTER_OPTIONS.statusGroup,
      "children": [
        { "label": "Unknown", "value": FILTER_OPTIONS.statusDeviceUnknown },
        { "label": "Offline", "value": FILTER_OPTIONS.statusDeviceOffline },
        { "label": "Fault", "value": FILTER_OPTIONS.statusDeviceInFault,
          "children": [
            { "label": "Any", "value": FILTER_OPTIONS.statusDeviceFaultsAny},
            { "label": "Internal Commms", "value": FILTER_OPTIONS.statusDeviceFaultsInternalCommsIssue},
            { "label": "Therm 1 Short", "value": FILTER_OPTIONS.statusDeviceFaultsTherm1ShortCircuit},
            { "label": "Therm 1 Open", "value": FILTER_OPTIONS.statusDeviceFaultsTherm1OpenCircuit},
            { "label": "Therm 2 Short", "value": FILTER_OPTIONS.statusDeviceFaultsTherm2ShortCircuit},
            { "label": "Therm 2 Open", "value": FILTER_OPTIONS.statusDeviceFaultsTherm2OpenCircuit},
            { "label": "Pump Comms", "value": FILTER_OPTIONS.statusDeviceFaultsPumpCommunicationsFault},
            { "label": "Exp 1 Fault", "value": FILTER_OPTIONS.statusDeviceFaultsExpansion1Fault},
            { "label": "Exp 2 Fault", "value": FILTER_OPTIONS.statusDeviceFaultsExpansion2Fault},
            { "label": "Ambient Fault", "value": FILTER_OPTIONS.statusDeviceFaultsAmbientTemperatureFault},
            { "label": "Ph ORP Fault", "value": FILTER_OPTIONS.statusDeviceFaultsPhOrpExpansionFault},
            { "label": "Therm 3 Short", "value": FILTER_OPTIONS.statusDeviceFaultsTherm3ShortCircuit},
            { "label": "Therm 4 Open", "value": FILTER_OPTIONS.statusDeviceFaultsTherm3OpenCircuit},
            // { "label": "Restart", "value": FILTER_OPTIONS.statusDeviceFaultsSystemRestarted},
          ]
      },
        { "label": "Good", "value": FILTER_OPTIONS.statusDeviceGood }
      ]
    },
    {
      "label": "Version",
      "value": FILTER_OPTIONS.versionGroup,
      "children": [
        { "label": "Not Set", "value": FILTER_OPTIONS.versionUnknown },
        { "label": "1.15B14", "value": FILTER_OPTIONS.version115B14 },
        { "label": "1.15B82", "value": FILTER_OPTIONS.version115B82},
        { "label": "Beta", "value": FILTER_OPTIONS.versionBeta},
        
      ]
    },
    {
      "label": "Model",
      "value": FILTER_OPTIONS.modelTypeGroup,
      "children": [
        { "label": "Aquatek", "value": FILTER_OPTIONS.modelTypeGroupAquatek },
        { "label": "Theralink", "value": FILTER_OPTIONS.modelTypeGroupTheralink },
        { "label": "Oasis", "value": FILTER_OPTIONS.modelTypeGroupOasis },
        { "label": "Pooltek", "value": FILTER_OPTIONS.modelTypeGroupPoolTek},
        { "label": "Henden", "value": FILTER_OPTIONS.modelTypeGroupHenden },
        { "label": "Aqualight", "value": FILTER_OPTIONS.modelTypeGroupAqualight },
      ]
    }
  ];
  return filterOptionsData;
};


export function handleEditDeviceUpdate (device, name , userDevName, modeltype, onUpdateDeviceName , onUpdateUserDeviceName) 
{
  const currentDevice = device;
  const currentName = name;
  const currentUserName = userDevName;
  if (currentDevice !== null) 
  {
    
    console.log("HV handleEditDeviceUpdate Result",currentDevice, currentName, currentUserName, modeltype);
    
    if ( currentName != undefined && modeltype != undefined )
    {
      if ( (currentDevice.userName !== currentName) || ( currentDevice.modelType !== modeltype )){
        onUpdateDeviceName(currentDevice.uniqueDeviceId, currentName, modeltype);
        
        console.log("edit: name changed", currentName, modeltype);
      }
    } 

    if ( currentDevice.usersDeviceName == undefined || (currentDevice.usersDeviceName !== currentUserName) )
    {
      onUpdateUserDeviceName(currentDevice.uniqueDeviceId, currentUserName);
      console.log("HV handleEditDeviceUpdate: Users Device name changed", currentUserName);
    }
    
  }
}

export const HomeView = (props) => {
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showInfoDevice, setShowAddDeviceInfoRecord] = useState(false);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(20);
  const [showStatsList, setShowStatsList] = useState(false);
  const isDeviceSelected = (device) => (
    (props.selectedDevices.length === 0) || (props.selectedDevices.includes(device.uniqueDeviceId))
  );
  

  const isModelTypeSelected = (device, filter) =>
(
    (filter === FILTER_OPTIONS.modelTypeGroupAquatek && ( device.modelType === "Aquatek" ) ) ||
    (filter === FILTER_OPTIONS.modelTypeGroupTheralink && ( device.modelType === "Theralink" ))  ||
    (filter === FILTER_OPTIONS.modelTypeGroupOasis && ( device.modelType === "Oasis" ) ) ||
    (filter === FILTER_OPTIONS.modelTypeGroupPoolTek && ( device.modelType === "Pooltek" ) ) ||
    (filter === FILTER_OPTIONS.modelTypeGroupHenden && ( device.modelType === "Henden" ) ) ||
    (filter === FILTER_OPTIONS.modelTypeGroupAqualight && ( device.modelType === "Aqualight" ) )   
);

const isDeviceFaultSelected = ( device, filter) => (
  (filter === FILTER_OPTIONS.statusDeviceFaultsAny && isDeviceInFault(device)) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsInternalCommsIssue && ( (device.currentFaults & faults_bit_t.FAULT_INTERNAL_COMMS_BIT) === faults_bit_t.FAULT_INTERNAL_COMMS_BIT  ) ) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm1ShortCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_1_SHORT_BIT) === faults_bit_t.FAULT_THERMISTOR_1_SHORT_BIT  ) )  ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm1OpenCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_1_OPEN_BIT) === faults_bit_t.FAULT_THERMISTOR_1_OPEN_BIT  ) ) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm2ShortCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_2_SHORT_BIT) === faults_bit_t.FAULT_THERMISTOR_2_SHORT_BIT  ) ) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm2OpenCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_2_OPEN_BIT) === faults_bit_t.FAULT_THERMISTOR_2_OPEN_BIT  ) )||
  (filter === FILTER_OPTIONS.statusDeviceFaultsPumpCommunicationsFault && ( (device.currentFaults & faults_bit_t.FAULT_PUMP_COMMS_FAULT_BIT) === faults_bit_t.FAULT_PUMP_COMMS_FAULT_BIT  ) )||
  (filter === FILTER_OPTIONS.statusDeviceFaultsExpansion1Fault && ( (device.currentFaults & faults_bit_t.FAULT_SLAVE1_COMMS_FAULT_BIT) === faults_bit_t.FAULT_SLAVE1_COMMS_FAULT_BIT  ) ) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsExpansion2Fault && ( (device.currentFaults & faults_bit_t.FAULT_SLAVE2_COMMS_FAULT_BIT) === faults_bit_t.FAULT_SLAVE2_COMMS_FAULT_BIT  ) )||
  (filter === FILTER_OPTIONS.statusDeviceFaultsAmbientTemperatureFault && ( (device.currentFaults & faults_bit_t.FAULT_AMBIENT_TEMPERATURE_BIT) === faults_bit_t.FAULT_AMBIENT_TEMPERATURE_BIT  ) )||
  (filter === FILTER_OPTIONS.statusDeviceFaultsPhOrpExpansionFault && ( (device.currentFaults & faults_bit_t.FAULT_SLAVE3_COMMS_FAULT_BIT) === faults_bit_t.FAULT_SLAVE3_COMMS_FAULT_BIT  ) ) ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm3ShortCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_3_SHORT_BIT) === faults_bit_t.FAULT_THERMISTOR_3_SHORT_BIT  ) )  ||
  (filter === FILTER_OPTIONS.statusDeviceFaultsTherm3OpenCircuit && ( (device.currentFaults & faults_bit_t.FAULT_THERMISTOR_3_OPEN_BIT) === faults_bit_t.FAULT_THERMISTOR_3_OPEN_BIT  ) ) 
);

const isDeviceStatusSelected = (device, filter) => (
  // (filter === FILTER_OPTIONS.statusDeviceUnknown === ) ||
  isDeviceFaultSelected(device,filter)||
  (filter === FILTER_OPTIONS.statusDeviceOffline  && isDeviceOffline(device)) ||
  (filter === FILTER_OPTIONS.statusDeviceInFault && isDeviceInFault(device)) ||
  (filter === FILTER_OPTIONS.statusDeviceGood && isDeviceGood(device))
);

const isVersionSelected = (device, filter) => {
  let result = false;
  if(filter === FILTER_OPTIONS.versionUnknown) {
    result = (device.versionNumber === "NOT SET");
  } 
  else if ( filter === FILTER_OPTIONS.versionBeta)
  {
    if (device.versionNumber)
    {
      var buildNumber = device.versionNumber.slice(-2);
      console.log( "Build NUmber", buildNumber);
      if ( Number(buildNumber) > 60 )
      {
        result = true;
      }
    }

  }
  else 
  {
    const index = filter - FILTER_OPTIONS.version115B14;
    if(index >= 0 && index < FIRMWARE_VERSIONS.length) {
      const desc = FIRMWARE_VERSIONS[index].name;
      result = (desc === device.versionNumber);
    }
  }
  return result;
};

const isFilterSelected = (device) => (
  (props.selectedFilter === null) ||
  isDeviceStatusSelected(device, props.selectedFilter) ||
  isModelTypeSelected(device, props.selectedFilter) ||
  // isTankModelSelected(device, props.selectedFilter) ||
  // isLocationSelected(device, props.selectedFilter) ||
  // isModeSelected(device, props.selectedFilter) ||
  isVersionSelected(device, props.selectedFilter) 
  // isApiAccessSelected(device, props.selectedFilter)
);

  const filteredDevices = props.devices.filter((device) => isDeviceSelected(device)).filter((device) => isFilterSelected(device)) ;
  const totalPages = Math.ceil(filteredDevices.length / numPerPage);
  const allDevices = usePagination(filteredDevices, numPerPage);
  const handleAddDevice = (result) => {
    setShowAddDevice(false);
    console.log("Added device",result, props.loginId)
    if(result !== null) 
    {
      const { id, devType, usersDevName} = result;


      // handleAddDevice
      props.onUpdateUserDeviceName( id, usersDevName , devType);
    }
  }

  const handleDeviceDeleteUpdate = (result) => {
    setShowConfirmDelete(false);
    
    if(result !== null) 
    {
      const { id  } = result;
      let recordID = getRecordIDFromUID(id);
      console.log("removeing device access record", recordID, props.loginId, id);
    
      deleteAccessRecord( recordID, props.loginId );
    
    }
  }

  const handleSetSHowArchivedDevices = (value,checked) =>
  {
    props.onToggleArchivedDevices(checked)
    console.log( "setting Archived Visible",props.showArchivedDevices,  value, checked)
  }


  const handleAddDeviceInfo = ( result) => {
    setShowAddDeviceInfoRecord(false);
    if (result !== null) {
      const { id, name , modeltype } = result;
      createInfoRecord(id, name, modeltype);
      console.log("Add device Info: name changed",id, name, modeltype);
    }
  }

  const handleShowStatsList = () => (
    setShowStatsList(!showStatsList)
  )

  const handleEditDeviceShow = (device) => {
    setDeviceToEdit(device);
    setShowEditDevice(true);
  }


  const handleQRCodeShow = (device) => {
    setDeviceToEdit(device);
    setShowQRCode(true);
  }

  const handleDeleteRecord = (device) =>
  {
    setDeviceToEdit(device);
    setShowConfirmDelete(true);
  }
  
  const doHandleEditDeviceUpdate = (device, name , userDevName, modeltype) => {
    setShowEditDevice(false);
    handleEditDeviceUpdate(device, name , userDevName, modeltype , props.onUpdateDeviceName, props.onUpdateUserDeviceName);
  }

  const handleArchiveRecord = (device) =>
  {
    setDeviceToEdit(device);
    console.log( "canged Archived Toggle",device)
    updateDeviceArchivedStatus(device.uniqueDeviceId, !device.archived)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    allDevices.next();
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    allDevices.prev();
  }

  const handlePageChange = (newPage, prevPage) => {
    console.log("handlePageChange: ", prevPage, " -> ", newPage);
    setCurrentPage(newPage);
    allDevices.jump(newPage);
  }

  const onNumPerPageChanged = (value) => {
    setNumPerPage(value);
    if(currentPage !== 1) {
      handlePageChange(1, currentPage);
    }
  }



  async function updateDeviceArchivedStatus(deviceId, archived ) {
    let record_ID = getRecordIDFromUID( deviceId);
    const result = await client.graphql({
      query: updateDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.INFO,
          recordID: record_ID, 
          archived: archived === true ? "archived" : "not archived",
          limit: apiLimit
        }
      }
    });

    if ( result !== undefined && result !== null)
    {
      props.onUpdateDeviceLocalArchiveStatus(deviceId, result.data.updateDevice.archived);
      console.log("updating archived status:", result, result.data.updateDevice.archived);
    }
  }

  const getRowNumber = (index) => {
    return ((currentPage - 1) * numPerPage) + index;
  }

  const handleQRDeviceUpdate = () => {
    setShowQRCode(false);
  }

  const onSelectedFilterChanged = (value) => {
    props.onSelectedFilterChanged(value);
    // if(currentPage !== 1) {
    //   handlePageChange(1, currentPage);
    // }
  }


  const DeviceCard = ( {theDevice, idx,  showAll}) => 
  {
    const [showMore, setShowMore] = useState(showAll);

    const buildUniqueKey = ( ) =>
    {
      return theDevice.uniqueDeviceId+getRandomIntInclusive(0,1000)
    }

    return (
    <Card key={buildUniqueKey} decoration="left" decorationColor={theDevice.verColor}>
      <Grid
        numItemsSm={2}
        numItemsMd={2}
        numItemsLg={2}
        className="gap-4"
        >
        <Flex justifyContent="start" className="gap-2">
        <Badge > <Bold>  {getRowNumber(idx+1)}</Bold></Badge>
        </Flex>
        <Flex justifyContent="end" className="gap-2">
          {
            GetDeviceBadgeFromStatus(theDevice)
          }
        </Flex>
      </Grid>
      <FlexboxGrid 
        justify="space-between"
        align="middle"
        spacing={4}
        style={{ marginTop: '12px' }}
      >
        <FlexboxGrid.Item as={Col} colspan={6} md={6}>
          <div className="tooltip">
            <img src={getControllerIconText_FROM_STRING(theDevice.modelType).icon} alt="Controller Icon" width="100" height="100" />
            <span className="tooltiptext">{theDevice.modelType}</span> 
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={16} md={6}>
          <List hover size="sm">
            <List.Item key={getRandomIntInclusive(0,1000)}>
              <Flex justifyContent="center">
                  <Flex justifyContent="start" className="space-x-4">
                  <ScaleText maxFontSize={20}>{theDevice.userName}</ScaleText>
                  </Flex>
              </Flex>
            </List.Item>
            {(theDevice.usersDeviceName !== 'Undefined') &&
            <List.Item key={getRandomIntInclusive(0,10000)}>
              <Flex justifyContent="center">
                  <Flex justifyContent="start" className="space-x-4">
                  <ScaleText maxFontSize={10}>{theDevice.usersDeviceName}</ScaleText>
                  </Flex>
              </Flex>
            </List.Item>
            }
            <List.Item key={1}>
              <FlexboxGrid
                align="middle"
                justify="space-between"
                style={{ fontSize: '0.9em' }}
              >
                <FlexboxGrid.Item>
                  <TremorButton
                    icon={IconFingerPrint}
                    iconPosition="right"
                    color={COLOURS.default}
                    size="sm"
                    variant="light"
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <Bold>  {"UID:" + theDevice.uniqueDeviceId}</Bold>
                </FlexboxGrid.Item>
                
                <FlexboxGrid.Item>
                  <IconButton
                    appearance="primary"
                    color="blue"
                    size="xs"
                    
                    icon={showMore ? <IconLess /> : <IconMore />}
                    onClick={() => setShowMore(!showMore)}
                  />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </List.Item>
            {showMore &&
              <List.Item key={4}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconBarcode}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"SN:" + theDevice.serialNumber}
                  </FlexboxGrid.Item> 
                  
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={5}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconInfo}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {GetBadgeForversion(theDevice)}
                  </FlexboxGrid.Item> 
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={6} >
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconWarning}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"Faults:" + build_fault_string( theDevice.currentFaults , true, "Nil").string}
                  </FlexboxGrid.Item> 
                </FlexboxGrid>
              </List.Item>
            }

            {showMore &&
              <List.Item  key={7}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconHammer }
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"Date Made:" + theDevice.dateMade}
                </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={8}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconInfo}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"IoVersion:" + theDevice.ioVersion}
                </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={9}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconInfo}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"Exp 1 Version:" + theDevice.exp1Version}
                </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={10}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconInfo}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"Exp 2 Version:" + theDevice.exp2Version}
                </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            }
            {showMore &&
              <List.Item  key={11}>
                <FlexboxGrid
                  align="middle"
                  justify="space-between"
                  style={{ fontSize: '0.9em' }}
                >
                  <FlexboxGrid.Item>
                    <TremorButton
                      icon={IconInfo}
                      iconPosition="right"
                      color={COLOURS.default}
                      size="sm"
                      variant="light"
                    
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                  {"Chem Version:" + theDevice.chemVersion}
                </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            }

          </List>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <div className="text-center" style={{ marginTop: '12px' }}>
        <FlexboxGrid justify="space-around"
          align="middle"
          spacing={4}
          style={{ marginTop: '12px' }}
        > 
          <FlexboxGrid.Item justify="end"  align="middle" className="gap-2">
            <ButtonToolbar>
              <Whisper placement="top" 
                    trigger="hover" 
                    speaker= { <Tooltip>
                    Edit This Device info
                    </Tooltip>}
              >
                <IconButton
                    appearance= "primary" 
                    color="blue"
                    size="sm"
                    icon={<IconEdit/>}
                    onClick={() => handleEditDeviceShow(theDevice)}
                />
              </Whisper>
              <Whisper placement="top" 
                    trigger="hover" 
                    speaker= { <Tooltip>
                    Select This Device
                    </Tooltip>}
              >
                <IconButton
                    appearance= "primary" 
                    color="blue"
                    size="sm"
                    startIcon={<IconPoint/>}
                    onClick={() => props.onViewDevice(theDevice.uniqueDeviceId)}
                />
              </Whisper>

              <Whisper placement="top" 
                      trigger="hover" 
                      speaker= { <Tooltip>
                      Show the QR Code for this Device.
                      </Tooltip>}
                >
                <IconButton
                    appearance= "primary" 
                    color="blue"
                    size="sm"
                    icon={<IconQRCode/>}
                    onClick={() => handleQRCodeShow(theDevice)}
                    
                />
              </Whisper>
              <Whisper placement="top" 
                      trigger="hover" 
                      speaker= { <Tooltip>
                    Archive this Device.
                    </Tooltip>}
              >
                <IconButton
                appearance="primary"  //{theDevice.archived ?  "primary" : 'default' }
                color="blue"
                size="sm"
                icon={theDevice.archived ? <IconArchive/>:<IconArchive2/>}
                // icon={theDevice.archived ? <IconTicked /> : <IconUnticked />}
                onClick={() => handleArchiveRecord(theDevice)}
                >
                </IconButton>
              </Whisper>
              <Whisper placement="top" 
                      trigger="hover" 
                      speaker= { <Tooltip>
                    Delete this Device.
                      </Tooltip>}
                >
                <IconButton
                  appearance= "primary" 
                  color="blue"
                  size="sm"
                  icon={<IconRubbish/>}
                  onClick={() => handleDeleteRecord(theDevice)}
                  
                ></IconButton>
              </Whisper>
            </ButtonToolbar>
          </FlexboxGrid.Item>
        </FlexboxGrid> 
      </div>
    </Card>
    );
  }


  async function addDevice(deviceId, name, loginId, devType) 
  {
    // if (props.userGroups.length !== 0) {
      let record_ID = getRecordIDFromUID( deviceId)
      var deviceText = "Unknown"
      if ( devType !== undefined)
      {
         deviceText = getControllerIconText(devType).Text
      }
      console.log("addDevice:", record_ID, "type", deviceText);
      let result;

      try {
          result = await client.graphql({
          query: createDevice,
          variables: {
            input: {
              recordType: RECORD_TYPES.ACCESS + loginId,
              recordID: record_ID,
              userName: name,
            }
          }
        })

      } catch (err) {
          console.log("Error Adding a Device", err);
          return err;
      }
      console.log("add:", result);
      props.onRefresh();
    // }
  }


  async function deleteAccessRecord(record_ID, userId ) {    
    const result = await client.graphql({
      query: deleteDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.ACCESS+ userId,
          recordID: record_ID
        }
      }
    });
    console.log("Deleting access record", record_ID, result)
    props.onRefresh();
  }

  async function createInfoRecord(deviceId, name , type) {
    let record_ID = getRecordIDFromUID( deviceId);

    console.log ( " createInfoRecord name :", record_ID , name, type);
      const result = await client.graphql({
      query: createDevice,
      variables: {
        input: {
          recordType: RECORD_TYPES.INFO,
          recordID: record_ID, 
          userName: name,
          modelType: type
        }
      }
    });
    console.log("info record created:", result);
    props.onRefresh();
    return result;
  }

  const elementStyle = {
    zIndex: 2011
  };

  return (
    <div className="mt-3 gap-2 space-y-4" >
      <Grid
        numItemsSm={3}
        numItemsMd={3}
        numItemsLg={3}
        className="gap-2"
      >
        <Col>
        <ButtonToolbar>
          {/* <Flex justifyContent="start" className="gap-2"> */}
           {/* <Button color={"blue"} size="sm" startIcon={<IconRefresh/>} onClick={props.onRefresh}>Refresh</Button>  */}
            <Button appearance="primary" color="blue" endIcon={<IconRefresh />} size="sm" loading={props.loading} onClick={props.onRefresh}>Refresh</Button>
            <Button appearance="primary" color={"blue"} size="sm" onClick={() => setShowAddDevice(true)}>Add a Device </Button>
            <Button appearance="primary" color={"blue"} size="sm" onClick={() => setShowAddDeviceInfoRecord(true)}>Add Info Record</Button>
          {/* </Flex> */}
        </ButtonToolbar>
        </Col>
        <Col>
        <div className="my-element" style={elementStyle}>
          Filter:
          <Cascader
          z-index={1023}
          data={getFilterOptionsData()}
          columnWidth={300}
          columnHeight={144}
          onChange={(value) => onSelectedFilterChanged(value)}
          placeholder="Filter By"
          searchable={false}
          size="md"
          value={props.selectedFilter}
          />
          </div>
        </Col>
        <Col>
          <Flex justifyContent="end" className="gap-2">
            Display:
            <TabGroup
              defaultIndex={props.selectedView}
              onIndexChange={(value) => props.onSelectedViewChanged(value)} >
              <TabList variant="solid" color={COLOURS.default}>
                <Tab>Grid</Tab>
                <Tab>List</Tab> 
                <Tab>Stats</Tab> 
                <Tab>Settings</Tab> 
                {/* <Tab>List 2</Tab>  */}
              </TabList>
            </TabGroup>
          
          Show Archived:
            <Checkbox  
            defaultChecked = {props.showArchivedDevices}
            checked={props.showArchivedDevices} 
            value = {12}
            color="blue" 
            onChange={ handleSetSHowArchivedDevices}
            >
             
            </Checkbox>
            <SelectItemsPerPage
            totalItems={filteredDevices.length}
            itemsPerPage={numPerPage}
            onChange={onNumPerPageChanged}
          />
        </Flex>
        </Col>
        
      </Grid>

      {props.loading === true ?( <LoadingView progress={props.progress}></LoadingView>) : (props.selectedView === selectedView_t.GRID? (
          <div>
       
          <Grid
            numItemsSm={1}
            numItemsMd={4}
            numItemsLg={4}
            className="gap-4"
          >
            {allDevices.currentData().map((device,index) => {
              return (
                      (device.archived === false || ( device.archived === true && props.showArchivedDevices)) && (
                      <DeviceCard  
                        theDevice = {device} 
                        idx = {index}
                        key = {device.uniqueDeviceId}
                      >
                      </DeviceCard>
                      )
                      );
            })}
          </Grid>       
            {(totalPages > 1) &&        
            <div className="mt-3">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePrevPage}
                onChange={handlePageChange}
                className="table-pagination"
              />
            </div>}
          </div>
          ):(props.selectedView === selectedView_t.STATS ? 
            (
              <div>
                <StatsView 
                stats={props.devicesStats}
                showList={showStatsList}
                loading={props.loading}
                progress={props.progress}
                onRefresh={props.onRefresh}
                onShowListChanged={handleShowStatsList}
                />
                </div>
            ) : (props.selectedView === selectedView_t.LIST)?
            (
              <CollectionHooksTable devices={filteredDevices}
              onSelectionChange={props.onSelectedDevicesChanged}
              onViewDevice={ props.onViewDevice}
              onQRCodeClick={handleQRCodeShow}
              onEditDevice={handleEditDeviceShow}/>
            ):
            (
              <div>
                <UserView
                stats={props.devicesStats}
                showList={showStatsList}
                loading={props.loading}
                progress={props.progress}
                onRefresh={props.onRefresh}
                onShowListChanged={handleShowStatsList}
                />
                </div>
            )
          )
        )
      }
        
        <AddDevice
          open={showAddDevice}
          handleClose={handleAddDevice}
        />
        <AddDeviceInfo
          open={showInfoDevice}
          handleClose={handleAddDeviceInfo}
        />
        <EditDevice
          open={showEditDevice}
          device={deviceToEdit}
          handleClose={doHandleEditDeviceUpdate}
        />
        <ShowDeviceQR
          open={showQRCode}
          device={deviceToEdit}
          handleClose={handleQRDeviceUpdate}
        />
        <ShowDeviceDeleteConfirmation
        open={showConfirmDelete}
        device={deviceToEdit}
        handleClose={handleDeviceDeleteUpdate}
        />
        
    </div>
  );
}