

import { 
  get_high_low,
} from "./DisplayItems";

import {
  format as DTformat
} from "date-fns";

import { isAsciiPrintable } from "../utils/utilities";
import {

  datetimeFormats,
  offOnOptions,
  DisableEnableOptions,
  
  spaModeStates,
  pump_states_t,
  solar_states_t,
  pump_triggers_t,
  heater_display_state_t,
  outputsTable,
  solar_type_t,
  thermistor_t,
  appliance_status_t,
  spaSizeOptions, 
  poolSizeOptions,
  socket_types,
  modelTypeDetails,
  spaBlowerTimeOutOptions,
  // dashTabItems,
  locations,
  vfTypesEnum,
  ReverseDisableEnableOptions,
} from "../constants";

import 
{
  getFaultTableItem,
  
} from "./Common";

import {
  regType,
  REGS,

} from "../registers";

export const padDigits = (value, padding) => {
  return value.toString().padStart(padding, '0');
}

export const temperatureValueToString = (value, unit) => {
  return (value / 2.0).toFixed(1).concat(" ", unit).trim();
}

export const formatSensorOffset = (value, unit) => {
  console.log( value, value-15)
  return (value - 15, unit);
}

export const formatTemperature = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = temperatureValueToString(value, unit)
  }
  return str;
}

export const formatPH = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = (value / 10).toFixed(1).trim();
  }
  return str;
}




export const formatORP = (value, unit) => {
  let str = offOnOptions[0].label;
  if (value !== 255) {
    str = (value * 10).toFixed(1).trim();
  }
  return str;
}


export const formatTime = (value) => {

  let hl = get_high_low(value)
  if (hl.high < 24 && hl.low < 60) {
    return padDigits(hl.high, 2) + ":" + padDigits(hl.low, 2);
  } else {
    return "--:--";
  }
}

export const formatDuration = (value, unit) => {

  let hl = get_high_low(value)
  if (hl.high <= 24) {
    return (hl.high + " " + unit).trim();
  } else {
    return "--";
  }
}
//const endpoint = "wss://a254daig9zo2wn-ats.iot.amazonaws.com/mqtt";
export const formatHex = (value) => {
  let hex_value = (value).toString(16)
  return ("0x" + padDigits(hex_value, 4));
}

export const getVFType = (offset, rawData) =>
{
  var result = 0
  if ( offset === 0)
  {
    if ( rawData.hasOwnProperty(REGS.VFCONTACT_1_TYPE) )
    {
      result = rawData[REGS.VFCONTACT_1_TYPE];
    }
  }
  else if ( offset === 1)
  {
    if ( rawData.hasOwnProperty(REGS.VFCONTACT_2_TYPE) )
    {
      result = rawData[REGS.VFCONTACT_2_TYPE];
    }
  }
  return result;
}

export const getFilterTimerEnabled = ( offset, rawData ) =>
{
  var filterTimerEnabled = false
  if ( rawData.hasOwnProperty(REGS.FILTER_TIME_ENABLE) )
  {
    const filterEnabledValue = rawData[REGS.FILTER_TIME_ENABLE];
    console.log("filterEnabledValue", filterEnabledValue)
    const bit_field = (1 << offset)
    if ((filterEnabledValue & bit_field) === bit_field) 
    {
      filterTimerEnabled = true;
      console.log(" filter time enabled")
    }
  }
  return filterTimerEnabled;
}


/*!

*/
export const getEnabledState = (regData, rawData) => {
  const address = regData.address;
  var enabled = true;

  switch (address) 
  {
    case REGS.GAS_HEATER_ENABLE:
      if ( getVFType(0,rawData) !== vfTypesEnum.GAS_HEATER)
      {
        enabled = false;
      }
    break;
    case REGS.HEAT_PUMP_ENABLE:
      const heatpumpType = getVFType(0,rawData);
      if ( ( heatpumpType !== vfTypesEnum.HEAT_PUMP)
      && (   heatpumpType !== vfTypesEnum.SMART_HEATER))
      {
        enabled = false;
      }
    break;

    case REGS.GAS_HEATER_2_ENABLE:
      if ( getVFType(1,rawData) !== vfTypesEnum.GAS_HEATER)
      {
        enabled = false;
      }
    break;
    case REGS.HEAT_PUMP_2_ENABLE:

      const heatpump2Type = getVFType(1,rawData);
      if ( ( heatpump2Type !== vfTypesEnum.HEAT_PUMP)
      && (   heatpump2Type !== vfTypesEnum.SMART_HEATER))
      {
        enabled = false;
      }
    break;

    case REGS.FILTER_TIME_1_START_TIME:
      enabled = getFilterTimerEnabled( 0, rawData)
    break;
    case REGS.FILTER_TIME_2_START_TIME:
      enabled = getFilterTimerEnabled( 1, rawData)
    break;
    case REGS.FILTER_TIME_3_START_TIME:
      enabled =getFilterTimerEnabled( 2, rawData)
    break;
    case REGS.FILTER_TIME_4_START_TIME:
      enabled = getFilterTimerEnabled( 3, rawData);
    break;
    default:
    break;
  }
  return enabled;
}

export const getLightNumber = (regData, rawData) =>
{
  const address = regData.address;
  var theResultLightCount = 0;
  var found = false;
  switch (address) 
  {
    case REGS.SOCKET_1_TYPE:
    case REGS.SOCKET_2_TYPE:
    case REGS.SOCKET_3_TYPE:
    case REGS.SOCKET_4_TYPE:
    case REGS.SOCKET_5_TYPE:
    case REGS.SOCKET_6_TYPE:
    case REGS.SOCKET_7_TYPE:
    case REGS.SOCKET_8_TYPE:
      const allSockets = [REGS.SOCKET_1_TYPE, REGS.SOCKET_2_TYPE, REGS.SOCKET_3_TYPE, REGS.SOCKET_4_TYPE,
        REGS.SOCKET_5_TYPE, REGS.SOCKET_6_TYPE, REGS.SOCKET_7_TYPE, REGS.SOCKET_8_TYPE];

      if (allSockets.every(v => rawData.hasOwnProperty(v))) 
      {
        var lightCountm = 0;
        for (let index = 0; index < 8; ++index) 
        {
          let offset = allSockets[index];
          let value = rawData[offset];
          if (value === socket_types.POOL_LIGHT 
            || value === socket_types.SPA_LIGHT)
          {
            lightCountm ++;
          }
          if ( address === offset)
          {
            theResultLightCount = lightCountm;
          }

        }
      }
      found = true;
    break;
    default: break;
  }
  return { isFound: found, number: theResultLightCount};
}


export const format = (regData, rawData) => {
  const address = regData.address;
  const value = rawData[address];
  let str = value.toString().concat(" ", regData.unit).trim();

  if (regData.type === regType.temperature) {
    str = formatTemperature(value, regData.unit);
  } else if (regData.type === regType.time) {
    str = formatTime(value);
  } else if (regData.type === regType.duration) {
    str = formatDuration(value, regData.unit);
  }
  else if (regData.type === regType.off_on)
  {
    str = ( value === 0)? offOnOptions[0].label:  offOnOptions[1].label;
  } 
  else if (regData.type === regType.dis_en)
  {
    str = ( value === 0)? DisableEnableOptions[0].label:  DisableEnableOptions[1].label;
  }
  else if (regData.type === regType.pump_speed)
  {
    str =  "Speed: " + ( value +1);
  }
  else if (regData.type === regType.lookup)
  {
    str = "None"
    let lookup =  regData.lookup_table.find(element => element.value === value)
    if ( lookup !== undefined)
    {
      str = lookup.label
    }
     console.log("lookup Array for ",regData.address, value, str)
  }
   
  else { // other
    var hl = 0;
    var builds = 0;
    switch (address) {

      case REGS.SPA_MODE:
        if (value < spaModeStates.length) {
          str = spaModeStates[value];
        }
        break;

      case REGS.PH_MEAS:

        let the_ph_number = Number(value)
        if ( the_ph_number === 0xFD)
        {
          str = "Not Yet Valid"
        }
        else if ( the_ph_number === 0xFC )
        {
          str = "Sensor Missing"
        }
        else if ( the_ph_number === 0xFE)
        {
          str = "pH > 8.5"
        }
        else if ( the_ph_number === 0xFF ) 
        {
          str = "pH < 5.5"
        }
        else
        {
          let pH=value/10;
          str = pH;
        }
        break;
      case REGS.PH_CURR:
      case REGS.PH_SET_POINT:
      case REGS.PH_SET_POINT_INSTALLER:
        let pH=value/10;
        str = pH;
        break;
      case REGS.ORP_CURR:
      case REGS.ORP_SET_POINT:
      case REGS.ORP_SET_POINT_INSTALLER:
        str = value * 10;
        break;
      case REGS.ORP_MEAS:

        let the_number = Number(value)
        if ( the_number === 0xFD)
        {
          str = "Not Yet Valid"
        }
        else if ( the_number === 0xFC )
        {
          str = "Sensor Missing"
        }
        else if ( the_number === 0xFE)
        {
          str = "ORP > 800"
        }
        else if ( the_number === 0xFF ) 
        {
          str = "ORP < 300"
        }
        else
        {
          str = value * 10;
        }
        break;
      case REGS.SET_MODEL:
      case REGS.MODEL:
        let model_value = value;
        modelTypeDetails.forEach((element)=>
        {
          if ( model_value === element.value)
          {
            str = element.label;
          }
        }
        )
      break;
      case REGS.HARDWARE_VERSION:
        hl = get_high_low(value)
        str = "V" + hl.high + "." + hl.low;
        break;
      case REGS.FIRMWARE_VERSION:
        hl = get_high_low(value)
        str = hl.high + "." + padDigits(hl.low, 2);
        if (rawData.hasOwnProperty(REGS.FIRMWARE_BUILDS)) {
          builds = get_high_low(rawData[REGS.FIRMWARE_BUILDS]);
          str = str + "B" + builds.high; // ((rawData[REGISTERS.FIRMWARE_BUILDS] & 0xFF00) >> 8);
        }
        //  console.log("Firmware Version", address, "=>", str);
        break;
      case REGS.EXPANSION_PLUS_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXPANSION_PLUS_BUILD)) {
            builds = get_high_low(rawData[REGS.EXPANSION_PLUS_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;

      case REGS.EXP1_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXP1_BUILD)) {
            builds = get_high_low(rawData[REGS.EXP1_BUILD]);
            str = str + "B" + builds.high;
          }
        }

        break;
      case REGS.EXP2_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.EXP2_BUILD)) {
            builds = get_high_low(rawData[REGS.EXP2_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;

      case REGS.FAULT_FLAGS:
        str = formatHex(value)
        let largeValue = value;
        if (rawData.hasOwnProperty(REGS.FAULT_FLAGS_2)) {
          str = str + " " + (formatHex(rawData[REGS.FAULT_FLAGS_2] & 0xFF00));
          largeValue = largeValue + ( rawData[REGS.FAULT_FLAGS_2]<<16);
        }
        console.log("Faults", address, "=>", str);

        str = "";
        for (let index = 0; index < 16; index++) {
          const bit_field = (1 << index)
          if ((largeValue & bit_field) === bit_field)  
          {
            const faultItem = getFaultTableItem(bit_field)
            if ( null != faultItem)
            {
              str += faultItem.label + "\n";
            }
          }
          // if ((largeValue & bit_field) === bit_field)
          // {
          //   str += fault_strings[bit_field] + "\n";
          //   console.log("Faults string built: ", str, value, bit_field)
          // }
        }
        break;
      case REGS.IOCTRL_VERSION:
        str = "---";
        if (value !== 0) {
          hl = get_high_low(value)
          str = hl.high + "." + padDigits(hl.low, 2);
          if (rawData.hasOwnProperty(REGS.IOCTRL_BUILD)) {
            builds = get_high_low(rawData[REGS.IOCTRL_BUILD]);
            str = str + "B" + builds.high;
          }
        }
        break;


      case REGS.POOL_SET_TEMPERATURE:
        let Temperature = value / 2;
        str = Temperature;
        break;
      case REGS.SPA_SET_TEMPERATURE:
        let STemperature = value / 2;
        str = STemperature;
        break;


      case REGS.PUMP_STATE:
        str = "None"
        let pump_state = get_high_low(value)
        str = pump_state.high + " : " + pump_states_t[pump_state.high]
        console.log("pump_state", pump_state.high, str)
        if (pump_state.high === pump_states_t["RUNNING"]) {
          str += " @ Speed " + (pump_state.low + 1)
        }
        break;
      case REGS.POOL_SIZE:
        if ( value <= poolSizeOptions.length)
        {
          str = poolSizeOptions[value].label
        }
        break;
        case REGS.SPA_SIZE:
        if ( value <= spaSizeOptions.length)
        {
          str = spaSizeOptions[value].label
        }
        break;

      case REGS.THERMISTOR_1_TYPE:
        str = value + ":" + thermistor_t[value]
        // console.log("Ther1 type State", value, str)
        break;
      case REGS.THERMISTOR_2_TYPE:
        str = value + ":" + thermistor_t[value]
        // console.log("Ther2 type State", value, str)
        break;
      case REGS.THERMISTOR_3_TYPE:
        str = value + ":" + thermistor_t[value]
        // console.log("Ther3 type State", value, str)
        break;

      case REGS.THERMISTOR_1_OFFSET:
      case REGS.THERMISTOR_2_OFFSET:
      case REGS.THERMISTOR_3_OFFSET:
      case REGS.THERMISTOR_4_OFFSET:
        str = formatSensorOffset(value, regData.unit);
        break;
      case REGS.HEATER_STATE:
        str = "None"
        str = value + ":" + heater_display_state_t[value]
        console.log("heater_state", value, str)
        break;
      case REGS.HEATER_2_STATE:
        str = "None"
        str = value + ":" + heater_display_state_t[value]
        console.log("heater_ 2 state", value, str)
        break;


      case REGS.SOCKET_1_STATUS:
      case REGS.SOCKET_2_STATUS:
      case REGS.SOCKET_3_STATUS:
      case REGS.SOCKET_4_STATUS:
      case REGS.SOCKET_5_STATUS:
      case REGS.SOCKET_6_STATUS:
      case REGS.SOCKET_7_STATUS:
      case REGS.SOCKET_8_STATUS:
      case REGS.VALVE_1_STATUS:
      case REGS.VALVE_2_STATUS:
      case REGS.VALVE_3_STATUS:
      case REGS.VALVE_4_STATUS:
      case REGS.VF_CONTACT_1_STATUS:
      case REGS.VF_CONTACT_2_STATUS:
        // let offset = address-REGISTERS.SOCKET_1_STATUS;
        if (value <= 3) {
          str = appliance_status_t[value];
        }
        break;


      case REGS.SOLAR_STATE:
        let solar_state = get_high_low(value)
        str = solar_state.low + ":" + solar_states_t[solar_state.low]
        console.log("solar State", solar_state.low, str)
        break;
      case REGS.BLOWER_TIMEOUT_ENABLED:
        if ( value <= spaBlowerTimeOutOptions.length)
        {
          str = spaBlowerTimeOutOptions[value].label
        }
      break;

      case REGS.PUMP_TRIGGER:
        str = "";
        if (value === 0) {
          str = "None";
        }
        else {
          for (let index = 0; index < 16; index++) {
            const bit_field = (1 << index)
            if ((value & bit_field) === bit_field) {
              str += pump_triggers_t[bit_field] + "|";
              console.log("pump trigger", str, value, bit_field)
            }
          }
        }
        // console.log ( "pump trigger",  str, value, )
        break;
      case REGS.SOLAR_TYPE:

        str = value + ":" + solar_type_t[value]
        console.log("solar Type", value, str)
        break;

      case REGS.RTC_YEAR:
        const rtcRegs = [REGS.RTC_MONTH, REGS.RTC_DAY, REGS.RTC_HOUR, REGS.RTC_MIN];//, REGISTERS.RTC_SEC ];
        if (rtcRegs.every(v => rawData.hasOwnProperty(v))) {
          const dt = new Date(rawData[REGS.RTC_YEAR], rawData[REGS.RTC_MONTH] - 1, rawData[REGS.RTC_DAY], rawData[REGS.RTC_HOUR], rawData[REGS.RTC_MIN], 0); // , rawData[REGISTERS.RTC_SEC]
          str = DTformat(dt, datetimeFormats.mid);
          console.log("Date Time", dt, str)
        }
        else {
          console.log("Date Time not every ", str)
        }
        break;

      case REGS.LOCATION:
        if(value < locations.length) {
          str = locations[value];
        }
        break;

      case REGS.APPLIANCE1_NAME_START:
      case REGS.APPLIANCE2_NAME_START:
      case REGS.APPLIANCE3_NAME_START:
      case REGS.APPLIANCE4_NAME_START:
      case REGS.APPLIANCE5_NAME_START:
      case REGS.APPLIANCE6_NAME_START:
      case REGS.APPLIANCE7_NAME_START:
      case REGS.APPLIANCE8_NAME_START:
      case REGS.APPLIANCE9_NAME_START:
      case REGS.APPLIANCE10_NAME_START:
      case REGS.APPLIANCE11_NAME_START:
      case REGS.APPLIANCE12_NAME_START:
      case REGS.APPLIANCE13_NAME_START:
      case REGS.APPLIANCE14_NAME_START:
      case REGS.APPLIANCE15_NAME_START:
      case REGS.APPLIANCE16_NAME_START:
      case REGS.APPLIANCE17_NAME_START:
      case REGS.APPLIANCE18_NAME_START:
      case REGS.APPLIANCE19_NAME_START:
      case REGS.DEVICE_NAME_START:
      case REGS.POOL_NAME_START:
      case REGS.SPA_NAME_START:
        
        const appNameRegisters = [address,address+1,address+2,address+3,address+4,address+5,address+6,address+7];
        if ( appNameRegisters.every(v => rawData.hasOwnProperty(v) ) )
        {
          var app_name = get_high_low(value);
          let tmpName = "";
          // if(!isAsciiPrintable(app_name.high)) {
          //          break;
          // }
          // tmpName = tmpName.concat(String.fromCharCode(ch));
          // if(!isAsciiPrintable(app_name.low)) 
          // {
          //   break;
          // }
          // str = String.fromCharCode(app_name.high) + String.fromCharCode(app_name.low);

          for (let index = 0; index < 8; ++index) 
          {
            let offset = address+index;
            if (rawData.hasOwnProperty(offset)) 
            {
              app_name = get_high_low(rawData[offset]);
              if(!isAsciiPrintable(app_name.high)) 
              {
                break;
              }
              tmpName = tmpName.concat(String.fromCharCode(app_name.high));

            
              if(!isAsciiPrintable(app_name.low)) 
              {
                break;
              }
              tmpName = tmpName.concat(String.fromCharCode(app_name.low));
              // str = str + String.fromCharCode(app_name.high) + String.fromCharCode(app_name.low);
            }
          }
          str = tmpName;
        }
        else
        {
          str = ""
        }

        

        // case registerAddress.DEVICE_NAME:
        // let nameRegs = [];
        // for(let i = 1; i < DEFAULTS.maxRegistersForName; i++) {
        //   nameRegs = nameRegs.concat(registerAddress.DEVICE_NAME + i);
        // }
        // if(nameRegs.every(v => rawData.hasOwnProperty(v))) {
        //   let tmpName = "";
        //   for(let i = 0; i < DEFAULTS.maxRegistersForName; i++) {
        //     const tmp = rawData[registerAddress.DEVICE_NAME + i];
        //     let ch = (tmp & 0xFF00) >> 8;
        //     if(!isAsciiPrintable(ch)) {
        //       break;
        //     }
        //     tmpName = tmpName.concat(String.fromCharCode(ch));
        //     ch = (tmp & 0x00FF);
        //     if(!isAsciiPrintable(ch)) {
        //       break;
        //     }
        //     tmpName = tmpName.concat(String.fromCharCode(ch));
        //   }
        //   str = tmpName;
        // } else {
        //   str = "";
        // }
        // break;

        // console.log("App Name ", str)
        break;
      case REGS.SOLAR_WINTER_NEXT_MINS:
        var minutes_till_next = value;
        let days = Math.floor( minutes_till_next / (24*60))
        minutes_till_next = value%(24*60);
        let hours = Math.floor( minutes_till_next /60);
        minutes_till_next = minutes_till_next%(60);
        let mins = minutes_till_next;
        str = String( days + " days " + hours + " hours "+ mins + " mins")
      break;
      case REGS.SMART_HEATER_FUNCTION:
      case REGS.HEATER_1_USES_SH_SENS:  
      case REGS.HEATER_2_USES_SH_SENS: 
      case REGS.HEATER_1_MOVE_ON:                 
      case REGS.HEATER_2_MOVE_ON:                
      case REGS.EXP4_SLAVE1_TYPE:              
      // case REGS.EXP4_SLAVE2_TYPE:              
      case REGS.EXPANSION4_PRESENT:             
      console.log("SMART HEATER STUFF:", address, "=>", str);
        break;


      case  REGS.OUTPUTS_STATUS:

        let number_outputs = 0;
        let idx_max = 13;
        str = "";
        for (let index = 0; index < idx_max; index++) 
        {
          const bit_field = (1 << index)
          let theOutput = outputsTable.find( (item) => item.value === bit_field );
          if ( theOutput )
          {
            // if ( number_outputs !== 0)
            // {
            //   str += " , "
            // }
            // if ( use_short_strings === true)
            // {
              str += theOutput.short_label;
            // }
      
            number_outputs++;
            if ((value & bit_field) === bit_field)  
            {
              str += ":ON";
            }
            else 
            {
              str += ":OFF";
            }
            str += "|";
          }
          
        }

        break;
      case REGS.EXPANSION_PLUS_WIFI_RSSI:
      case REGS.WIFI_RSSI:
        let tmp = new Uint16Array(1);
        tmp[0] = value;
        tmp = (tmp > 0x7FFF) ? (tmp - 0x10000) : tmp;
        str = tmp + " " + regData.unit;
        break;
      case REGS.PUMP_PRIME_DISABLE:
          str = ( value === 0)? ReverseDisableEnableOptions[0].label:  ReverseDisableEnableOptions[1].label;
      break;
      default:
        // console.log("No custom formatting for register", address, "=>", str);
        break;
    }
  }
  return str;
}


// export const handleReceivedData = (topic, data) => {
//   const { value } = data;
//   const topicFromData =  data.value[Object.getOwnPropertySymbols(data.value)[0]] //data.value.topic;
//   const topfromData2 = data.value.topicSymbol
//   console.log("data Rec", data, value, topicFromData, topfromData2 );
  
//   if ( (topicFromData === topic) || (topfromData2 === topic) ){
//     const { messageId, modbusReg, modbusVal } = value;
//     console.log("received message", messageId, modbusReg, modbusVal);

//     const rawData = {};
//     if ((messageId === "read") && (modbusReg === 1) && (modbusVal.length % 2 === 0)) {
//       for (let i = 0; i < modbusVal.length; i += 2) {
//         const address = modbusVal[i];
//         const value = modbusVal[i + 1];
//         rawData[address] = value;
//       }
//     } else {
//       for (let i = 0; i < modbusVal.length; i++) {
//         const address = modbusReg + i;
//         const value = modbusVal[i];
//         rawData[address] = value;
//       }
//     }

//     for (let i = 0; i < registerData.length; i++) {
//       const address = registerData[i].address;
//       if (address in rawData) {
//         const fmtVal = format(registerData[i], rawData);
//         setRegisterData(prevState => {
//           const newState = prevState.map(obj => {
//             if (obj.address === address) {
//               return { ...obj, value: rawData[address], display: fmtVal };
//             }
//             return obj;
//           });
//           return newState;
//         });
//       }
//     }
//   } else {
//     console.log("topic mismatch, expected", topic, "received", topicFromData);
//   }
// }







//   // const updateSelectedTab = (value) => {
//   //   setSelectedTab(value);
  
//   //   switch (value) {
//   //     case dashTabItems.control:
//   //       break;
//   //     case dashTabItems.heating:
      
//   //       break;
//   //     case dashTabItems.solar:
       
//   //       break;
//   //     case dashTabItems.pump:
       
//   //       break;
//   //     case dashTabItems.chemical:
//   //       break;
//   //     default:
//   //       break;
//   //   }
//   // }
  

//   // let initialDeviceNames = [
//   //   "","",""
//   // ];
  
//   // const [deviceNames, setDeviceName] = useState(
//   //   initialDeviceNames
//   // );
  
//   // const SetTheDeviceName = (thename, the_index) => {
    
//   //     const nextNames = deviceNames.map((c, i) => {
//   //       if (i === the_index) {
//   //         return thename;
//   //       } else {
//   //         // The rest haven't changed
//   //         return c;
//   //       }
//   //     });
//   //     setDeviceName(nextNames);
//   // }


//   // const [deviceNames, setName] = useState(deviceNamesArray)
//   // const pubTopic = "dontek" + macAddr + "/cmd/psw";
//   // const subTopic = "dontek" + macAddr + "/status/psw";
//   // console.log(pubTopic, subTopic);
//   //var cognitoIdentityId = {};


//   )

  

  
  

